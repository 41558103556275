<template>
  <div class="text-left">

    <div class="row" v-if="databusiness == 1">
        <div class="col-md-4">
          <div class="form-group">
            <label>{{ str_ragione_sociale }}</label>
            <b-form-input
              :state="ragione_sociale_state"
              v-on:keyup="changeStateRagioneSociale"
              v-on:blur="changeStateRagioneSociale"
              type="text"
              class="form-control border-blue"
              v-model="ragione_sociale"             
              autocomplete="off"
              role="presentation"
            />
          </div>
        </div>
        <div class="col-md-4">
          <p>{{ str_disposizione_procura }}</p>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              value="1"
              v-model="disposizione_procura"
            />
            <label class="form-check-label">{{ str_si }}</label>
          </div>

          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              value="0"
              v-model="disposizione_procura"
            />
            <label class="form-check-label">{{ str_no }}</label>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group" v-if="disposizione_procura == 1">
            <label>{{ str_upload_procura }}</label>
            <b-form-file
              v-model="procura_url"
              :state="procura_url_state"              
              :drop-placeholder="str_upload_procura"
              v-on:input="changeStateProcuraUrl"
              :browse-text="str_browse_text"
              accept="image/jpeg, image/png, image/gif, application/pdf"
              :placeholder="str_nessun_file"
            ></b-form-file>

          </div>
        </div>

      </div>

      

    <div class="row">
      <div class="col-md-6">
        <div class="form-group" v-if="databusiness == 0">
            <label>{{ str_codice_fiscale }}</label>
            <b-form-input
              type="text"
              :state="codice_fiscale_state"
              v-on:keyup="changeStateCodiceFiscale"
              v-on:blur="changeStateCodiceFiscale"
              v-model="codice_fiscale"
              class="form-control border-blue"              
              autocomplete="off" 
              role="presentation"           
            />
          </div>

          <div class="form-group" v-if="databusiness == 1">
            <label>{{ str_partita_iva }}</label>
            <b-form-input
              type="text"
              :state="partita_iva_state"
              v-on:keyup="changeStatePartitaIVA"
              v-on:blur="changeStatePartitaIVA"
              v-model="partita_iva"
              class="form-control border-blue"              
              autocomplete="off"
              role="presentation"
            />
          </div>
      </div>

      

      <div class="col-md-6">
        <b-form-group :label="str_sesso" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group-2"            
            :aria-describedby="ariaDescribedby"
            name="radio-sub-component"
            v-model="gender" :state="gender_state"
            v-on:change="changeStateGender"
          >
            <b-form-radio value="M" :state="gender_state">{{ str_maschio }}</b-form-radio>
            <b-form-radio value="F" :state="gender_state">{{ str_femmina }}</b-form-radio>            
            <b-form-radio value="X" :state="gender_state">{{ str_no_specificato }}</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

      </div>

    </div>  

    <div class="row" v-if="databusiness == 1">
        <div class="col-md-6">
          <label>{{ str_fatt_elettronica }}</label>
          <input
            type="text"
            class="form-control border-blue"
            v-model="codice_fatturazione_elettronica"            
            autocomplete="off"
            role="presentation"
          />
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>{{ str_cod_fisc_div_pi }}</label>
           
            <b-form-input
              type="text"
              :state="codice_fiscale_state"
              v-on:keyup="changeStateCodiceFiscale"
              v-on:blur="changeStateCodiceFiscale"
              v-model="codice_fiscale"
              class="form-control border-blue"              
              autocomplete="off"      
              role="presentation"       
            />

          </div>
        </div>
      </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
            <label>{{ str_indirizzo }}</label>
            <b-form-input
              type="text"
              :state="indirizzo_state"
              v-on:keyup="changeStateIndirizzo"
              v-on:blur="changeStateIndirizzo"
              v-model="indirizzo"
              class="form-control border-blue"
              :placeholder="str_placeholder_indirizzo"
              autocomplete="off"
              role="presentation"
            />
          </div>
      </div>
      <div class="col-md-4">
         <div class="form-group">
            <label>{{ str_cap }}</label>
            <b-form-input
              :state="cap_state"
              v-on:keyup="changeStateCAP"
              v-on:blur="changeStateCAP"
              type="text"
              v-model="cap"
              class="form-control border-blue"             
              autocomplete="off"
              role="presentation"
            />
          </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
            <label>{{ str_comune }}</label>
            <b-form-input
              :state="comune_state"
              v-on:keyup="changeStateComune"
              v-on:blur="changeStateComune"
              type="text"
              v-model="comune"
              class="form-control border-blue"              
              autocomplete="off"
              role="presentation"
            />
          </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
            <label>{{ str_paese }}</label>
            <b-form-select v-model="country_id" class="border-blue" :state="country_state" v-on:change="changeStateCountry">
              <option value="">{{ str_paese }}</option>
              <option
                :value="naz.id"
                v-for="(naz, indice) in paesi"
                :key="indice"
                >{{ naz.name }}</option
              >
            </b-form-select>
          </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
            <label>{{ str_provincia }}</label>
            <b-form-select
              :state="provincia_sigla_state"
              v-on:change="changeStateProvinciaSigla"
              v-model="provincia_sigla"
              class="border-blue"
              v-if="country_id == 151 || country_id == 144"
            >
              <option value="">{{ str_provincia }}</option>
              <option
                :value="prov.codice"
                v-for="(prov, indice) in dataprovince"
                :key="indice"
                >{{ prov.nome }}</option
              >
            </b-form-select>


            <input
                    v-if="country_id != 151 && country_id != 144"
              type="text"
              class="form-control border-blue"
              v-model="provincia_estera"

            />
          </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <label>{{ str_prefisso_paese }} <span class="text-danger">*</span></label>
        <b-form-select class="border-blue" v-model="prefix_tel" :options="prefixes"></b-form-select>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ str_telefono }} ({{ str_senza_spazi}})</label>
          <!--<b-form-input v-model="$v.telefono.$model" placeholder="12345678" :state="telefono_state" @keyup="changeTelefonoState" @blur="changeTelefonoState"></b-form-input>-->
          <b-form-input v-model="telefono" placeholder="12345678" :state="telefono_state" @keyup="changeTelefonoState" @blur="changeTelefonoState"></b-form-input>
          <!--<input
            type="tel"
            class="form-control"
            v-model="$v.telefono.$model"
            :class="status($v.telefono)" placeholder="12345678"
          />-->
          <!--
          <div class="error" v-if="!$v.telefono.required && bloccato == true">
            {{ str_campo_obbligatorio }}
          </div>
          <div class="error" v-if="!$v.telefono.validFixedPhone && bloccato == true">
            {{ str_formato_telefono_non_valido }}
          </div>
          -->
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ str_tipo_documento }}</label>

            <b-form-select v-model="tipo_documento_riconoscimento" @change="changeStateTipoDocumentoRiconoscimento" :state="tipo_documento_riconoscimento_state" :options="document_options" />
              

          <!--
          <select
            v-model="$v.tipo_documento_riconoscimento.$model"
            class="form-control"
            :class="status($v.tipo_documento_riconoscimento)"
          >
            <option value=""></option>
            <option value="Carta di identità">{{ str_ci }}</option>
            <option value="Passaporto">{{ str_passaporto }}</option>
            <option value="Patente">{{ str_patente }}</option>
          </select>
          <div
            class="error"
            v-if="
              !$v.tipo_documento_riconoscimento.required && bloccato == true
            "
          >
            {{ str_campo_obbligatorio }}
          </div>
          -->
        </div>
      </div>
    </div>

    <div class="row">
      
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ str_numero_documento }}</label>
          
          <!--<b-form-input v-model="$v.numero_documento.$model" :state="numero_documento_state" @keyup="changeNumeroDocumentoState" @blur="changeNumeroDocumentoState"></b-form-input>-->
          <b-form-input v-model="numero_documento" :state="numero_documento_state" @keyup="changeNumeroDocumentoState" @blur="changeNumeroDocumentoState"></b-form-input>
          <!--<input
            type="text"
            class="form-control"
            v-model="$v.numero_documento.$model"
            :class="status($v.numero_documento)"
          />-->
         <!-- <div
            class="error"
            v-if="!$v.numero_documento.required && bloccato == true"
          >
            {{ str_campo_obbligatorio }}
          </div> -->
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label>{{ str_carica_documento }}</label><br>
          <!--
          <b-form-file
              v-model="documento_url"
              :state="documen"              
              :drop-placeholder="str_upload_procura"
              v-on:input="handleFileUpload"
              :browse-text="str_browse_text"
              accept="image/jpeg, image/png, image/gif, application/pdf"
              ref="upload_documento"
            ></b-form-file>
            -->

            <b-form-file
              v-model="documento_url"
              :state="documento_url_state"                            
              v-on:input="changeStateDocumentoUrl"
              :browse-text="str_browse_text"
              accept="image/jpeg, image/png, image/gif, application/pdf"
              :placeholder="str_nessun_file"
            ></b-form-file>
          <!--
          <input
            type="file"
            name="documento"
            style="color: #000"
            ref="upload_documento"
            v-on:change="handleFileUpload"
          />
          -->
          
          <!--<div v-if="documento_url">
            <span class="bg-secondary  text-white" v-if="documento_url">{{
              documento_url.name
            }}</span>
          </div>-->

         <!-- <div class="error" v-if="errore_documento_url && bloccato == true">
            {{ str_campo_obbligatorio }}
          </div> -->
        </div>
      </div>

    </div>

    <div class="row">
      
      <div class="col-md-6">
        {{ vedi_visura }}
        <div class="form-group" v-if="business == 1">
          <label>{{ str_upload_visura }}</label>

          <b-form-file
              v-model="visura_camerale_url"
              :state="visura_camerale_url_state"                            
              v-on:input="changeStateVisuraCameraleUrl"
              :browse-text="str_browse_text"
              accept="image/jpeg, image/png, image/gif, application/pdf"
              :placeholder="str_nessun_file"
            ></b-form-file>

        <!--  <input
            type="file"
            name="upload_vc"
            ref="upload_vc"
            style="color: #000"
            v-on:change="handleFileUploadVC"
          /> -->

        

         <!-- <div class="error" v-if="errore_vc && bloccato == true">
            {{ str_campo_obbligatorio }}
          </div>-->
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label>{{ str_data_scadenza_documento }}</label>
<!--
          <b-form-datepicker                
              :state="data_scadenza_ci_state"         
                v-on:shown="changeStateDataScadenzaCI"
                v-on:hidden="changeStateDataScadenzaCI"
                v-model="data_scadenza_documento" 
                class="form-control border-blue" 
                no-flip
                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"          
                :date-disabled-fn="getMaxDateScadenzaCI" 
                show-decade-nav 
                :initial-date="getInitialDateCI"    
                :placeholder="str_data_scadenza_documento"            
                hide-header></b-form-datepicker>
-->
<!--
          <b-form-datepicker 
               v-if="isIOSDevice"
              :state="data_scadenza_ci_state"         
                v-on:shown="changeStateDataScadenzaCI"
                v-on:hidden="changeStateDataScadenzaCI"
                v-model="data_scadenza_documento" 
                class="form-control border-blue" 
                no-flip
                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"          
                :date-disabled-fn="getMaxDateScadenzaCI" 
                show-decade-nav 
                :initial-date="getInitialDateCI"    
                :placeholder="str_data_scadenza_documento"            
                hide-header></b-form-datepicker>

          <b-form-input v-if="!isIOSDevice" 
        :type="datepicker_type" v-model="data_scadenza_documento" :placeholder="str_data_scadenza_documento"
         :min="getStartCalendar" 
         @input="changeStateDataScadenzaCI" 
          class="form-control border-blue" :state="data_scadenza_ci_state" @focus="setAsDate"></b-form-input> 
          -->

          <b-form-input 
        type="date" v-model="data_scadenza_documento" :placeholder="str_data_scadenza_documento"
         :min="getStartCalendar" 
         @input="changeStateDataScadenzaCI" 
          class="form-control border-blue" :state="data_scadenza_ci_state" @focus="setAsDate"></b-form-input> 

              
        </div>
      </div>

    </div>

    <div class="error p-2" v-if="bloccato">{{ str_required_fields_form_missing }}</div>

    <div class="row">
      <div class="col text-center">
        <button class="btn btn-primary" v-on:click="inviaModulo" :disabled="disableButton">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-person-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
            />
          </svg>
           {{ str_completa_profilo }}
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>

.dirty {
    border-color: #5a5;
    /*background: #efe;*/
    background: #fff;
  }
  
  .dirty:focus {
    outline-color: #8e8;
  }
  
  .error {
    border:1px solid red;
    background: #fdd;
    padding: 5px;
    
  }
  
  .error:focus {
    outline-color: #f99;
  }
  

</style>
<script>
import Vue from "vue";



const default_layout = "default";

import axios from "axios";
import Datepicker from "vuejs-datepicker";
import { en, it } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import { required, requiredIf, helpers } from "vuelidate/lib/validators";
const validFixedPhone = helpers.regex("validFixedPhone", /^[\+]?\d{5,16}$/);

import { BootstrapVue, IconsPlugin, BFormInput, BFormCheckbox,
 BFormFile, BFormDatepicker, BFormSelect, BFormGroup,
  BFormRadio, BFormRadioGroup, BFormSelectOption } from 'bootstrap-vue'

export default {
  components: {
    Datepicker,
    BFormSelect,
    BFormInput,
    BFormDatepicker,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BFormFile,
    BFormSelectOption
  },
  mounted: function() {
   window.addEventListener("keyup", (ev) => {
        if (ev.key == 'Enter') {
          this.inviaModulo();
        }
      })
  },
  computed: {
    getInitialDateCI: function() {
      return moment().toDate();
    },

    getStartCalendar: function() {     
      return moment().format('YYYY-MM-DD');
    },

    isIOSDevice: function(){
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },

    disableButton: function() {
      return this.disabled_submit;
    },
  },
  props: {
    customer: null,
    business: null,
    icon_url: String,
    prefixes_param: String,
    countries_param: String,
    province_param: String,
  },
  data() {
    return {
      document_options: [
        {value: translate("fe_vue_profilo_cliente.Carta di identità"),text: translate("fe_vue_profilo_cliente.Carta di identità")},
        {value: translate("fe_vue_profilo_cliente.Passaporto"),text: translate("fe_vue_profilo_cliente.Passaporto")},
        {value: translate("fe_vue_profilo_cliente.Patente"),text: translate("fe_vue_profilo_cliente.Patente")}
      ],

      paesi: JSON.parse(this.countries_param),
      dataprovince: JSON.parse(this.province_param),
      vedi_visura: this.customer.privato_business,
      /* Strings */
      str_campo_obbligatorio: translate("fe_vue_profilo_cliente.Campo obbligatorio"),
      str_formato_telefono_non_valido: translate("fe_vue_profilo_cliente.Formato telefono non valido"),
      str_telefono:  translate("fe_vue_profilo_cliente.Telefono"),
      str_tipo_documento:  translate("fe_vue_profilo_cliente.Tipo documento di riconoscimento"),
      str_ci:  translate("fe_vue_profilo_cliente.Carta di identità"),
      str_passaporto:  translate("fe_vue_profilo_cliente.Passaporto"),
      str_patente:  translate("fe_vue_profilo_cliente.Patente"),
      str_carica_documento:  translate("fe_vue_profilo_cliente.Carica documento"),
      str_numero_documento:  translate("fe_vue_profilo_cliente.Numero documento"),
      str_data_scadenza_documento:  translate("fe_vue_profilo_cliente.Data scadenza documento"),
      str_upload_visura:  translate("fe_vue_profilo_cliente.Upload visura camerale"),
      str_completa_profilo:  translate("fe_vue_profilo_cliente.Completa il tuo profilo"),
      str_senza_spazi: translate("fe_vue_profilo_cliente.Senza spazi"),

      str_codice_fiscale: translate("fe_vue_profilo_cliente.Codice fiscale") + '*',

      str_sesso:  translate("fe_vue_profilo_cliente.Sesso"),
      str_maschio:  translate("fe_vue_profilo_cliente.Maschio"),
      str_femmina:  translate("fe_vue_profilo_cliente.Femmina"),
      str_no_specificato: translate("fe_vue_profilo_cliente.Preferisco non specificare"),
      str_indirizzo: translate("fe_vue_profilo_cliente.Indirizzo") + '*',
      str_placeholder_indirizzo: translate("fe_vue_profilo_cliente.Via Roma 10"), 
      str_cap: translate("fe_vue_profilo_cliente.CAP") + '*',
      str_comune: translate("fe_vue_profilo_cliente.Comune") + '*',
      str_paese: translate("fe_vue_profilo_cliente.Paese") + '*',
      str_provincia: translate("fe_vue_profilo_cliente.Provincia") + '*',
      str_ragione_sociale: translate("fe_vue_profilo_cliente.Ragione sociale") + '*',
      str_disposizione_procura: translate("fe_vue_profilo_cliente.Disponi di una procura"),   
      str_si: translate("fe_vue_profilo_cliente.Sì"),
      str_no: translate("fe_vue_profilo_cliente.No"),
      str_upload_procura: translate("fe_vue_profilo_cliente.Upload procura") + '*',
      str_browse_text: translate("fe_vue_profilo_cliente.Sfoglia"),
      str_fatt_elettronica: translate(
        "fe_vue_profilo_cliente.Codice destinatario fatturazione elettronica"
      ),
      str_cod_fisc_div_pi: translate(
        "fe_vue_profilo_cliente.Codice Fiscale se diverso da partita IVA"
      ),
      str_prefisso_paese: translate("fe_vue_profilo_cliente.str_prefisso_paese"),
      str_required_fields_form_missing: translate("fe_vue_profilo_cliente.str_required_fields_form_missing"),
      str_partita_iva: translate("fe_vue_profilo_cliente.Partita IVA") + '*',
      str_nessun_file: translate("fe_vue_profilo_cliente.Nessun file"),



      bloccato: false,
      it: it,
      en: en,
      disabledDates: {
        to: moment().toDate(),
      },
      datacustomer: this.customer,
      cliente: JSON.parse(this.customer),
      prefixes: JSON.parse(this.prefixes_param),
      tipo_documento_riconoscimento: "",
      telefono: "",
      /*documento_url: "",*/
      documento_url: [],
      numero_documento: "",
      data_scadenza_documento: "",
      /*visura_camerale_url: "",*/
      visura_camerale_url: [],
      prefix_tel: 353,
      codice_fiscale: "",
      gender: '',
      indirizzo: "",
      cap: "",
      comune: "",
      country_id: 144,
      provincia_sigla: "",
      provincia_estera: "",
      disposizione_procura: 0,
      ragione_sociale: "",
      procura_url: [],
      codice_fatturazione_elettronica: "",
      partita_iva: '',

      errore_documento_url: false,
      errore_vc: false,

      smiley_icon_url: this.icon_url,

      telefono_state: null,      
      numero_documento_state: null,
      data_scadenza_ci_state: null,

      databusiness: this.business,

      codice_fiscale_state: null,
      gender_state: null,
      indirizzo_state: null,
      cap_state: null,
      comune_state: null,
      country_state: null,
      provincia_sigla_state: null,
      ragione_sociale_state: null,
      procura_url_state: null,
      tipo_documento_riconoscimento_state: null,
      partita_iva_state: null,
      documento_url_state: null,
      visura_camerale_url_state: null,


      datepicker_type: 'text',

      disabled_submit: false,
      
    };
  },
  methods: {
    handleFileUpload: function() {
      this.documento_url = this.$refs.upload_documento.files[0];
      this.errore_documento_url = false;
    },
    cancella_data: function() {
      this.data_scadenza_documento = "";
    },
    handleFileUploadVC: function() {
      this.visura_camerale_url = this.$refs.upload_vc.files[0];
      this.errore_vc = false;
    },

    openCalendar: function() {
      this.$refs.data_scadenza_documento.showCalendar();
    },

    inviaModulo: function() {
      if (this.checkRequiredData() == 1) {
        
        return;
      }

      
      /*
      if (this.$v.$invalid) {
        this.bloccato = true;
        return false;
      }
      if (this.documento_url == "") {
        
        this.errore_documento_url = true;
        this.bloccato = true;
        return false;
      }
      */

     /*
      if (
        this.visura_camerale_url == "" &&
        this.cliente.privato_business == 1
      ) {
        this.errore_vc = true;
        this.bloccato = true;
        return false;
      }
      */
      
      this.disabled_submit = true;
      
      let formData = new FormData();
      formData.append("prefix_tel", this.prefix_tel);
      formData.append("telefono", this.telefono);
      formData.append(
        "tipo_documento_riconoscimento",
        this.tipo_documento_riconoscimento
      );
      formData.append("documento_url", this.documento_url);
      formData.append("numero_documento", this.numero_documento);
      let data_scadenza_documento = moment(this.data_scadenza_documento).format(
        "YYYY-MM-DD"
      );

      formData.append("data_scadenza_documento", data_scadenza_documento);
      if (this.visura_camerale_url != "") {
        formData.append("visura_camerale_url", this.visura_camerale_url);
      }
      // Nuovi da integrare      
      formData.append("ragione_sociale", this.ragione_sociale);
      formData.append("disposizione_procura", this.disposizione_procura);
      if (this.procura_url != "") {
        formData.append("procura_url", this.procura_url);
      }
      if (this.codice_fatturazione_elettronica != "") {
        formData.append(
          "codice_fatturazione_elettronica",
          this.codice_fatturazione_elettronica
        );
      }
      formData.append("codice_fiscale", this.codice_fiscale);
      if (this.partita_iva != "") {
        formData.append("partita_iva", this.partita_iva);
      }
      formData.append("gender", this.gender);
      formData.append("indirizzo", this.indirizzo);
      formData.append("cap", this.cap);
      formData.append("comune", this.comune);
      formData.append("country_id", this.country_id);
      if (this.provincia_sigla != "") {
        formData.append("provincia_sigla", this.provincia_sigla);
      }
      if (this.provincia_estera != "") {
        formData.append("provincia_estera", this.provincia_estera);
      }
      


      

      axios
        .post("/customer/step2", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("siamo qua 780")
          this.$swal({
            /*iconHtml: '<img src="' + this.smiley_icon_url + '" width="80" height="80">',*/
            icon: 'success',
            confirmButtonColor: '#001f5c',
            text: translate("fe_vue_profilo_cliente.Grazie per avere completato i tuoi dati"),

            confirmButtonText: `OK`,
          }).then((result) => {
            if (result.isConfirmed) {
              location.href = "/customer";
              //location.href = "/search/fine_acquisto";
            }
          });
        })
        .catch((e) => {
          console.log(e);
          //this.errori_validazione.record(e.response.data);
        });
    },

    changeTelefonoState: function() {
      const regex = /^[\+]?\d{5,16}$/
      if (!regex.test(this.telefono)) {
        this.telefono_state = false;
      } else {
        this.telefono_state = true;
      }
    },

    changeNumeroDocumentoState: function() {
      if (this.numero_documento == '') {
        this.numero_documento_state = false;
      } else {
        this.numero_documento_state = true;
      }
    },

    changeStateDataScadenzaCI: function() {
      const deadline_date = moment(this.data_scadenza_documento).toDate()
      const today = moment().toDate();
      if (this.data_scadenza_documento == '') {
        this.data_scadenza_ci_state = false;
      } else if (deadline_date < today) {
        this.data_scadenza_ci_state = false;
      } else {
        this.data_scadenza_ci_state = true;
      }
    },

    changeStateCodiceFiscale: function() {
      if (this.country_id != 151) {
        this.codice_fiscale_state = true;
        return;
      }
      const regex = new RegExp(
        /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/
      );
      if (this.databusiness == 0 && !regex.test(this.codice_fiscale)) {              
        this.codice_fiscale_state = false;
      } else if (this.databusiness == 1 && this.codice_fiscale != '' && !regex.test(this.codice_fiscale)) {        
        this.codice_fiscale_state = false;
      } else {        
        this.codice_fiscale_state = true;
      }      
    },

    changeStateGender: function() {      
      if (this.gender == '') {
        this.gender_state = false;
      } else {        
        this.gender_state = true;
      }
    },

     changeStateIndirizzo: function() {
      if (this.indirizzo == "") {        
        this.indirizzo_state = false;
      } else {
        this.indirizzo_state = true;
      }      
    },

    changeStateCAP: function() {
      if (this.cap == "") {        
        this.cap_state = false;
      } else {
        this.cap_state = true;
      }      
    },

    changeStateComune: function() {
      if (this.comune == "") {        
        this.comune_state = false;
      } else {
        this.comune_state = true;
        if (this.provincia_sigla == '') {
          this.provincia_sigla_state = false;
        }        
      }      
    },

    changeStateCountry: function() {
      if (this.country_id == "") {        
        this.country_state = false;
      } else {
        this.changeStatePartitaIVA();
        this.changeStateCodiceFiscale();        
        this.country_state = true;
      }      
    },

    changeStateProvinciaSigla: function() {
      if ((this.country_id == 151 || this.country_id == 144)  && this.provincia_sigla == "") {
        this.provincia_sigla_state = false;
      } else {
        this.provincia_sigla_state = true;
      }      
    },

    changeStatePartitaIVA: function() {
      if (this.databusiness == 1 && this.partita_iva == "") {   
        
        this.partita_iva_state = false;       


      } else {

        if (this.databusiness == 1) {
          if (this.country_id == 151) {
            var reg = /^\d+$/;
            if (this.partita_iva.length == 11) {
              this.partita_iva_state = true;
            } 
            if (reg.test(this.partita_iva)) {
              //this.errore_non_numerica_pi = false;
              this.partita_iva_state = true;
            }

            if (this.partita_iva.length != 11 || !reg.test(this.partita_iva)) {
              this.partita_iva_state = false;
            } else {          
              this.partita_iva_state = true;
            }
          } else {
            this.partita_iva_state = true;          
          }
        } else {
          this.partita_iva_state = true;          
        }
        
        
      }      
    },

    changeStateRagioneSociale: function() {
      if (this.databusiness == 1 && this.ragione_sociale == "") {        
        this.ragione_sociale_state = false;
      } else {
        this.ragione_sociale_state = true;
      }      
    },

    changeStateProcuraUrl: function() {      
      if (
        this.databusiness == 1 &&
        this.disposizione_procura == 1 &&
        (!this.procura_url || this.procura_url.length == 0)
      ) {
      
        this.procura_url_state = false;
      } else {
        
        this.procura_url_state = true;
      }      
    },

    changeStateDocumentoUrl: function() {      
      if (!this.documento_url || this.documento_url.length == 0) {
      
        this.documento_url_state = false;
      } else {
        
        this.documento_url_state = true;
      }      
    },

    changeStateVisuraCameraleUrl: function() {      
      if (this.databusiness == 1 && (!this.visura_camerale_url || this.visura_camerale_url.length == 0)) {
      
        this.visura_camerale_url_state = false;
      } else {
        
        this.visura_camerale_url_state = true;
      }      
    },
   

    changeStateTipoDocumentoRiconoscimento: function() {    
      
      if (this.tipo_documento_riconoscimento == '') {        
        this.tipo_documento_riconoscimento_state = false;
      } else {        
        this.tipo_documento_riconoscimento_state = true;
      }
    },

     getMaxDateScadenzaCI: function(ymd, date) {      
      return date < moment().toDate();
    },


    checkRequiredData: function() {
      let error = 0;
      this.changeStateCodiceFiscale();      
      this.changeStateGender();      
      this.changeStateRagioneSociale();      
      this.changeStateProcuraUrl();
      this.changeStatePartitaIVA();
      this.changeStateIndirizzo();
      this.changeStateCAP();
      this.changeStateComune();
      this.changeStateCountry();
      this.changeStateProvinciaSigla();
      this.changeTelefonoState();
      this.changeStateTipoDocumentoRiconoscimento();
      this.changeNumeroDocumentoState();
      this.changeStateDataScadenzaCI();
      this.changeStateDocumentoUrl();
      this.changeStateVisuraCameraleUrl();



      if (this.documento_url == '') {
        
        this.errore_documento_url = true;
        this.bloccato = true;
        error = 1;
      }

      if (
        this.visura_camerale_url == "" &&
        this.cliente.privato_business == 1
      ) {
        this.errore_vc = true;
        this.bloccato = true;
        error = 1;
      }

      
      if (this.codice_fiscale_state == false || this.gender_state == false
       || this.ragione_sociale_state == false || this.procura_url_state == false 
       || this.partita_iva_state == false || this.indirizzo_state == false 
       || this.cap_state == false || this.comune_state == false 
       || this.country_state == false || this.provincia_sigla_state == false 
       || this.telefono_state == false || this.tipo_documento_riconoscimento_state == false 
       || this.numero_documento_state == false || this.documento_url_state == false 
       || this.data_scadenza_ci_state == false || this.visura_camerale_url_state == false) {
        error = 1;
       }
       
      return error;
    },

    setAsDate: function() {
      this.datepicker_type = 'date';
    },


    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },

  validations: {
    telefono: {
      required,
      validFixedPhone,
    },

    tipo_documento_riconoscimento: {
      required,
    },

    numero_documento: {
      required,
    },

    data_scadenza_documento: {
      required,
    },
  },
};
</script>
