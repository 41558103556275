<template>
  <div>
    <step-1
      v-if="passo1"
      :passo1_param="passo1"
      :bloccato_param="bloccato"
      :prefixes_param="prefixes"
      :prefix_cell_param="prefix_cell"
      :comunicazioni_commerciali_param="comunicazioni_commerciali"
      :name_param="name"
      :last_name_param="last_name"
      :email_param="email"
      :cellulare_param="cellulare"
      :data_scadenza_ci_param="data_scadenza_ci"
      :ci_fronte_url_param="ci_fronte_url"
      :cf_url_param="cf_url"
      :privacy_param="privacy"
      :fidavo_contract_param="fidavo_contract"
      :password_param="password"
      :conferma_password_param="conferma_password"
      :passo2_param="passo2"
      v-on:vaiapasso2="loadModelStep2" :locale_param="locale"
    ></step-1>

    <!-- Passo 2 -->
    <step-2
      v-if="passo2"
      :anno_apertura_param="anno_apertura"
      :cap_param="cap"
      :comune_param="comune"
      :comunicazione_prosecuzione_attivita_param="
        comunicazione_prosecuzione_attivita
      "
      :country_id_param="country_id"
      :data_emissione_licenza_prefettizia_param="
        data_emissione_licenza_prefettizia
      "
      :data_scadenza_licenza_prefettizia_param="
        data_scadenza_licenza_prefettizia
      "
      :dataprovince_param="dataprovince"
      :holder_address_param="holder_address"
      :holder_birthplace_param="holder_birthplace"
      :holder_city_param="holder_city"
      :holder_country_id_param="holder_country_id"
      :holder_county_param="holder_county"
      :holder_date_of_birth_param="holder_date_of_birth"
      :holder_foreign_county_param="holder_foreign_county"
      :holder_zipcode_param="holder_zipcode"
      :indirizzo_sede_principale_param="indirizzo_sede_principale"
      :latitude_param="latitude"
      :licenza_prefettizia_url_param="licenza_prefettizia_url"
      :longitude_param="longitude"
      :numero_licenza_param="numero_licenza"
      :paesi_param="paesi"
      :partita_iva_param="partita_iva"
      :pec_param="pec"
      :preccint_id_param="preccint_id"
      :prefecture_id_param="prefecture_id"
      :prefetture_param="prefetture"
      :prefix_tel_param="prefix_tel"
      :prefixes_param="prefixes"
      :provincia_estera_param="provincia_estera"
      :provincia_sigla_param="provincia_sigla"
      :questure_param="questure"
      :ragione_sociale_param="ragione_sociale"
      :telefono_param="telefono"
      :visura_camerale_url_param="visura_camerale_url"
      :years_param="years"
      :show_google_place_holder_param="show_google_place_holder"
      :show_google_place_param="show_google_place" 
      :mostra_comunicazione_prosecuzione_attivita_param="mostra_comunicazione_prosecuzione_attivita"
      v-on:vaiapasso3="loadModelStep3"
      v-on:tornaapasso1="tornaAPasso1" :locale_param="locale"
    ></step-2>

    <step-3
      v-if="passo3"
      :bloccato_param="bloccato"
      :data_tipi_indagine_param="data_tipi_indagine"
      :passo1_param="passo1"
      :passo2_param="passo2"
      :tariffario_param="tariffario"
      :tipi_indagine_da_inviare_param="tipi_indagine_da_inviare"
      v-on:vaiapasso4="loadModelStep4"
      v-on:tornaapasso2="tornaAPasso2"
    ></step-3>

    <step-4
      v-if="passo4"
      :bloccato_param="bloccato"
      :bonifica_ambientale_param="bonifica_ambientale"
      :c_9001_url_param="c_9001_url"
      :foto_param="foto"
      :iso_18000_url_param="iso_18000_url"
      :localizzazione_gps_param="localizzazione_gps"
      :logo_param="logo"
      :passo1_param="passo1"
      :passo2_param="passo2"
      :passo3_param="passo3"
      :presentazione_param="presentazione"
      :salvataggio_agenzia_in_corso_param="salvataggio_agenzia_in_corso"
      v-on:tornaapasso3="tornaAPasso3"
      v-on:preparasalvataggio="preparaSalvataggio"
    ></step-4>

    
  </div>
</template>
<script>
import axios from "axios";
import EventBus from "../new-search/EventBus";
import Datepicker from "vuejs-datepicker";
import { en, it } from "vuejs-datepicker/dist/locale";
import moment from "moment";

import Step1 from "./components/Step1.vue";
import Step2 from "./components/Step2.vue";
import Step3 from "./components/Step3.vue";
import Step4 from "./components/Step4.vue";

const default_layout = "default";

export default {
  components: {
    EventBus,
    Step1,
    Step2,
    Step3,
    Step4,
  },

  mounted() {
    setTimeout(() => {
      EventBus.$emit("set_register_url", this.register_url);
    }, 100);
  },

  computed: {
          
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1900 },
        (value, index) => 1901 + index
      );
    },

  
  },


  props: {
    nazioni: String,
    province: String,
    tipi_indagine: String,
    data_oggi: String,
    data_oggi_ts: String,
    prop_prefetture: String,
    prop_questure: String,
    apikey: String,
    privacy_url_param: String,
    register_url_param: String,
    prefixes_param: String,
    locale_param: String,
  },

  data() {
    return {  
    
      paesi: JSON.parse(this.nazioni),
      dataprovince: JSON.parse(this.province),
      data_tipi_indagine: JSON.parse(this.tipi_indagine),
      prefetture: JSON.parse(this.prop_prefetture),
      questure: JSON.parse(this.prop_questure),
      api_key: this.apikey,
      privacy_url: this.privacy_url_param,
      register_url: this.register_url_param,
      prefixes: JSON.parse(this.prefixes_param),
      
      mostra_comunicazione_prosecuzione_attivita: false,

      it: it,
      en: en,
      disabledDates: {        
        to: new Date(Date.now()),
      },
      disabledDatesLicenza: {
        from: new Date(Date.now()),
      },
      disabledBirthDate: {
        from: moment()
          .subtract(18, "years")
          .toDate(),
      },
      openDate: moment()
        .subtract(18, "years")
        .toDate(),
      locale: this.locale_param,
           
      bloccato: false,
      
      errori_validazione: new Errors(),
     

      passo1: true,
      passo2: false,
      passo3: false,
      passo4: false,

      name: "",
      last_name: "",
      email: "",
      prefix_cell: 353,
      cellulare: "",
      data_scadenza_ci: "",
      ci_fronte_url: "",
      cf_url: "",
      privacy: 0,
      fidavo_contract: 0,
      comunicazioni_commerciali: 0,
      password: "",
      conferma_password: "",

      ragione_sociale: "",
      partita_iva: "",

      pec: "",
      prefix_tel: 353,
      telefono: "",

      indirizzo_sede_principale: "",
      cap: "",
      comune: "",
      country_id: 144,
      provincia_sigla: "",
      provincia_estera: "",

      numero_licenza: "",
      anno_apertura: "",
      visura_camerale_url: "",
      data_emissione_licenza_prefettizia: "",
      data_scadenza_licenza_prefettizia: "",
      licenza_prefettizia_url: "",
      comunicazione_prosecuzione_attivita: "",

      holder_birthplace: "",
      holder_date_of_birth: "",
      holder_address: "",
      holder_city: "",
      holder_zipcode: "",
      holder_country_id: 144,
      holder_foreign_county: "",
      holder_county: "",
      prefecture_id: "",
      preccint_id: "",

      tariffario: "",
      tipi_indagine_da_inviare: [],
      presentazione: "",
      logo: "",
      c_9001_url: "",
      iso_18000_url: "",
      localizzazione_gps: 0,
      bonifica_ambientale: 0,
      foto: [],

      isAddressLoading: false,
      geoaddress: [],
      coordsArray: [],
      latitude: 0,
      longitude: 0,
      provincias: [],
      caps: [],
      comunes: [],
      addresses: [],
  

      salvataggio_agenzia_in_corso: false,

    
      show_google_place: true,
      show_google_place_holder: true,
    };
  },

  methods: {
       
    tornaAPasso1: function() {
      this.passo2 = false;
      this.passo1 = true;
      this.password = "";
      this.conferma_password = "";
    },    

    tornaAPasso2: function() {
      this.passo3 = false;
      this.passo2 = true;
      this.show_google_place = false;
      this.show_google_place_holder = false;
    },   

    tornaAPasso3: function() {
      this.passo4 = false;
      this.passo3 = true;
    },

    // Salva l'agenzia nel database
    salvaAgenzia: function() {
     
      this.salvataggio_agenzia_in_corso = true;

      let formData = new FormData();

      formData.append("name", this.name);
      formData.append("last_name", this.last_name);
      formData.append("email", this.email);
      formData.append("prefix_cell", this.prefix_cell);
      formData.append("cellulare", this.cellulare);
      formData.append(
        "data_scadenza_ci",
        moment(this.data_scadenza_ci).format("YYYY-MM-DD")
      ); // Formattare
      formData.append("ci_fronte_url", this.ci_fronte_url);
      formData.append("cf_url", this.cf_url);
      formData.append("privacy", this.privacy ? 1 : 0);
      formData.append("fidavo_contract", this.fidavo_contract ? 1 : 0);
      formData.append(
        "comunicazioni_commerciali",
        this.comunicazioni_commerciali ? 1 : 0
      );
      formData.append("password", this.password);
      formData.append("password_confirmation", this.conferma_password);
      formData.append("ragione_sociale", this.ragione_sociale);
      formData.append("partita_iva", this.partita_iva);
      formData.append("pec", this.pec);
      formData.append("prefix_tel", this.prefix_tel);
      formData.append("telefono_sede_principale", this.telefono);
      formData.append(
        "indirizzo_sede_principale",
        this.indirizzo_sede_principale
      );
      formData.append("cap", this.cap);
      formData.append("comune", this.comune);
      formData.append("country_id", this.country_id);
      formData.append("provincia_sigla", this.provincia_sigla);
      if (this.provincia_estera != "") {
        formData.append("provincia_estera", this.provincia_estera);
      }
      formData.append("numero_licenza", this.numero_licenza);
      formData.append("anno_apertura", this.anno_apertura);
      formData.append("visura_camerale_url", this.visura_camerale_url);
      formData.append(
        "data_emissione_licenza_prefettizia",
        moment(this.data_emissione_licenza_prefettizia).format("YYYY-MM-DD")
      ); // Formattare
      formData.append(
        "data_scadenza_licenza_prefettizia",
        moment(this.data_scadenza_licenza_prefettizia).format("YYYY-MM-DD")
      ); // Formattare
      formData.append("licenza_prefettizia_url", this.licenza_prefettizia_url);
      if (this.comunicazione_prosecuzione_attivita != "") {
        formData.append(
          "comunicazione_prosecuzione_attivita",
          this.comunicazione_prosecuzione_attivita
        );
      }

      formData.append("tariffario_url", this.tariffario);
      formData.append(
        "tipo_indagine",
        JSON.stringify(this.tipi_indagine_da_inviare)
      );
      formData.append("presentazione", this.presentazione);
      if (this.logo != "") {
        formData.append("logo", this.logo);
      }
      if (this.c_9001_url != "") {
        formData.append("c_9001_url", this.c_9001_url);
      }
      if (this.iso_18000_url != "") {
        formData.append("iso_18000_url", this.iso_18000_url);
      }
      formData.append("localizzazione_gps", this.localizzazione_gps ? 1 : 0);
      formData.append("bonifica_ambientale", this.bonifica_ambientale ? 1 : 0);

      let n = 0;
      for (let i = 1; i <= this.foto.length; i++) {
        formData.append("foto" + i, this.foto[n]);
        n++;
      }

      formData.append("holder_birthplace", this.holder_birthplace);

      formData.append(
        "holder_date_of_birth",
        moment(this.holder_date_of_birth).format("YYYY-MM-DD")
      );
      formData.append("holder_address", this.holder_address);
      formData.append("holder_city", this.holder_city);
      formData.append("holder_zipcode", this.holder_zipcode);
      formData.append("holder_county", this.holder_county);
      formData.append("holder_foreign_county", this.holder_foreign_county);
      formData.append("holder_country_id", this.holder_country_id);

      formData.append("prefecture_id", this.prefecture_id);
      formData.append("preccint_id", this.preccint_id);
      formData.append("latitude", this.latitude);
      formData.append("longitude", this.longitude);

      axios
        .post("/agency/inserisci", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {

          if (res.data == 'OK') {
            this.salvataggio_agenzia_in_corso = false;
            this.$swal({
              icon: "success",
              text: translate("fe_vue_registrazione_agenzia.str_grazie_per_esserti_registrato"),
              confirmButtonColor: "#001f5c",
              confirmButtonText: `OK`,
            }).then((result) => {
              if (result.isConfirmed) {
                location.href = "/";
              }
            });
          } else {
            console.error(res.data);
          }


        })
        .catch((e) => {
          console.log(e);
          this.errori_validazione.record(e.response.data);
        });
    },      
    
    // Applica i models provenienti dal componente figlio e apre lo step successivo
    loadModelStep2: function(
      passo1,
      passo2,
      prefix_cell,
      name,
      last_name,
      email,
      cellulare,
      data_scadenza_ci,
      ci_fronte_url,
      cf_url,
      privacy,
      fidavo_contract,
      comunicazioni_commerciali,
      password,
      conferma_password
    ) {
      this.passo1 = passo1;
      this.passo2 = passo2;

      this.prefix_cell = prefix_cell;
      this.name = name;
      this.last_name = last_name;
      this.email = email;
      this.cellulare = cellulare;
      this.data_scadenza_ci = data_scadenza_ci;
      this.ci_fronte_url = ci_fronte_url;
      this.cf_url = cf_url;
      this.privacy = privacy;
      this.fidavo_contract = fidavo_contract;
      this.comunicazioni_commerciali = comunicazioni_commerciali;
      this.password = password;
      this.conferma_password = conferma_password;
    },
    // Applica i models provenienti dal componente figlio e apre lo step successivo
    loadModelStep3: function(
      passo1,
      passo2,
      passo3,
      ragione_sociale,
      partita_iva,
      pec,
      prefix_tel,
      telefono,
      indirizzo_sede_principale,
      cap,
      country_id,
      provincia_sigla,
      provincia_estera,
      numero_licenza,
      anno_apertura,
      visura_camerale_url,
      data_emissione_licenza_prefettizia,
      data_scadenza_licenza_prefettizia,
      licenza_prefettizia_url,
      comunicazione_prosecuzione_attivita,
      holder_birthplace,
      holder_date_of_birth,
      holder_address,
      holder_city,
      holder_zipcode,
      holder_country_id,
      holder_county,
      holder_foreign_county,
      prefecture_id,
      preccint_id,
      latitude,
      longitude,
      comune,
      mostra_comunicazione_prosecuzione_attivita
    ) {
      this.passo1 = passo1;
      this.passo2 = passo2;
      this.passo3 = passo3;
      this.ragione_sociale = ragione_sociale;
      this.partita_iva = partita_iva;
      this.pec = pec;
      this.prefix_tel = prefix_tel;
      this.telefono = telefono;
      this.indirizzo_sede_principale = indirizzo_sede_principale;
      this.cap = cap;
      this.country_id = country_id;
      this.provincia_sigla = provincia_sigla;
      this.provincia_estera = provincia_estera;
      this.numero_licenza = numero_licenza;
      this.anno_apertura = anno_apertura;
      this.visura_camerale_url = visura_camerale_url;
      this.data_emissione_licenza_prefettizia = data_emissione_licenza_prefettizia;
      this.data_scadenza_licenza_prefettizia = data_scadenza_licenza_prefettizia;
      this.licenza_prefettizia_url = licenza_prefettizia_url;
      this.comunicazione_prosecuzione_attivita = comunicazione_prosecuzione_attivita;
      this.holder_birthplace = holder_birthplace;
      this.holder_date_of_birth = holder_date_of_birth;
      this.holder_address = holder_address;
      this.holder_city = holder_city;
      this.holder_zipcode = holder_zipcode;
      this.holder_country_id = holder_country_id;
      this.holder_county = holder_county;
      this.holder_foreign_county = holder_foreign_county;
      this.prefecture_id = prefecture_id;
      this.preccint_id = preccint_id;
      this.latitude = latitude;
      this.longitude = longitude;
      this.comune = comune;
      this.mostra_comunicazione_prosecuzione_attivita = mostra_comunicazione_prosecuzione_attivita;
    },
    // Applica i models provenienti dal componente figlio e apre lo step successivo
    loadModelStep4: function(tipi_indagine_da_inviare, tariffario) {
      this.tipi_indagine_da_inviare = tipi_indagine_da_inviare;
      this.tariffario = tariffario;
      this.passo1 = false;
      this.passo2 = false;
      this.passo3 = false;
      this.passo4 = true;
    },
    // Applica i models provenienti dal componente figlio e poi richiama la funzione che salva tutto
    preparaSalvataggio: function(
      presentazione,
      logo,
      c_9001_url,
      iso_18000_url,
      foto,
      localizzazione_gps,
      bonifica_ambientale
    ) {
      this.presentazione = presentazione;
      this.logo = logo;
      this.c_9001_url = c_9001_url;
      this.iso_18000_url = iso_18000_url;
      this.foto = foto;
      this.localizzazione_gps = localizzazione_gps;
      this.bonifica_ambientale = bonifica_ambientale;

      this.salvaAgenzia();
    },

    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },

  validations: {  

  },
};

// La seguente classe va lasciata
class Errors {
  constructor() {
    this.errors = {};
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  record(errors) {
    this.errors = errors;
  }
}
</script>
