<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-briefcase-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"
            />
            <path
              d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"
            />
          </svg>
          {{ str_dettagli_attivita_agenzia }}
        </h4>
      </div>
      <div class="card-body">
        <p><span class="text-danger">*</span> {{ str_scegliere_almeno_una }}</p>
        <div class="row mt-3">
          <div
            class="col-md-6"
            v-for="(tipo, indice) in data_tipi_indagine"
            :key="indice"
          >
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                :value="tipo.id"
                v-model="tipi_indagine_da_inviare"
              />
              <label class="form-check-label">
                {{ tipo.nome }}
              </label>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">
            <label
              >{{ str_tariffario }} <span class="text-danger">*</span></label
            >
            <input
              type="file"
              v-on:change="handleFileUploadTariffario()"
              ref="tariffario"
              accept="image/jpeg, image/gif, image/png, application/pdf"
              value=""
              style="color: #ffffff"
            />
            <div v-if="tariffario">
              <span class="" v-if="tariffario">{{ tariffario.name }}</span>
            </div>
            <div class="error" v-if="errore_tariffario && bloccato == true">
              {{ str_campo_obbligatorio }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 text-left">
            <button
              class="btn btn-secondary btn-yellow"
              v-on:click="tornaAPasso2"
            >
              {{ str_indietro }}
            </button>
          </div>
          <div class="col-md-6 text-right">
            <button
              class="btn btn-secondary"
              v-if="!verificaCompletiPasso3"
              disabled
            >
              {{ str_avanti }}
            </button>
            <button
              class="btn btn-secondary btn-yellow"
              v-on:click="vaiAPasso4()"
              v-if="verificaCompletiPasso3"
            >
              {{ str_avanti }}
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <span class="text-danger">*</span> {{ str_campi_obbligatori }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
input[type="file"] {
  color: #001f5c;
}
.form-check-label {
  font-size: 1rem;
}
button.btn-yellow {
  background-color: #ffa91f;
  color: #fff;
}

.dirty {
  border-color: #5a5;
  background: #efe;
}

.dirty:focus {
  outline-color: #8e8;
}

.error {
  border: 1px solid red;
  background: #fdd;
  padding: 5px;
}

.error:focus {
  outline-color: #f99;
}
</style>
<script>
import moment from "moment";
import axios from "axios";

import {
  required,
  email,
  sameAs,
  requiredIf,
  helpers,
  minLength,
  numeric,
} from "vuelidate/lib/validators";

const validPhone = helpers.regex("validPhone", /^[\+]?\d{5,16}$/);

import {
  BootstrapVue,
  IconsPlugin,
  BFormInput,
  BFormCheckbox,
  BFormFile,
  BFormDatepicker,
  BFormSelect,
} from "bootstrap-vue";
import PasswordAnalyzer from "../../includes/PasswordAnalyzer.vue";

const default_layout = "default";

export default {
  props: {
    passo1_param: Boolean,
    passo2_param: Boolean,
    bloccato_param: Boolean,

    data_tipi_indagine_param: Array,
    tipi_indagine_da_inviare_param: Array,
    tariffario_param: [String, File],
  },
  components: {
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    BFormSelect,
    PasswordAnalyzer,
  },
  computed: {
    verificaCompletiPasso3: function() {
      if (this.tariffario != "" && this.tipi_indagine_da_inviare.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      /* Props */
      passo1: this.passo1_param,
      passo2: this.passo2_param,
      bloccato: this.bloccato_param,

      data_tipi_indagine: this.data_tipi_indagine_param,

      /* Stringhe */
      str_dettagli_attivita_agenzia: translate("fe_vue_registrazione_agenzia.Dettagli attività agenzia"),
      str_scegliere_almeno_una: translate("fe_vue_registrazione_agenzia.Scegliere almeno una"),
      str_tariffario: translate("fe_vue_registrazione_agenzia.Tariffario"),
      str_campo_obbligatorio: translate("fe_vue_registrazione_agenzia.Campo obbligatorio"),
      str_avanti: translate("fe_vue_registrazione_agenzia.Avanti"),
      str_indietro: translate("fe_vue_registrazione_agenzia.Indietro"),
      str_campi_obbligatori: translate("fe_vue_registrazione_agenzia.Campi obbligatori"),

      /* Models */
      tipi_indagine_da_inviare: this.tipi_indagine_da_inviare_param,
      tariffario: this.tariffario_param,  
      /* Errori */
      errore_tariffario: false,
      /* States */

      /* Altro */
    };
  },

  methods: {
    handleFileUploadTariffario() {
      this.tariffario = this.$refs.tariffario.files[0];
    },

    tornaAPasso2: function() {
      this.$emit("tornaapasso2");
    },

    vaiAPasso4: function() {
      if (this.$v.$invalid || this.tariffario == "") {
        this.bloccato = true;
        if (!this.tariffario) {
          this.errore_tariffario = true;
        }
      } else {
        this.bloccato = false;
      }

      if (this.bloccato == false) {
        //this.passo3 = false;
        //this.passo4 = true;
        this.$emit('vaiapasso4', this.tipi_indagine_da_inviare, this.tariffario);
      }
    },

    tornaAPasso2: function() {     
      this.$emit('tornaapasso2');
    },

    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },

  validations: {},
};

class Errors {
  constructor() {
    this.errors = {};
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  record(errors) {
    this.errors = errors;
  }
}
</script>
