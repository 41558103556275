<template>
  <modal name="generatore-password" :clickToClose="false">
    <div style="padding: 10px;">
      <div class="modal-header">
        <h5 class="modal-title" id="passwordModalCenterTitle">
          Genera password
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          v-on:click="chiudi"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <button class="btn btn-success my-1" v-on:click="generaPassword">
          Genera
        </button>
        <div class="form-group">
          <input type="text" v-model="password_generata" class="form-control" />
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="1"
            v-model="ceccato"
          />
          <label class="form-check-label">
            Ho copiato la password in un posto sicuro
          </label>
        </div>
      </div>
      <div class="modal-footer">
        <input type="hidden" name="id" id="user_to_delete" />
        <button type="button" class="btn btn-secondary" v-on:click="chiudi">
          Chiudi
        </button>
        <button v-if="ceccato == false"
          type="button"
          class="btn btn-primary"          
          disabled
        >
        Usa password
        </button>
        <button v-if="ceccato == true"
          type="button"
          class="btn btn-primary"
          v-on:click="usaPassword"
          
        >
          Usa password
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "GeneratorePassword",

  data: function() {
    return {
        password_generata: '',
        ceccato: 0,        
    };
  },

  props: [],

  mounted() {
    this.$modal.show("generatore-password");    
  },

  methods: {
    
    
    generaPassword: function() {
        
      let length = 8,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*(){}[]=<>/,.|~?",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }

      this.password_generata = retVal;
    },

    usaPassword: function() {
        this.$parent.password = this.password_generata;
        this.$parent.conferma_password = this.password_generata;

        this.$parent.$refs.password.setAttribute("type", "password");  
        this.$parent.$refs.conferma_password.setAttribute("type", "password");      

        this.$modal.hide("generatore-password");               
        this.$parent.modale = false;

    },

    chiudi: function() {
        this.$modal.hide("generatore-password");
        this.$parent.modale = false;
    }



  },
};
</script>
