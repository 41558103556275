<template>
  <div>
    <section class="horizontal-wizard">
      <div class="bs-stepper horizontal-wizard-example">
        <div class="bs-stepper-header">
          <div class="step" data-target="#step1">
            <button type="button" class="step-trigger">
              <span class="bs-stepper-box">1</span>
              <span class="bs-stepper-label">
                <span class="bs-stepper-title">{{ str_dati_basilari }}</span>
                <span class="bs-stepper-subtitle"></span>
              </span>
            </button>
          </div>
          <div class="line">
            <i data-feather="chevron-right" class="font-medium-2"></i>
          </div>
          <div class="step" data-target="#personal-info">
            <button type="button" class="step-trigger">
              <span class="bs-stepper-box">2</span>
              <span class="bs-stepper-label">
                <span class="bs-stepper-title">{{
                  str_dati_fatturazione
                }}</span>
                <span class="bs-stepper-subtitle"></span>
              </span>
            </button>
          </div>
          <div class="line">
            <i data-feather="chevron-right" class="font-medium-2"></i>
          </div>
          <div class="step" data-target="#address-step">
            <button type="button" class="step-trigger">
              <span class="bs-stepper-box">3</span>
              <span class="bs-stepper-label">
                <span class="bs-stepper-title">{{ str_dati_identita }}</span>
                <span class="bs-stepper-subtitle"></span>
              </span>
            </button>
          </div>
        </div>
        <div class="bs-stepper-content">
          <div id="step1" class="content">
            <div class="content-header">
              <h5 class="mb-0">{{ str_dati_basilari }}</h5>
              <small class="text-muted"></small>
            </div>
            <form>
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="form-label">{{ str_nome }} *</label>
                  <b-form-input
                    v-model="nome"
                    :state="nome_state"
                    @keyup="changeNomeState"
                    @blur="changeNomeState"
                  ></b-form-input>
                </div>
                <div class="form-group col-md-6">
                  <label class="form-label">{{ str_cognome }} *</label>
                  <b-form-input
                    v-model="cognome"
                    :state="cognome_state"
                    @keyup="changeCognomeState"
                    @blur="changeCognomeState"
                  ></b-form-input>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-4">
                  <label class="form-label">{{ str_email }} *</label>
                  <b-form-input
                    v-model="email"
                    type="email"
                    :state="email_state"
                    @keyup="changeEmailState"
                    @blur="changeEmailState"
                  ></b-form-input>
                </div>
                <div class="form-group col-md-4">
                  <label class="form-label">{{ str_data_di_nascita }}</label>
                  <div class="input-group">
                    <datepicker
                      v-model="data_di_nascita"
                      ref="data_di_nascita"
                      :bootstrap-styling="true"
                      input-class="bg-white"
                      format="dd-MM-yyyy"
                      :language="it"
                      :disabledDates="disabledDates"
                    ></datepicker>

                    <div
                      class="input-group-append"
                      v-on:click="openCalendarDateOfBirth"
                    >
                      <div class="input-group-text">
                        <!--<i class="far fa-calendar-alt"></i>-->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-calendar"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <b-form-group :label="str_sesso" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="radio-group-2"
                      :aria-describedby="ariaDescribedby"
                      name="radio-sub-component"
                      v-model="gender"
                      :state="gender_state"
                      v-on:change="changeGenderState"
                    >
                      <b-form-radio value="M">{{ str_maschio }}</b-form-radio>
                      <b-form-radio value="F">{{ str_femmina }}</b-form-radio>
                      <b-form-radio value="X" class="mt-1">{{
                        str_no_specificato
                      }}</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-4">
                  <label class="form-label">{{ str_tipologia }}</label>
                  <select
                    disabled="disabled"
                    v-model="tipologia"
                    class="form-control"
                  >
                    <option value="0">{{ str_privato }}</option>
                    <option value="1">{{ str_business }}</option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label class="form-label">{{ str_professione }}</label>
                  <select
                    class="form-control"
                    v-model="professione"
                    disabled
                    v-if="tipologia == 0"
                  >
                    <option :value="str_avvocato">{{ str_avvocato }}</option>
                    <option :value="str_hr_manager">{{
                      str_hr_manager
                    }}</option>
                    <option :value="str_perito">{{ str_perito }}</option>
                    <option :value="str_cfo">{{ str_cfo }}</option>
                    <option :value="str_ceo">{{ str_ceo }}</option>
                    <option :value="str_commercialista">{{
                      str_commercialista
                    }}</option>
                    <option :value="str_altro">{{ str_altro }}</option>
                  </select>

                  <select
                    class="form-control"
                    v-model="professione"
                    v-if="tipologia == 1"
                  >
                    <option
                      v-for="(opzione, index) in professional_role_names"
                      :key="index"
                      :value="opzione.value"
                      v-text="opzione.text"
                    ></option>
                    <!--<option :value="str_avvocato">{{ str_avvocato }}</option>
                    <option :value="str_hr_manager">{{
                      str_hr_manager
                    }}</option>
                    <option :value="str_perito">{{ str_perito }}</option>
                    <option :value="str_cfo">{{ str_cfo }}</option>
                    <option :value="str_ceo">{{ str_ceo }}</option>
                    <option :value="str_commercialista">{{
                      str_commercialista
                    }}</option>-->
                    <option :value="str_altro">{{ str_altro }}</option>
                  </select>
                </div>

                <div class="form-group col-md-4">
                  <div class="form-check align-middle pt-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="1"
                      id="comm"
                      v-model="comunicazioni_commerciali"
                      tabindex="9"
                    />
                    <label class="form-check-label" for="comm">
                      {{ str_comunicazioni_commerciali }}
                    </label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <h3>{{ str_cambia_la_tua_password }}</h3>
                </div>
              </div>

              <div class="row">
                <div class="form-group form-password-toggle col-md-4">
                  <label class="form-label">{{ str_vecchia_password }}</label>
                  <div class="input-group">
                    <input
                      type="password"
                      ref="old_password"
                      v-model="old_password"
                      :class="old_password_classes"
                      tabindex="10"
                      v-on:keyup="changeStateOldPassword"
                      v-on:blur="changeStateOldPassword"
                      autocomplete="new-password"
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text cursor-pointer"
                        v-on:click="mostraPassword('old_password')"
                      >
                        <i class="fas fa-eye"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form-group form-password-toggle col-md-4">
                  <label class="form-label"
                    >Password ({{ str_8_caratteri }})</label
                  >
                  <div class="input-group">
                    <input
                      type="password"
                      ref="password"
                      v-model="password"
                      :class="password_classes"
                      tabindex="10"
                      v-on:keyup="changeStatePassword"
                      v-on:blur="changeStatePassword"
                      autocomplete="new-password"
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text cursor-pointer"
                        v-on:click="mostraPassword('password')"
                      >
                        <i class="fas fa-eye"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-group form-password-toggle col-md-4">
                  <label class="form-label">{{ str_conferma_password }}</label>
                  <div class="input-group">
                    <input
                      type="password"
                      ref="password_confirmation"
                      v-model="password_confirmation"
                      v-on:keyup="changeStatePasswordConfirmation"
                      v-on:blur="changeStatePasswordConfirmation"
                      autocomplete="off"
                      tabindex="11"
                      :class="password_confirmation_classes"
                    />

                    <div class="input-group-append">
                      <span
                        class="input-group-text cursor-pointer"
                        v-on:click="mostraPassword('password_confirmation')"
                      >
                        <i class="fas fa-eye"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <button
                    class="btn btn-info mb-1"
                    v-on:click="apriGeneratorePassword"
                    type="button"
                  >
                    {{ str_generatore_password }}
                  </button>

                  <button
                    class="btn btn-danger mb-1"
                    type="button"
                    v-on:click="requestAccountDeletion"
                  >
                    {{ str_cancella_account }}
                  </button>
                </div>
              </div>
            </form>

            <div
              class="row"
              v-if="
                err_nome ||
                  err_cognome ||
                  err_email ||
                  err_password ||
                  err_password_confirmation ||
                  err_gender ||
                  err_old_password
              "
            >
              <div class="col error m-1">
                <p>{{ str_rivedere_campi }}:</p>
                <ul>
                  <li v-if="err_nome">{{ str_nome }}</li>
                  <li v-if="err_cognome">{{ str_cognome }}</li>
                  <li v-if="err_email">{{ str_email }}</li>
                  <li v-if="err_gender">{{ str_sesso }}</li>
                  <li v-if="err_password">Password</li>
                  <li v-if="err_password_confirmation">
                    {{ str_conferma_password }}
                  </li>
                  <li v-if="err_old_password">{{ str_vecchia_password }}</li>
                </ul>
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <button
                class="btn btn-outline-secondary btn-prev invisible"
                disabled
              >
                <i
                  data-feather="arrow-left"
                  class="align-middle mr-sm-25 mr-0"
                ></i>
                <span class="align-middle d-sm-inline-block d-none">{{
                  str_indietro
                }}</span>
              </button>

              <button
                class="btn btn-primary btn-next"
                v-on:click="mostraErroriStep1"
                v-if="!controlliPasso1"
              >
                <span class="align-middle d-sm-inline-block d-none">{{
                  str_avanti
                }}</span>
                <i
                  data-feather="arrow-right"
                  class="align-middle ml-sm-25 ml-0"
                ></i>
              </button>

              <button class="btn btn-primary btn-next" v-if="controlliPasso1">
                <span class="align-middle d-sm-inline-block d-none">{{
                  str_avanti
                }}</span>
                <i
                  data-feather="arrow-right"
                  class="align-middle ml-sm-25 ml-0"
                ></i>
              </button>
            </div>
          </div>
          <div id="personal-info" class="content" ref="personal_info">
            <div class="content-header">
              <h5 class="mb-0">{{ str_dati_fatturazione }}</h5>
              <small></small>
            </div>
            <form>
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="form-label">{{ str_indirizzo }} *</label>
                  <b-form-input
                    v-model="indirizzo"
                    :state="indirizzo_state"
                    @keyup="changeIndirizzoState"
                    @blur="changeIndirizzoState"
                  ></b-form-input>
                </div>
                <div class="form-group col-md-6">
                  <label class="form-label"
                    >{{ str_cap }}
                    <span v-if="country_id == 151">({{ str_5_numeri }})</span>
                    *</label
                  >
                  <b-form-input
                    v-model="cap"
                    :state="cap_state"
                    @keyup="changeCapState"
                    @blur="changeCapState"
                  ></b-form-input>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-4">
                  <label class="form-label">{{ str_comune }} *</label>
                  <b-form-input
                    v-model="comune"
                    :state="comune_state"
                    @keyup="changeComuneState"
                    @blur="changeComuneState"
                  ></b-form-input>
                </div>
                <div class="form-group col-md-4">
                  <label class="form-label">{{ str_paese }} *</label>
                  <select
                    class="form-control"
                    v-model="country_id"
                    :class="country_id_state"
                    v-on:change="changeCountryIdState"
                  >
                    <option value=""></option>
                    <option
                      :value="nazione.id"
                      v-for="(nazione, dn) in datanazioni"
                      :key="dn"
                      >{{ nazione.name }}</option
                    >
                  </select>
                </div>
                <div class="form-group col-md-4" v-if="country_id == 151 || country_id == 144">
                  <label class="form-label">{{ str_provincia }} *</label>
                  <select
                    class="form-control"
                    v-model="provincia"
                    :class="provincia_state"
                    v-on:change="changeProvinciaState"
                  >
                    <option value=""></option>
                    <option
                      :value="prov.codice"
                      v-for="(prov, p) in dataprovince"
                      :key="p"
                      >{{ prov.nome }}</option
                    >
                  </select>
                </div>

                <div class="form-group col-md-4" v-if="country_id != 151 && country_id != 144">
                  <label>{{ str_provincia }}</label>
                  <input
                    type="text"
                    v-model="provincia_estera"
                    class="form-control"
                    tabindex="9"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label
                      >{{ str_codice_fiscale }}
                      <span v-if="tipologia == 0">*</span></label
                    >
                    <b-form-input
                      v-model="codice_fiscale"
                      :state="codice_fiscale_state"
                      @keyup="changeCodiceFiscaleState"
                      @blur="changeCodiceFiscaleState"
                    ></b-form-input>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group" v-if="tipologia == 1">
                    <label>{{ str_ragione_sociale }} *</label>
                    <b-form-input
                      v-model="ragione_sociale"
                      :state="ragione_sociale_state"
                      @keyup="changeRagioneSocialeState"
                      @blur="changeRagioneSocialeState"
                    ></b-form-input>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group" v-if="tipologia == 1">
                    <label
                      >{{ str_partita_iva }}
                      <span v-if="country_id == 151">({{ str_11_numeri }})</span
                      >*</label
                    >
                    <b-form-input
                      v-model="partita_iva"
                      :state="partita_iva_state"
                      @keyup="changePartitaIvaState"
                      @blur="changePartitaIvaState"
                    ></b-form-input>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group" v-if="tipologia == 1">
                    <label>{{ str_codice_destinatario }}</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="codice_destinatario_fe"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <p v-if="tipologia == 1">{{ str_procura }}</p>

                  <div
                    class="form-check form-check-inline"
                    v-if="tipologia == 1"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      value="1"
                      v-model="disposizione_procura"
                    />
                    <label class="form-check-label">{{ str_si }}</label>
                  </div>

                  <div
                    class="form-check form-check-inline"
                    v-if="tipologia == 1"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      value="0"
                      v-model="disposizione_procura"
                    />
                    <label class="form-check-label">{{ str_no }}</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group" v-if="tipologia == 1">
                    <label
                      >{{ str_allegato_procura }} ({{
                        str_formati_ammessi
                      }} <b>max 20MB</b>)</label
                    >
                    <input
                      type="file"
                      v-on:change="handleFileProcura"
                      accept="image/jpg,image/jpeg,image/png,application/pdf"
                      ref="procura_url"
                    />
                   <!-- <a
                      :href="download_file_procura"
                      v-if="download_file_procura != ''"
                      class="btn btn-outline-secondary mt-1"
                      target="_blank"
                      >{{ str_vedi_file }}</a
                    >-->
                    <br>
                    <a
                      @click="openPhotoModal(download_file_procura, cliente.procura_url)"                      
                      v-if="download_file_procura != ''"
                      class="btn btn-outline-secondary mt-1"                      
                      >{{ str_vedi_file }}</a
                    >
                  </div>
                </div>
              </div>
            </form>

            <div
              class="row"
              v-if="
                err_indirizzo ||
                  err_cap ||
                  err_comune ||
                  err_paese ||
                  err_provincia ||
                  err_codice_fiscale ||
                  err_ragione_sociale ||
                  err_partita_iva ||
                  err_procura_url
              "
            >
              <div class="col error m-1">
                <p>{{ str_rivedere_campi }}:</p>
                <ul>
                  <li v-if="err_indirizzo">{{ str_indirizzo }}</li>
                  <li v-if="err_cap">{{ str_cap }}</li>
                  <li v-if="err_comune">{{ str_comune }}</li>
                  <li v-if="err_paese">{{ str_paese }}</li>
                  <li v-if="err_provincia">{{ str_provincia }}</li>
                  <li v-if="err_codice_fiscale">{{ str_codice_fiscale }}</li>
                  <li v-if="err_ragione_sociale">{{ str_ragione_sociale }}</li>
                  <li v-if="err_partita_iva">{{ str_partita_iva }}</li>
                  <li v-if="err_procura_url">{{ str_allegato_procura }}</li>
                </ul>
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <button class="btn btn-primary btn-prev" ref="last_back0">
                <i
                  data-feather="arrow-left"
                  class="align-middle mr-sm-25 mr-0"
                ></i>
                <span class="align-middle d-sm-inline-block d-none">{{
                  str_indietro
                }}</span>
              </button>

              <button
                type="button"
                class="btn btn-primary btn-next"
                v-on:click="mostraErroriStep2"
                v-if="!controlliPasso2"
                id="btn-goto-step3"
              >
                <span class="align-middle d-sm-inline-block d-none">{{
                  str_avanti
                }}</span>
                <i
                  data-feather="arrow-right"
                  class="align-middle ml-sm-25 ml-0"
                ></i>
              </button>

              <button
                class="btn btn-primary btn-next"
                v-if="controlliPasso2 == true"
              >
                <span class="align-middle d-sm-inline-block d-none">{{
                  str_avanti
                }}</span>
                <i
                  data-feather="arrow-right"
                  class="align-middle ml-sm-25 ml-0"
                ></i>
              </button>
            </div>
          </div>
          <div id="address-step" class="content">
            <div class="content-header">
              <h5 class="mb-0">{{ str_dati_identita }}</h5>
              <small></small>
            </div>
            <form>
              <div class="row">
                <div class="form-group col-md-3">
                  <label class="form-label">{{ str_tipo_documento }} *</label>
                  <!--<select
                    class="form-control"
                    v-model="$v.tipo_documento_riconoscimento.$model" :class="status($v.tipo_documento_riconoscimento)"
                  >
                    <option value=""></option>
                    <option value="Carta di identità">Carta di identità</option>
                    <option value="Passaporto">Passaporto</option>
                    <option value="Patente">Patente</option>
                  </select>-->

                  <select
                    v-model="tipo_documento_riconoscimento"
                    :class="tipo_documento_riconoscimento_state"
                    v-on:change="changeTipoDocumentoRiconoscimentoState"
                  >
                    <option value=""></option>
                    <option value="Carta di identità">{{
                      str_carta_identita
                    }}</option>
                    <option value="Passaporto">{{ str_passaporto }}</option>
                    <option value="Patente">{{ str_patente }}</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label class="form-label">{{ str_numero_documento }} *</label>
                  <!--<input
                    type="text"
                    class="form-control"
                    v-model="$v.numero_documento.$model" :class="status($v.numero_documento)"
                  />-->

                  <b-form-input
                    v-model="numero_documento"
                    :state="numero_documento_state"
                    @keyup="changeNumeroDocumentoState"
                    @blur="changeNumeroDocumentoState"
                  ></b-form-input>
                </div>

                <div class="form-group col-md-4">
                  <label class="form-label"
                    >{{ str_scadenza_documento }} *</label
                  >
                  <div class="input-group">
                    <datepicker
                      v-model="data_scadenza_documento"
                      :bootstrap-styling="true"
                      input-class="bg-white"
                      format="dd-MM-yyyy"
                      :language="it"
                      :disabledDates="disabledDates2"
                      ref="data_scadenza_documento"
                      @input="changeStateScadenzaDocumento"
                    ></datepicker>

                    <div
                      class="input-group-append"
                      v-on:click="openCalendarDocumentExpiration"
                    >
                      <div class="input-group-text">
                        <!--<i class="far fa-calendar-alt"></i>-->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-calendar"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-3">
                  <label class="form-label"
                    >{{ str_allegato_documento }} ({{ str_formati_ammessi }} <b>max 20MB</b>)
                    *</label
                  >
                  <input
                    type="file"
                    v-on:change="handleFileDocumento"
                    accept="image/jpg,image/jpeg,image/png,application/pdf"
                    ref="documento_url"
                  />
                  <br>
                  <!--<a
                    :href="download_documento"
                    v-if="download_documento"
                    target="_blank"
                    class="btn btn-outline-secondary mt-1"
                    >{{ str_vedi_file }}</a
                  >-->

                  <a
                    @click="openPhotoModal(download_documento, cliente.documento_url)"
                    v-if="download_documento"
                    target="_blank"
                    class="btn btn-outline-secondary mt-1"
                    >{{ str_vedi_file }}</a
                  >
                </div>

                <div class="form-group col-md-3" v-if="tipologia == 1">
                  <label class="form-label"
                    >{{ str_allegato_visura }} ({{ str_formati_ammessi }} <b>max 20MB</b>)
                    *</label
                  >
                  <input
                    type="file"
                    v-on:change="handleFileVisura"
                    accept="image/jpg,image/jpeg,image/png,application/pdf"
                    ref="visura_camerale_url"
                  />
                  <br />
                  <!--
                  <a
                    :href="download_file_visura_camerale_url"
                    target="_blank"
                    v-if="download_file_visura_camerale_url"
                    class="btn btn-outline-secondary mt-1"
                    >{{ str_vedi_file }}</a
                  >-->
                  <a
                  @click="openPhotoModal(download_file_visura_camerale_url, cliente.visura_camerale_url)"                    
                    target="_blank"
                    v-if="download_file_visura_camerale_url"
                    class="btn btn-outline-secondary mt-1"
                    >{{ str_vedi_file }}</a
                  >
                </div>

                <div class="form-group col-md-3">
                  <label
                    >{{ str_paese }} <span class="text-danger">*</span></label
                  >
                  <b-form-select
                    class="border-blue"
                    v-model="prefix_tel"
                    :options="prefixes"
                  ></b-form-select>
                </div>

                <div class="form-group col-md-3">
                  <label class="form-label"
                    >{{ str_telefono }} ({{ str_senza_spazi }}) *</label
                  >
                  <b-form-input
                    type="tel"
                    v-model="telefono"
                    :state="telefono_state"
                    @keyup="changeTelefonoState"
                    @blur="changeTelefonoState"
                  ></b-form-input>
                </div>
              </div>
            </form>

            <div
              class="row"
              v-if="
                mostra_errori &&
                  (err_tipo_documento ||
                    err_numero_documento ||
                    err_scadenza_documento ||
                    err_documento_exists ||
                    err_visura_exists ||
                    err_phone_valid)
              "
            >
              <div class="col error m-1">
                <p>{{ str_rivedere_campi }}:</p>
                <ul>
                  <li v-if="err_tipo_documento">{{ str_tipo_documento }}</li>
                  <li v-if="err_numero_documento">
                    {{ str_numero_documento }}
                  </li>
                  <li v-if="err_scadenza_documento">
                    {{ str_scadenza_documento }}
                  </li>
                  <li v-if="err_documento_exists">
                    {{ str_allegato_documento }}
                  </li>
                  <li v-if="err_visura_exists">{{ str_allegato_visura }}</li>
                  <li v-if="err_phone_valid">{{ str_telefono }}</li>
                </ul>
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <button class="btn btn-primary btn-prev" ref="last_back">
                <i
                  data-feather="arrow-left"
                  class="align-middle mr-sm-25 mr-0"
                ></i>
                <span class="align-middle d-sm-inline-block d-none">{{
                  str_indietro
                }}</span>
              </button>
              <button
                class="btn btn-success"
                v-if="!controlliPasso3"
                v-on:click="mostraErrori"
              >
                <span class="align-middle ">{{ str_conferma }}</span>
              </button>
              <button
                class="btn btn-success"
                v-if="controlliPasso3"
                v-on:click="aggiornaCliente"
              >
                <span class="align-middle ">{{ str_conferma }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--<div
        class="alert alert-danger"
        v-for="(err, indice) in errori_validazione.errors.errors"
        :key="indice"
      >
        {{ err[0] }}
      </div>-->
    </section>

    <generatore-password v-if="modale"></generatore-password>


    <b-modal id="modal-photo" size="lg" hide-footer>
    <img :src="photo_modal_src" alt="Picture" class="img-fluid" v-if="photo_modal_src">
    <div class="text-center" v-if="photo_modal_src == ''">
      <p v-html="str_desc_download_pdf"></p>
      <a :href="file_modal_src" class="btn btn-secondary" target="_blank">{{ str_vedi_file }}</a>
      <a :href="file_modal_src + '/1'" class="btn btn-secondary" target="_blank">Download PDF</a>
    </div>
    </b-modal>

  </div>
</template>
<style scoped>
input[type="file"] {
  color: #5e5873;
}

.dirty {
  border-color: #5a5;
  background: #efe;
}

.dirty:focus {
  outline-color: #8e8;
}

.error {
  border: 1px solid red;
  background: #fdd;
  padding: 5px;
}

.error:focus {
  outline-color: #f99;
}
</style>

<script>
import Vue from "vue";

import axios from "axios";
import Datepicker from "vuejs-datepicker";
import { en, it } from "vuejs-datepicker/dist/locale";
import moment from "moment";
/*import {
  required,
  email,
  sameAs,
  requiredIf,
  helpers,
  minLength,   
  numeric,
} from "vuelidate/lib/validators";
const validPhone = helpers.regex("validPhone", /^[\+]?\d{5,16}$/);
const validFixedPhone = helpers.regex("validFixedPhone", /^[\+]?\d{5,16}$/);*/
import GeneratorePassword from "./GeneratorePassword";
//import './customers.css';

import PasswordAnalyzerEdit from "../frontend/includes/PasswordAnalyzerEdit.vue";

import {
  BootstrapVue,
  BFormInput,
  BFormSelect,
  BFormFile,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BModal
} from "bootstrap-vue";

const default_layout = "default";

export default {
  mounted: function() {
    
    this.nome = this.cliente.user.name;
    this.cognome = this.cliente.user.last_name;
    this.gender = this.cliente.gender;
    this.email = this.cliente.user.email;
    this.data_di_nascita = this.cliente.data_di_nascita;
    this.indirizzo = this.cliente.indirizzo_fatturazione
      ? this.cliente.indirizzo_fatturazione
      : "";
    this.cap = this.cliente.cap ? this.cliente.cap : "";
    this.comune = this.cliente.comune ? this.cliente.comune : "";
    this.country_id =
      this.cliente.country_id == null ? 151 : this.cliente.country_id;
    this.provincia = this.cliente.provincia_sigla
      ? this.cliente.provincia_sigla
      : "";
    this.provincia_estera = this.cliente.provincia_estera
      ? this.cliente.provincia_estera
      : "";
    this.ragione_sociale = this.cliente.ragione_sociale
      ? this.cliente.ragione_sociale
      : "";
    this.codice_fiscale = this.cliente.codice_fiscale
      ? this.cliente.codice_fiscale
      : "";
    this.partita_iva = this.cliente.partita_iva ? this.cliente.partita_iva : "";
    this.prefix_tel = this.cliente.prefix_tel;

    this.tipologia = this.cliente.privato_business;
    this.professione = this.cliente.professione;
    this.comunicazioni_commerciali = this.cliente.user.comunicazioni_commerciali;
    this.codice_destinatario_fe = this.cliente.codice_destinatario_fe
      ? this.cliente.codice_destinatario_fe
      : "";
    this.disposizione_procura =
      this.cliente.disposizione_procura == null
        ? 0
        : this.cliente.disposizione_procura;
    //this.procura_url = this.cliente.procura_url;
    let id_cliente = this.cliente.user.id;
    if (this.cliente.procura_url) {
      this.download_file_procura = `/files/view_file_customer/${this.criptid}/procura_url`;
    }

    //console.log("disposizione procura=", this.cliente);

    this.tipo_documento_riconoscimento = this.cliente
      .tipo_documento_riconoscimento
      ? this.cliente.tipo_documento_riconoscimento
      : "";
    //this.documento_url = this.cliente.documento_url;
    this.numero_documento = this.cliente.numero_documento
      ? this.cliente.numero_documento
      : "";

    this.data_scadenza_documento = this.cliente.data_scadenza_documento
      ? this.cliente.data_scadenza_documento
      : "";
    //this.visura_camerale_url = this.cliente.visura_camerale_url;
    this.telefono = this.cliente.user.telefono
      ? this.cliente.user.telefono
      : "";
    if (this.cliente.numero_documento) {
      this.download_documento = `/files/view_file_customer/${this.criptid}/documento_url`;
    }
    if (this.cliente.visura_camerale_url) {
      this.download_file_visura_camerale_url = `/files/view_file_customer/${this.criptid}/visura_camerale_url`;
    }

    this.dataprovince = JSON.parse(this.province);
    this.datanazioni = JSON.parse(this.nazioni);

    const professional_role_names_param = JSON.parse(
      this.professional_role_names_param
    );
    professional_role_names_param.forEach((element) => {
      if (this.locale == "it" || this.locale == "it-IT") {
        this.professional_role_names.push({
          value: element.name_it,
          text: element.name_it,
        });
      } else {
        this.professional_role_names.push({
          value: element.name_en,
          text: element.name_en,
        });
      }
    });
  },
  components: {
    Datepicker,
    GeneratorePassword,
    BFormInput,
    BFormDatepicker,
    BFormSelect,
    PasswordAnalyzerEdit,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BModal
  },
  computed: {
    controlliPasso1: function() {
      if (
        this.nome == "" ||
        this.cognome == "" ||
        this.email == "" ||
        !this.emailIsValid(this.email) ||
        this.data_di_nascita == "" ||
        this.gender == ""
      ) {
        return false;
      }
      if (this.password != "" && this.password.length < 8) {
        return false;
      }

      if (this.password != "" && this.old_password == "") {
        return false;
      }

      if (this.password != this.password_confirmation) {
        return false;
      }
      return true;
    },

    controlliPasso2: function() {
      if (
        this.indirizzo == "" ||
        !this.indirizzo ||
        this.indirizzo == null ||
        this.cap == "" ||
        !this.cap ||
        this.cap == null ||
        this.comune == "" ||
        !this.comune ||
        this.comune == null ||
        this.country_id == "" ||
        !this.country_id ||
        this.country_id == null ||
        ((this.country_id == 151 || this.country_id == 144) &&
          (this.provincia == "" ||
            !this.provincia ||
            this.provincia == null)) ||
        /*(this.tipologia == 0 && (!this.isCodiceFiscaleValid())) ||*/
        !this.isCodiceFiscaleValid() ||
        (this.tipologia == 1 &&
          (this.ragione_sociale == "" ||
            !this.ragione_sociale ||
            this.ragione_sociale == null)) ||
        (this.tipologia == 1 && !this.isPartitaIvaValid()) ||
        !this.procuraUrlIsValid()
      ) {
        this.bloccato = false;
        return false;
      }
      this.bloccato = false;
      return true;
    },

    controlliPasso3: function() {
      if (
        this.tipo_documento_riconoscimento == "" ||
        !this.tipo_documento_riconoscimento ||
        this.tipo_documento_riconoscimento == null ||
        this.numero_documento == "" ||
        !this.numero_documento ||
        this.numero_documento == null ||
        this.data_scadenza_documento == "" ||
        !this.data_scadenza_documento ||
        this.data_scadenza_documento == null ||
        this.telefono == "" ||
        !this.telefono ||
        this.telefono == null ||
        this.telefono_state == false ||
        /*this.documento_url == "" || !this.documento_url || this.documento_url == null*/
        !this.documentoExists()
      ) {
        this.bloccato = false;
        return false;
      }
      this.bloccato = false;
      return true;
    },

    getInitialDate: function() {
      return moment()
        .subtract(18, "years")
        .toDate();
    },
  },
  props: {
    customer: null,
    province: null,
    nazioni: null,
    criptid: null,
    prefixes_param: String,
    professional_role_names_param: String,
  },
  data() {
    return {
      step1: true,
      step2: false,
      step3: false,

      cliente: JSON.parse(this.customer),
      professional_role_names: [],

      str_dati_basilari: translate("fe_vue_profilo_cliente.Dati basilari"),
      str_dati_fatturazione: translate(
        "fe_vue_profilo_cliente.Dati fatturazione"
      ),
      str_dati_identita: translate("fe_vue_profilo_cliente.Dati identità"),
      str_nome: translate("fe_vue_profilo_cliente.Nome"),
      str_cognome: translate("fe_vue_profilo_cliente.Cognome"),
      str_email: translate("fe_vue_profilo_cliente.Email"),
      str_data_di_nascita: translate("fe_vue_profilo_cliente.Data di nascita"),
      str_tipologia: translate("fe_vue_profilo_cliente.Tipo cliente"),
      str_professione: translate("fe_vue_profilo_cliente.Professione / Ruolo"),
      str_privato: translate("fe_vue_profilo_cliente.Privato"),
      str_business: translate("fe_vue_profilo_cliente.Business"),
      str_avvocato: translate("fe_vue_profilo_cliente.Avvocato"),
      str_hr_manager: translate("fe_vue_profilo_cliente.HR Manager"),
      str_perito: translate("fe_vue_profilo_cliente.Perito"),
      str_cfo: translate("fe_vue_profilo_cliente.CFO"),
      str_ceo: translate("fe_vue_profilo_cliente.CEO"),
      str_commercialista: translate("fe_vue_profilo_cliente.Commercialista"),
      str_altro: translate("fe_vue_profilo_cliente.Altro"),
      str_indirizzo: translate("fe_vue_profilo_cliente.Indirizzo"),
      str_cap: translate("fe_vue_profilo_cliente.CAP"),
      str_comune: translate("fe_vue_profilo_cliente.Comune"),
      str_paese: translate("fe_vue_profilo_cliente.Paese"),
      str_provincia: translate("fe_vue_profilo_cliente.Provincia"),
      str_ragione_sociale: translate("fe_vue_profilo_cliente.Ragione sociale"),
      str_codice_fiscale: translate("fe_vue_profilo_cliente.Codice fiscale"),
      str_partita_iva: translate("fe_vue_profilo_cliente.Partita IVA"),
      str_codice_destinatario: translate(
        "fe_vue_profilo_cliente.Codice destinatario"
      ),
      str_procura: translate("fe_vue_profilo_cliente.Procura"),
      str_allegato_procura: translate(
        "fe_vue_profilo_cliente.Allegato procura"
      ),
      str_si: translate("Sì"),
      str_no: translate("fe_vue_profilo_cliente.No"),
      str_vedi_file: translate("fe_vue_profilo_cliente.Vedi file"),
      str_telefono: translate("fe_vue_profilo_cliente.Telefono"),
      str_tipo_documento: translate("fe_vue_profilo_cliente.Tipo documento"),
      str_numero_documento: translate(
        "fe_vue_profilo_cliente.Numero documento"
      ),
      str_scadenza_documento: translate(
        "fe_vue_profilo_cliente.Scadenza documento"
      ),
      str_allegato_documento: translate(
        "fe_vue_profilo_cliente.Allegato documento"
      ),
      str_allegato_visura: translate("fe_vue_profilo_cliente.Allegato visura"),
      str_conferma: translate("fe_vue_profilo_cliente.Conferma"),
      str_avanti: translate("fe_vue_profilo_cliente.Avanti"),
      str_indietro: translate("fe_vue_profilo_cliente.Indietro"),
      str_campo_obbligatorio: translate(
        "fe_vue_profilo_cliente.Campo obbligatorio"
      ),
      str_password_diversa: translate(
        "fe_vue_profilo_cliente.Le 2 password devono corrispondere"
      ),
      str_password_lunghezza: translate(
        "fe_vue_profilo_cliente.La password deve essere almeno di 8 caratteri"
      ),
      str_email_non_valida: translate(
        "fe_vue_profilo_cliente.Email non valida"
      ),
      str_generatore_password: translate(
        "fe_vue_profilo_cliente.Genera password"
      ),
      str_formato_telefono_non_valido: translate(
        "fe_vue_profilo_cliente.Formato telefono non valido"
      ),
      str_comunicazioni_commerciali: translate(
        "fe_vue_profilo_cliente.Accettazione invio comunicazioni commerciali"
      ),
      str_solo_numeri: translate("fe_vue_profilo_cliente.Solo numeri"),
      str_5_numeri: translate("fe_vue_profilo_cliente.Composto da 5 numeri"),
      str_11_numeri: translate("fe_vue_profilo_cliente.Composta da 11 numeri"),
      str_carta_identita: translate("fe_vue_profilo_cliente.Carta di identità"),
      str_patente: translate("fe_vue_profilo_cliente.Patente"),
      str_passaporto: translate("fe_vue_profilo_cliente.Passaporto"),
      str_rivedere_campi: translate(
        "fe_vue_profilo_cliente.Rivedere i seguenti campi"
      ),
      str_conferma_password: translate(
        "fe_vue_profilo_cliente.Conferma Password"
      ),
      str_8_caratteri: translate("fe_vue_profilo_cliente.Minimo 8 caratteri"),
      str_compilare_solo_per_cambiare: translate(
        "fe_vue_profilo_cliente.Compilare la password solo se si vuole cambiare"
      ),
      str_cambia_la_tua_password: translate(
        "fe_vue_profilo_cliente.Cambia la tua password"
      ),
      str_senza_spazi: translate("fe_vue_profilo_cliente.Senza spazi"),
      str_formati_ammessi: translate(
        "fe_vue_profilo_cliente.formati_immagini_e_pdf"
      ),
      str_file_troppo_grande: translate(
        "fe_vue_profilo_cliente.str_file_troppo_grande"
      ),

      str_sesso: translate("fe_vue_profilo_cliente.Sesso"),
      str_maschio: translate("fe_vue_profilo_cliente.Maschio"),
      str_femmina: translate("fe_vue_profilo_cliente.Femmina"),
      str_no_specificato: translate(
        "fe_vue_profilo_cliente.Preferisco non specificare"
      ),
      str_cancella_account: translate(
        "fe_vue_profilo_cliente.Richiedi la cancellazione dell'account"
      ),

      str_vecchia_password: translate(
        "fe_vue_profilo_cliente.Inserisci la vecchia password"
      ),

      str_desc_download_pdf: translate(
        "fe_vue_profilo_cliente.str_desc_download_pdf"
      ),

      dataprovince: [],
      datanazioni: [],
      prefixes: JSON.parse(this.prefixes_param),

      nome: "",
      cognome: "",
      gender: "",
      email: "",
      data_di_nascita: "",
      tipologia: "",
      professione: "",
      comunicazioni_commerciali: 0,
      indirizzo: "",
      cap: "",
      comune: "",
      country_id: "",
      provincia: "",
      provincia_estera: "",
      ragione_sociale: "",
      partita_iva: "",
      codice_fiscale: "",
      codice_destinatario_fe: "",
      disposizione_procura: "",
      procura_url: "",
      download_file_procura: "",

      tipo_documento_riconoscimento: "",
      documento_url: "",
      numero_documento: "",
      data_scadenza_documento: "",
      visura_camerale_url: "",
      prefix_tel: "",
      telefono: "",
      download_documento: "",
      download_file_visura_camerale_url: "",

      password: "",
      password_confirmation: "",
      old_password: "",

      it: it,
      en: en,
      bloccato: false,
      campo_mostra_password: "password",
      campo_mostra_password2: "password",
      campo_mostra_password_old: "password",
      //errori_validazione: new Errors(),
      modale: false,

      disabledDates: {
        from: moment()
          .subtract(18, "years")
          .toDate(),
      },

      disabledDates2: {
        to: moment().toDate(),
      },

      locale: this.locale_param ? this.locale_param : "it-IT",

      mostra_errori: false,

      /* States */
      nome_state: null,
      cognome_state: null,
      gender_state: null,
      email_state: null,
      indirizzo_state: null,
      cap_state: null,
      comune_state: null,
      country_id_state: "form-control",
      provincia_state: "form-control",
      codice_fiscale_state: null,
      ragione_sociale_state: null,
      partita_iva_state: null,
      tipo_documento_riconoscimento_state: "form-control",
      numero_documento_state: null,
      telefono_state: null,
      password_classes: "form-control",
      password_confirmation_classes: "form-control",
      old_password_classes: "form-control",

      err_nome: null,
      err_cognome: null,
      err_gender: null,
      err_email: null,
      err_password: null,
      err_password_confirmation: null,
      err_old_password: null,

      err_indirizzo: false,
      err_cap: false,
      err_comune: false,
      err_paese: false,
      err_provincia: false,
      err_codice_fiscale: false,
      err_ragione_sociale: false,
      err_partita_iva: false,
      err_procura_url: false,

      err_tipo_documento: false,
      err_numero_documento: false,
      err_scadenza_documento: false,
      err_documento_exists: false,
      err_visura_exists: false,
      err_phone_valid: false,

      photo_modal_src: '',
      file_modal_src: '',
    };
  },

  methods: {
    openPhotoModal: function(src, filename) {   

      if (filename.slice(-4) == '.pdf') {
        this.photo_modal_src = ''; 
        this.file_modal_src = src;
      } else {
        this.photo_modal_src = src;   
        this.file_modal_src = '';
      }
      this.$bvModal.show('modal-photo');
    },



    /* Change state methods */
    changeNomeState: function() {
      if (!this.nome || this.nome == null || this.nome == "") {
        this.nome_state = false;
      } else {
        this.nome_state = true;
        this.err_nome = false;
      }
    },

    changeCognomeState: function() {
      if (!this.cognome || this.cognome == null || this.cognome == "") {
        this.cognome_state = false;
      } else {
        this.cognome_state = true;
        this.err_cognome = false;
      }
    },

    changeGenderState: function() {
      if (!this.gender || this.gender == null || this.gender == "") {
        this.gender_state = false;
      } else {
        this.gender_state = true;
        this.err_gender = false;
      }
    },

    changeEmailState: function() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(this.email)) {
        this.email_state = false;
      } else {
        this.email_state = true;
        this.err_email = false;
        this.checkIfEmailExists();
      }
    },

    changeStatePassword: function() {
      if (this.password == "") {
        this.password_classes = "form-control";
        this.password_confirmation_classes = "form-control";
        this.old_password_confirmation_classes = "form-control";

        this.err_password = false;
        this.err_password_confirmation = false;
      } else if (this.password != "" && this.password.length < 8) {
        this.password_classes = "form-control is-invalid";
      } else {
        this.password_classes = "form-control is-valid";
        this.err_password = false;
      }
    },

    changeStateOldPassword: function() {
      if (this.old_password == "" && this.password != "") {
        this.old_password_classes = "form-control is-invalid";
      } else {
        this.old_password_classes = "form-control is-valid";
        this.err_old_password = false;
      }
    },

    changeStatePasswordConfirmation: function() {
      if (this.password == "") {
        this.password_confirmation_classes = "form-control is-valid";
        this.err_password_confirmation = false;
        this.password_confirmation = "";
        this.old_password = "";
        return;
      }

      if (
        this.password_confirmation != this.password ||
        this.password.length < 8
      ) {
        this.password_confirmation_classes = "form-control is-invalid";
      } else {
        this.password_confirmation_classes = "form-control is-valid";
        this.err_password_confirmation = false;
      }
    },

    changeIndirizzoState: function() {
      if (!this.indirizzo || this.indirizzo == null || this.indirizzo == "") {
        this.indirizzo_state = false;
      } else {
        this.indirizzo_state = true;
        this.err_indirizzo = false;
      }
    },

    changeCapState: function() {
      if (this.country_id != 151) {
        this.cap_state = true;
        this.err_cap = false;
        return;
      }
      if (!this.cap || this.cap == null || this.cap.length != 5) {
        this.cap_state = false;
      } else {
        var reg = /^\d+$/;
        if (!reg.test(this.cap)) {
          this.cap_state = false;
        } else {
          this.cap_state = true;
          this.err_cap = false;
        }
      }
    },

    changeComuneState: function() {
      if (!this.comune || this.comune == null || this.comune == "") {
        this.comune_state = false;
      } else {
        this.comune_state = true;
        this.err_comune = false;
      }
    },

    changeProvinciaState: function() {
      if (this.country_id != 151 && this.country_id != 144) {
        this.provincia_state = "form-control is-valid";
        this.err_provincia = false;
        return;
      }
      if (!this.provincia || this.provincia == null || this.provincia == "") {
        this.provincia_state = "form-control is-invalid";
      } else {
        this.provincia_state = "form-control is-valid";
        this.err_provincia = false;
      }
    },

    changeCountryIdState: function() {
      if (
        !this.country_id ||
        this.country_id == null ||
        this.country_id == ""
      ) {
        this.country_id_state = "form-control is-invalid";
      } else {
        this.country_id_state = "form-control is-valid";
        this.err_paese = false;
        this.changePartitaIvaState();
        this.changeCodiceFiscaleState();
        this.changeCapState();
      }
    },

    changeCodiceFiscaleState: function() {
      if (this.country_id != 151) {
        this.codice_fiscale_state = true;
        this.err_codice_fiscale = false;
        return;
      }
      const regex = new RegExp(
        /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/
      );
      if (this.tipologia == 0 && !regex.test(this.codice_fiscale)) {
        this.codice_fiscale_state = false;
      } else if (
        this.tipologia == 1 &&
        this.codice_fiscale != "" &&
        !regex.test(this.codice_fiscale)
      ) {
        this.codice_fiscale_state = false;
      } else {
        this.codice_fiscale_state = true;
        this.err_codice_fiscale = false;
      }
    },

    changeRagioneSocialeState: function() {
      if (
        this.tipologia == 1 &&
        (!this.ragione_sociale ||
          this.ragione_sociale == null ||
          this.ragione_sociale == "")
      ) {
        this.ragione_sociale_state = false;
      } else {
        this.ragione_sociale_state = true;
        this.err_ragione_sociale = false;
      }
    },

    changePartitaIvaState: function() {
      if (this.country_id == 151) {
        var reg = /^\d+$/;
        if (
          this.tipologia == 1 &&
          (!this.partita_iva ||
            this.partita_iva == null ||
            this.partita_iva == "" ||
            this.partita_iva.length != 11 ||
            !reg.test(this.partita_iva))
        ) {
          this.partita_iva_state = false;
        } else {
          this.partita_iva_state = true;
          this.err_partita_iva = false;
        }
      } else {
        this.partita_iva_state = true;
        this.err_partita_iva = false;
      }
    },

    changeTipoDocumentoRiconoscimentoState: function() {
      if (
        !this.tipo_documento_riconoscimento ||
        this.tipo_documento_riconoscimento == null ||
        this.tipo_documento_riconoscimento == ""
      ) {
        this.tipo_documento_riconoscimento_state = "form-control is-invalid";
      } else {
        this.tipo_documento_riconoscimento_state = "form-control is-valid";
        this.err_tipo_documento = false;
      }
    },

    changeNumeroDocumentoState: function() {
      if (
        !this.numero_documento ||
        this.numero_documento == null ||
        this.numero_documento == ""
      ) {
        this.numero_documento_state = false;
      } else {
        this.numero_documento_state = true;
        this.err_numero_documento = false;
      }
    },

    changeTelefonoState: function() {
      let reg = /^[\+]?\d{5,16}$/;
      if (
        !this.telefono ||
        this.telefono == null ||
        this.telefono == "" ||
        !reg.test(this.telefono)
      ) {
        this.telefono_state = false;
      } else {
        this.telefono_state = true;
        this.err_phone_valid = false;
      }
    },

    changeStateScadenzaDocumento: function() {
      if (this.scadenza_documento != "") {
        this.err_scadenza_documento = false;
      }
    },

    isPartitaIvaValid: function() {
      if (this.country_id != 151) {
        return true;
      }
      var reg = /^\d+$/;
      if (
        this.tipologia == 1 &&
        (!this.partita_iva ||
          this.partita_iva == null ||
          this.partita_iva == "" ||
          this.partita_iva.length != 11 ||
          !reg.test(this.partita_iva))
      ) {
        return false;
      } else {
        return true;
      }
    },

    isCodiceFiscaleValid: function() {
      if (this.country_id != 151) {
        return true;
      }
      const regex = new RegExp(
        /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/
      );
      if (this.tipologia == 0 && !regex.test(this.codice_fiscale)) {
        return false;
      } else if (
        this.tipologia == 1 &&
        this.codice_fiscale != "" &&
        !regex.test(this.codice_fiscale)
      ) {
        return false;
      } else {
        return true;
      }
    },

    phoneIsValid: function() {
      let reg = /^[\+]?\d{5,16}$/;
      if (
        !this.telefono ||
        this.telefono == null ||
        this.telefono == "" ||
        !reg.test(this.telefono)
      ) {
        return false;
      } else {
        return true;
      }
    },

    validTipoDocumento: function() {
      if (
        !this.tipo_documento_riconoscimento ||
        this.tipo_documento_riconoscimento == null ||
        this.tipo_documento_riconoscimento == ""
      ) {
        return false;
      } else {
        return true;
      }
    },

    validNumeroDocumento: function() {
      if (
        !this.numero_documento ||
        this.numero_documento == null ||
        this.numero_documento == ""
      ) {
        return false;
      } else {
        return true;
      }
    },

    validScadenzaDocumento: function() {
      if (
        !this.data_scadenza_documento ||
        this.data_scadenza_documento == null ||
        this.data_scadenza_documento == ""
      ) {
        return false;
      } else {
        return true;
      }
    },

    documentoExists: function() {
      console.log(this.documento_url);

      if (
        !this.download_documento ||
        this.download_documento == null ||
        this.download_documento == ""
      ) {
        if (this.documento_url == "") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    visuraExists: function() {
      if (this.tipologia == 1) {
        if (
          !this.download_file_visura_camerale_url ||
          this.download_file_visura_camerale_url == null ||
          (this.download_file_visura_camerale_url == "" &&
            this.visura_camerale_url == "")
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    handleFileDocumento: function() {

     /* this.$swal({
        icon: "error",
        text: translate("fe_vue_profilo_cliente.str_file_troppo_grande"),
        confirmButtonText: `OK`,
        confirmButtonColor: "#001f5c",
      });*/

      if (this.$refs.documento_url.files[0].size > 25165824) {
        this.$swal({
          icon: "error",
          text: translate("fe_vue_profilo_cliente.str_file_troppo_grande"),
          confirmButtonText: `OK`,
          confirmButtonColor: "#001f5c",
        });
        this.$refs.documento_url.value = "";
        return;
      }
      this.documento_url = this.$refs.documento_url.files[0];
      this.err_documento_exists = false;
    },
    handleFileProcura: function() {
      if (this.$refs.procura_url.files[0].size > 25165824) {
        this.$swal({
          icon: "error",
          text: translate("fe_vue_profilo_cliente.str_file_troppo_grande"),
          confirmButtonText: `OK`,
          confirmButtonColor: "#001f5c",
        });
        this.$refs.procura_url.value = "";
        return;
      }
      this.procura_url = this.$refs.procura_url.files[0];
      this.err_procura_url = false;
    },
    handleFileVisura: function() {
      if (this.$refs.visura_camerale_url.files[0].size > 25165824) {
        this.$swal({
          icon: "error",
          text: translate("fe_vue_profilo_cliente.str_file_troppo_grande"),
          confirmButtonText: `OK`,
          confirmButtonColor: "#001f5c",
        });
        this.$refs.visura_camerale_url.value = "";
        return;
      }
      this.visura_camerale_url = this.$refs.visura_camerale_url.files[0];
      this.err_visura_exists = false;
    },

    checkIfEmailExists: function() {
      axios
        .post(`/customer/check_if_email_exists_edit`, {
          email: this.email,
        })
        .then((res) => {
          if (res.data > 0) {
            this.$swal({
              text: translate(
                "fe_vue_profilo_cliente.Questo indirizzo email risulta già registrato"
              ),
              confirmButtonColor: "#001f5c",
            }).then((result) => {
              if (result.isConfirmed) {
                this.email_state = false;
                this.err_email = true;
                this.email = "";
              }
            });
          }
        })
        .catch((e) => {
          console.log(e.toString());
        });
    },

    aggiornaCliente: function() {
      /*if (this.$v.$invalid) {
        this.bloccato = true;
        return;
      }*/
      this.bloccato = false;

      let formData = new FormData();
      formData.append("prefix_tel", this.prefix_tel);
      formData.append("name", this.nome);
      formData.append("last_name", this.cognome);
      formData.append("gender", this.gender);
      formData.append("email", this.email);
      let comunicazioni_commerciali = this.comunicazioni_commerciali ? 1 : 0;
      formData.append("comunicazioni_commerciali", comunicazioni_commerciali);
      formData.append(
        "data_di_nascita",
        moment(this.data_di_nascita).format("YYYY-MM-DD")
      );

      if (this.password != "") {
        formData.append("password", this.password);
        formData.append("password_confirmation", this.password_confirmation);
        formData.append("old_password", this.old_password);
      }
      formData.append("indirizzo_fatturazione", this.indirizzo);
      formData.append("cap", this.cap);
      formData.append("comune", this.comune);
      formData.append("country_id", this.country_id);
      formData.append("provincia_sigla", this.provincia);
      formData.append("provincia_estera", this.provincia_estera);
      formData.append("ragione_sociale", this.ragione_sociale);
      formData.append("codice_fiscale", this.codice_fiscale);
      formData.append("partita_iva", this.partita_iva);
      formData.append("professione", this.professione);
      formData.append("codice_destinatario_fe", this.codice_destinatario_fe);
      formData.append("disposizione_procura", this.disposizione_procura);
      if (this.procura_url != "") {
        formData.append("procura_url", this.procura_url);
      }
      formData.append(
        "tipo_documento_riconoscimento",
        this.tipo_documento_riconoscimento
      );
      formData.append("numero_documento", this.numero_documento);
      formData.append(
        "data_scadenza_documento",
        moment(this.data_scadenza_documento).format("YYYY-MM-DD")
      );
      if (this.documento_url != "") {
        formData.append("documento_url", this.documento_url);
      }
      if (this.visura_camerale_url != "") {
        formData.append("visura_camerale_url", this.visura_camerale_url);
      }

      formData.append("telefono", this.telefono);

      axios
        .post("/customer/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data == "OK") {
            this.$swal({
              icon: "success",
              text: translate(
                "fe_vue_profilo_cliente.I tuoi dati sono stati aggiornati con successo"
              ),
              confirmButtonColor: "#001f5c",
              confirmButtonText: `OK`,
            }).then((result) => {
              if (result.isConfirmed) {
                location.href = "/customer";
              }
            });
          } else {


            this.$swal({
              icon: "error",
              text: res.data,
              confirmButtonColor: "#001f5c",
              confirmButtonText: `OK`,
            })
            
          }
        })
        .catch((e) => {
          console.log(e);
          this.errori_validazione.record(e.response.data);
        });
    },

    apriGeneratorePassword: function() {
      this.modale = true;
    },

    mostraPassword: function(campo) {
      if (campo == "password") {
        if (this.$refs.password.getAttribute("type") == "password") {
          this.$refs.password.setAttribute("type", "text");
          this.campo_mostra_password = "text";
        } else {
          this.$refs.password.setAttribute("type", "password");
          this.campo_mostra_password = "password";
        }
      } else if (campo == "old_password") {
        if (this.$refs.old_password.getAttribute("type") == "password") {
          this.$refs.old_password.setAttribute("type", "text");
          this.campo_mostra_password_old = "text";
        } else {
          this.$refs.old_password.setAttribute("type", "password");
          this.campo_mostra_password_old = "password";
        }
      } else {
        if (
          this.$refs.password_confirmation.getAttribute("type") == "password"
        ) {
          this.$refs.password_confirmation.setAttribute("type", "text");
          this.campo_mostra_password2 = "text";
        } else {
          this.$refs.password_confirmation.setAttribute("type", "password");
          this.campo_mostra_password2 = "password";
        }
      }
    },

    openCalendarDateOfBirth: function() {
      this.$refs.data_di_nascita.showCalendar();
    },

    openCalendarDocumentExpiration: function() {
      this.$refs.data_scadenza_documento.showCalendar();
    },

    emailIsValid: function(email) {
      return /\S+@\S+\.\S+/.test(email);
    },

    procuraUrlIsValid: function() {
      if (this.tipologia == 1 && this.disposizione_procura == 1) {
        if (
          (!this.download_file_procura ||
            this.download_file_procura == null ||
            this.download_file_procura == "") &&
          this.procura_url == ""
        ) {
          console.log("quiii", this.download_file_procura);
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    getMaxDate: function(ymd, date) {
      return (
        date >
        moment()
          .subtract(18, "years")
          .toDate()
      );
    },

    mostraErrori: function() {
      this.changeTipoDocumentoRiconoscimentoState();
      const tipo_documento = this.validTipoDocumento();
      this.changeNumeroDocumentoState();
      const numero_documento = this.validNumeroDocumento();
      const scadenza_documento = this.validScadenzaDocumento();
      const documento_exists = this.documentoExists();
      const visura_exists = this.visuraExists();
      this.changeTelefonoState();
      const phone_valid = this.phoneIsValid();
      if (
        !tipo_documento ||
        !numero_documento ||
        !scadenza_documento ||
        !documento_exists ||
        !visura_exists ||
        !phone_valid
      ) {
        this.mostra_errori = true;

        if (!tipo_documento) {
          this.err_tipo_documento = true;
        }
        if (!numero_documento) {
          this.err_numero_documento = true;
        }
        if (!scadenza_documento) {
          this.err_scadenza_documento = true;
        }
        if (!documento_exists) {
          this.err_documento_exists = true;
        }
        if (!visura_exists) {
          this.err_visura_exists = true;
        }
        if (!phone_valid) {
          this.err_phone_valid = true;
        }
      }
    },

    mostraErroriStep1: function() {
      this.$refs.last_back0.click();

      this.changeNomeState();
      if (this.nome_state == false) {
        this.err_nome = true;
      }

      this.changeCognomeState();

      if (this.cognome_state == false) {
        this.err_cognome = true;
      }

      this.changeGenderState();
      if (this.gender_state == false) {
        this.err_gender = true;
      }

      this.changeEmailState();

      if (this.email_state == false) {
        this.err_email = true;
      }

      this.changeStatePassword();
      if (this.password_classes == "form-control is-invalid") {
        this.err_password = true;
      }
      this.changeStateOldPassword();
      if (this.old_password_classes == "form-control is-invalid") {
        this.err_old_password = true;
      }

      this.changeStatePasswordConfirmation();

      if (this.password_confirmation_classes == "form-control is-invalid") {
        this.err_password_confirmation = true;
      }
    },

    mostraErroriStep2: function() {
      this.$refs.last_back.click();

      this.changeIndirizzoState();
      if (this.indirizzo_state == false) {
        this.err_indirizzo = true;
      }
      this.changeCapState();
      if (this.cap_state == false) {
        this.err_cap = true;
      }
      this.changeComuneState();
      if (this.comune_state == false) {
        this.err_comune = true;
      }
      this.changeCountryIdState();
      if (this.country_id_state == "form-control is-invalid") {
        this.err_paese = true;
      }
      this.changeProvinciaState();
      if (this.provincia_state == "form-control is-invalid") {
        this.err_provincia = true;
      }
      this.changeCodiceFiscaleState();
      if (this.codice_fiscale_state == false) {
        this.err_codice_fiscale = true;
      }
      this.changeRagioneSocialeState();
      if (this.ragione_sociale_state == false) {
        this.err_ragione_sociale = true;
      }
      this.changePartitaIvaState();
      if (this.partita_iva_state == false) {
        this.err_partita_iva = true;
      }
      if (this.procuraUrlIsValid() == false) {
        this.err_procura_url = true;
      }
      //document.getElementById('btn-goto-step3').classList.add("btn-next");
    },

    assignPassword: function(value) {
      this.password = value;
    },

    requestAccountDeletion: function() {
      axios
        .get(`/customer/check_if_active_cases`)
        .then((res) => {
          if (res.data > 0) {
            this.$swal({
              icon: "warning",
              text: translate("fe_vue_profilo_cliente.str_casi_attivi"),
              confirmButtonText: `OK`,
              confirmButtonColor: "#001f5c",
            }).then((resmsg) => {
              if (resmsg.isConfirmed) {
                return;
              }
            });
          } else {
            this.$swal({
              icon: "warning",
              text: translate(
                "fe_vue_profilo_cliente.str_avviso_prima_di_cancellazione_account"
              ),
              confirmButtonText: `OK`,
              confirmButtonColor: "#001f5c",
              showCancelButton: true,
            }).then((resmsg) => {
              if (resmsg.isConfirmed) {
                const str_message_to_send =
                  translate("fe_vue_profilo_cliente.Il cliente") +
                  " " +
                  this.cliente.user.name +
                  " " +
                  this.cliente.user.last_name +
                  " " +
                  translate("fe_vue_profilo_cliente.con indirizzo email") +
                  " " +
                  this.cliente.user.email +
                  " " +
                  translate(
                    "fe_vue_profilo_cliente.richiede la cancellazione del suo account"
                  );

                axios
                  .post(`/customer/messages/send`, {
                    sender_id: this.cliente.user_id,
                    sender_role: 5,
                    receiving_id: 1,
                    receiving_role: 1,
                    subject: translate(
                      "fe_vue_profilo_cliente.Richiesta eliminazione account"
                    ),
                    message: str_message_to_send,
                    nuovo: 1,
                  })
                  .then((res) => {
                    this.$swal({
                      icon: "success",
                      text: translate(
                        "fe_vue_profilo_cliente.str_richiesta_cancellazione_inviata"
                      ),
                      confirmButtonText: `OK`,
                      confirmButtonColor: "#001f5c",
                    });
                  })
                  .catch((e) => {
                    console.log(e.toString());
                  });
              } else {
                return;
              }
            });
          }
        })
        .catch((e) => {
          console.log(e.toString());
        });
    },

    status(validation) {
      return {
        /* error: validation.$error,
        dirty: validation.$dirty,*/
      };
    },
  },

  validations: {},
};
</script>
