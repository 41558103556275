<template>
  <div></div>
</template>
<script>
import axios from 'axios';
import EventBus from "../EventBus";

const default_layout = "default";

export default {
  

  mounted() {
    //const open_form = `<p><button class='btn btn-link' type='button' onclick="${this.sendFormResendEmail()}">` + translate("fe_vue_profilo_cliente.str_resend_email") + `</button></p>`;


    EventBus.$on("customer_register_success", (res) => {
      this.$swal({
        icon: "success",
        html: "<p>" + translate("fe_vue_profilo_cliente.str_grazie_per_esserti_registrato_customer_with_verification_email") + "</p>" + res + "<p>" + translate("fe_vue_profilo_cliente.str_controllo_mail_spam") + "</p>",
        confirmButtonColor: "#001f5c",
        /*confirmButtonText: translate("fe_vue_profilo_cliente.str_resend_email"),
        showCancelButton: true,
        cancelButtonText: `OK`,*/
      }).then((result) => {
        if (result.isConfirmed) {
        
        /*  

          axios.post(`/email/resend`).then((res) => {
            console.log(res)

            this.$swal({
              icon: "success",
              text: translate("fe_vue_profilo_cliente.str_email_inviata"),
              confirmButtonColor: "#001f5c",
              confirmButtonText:  `OK`,
            }).then((res2) => {
              if (res2.isConfirmed) {
                location.href = "/";
              }
            })

          }).catch((e) => {
            console.warn(e.toString())
          })
        */

        }
      });
    });
  },
  computed: {},
  components: { EventBus },
  data() {
    return {
       csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  },

  methods: {
  
  }

  
};
</script>
