<template>
  <div class="text-left">
    <div class="row">
      <div class="col text-center">
        <button class="btn btn-primary" v-on:click="inviaModulo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-check"
            viewBox="0 0 16 16"
          >
            <path
              d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
            />
          </svg>
          Completa l'acquisto
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";

//Vue.prototype.translate = require('../VueTranslation/Translation');

const default_layout = "default";

import axios from "axios";
import Datepicker from "vuejs-datepicker";
import { en, it } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    Datepicker,
  },
  mounted: function() {
    /*this.$swal({
      text: translate(
        "vue.Grazie per avere acquistato su Fidavo. L'agenzia che hai selezionato ha già ricevuto conferma metterà in contatto con te quanto prima."
      ),
    });*/
  },
  computed: {},
  props: {customer: String},
  data() {
    return {
      str_campo_obbligatorio: translate("vue.Campo obbligatorio"),
      bloccato: false,
      it: it,
      en: en,
      disabledDates: {
        to: moment().toDate(),
      },
      datacustomer: this.customer,
    };
  },
  methods: {
    inviaModulo: function() {
      let formData = new FormData();

      axios
        .post("/cliente/secondostep_senza_dati", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          /*this.$swal({
            icon: "success",
            text: translate("locale.Grazie per avere completato i tuoi dati."),

            confirmButtonText: `OK`,
          }).then((result) => {
            if (result.isConfirmed) {
              location.href = "/cliente";
            }
          });*/
          //location.href = "/cliente";
          location.href = "/search/fine_acquisto";
        })
        .catch((e) => {
          console.log(e);
          //this.errori_validazione.record(e.response.data);
        });
    },

    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },

  validations: {
    /*telefono: {
      required
    },

    tipo_documento_riconoscimento: {
      required
    },

    numero_documento: {
      required,
    },

    data_scadenza_documento: {
      required
    },*/
  },
};
</script>
