<template>
  <div>
    <a class="nav-link btn btn-secondary" href="javascript:void(0)" v-on:click="openRegister">{{ str_register }}</a>
    <customer-register-modal :locale_param="locale" :professional_role_names_param="professional_role_names"></customer-register-modal>
  </div>  
</template>
<script>
import EventBus from "../EventBus";
import CustomerRegisterModal from "./CustomerRegisterModal.vue";



const default_layout = "default";



export default {
  computed: {},
  props: {
    locale_param: String,
    professional_role_names_param: String,
  },
  components: {
      CustomerRegisterModal,
  },
  data() {
      return {          
          str_register: translate("fe_layout.Registrati"),

          locale: this.locale_param,

          professional_role_names: JSON.parse(this.professional_role_names_param),
      }
  },
  mounted() {
    
  },
  methods: {
      openRegister: function() {          
          EventBus.$emit("show_register_modal", 1);
      }
  }
};
</script>
