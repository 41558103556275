<template>
  <div class="col">
    <h2>{{ str_contacts }}</h2>

    <p>* {{ str_required }}</p>

    <div class="form-group">
      <label>{{ str_first_name }} *</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="fas fa-user"></i>
          </div>
        </div>
        
        <b-form-input :state="first_name_state"
         class="form-control" 
         v-model="first_name"
          v-on:input="changeStateFirstName" 
          v-on:blur="changeStateFirstName" />
      </div>

    </div>

    <div class="form-group">
      <label>{{ str_last_name }} *</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="fas fa-user"></i>
          </div>
        </div>
        
        <b-form-input 
        :state="last_name_state" 
        class="form-control" 
        v-model="last_name" 
        v-on:input="changeStateLastName" 
        v-on:blur="changeStateLastName" />
      </div>
    </div>

    <div class="form-group">
      <label>{{ str_email }} *</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="fas fa-envelope"></i>
          </div>
        </div>
        
        <b-form-input :state="email_state" type="email"
         class="form-control" 
         v-model="email"
          v-on:input="changeStateEmail" 
          v-on:blur="changeStateEmail" />
      </div>

    </div>

    <div class="form-group">
      <label>{{ str_subject }} *</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="fas fa-pencil-alt"></i>
          </div>
        </div>
        
        <b-form-input 
        :state="subject_state" 
        class="form-control" 
        v-model="subject" 
        v-on:input="changeStateSubject" 
        v-on:blur="changeStateSubject" />

      </div>
    </div>

    <div class="form-group">
      <label>{{ str_text }} *</label>      
      <b-form-textarea rows="10" class="form-control" 
      v-model="message" :state="message_state"
       v-on:input="changeStateMessage" v-on:blur="changeStateMessage"></b-form-textarea>
    </div>

    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="privacy"
        v-model="privacy"
      />
      <label class="form-check-label" for="privacy">
        <a :href="privacy_url">{{ str_privacy }}</a> *
      </label>
    </div>



    <div class="row mt-3" v-if="msg_invio_in_corso">
      <div class="col px-1 py-1">
        <div class="card bg-warning">
          <div class="card-body">
            {{ str_invio_in_corso }}
          </div>
        </div>
      </div>
    </div>


    <vue-recaptcha :sitekey="recaptcha_key" @verify="checkCaptcha" ref="grecaptcha_field"></vue-recaptcha>



    <div class="form-group text-right">
      <button
        class="btn btn-secondary"
        v-if="!checkIfComplete"
        :disabled="disabled"
      >
        {{ str_send }}
      </button>
      <button class="btn btn-secondary" v-if="checkIfComplete" @click="sendForm">
        {{ str_send }}
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueRecaptcha from 'vue-recaptcha';

import { BootstrapVue, BFormInput, BSpinner, BFormTextarea } from "bootstrap-vue";

const default_layout = "default";

export default {
  props: {
    recaptcha_param: String,
  },
  components: {VueRecaptcha, BootstrapVue, BFormInput, BSpinner, BFormTextarea},
  computed: {
    checkIfComplete: function() {
      if (
        this.first_name != "" &&
        this.last_name != "" &&
        this.email != "" &&
        this.subject != "" &&
        this.checkPrivacy() == 1 &&
        this.message != "" &&
        this.ValidateEmail(this.email) &&
        this.recaptcha_lock == false &&
        this.msg_invio_in_corso == false
      ) {
        return true;
      }
    },
  },
  data() {
    return {
      str_contacts: translate("fe_vue_contatti.Contatti"),
      str_first_name: translate("fe_vue_contatti.Nome"),
      str_last_name: translate("fe_vue_contatti.Cognome"),
      str_email: translate("fe_vue_contatti.Email"),
      str_subject: translate("fe_vue_contatti.Oggetto"),
      str_text: translate("fe_vue_contatti.Testo del messaggio"),
      str_send: translate("fe_vue_contatti.Invia"),
      str_privacy: translate("fe_vue_contatti.Accetto il trattamento dei dati personali"),
      str_required: translate("fe_vue_contatti.Campi obbligatori"),
      str_response_message: translate("fe_vue_contatti.str_thanks_contatti"),
      str_invio_in_corso: translate("fe_vue_contatti.Invio messaggio in corso"),

      disabled: true,
      privacy_url: "/privacy",
      msg_invio_in_corso: false,

      recaptcha_key: this.recaptcha_param,
      recaptcha_lock: true,

      email_class: "form-control",

      first_name: "",
      last_name: "",
      email: "",
      subject: "",
      message: "",
      privacy: false,


      /* States */
      first_name_state: null,
      last_name_state: null,
      email_state: null,
      subject_state: null,
      message_state: null,
    };
  },
  methods: {
    sendForm: function() {
      this.msg_invio_in_corso = true;

      let formData = new FormData();
      formData.append("first_name", this.first_name);
      formData.append("last_name", this.last_name);
      formData.append("email", this.email);
      formData.append("subject", this.subject);
      formData.append("message", this.message);

      axios
        .post("/contacts/send", formData)
        .then((res) => {
          this.disabled = false;
          this.msg_invio_in_corso = false;
          this.$swal({
            icon: "success",
            text: this.str_response_message,

            confirmButtonText: `OK`,
          }).then((result) => {
            if (result.isConfirmed) {
              location.href = "/";
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    ValidateEmail: function(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {        
        return true;
      }      
      return false;
    },

    checkPrivacy: function() {
      if (this.privacy == true) {
        return 1;
      }
      return 0;
    },

    checkCaptcha: function(response) {


      if (response) {
       
        axios.post(`/contacts/check_captcha`, {
          ccaptcha: response
        }).then((res) => {                           
          if (res.data == 1) {            
            
            this.recaptcha_lock = false;
            
          } else {
            this.$refs.grecaptcha_field.reset();
            
          }
        }).catch((e) => {
          console.log(e.toString());
        })       
      }
    },

    changeStateFirstName: function() {
      if (this.first_name == "") {
        this.first_name_state = false;
      } else {
        this.first_name_state = true;
      }
    },

    changeStateLastName: function() {
      if (this.last_name == "") {
        this.last_name_state = false;
      } else {
        this.last_name_state = true;
      }
    },

    changeStateEmail: function()
    {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.email_state = true;
      } else {
        this.email_state = false;
      }      
    },

    changeStateSubject: function() {
      if (this.subject == "") {
        this.subject_state = false;
      } else {
        this.subject_state = true;
      }
    },

    changeStateMessage: function() {
      if (this.message == "") {
        this.message_state = false;
      } else {
        this.message_state = true;
      }
    },

  },
};
</script>
