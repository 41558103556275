<template>
  <div style="text-align:left">
    <div class="mt-3">
     <h1>{{ str_risultati }}</h1>
      
      <h4 v-if="categoria != ''">{{ descrizione_categoria }}</h4>

      <div class="loader" v-if="loader">Loading...</div>

      
      <!-- Nuovo pannello -->
      <div >
        <div
          class="bg_white band mb-3"
          v-for="(res, indice) in elenco_risultati"
          :key="indice"
        >
          <div class="px-3 mx-5">
            <div class="card mb-3">
              <div class="row">
                <div class="col-md-2">
                  <img
                    :src="res.logo_criptato"
                    v-if="res.logo"
                    class="img-fluid border-blue rounded"
                    alt="..."
                  />

                  <svg
                    height="150"
                    width="150"
                    class="cerchio"
                    v-if="!res.logo"
                  >
                    <circle
                      cx="75"
                      cy="75"
                      r="50%"
                      stroke="black"
                      stroke-width="0"
                      fill="grey"
                    />
                    Sorry, your browser does not support inline SVG.
                    <text
                      x="50%"
                      y="55%"
                      text-anchor="middle"
                      fill="white"
                      font-size="2em"
                    >
                      {{ res.ragione_sociale | iniziali(res.ragione_sociale) }}
                    </text>
                  </svg>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h5 class="card-title">
                      <a :href="getUrlAgencyProfile(res.id)">{{
                        res.ragione_sociale
                      }}</a>
                      <img
                        :src="corona_url"
                        v-if="res.piano == 3"
                        class="align-baseline ml-2"
                        alt="..."
                      />
                    </h5>

                    <p class="card-text color_orange">
                      <span v-html="convertPointsToStars(res.average)"></span>

                      <span
                        class="color_dark_blue"
                        @click="mostra_recensioni(res.id)"
                        >({{ res.reviews }})</span
                      >
                    </p>

                    <p class="card-text">
                      <small>
                        <!--<i class="fas fa-circle text-success"></i>
                      <img src="data-frontend/images/message.jpg" alt="..." />
                      Contattaci
                      -->
                        <span
                          v-for="(prod, chiave) in res.agency_products"
                          :key="chiave"
                        >
                          <span v-for="(pr, i) in prod.products" :key="i">{{
                            pr.nome
                          }}</span>
                        </span>
                      </small>
                    </p>
                  </div>
                </div>
                <div class="col-md-3 py-3">
                  <h5 class="text-right text-muted font-light">
                    <span v-for="(prod, chiave) in res.agency_products" :key="chiave">
                      
                      <span v-if="prod.product_id == id_prodotto">
                      <del v-if="prod.prezzo_scontato &&  prod.prezzo_scontato > 0">{{ prod.prezzo }} €</del>
                      <h5 class="text-right" v-if="!prod.prezzo_scontato || prod.prezzo_scontato == 0">
                        {{ prod.prezzo }} €
                      </h5>
                      <h5 class="text-right" v-if="prod.prezzo_scontato &&  prod.prezzo_scontato > 0">
                        {{ prod.prezzo_scontato }} €
                      </h5>
                      </span>
                    </span>
                  </h5>
                  <span v-for="(prod, chiave) in res.agency_products" :key="chiave">
                    <span v-for="(pr, i) in prod.products" :key="i">
                      <a
                        class="btn btn-secondary w-100"
                        href="#"
                        v-if="prod.prezzo_scontato &&  prod.prezzo_scontato > 0"
                        v-on:click="
                          procediAcquisto(prod.prezzo_scontato, res.id, pr.id, data_servizio)
                        "
                        >{{ str_acquista }}</a
                      >
                      <a
                        class="btn btn-secondary w-100"
                        href="#"
                        v-if="!prod.prezzo_scontato || prod.prezzo_scontato == 0"
                        v-on:click="procediAcquisto(prod.prezzo, res.id, pr.id, data_servizio)"
                        >{{ str_acquista }}</a
                      >
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Fine nuovo pannello -->


      <div class="card" v-if="elenco_risultati.length == 0">
        <div class="card-body">
          No results
        </div>
      </div>
    </div>

    <ModalStep1
      v-if="mostra_modal"
      :business="business"
      :nazioni="paesi"
      :province="dataprovince"
      :agenzia_id="agenzia_acquisto_id"
      :prezzo="prezzo_acquisto"
      :prodotto="prod_id"
    ></ModalStep1>

    <form action="/search/redireziona_pagamento" method="post" ref="formredir">
      <input type="hidden" name="_token" :value="csrf" />
      <input
        type="hidden"
        name="agenzia_id"
        ref="frm_agenzia_id"
        :value="agenzia_acquisto_id"
      />
      <input
        type="hidden"
        name="prezzo"
        ref="frm_prezzo_id"
        :value="prezzo_acquisto"
      />
      <input type="hidden" name="prod_id" ref="frm_prod_id" :value="prod_id" />
    </form>
  </div>
</template>
<style scoped>
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0,105,217, 0.2);
  border-right: 1.1em solid rgba(0,105,217, 0.2);
  border-bottom: 1.1em solid rgba(0,105,217, 0.2);
  border-left: 1.1em solid #0069d9;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.giallo {
  color: #FFCC00;
}

svg.cerchio{
  display: block;
  margin: 5px auto 5px auto;
}

.dirty {
  border-color: #5a5;
  background: #efe;
}

.dirty:focus {
  outline-color: #8e8;
}

.error {
  border:1px solid red;
  background: #fdd;
  padding: 5px;
  
}

.error:focus {
  outline-color: #f99;
}

input[type='file'] {
  color: #fff;  
}



/* Tooltip */
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
</style>
<script>
import Vue from "vue";
import axios from "axios";
import VueSimpleRangeSlider from "vue-simple-range-slider";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import Datepicker from "vuejs-datepicker";
import { en } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import ModalStep1 from "./ModalStep1";
//import "./Ricerca.css";

import EventBus from "./EventBus";

import ExpandableTextLine from "vue-expandable-text-line";

const default_layout = "default";

export default {
  components: {
    VueSimpleRangeSlider,
    Datepicker,
    ModalStep1,
    ExpandableTextLine,
    EventBus,
  },
  mounted: function() {
    this.business = this.datacustomer.privato_business;

    console.log(this.elenco_risultati);
  },
  computed: {
    /*filtraCategorieComp: function(prod_id) {
      return this.elenco_categorie.filter(function(cat) {
        //return cat.product_id == prod_id;

        return cat.product_id == prod_id;
      });
    },*/
  },
  
  props: {
    customer: String,
    risultati_alternativi: String,
    product_id_param: String,
    corona_url_param: String
  },

  filters: {
    truncate: function(value, lung) {
      if (value.length > lung) {
        return value.substr(0, lung) + "...";
      } else {
        return value;
      }
    },

    iniziali: function(value) {
      return value.substr(0, 2).toUpperCase();
    },
  },
  data() {
    return {
      nome: "",
      prezzo_da: 0,
      prezzo_a: 0,
      categoria: "",
      provincia: "",
      data_servizio: "",
      data_servizio_a: "",
      it: en,
      en: en,
      disabledDates: {
        to: moment().toDate(),
      },
      str_risultati: "Search results",
      //str_durata_servizio: translate("fe_vue_ricerca.Durata del servizio"),
      //str_giorni: translate("fe_vue_ricerca.giorni"),
      //str_ore: translate("fe_vue_ricerca.ore"),
      //str_prezzo: translate("fe_vue_ricerca.Prezzo"),
      str_acquista: "Buy",
      //str_continua: translate("fe_vue_ricerca.Continua"),
      /*str_tooltip_singola: translate(
        "fe_vue_ricerca.Prodotti come visura camerale, rintracciare numero telefono etc."
      ),*/
      //str_tooltip_durata: translate("fe_vue_ricerca.Servizio di pedinamento etc."),
      //str_fini_giudiziari: translate("fe_vue_ricerca.Prova valida ai fini giudiziari"),
      /*str_videochiamata: translate(
        "fe_vue_ricerca.Questo prodotto richiede una videochiamata prima della conferma"
      ),*/
      /*str_localizzazione: translate(
        "fe_vue_ricerca.Questo prodotto richiede di indicare un luogo dove si svolgerà"
      ),*/
      //str_clicca_leggi_tutto: translate("fe_vue_ricerca.Clicca per leggere tutto"),
      //str_recensioni: translate("fe_vue_ricerca.Recensioni"),

      //url_loghi1: "/files/view_file_agenzia/",
      //url_loghi2: "/logo",
      descrizione_categoria: null,

      
      
      risultati: false,
      
      elenco_risultati: JSON.parse(this.risultati_alternativi),
      corona_url: this.corona_url_param,
      
      loader: false,
      mostra_modal: false,
      datacustomer: JSON.parse(this.customer),
      business: null,
      
      agenzia_acquisto_id: 0,
      prezzo_acquisto: 0,
      id_prodotto: this.product_id_param,
      prod_id: 0,
      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  },
  methods: {
    filtraCategorie: function(prodotto_id) {
      return this.elenco_categorie.filter(function(cat) {
        cat = JSON.stringify(cat);
        console.log(cat.product_id, prodotto_id);
        return cat.product_id == prodotto_id;
      });
    },
    getFotoProdotto: function(product_id) {
      return `/files/view_file_prodotto_ricerca/${product_id}`;
    },
    

   

    procediAcquisto: function(prezzo, agenzia_id, prod_id) {
      if (this.datacustomer.cap) {
        this.agenzia_acquisto_id = agenzia_id;
        this.prezzo_acquisto = prezzo;

        this.$refs.frm_agenzia_id.value = agenzia_id;
        this.$refs.frm_prezzo_id.value = prezzo;
        this.$refs.frm_prod_id.value = prod_id;
        //console.log(prezzo);
        this.$refs.formredir.submit();
      } else {
        this.mostra_modal = true;
        this.agenzia_acquisto_id = agenzia_id;
        this.prezzo_acquisto = prezzo;
        this.prod_id = prod_id;
      }
    },

    

   

    convertPointsToStars: function(points) {
      let output;
      const vuota = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
          <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
        </svg>
      `;

      const mezza = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-half" viewBox="0 0 16 16">
          <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z"/>
        </svg>`;

      const piena = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
        </svg>
      `;

      const zero = vuota + vuota + vuota + vuota + vuota;
      const zero_cinque = mezza + vuota + vuota + vuota + vuota;
      const uno = piena + vuota + vuota + vuota + vuota;
      const uno_cinque = piena + mezza + vuota + vuota + vuota;
      const due = piena + piena + vuota + vuota + vuota;
      const due_cinque = piena + piena + mezza + vuota + vuota;
      const tre = piena + piena + piena + vuota + vuota;
      const tre_cinque = piena + piena + piena + mezza + vuota;
      const quattro = piena + piena + piena + piena + vuota;
      const quattro_cinque = piena + piena + piena + piena + mezza;
      const cinque = piena + piena + piena + piena + piena;

      if (points == 0) {
        output = zero;
      } else if (points > 0 && points <= 0.5) {
        output = zero_cinque;
      } else if (points > 0.5 && points <= 1) {
        output = uno;
      } else if (points > 1 && points <= 1.5) {
        output = uno_cinque;
      } else if (points > 1.5 && points <= 2) {
        output = due;
      } else if (points > 2 && points <= 2.5) {
        output = due_cinque;
      } else if (points > 2.5 && points <= 3) {
        output = tre;
      } else if (points > 3 && points <= 3.5) {
        output = tre_cinque;
      } else if (points > 3.5 && points <= 4) {
        output = quattro;
      } else if (points > 4 && points <= 4.5) {
        output = quattro_cinque;
      } else if (points > 4.5 && points <= 5) {
        output = cinque;
      }

      return output;
    },

    mostra_recensioni: function(id) {
      console.log(id);
      EventBus.$emit("show_sidebar_agency", id);
    },

    getUrlAgencyProfile: function(id) {
      return `/agency-profile/${id}`;
    },
  },
};
</script>
