// Genera password
$(document).on("click", "#btn-generate-password", function () {
  var length = 8,
          charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*(){}[]=<>/,.|~?",
          retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
  }

  $("#password_generata").val(retVal);
})

$(document).on("click", "#chk-copied", function () {
  if ($(this).is(":checked")) {
      $("#btn-use-password").attr("disabled", false);
  } else {
      $("#btn-use-password").attr("disabled", true);
  }
})

$(document).on("click", "#btn-use-password", function () {
  let password_generata = $("#password_generata").val();
  $("#password_insert").val(password_generata);
  $("#password_insert_confirm").val(password_generata);

  $("#modifica_password").val(password_generata);
  $("#conferma_modifica_password").val(password_generata);

  $("#passwordModalCenter").modal('hide');
})

import Vue from 'vue'
window.translate = require('../VueTranslation/Translation').default.translate;

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const opzioni_sweet_alert = {
  confirmButtonColor: '#001f5c',    
};
Vue.use(VueSweetalert2, opzioni_sweet_alert);

import vmodal from 'vue-js-modal'
Vue.use(vmodal)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import DisableAutocomplete from 'vue-disable-autocomplete';
//Vue.use(DisableAutocomplete);

import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);

import VueSession from 'vue-session'
var session_options = {
  persist: true
}

Vue.use(VueSession, session_options)

var VueCookie = require('vue-cookie');
// Tell Vue to use the plugin
Vue.use(VueCookie);


import * as VueGoogleMaps from 'vue2-google-maps'
//if ($("#autocomplete_search_location").length == 0) { // Se non c'è da compilare il luogo del caso
  //console.log("in custom.js")
  Vue.use(VueGoogleMaps, {
      load: {
        key: process.env.MIX_GOOGLE_API_KEY,
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)
    
        //// If you want to set the version, you can do so:
        // v: '3.26',
      },
    
      //// If you intend to programmatically custom event listener code
      //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
      //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
      //// you might need to turn this on.
      // autobindAllEvents: false,
    
      //// If you want to manually install components, e.g.
      //import {GmapMarker} from 'vue2-google-maps/src/components/marker'
      //Vue.component('GmapMarker', GmapMarker)
      //// then disable the following:
      //installComponents: true,
    })
 
//}



var EventBus = new Vue;

import RegistrazioneAgenzia from "../views/frontend/agenzia/RegistrazioneAgenzia";
Vue.component('registrazione-agenzia', require('../views/frontend/agenzia/RegistrazioneAgenzia').default);

//import RegistrazioneAgenziaNew from "../views/frontend/agenzia/RegistrazioneAgenziaNew";
//Vue.component('registrazione-agenzia-new', require('../views/frontend/agenzia/RegistrazioneAgenziaNew').default);

import RegistrazioneCliente from "../views/frontend/cliente/RegistrazioneCliente";
Vue.component('registrazione-cliente', require('../views/frontend/cliente/RegistrazioneCliente').default);

import RegistrazioneAgente from "../views/frontend/agente/RegistrazioneAgente";
Vue.component('registrazione-agente', require('../views/frontend/agente/RegistrazioneAgente').default);

//import Ricerca from "../views/frontend/ricerca/Ricerca.vue";
//Vue.component('ricerca', require('../views/frontend/ricerca/Ricerca.vue').default);

import NewSearch from "../views/frontend/new-search/NewSearch.vue";
Vue.component('new-search', require('../views/frontend/new-search/NewSearch.vue').default);

import NewSearchHome from "../views/frontend/new-search/NewSearchHome.vue";
Vue.component('new-search-home', require('../views/frontend/new-search/NewSearchHome.vue').default);

import Related from "../views/frontend/new-search/Related.vue";
Vue.component('related-products', require('../views/frontend/new-search/Related.vue').default);

import Alternatives from "../views/frontend/ricerca/Alternatives.vue";
Vue.component('alternatives', require('../views/frontend/ricerca/Alternatives.vue').default);

import Acquisto from "../views/frontend/acquisto/Acquisto";
Vue.component('acquisto-dati-cliente', require('../views/frontend/acquisto/Acquisto.vue').default);

import AcquistoSenzaDati from "../views/frontend/acquisto/AcquistoSenzaDati";
Vue.component('acquisto-senza-dati', require('../views/frontend/acquisto/AcquistoSenzaDati.vue').default);

import EditCustomer from "../views/customers/EditCustomer";
Vue.component('edit-customer', require('../views/customers/EditCustomer.vue').default);

import Contacts from '../views/frontend/contacts/Contacts';
Vue.component('contact-form', require('../views/frontend/contacts/Contacts.vue').default);

import Answer from '../views/frontend/contacts/Answer';
Vue.component('answer', require('../views/frontend/contacts/Answer.vue').default);


import BSidebar from "../views/frontend/ricerca/BSidebar";
Vue.component('barra-bootstrap', require("../views/frontend/ricerca/BSidebar").default);

import ResultMap from "../views/frontend/new-search/ResultMap.vue";
Vue.component('result-map', require("../views/frontend/new-search/ResultMap.vue").default);

import Step1DataModal from "../views/frontend/new-search/includes/Step1DataModal.vue";
Vue.component('step1-data-modal', require("../views/frontend/new-search/includes/Step1DataModal.vue").default);

import ButtonRegistrati from "../views/frontend/new-search/includes/ButtonRegistrati.vue";
Vue.component('button-registrati', require("../views/frontend/new-search/includes/ButtonRegistrati.vue").default);

import ButtonRegistratiMobile from "../views/frontend/new-search/includes/ButtonRegistratiMobile.vue";
Vue.component('button-registrati-mobile', require("../views/frontend/new-search/includes/ButtonRegistratiMobile.vue").default);

import LoginButton from "../views/frontend/auth/LoginButton.vue";
Vue.component('button-login', require("../views/frontend/auth/LoginButton.vue").default);

import BlueLoginButton from "../views/frontend/auth/BlueLoginButton.vue";
Vue.component('blue-button-login', require("../views/frontend/auth/BlueLoginButton.vue").default);

import BlueMobileLoginButton from "../views/frontend/auth/BlueMobileLoginButton.vue";
Vue.component('blue-mobile-button-login', require("../views/frontend/auth/BlueMobileLoginButton.vue").default);

import MobileLoginButton from "../views/frontend/auth/LoginButton.vue";
Vue.component('mobile-button-login', require("../views/frontend/auth/MobileLoginButton.vue").default);

import OpenLogin from "../views/frontend/auth/OpenLogin.vue";
Vue.component('open-login', require("../views/frontend/auth/OpenLogin.vue").default);

import OpenRegister from "../views/frontend/auth/OpenRegister.vue";
Vue.component('open-register', require("../views/frontend/auth/OpenRegister.vue").default);

import OpenPasswordRecover from "../views/frontend/auth/OpenPasswordRecover.vue";
Vue.component('open-password-recover', require("../views/frontend/auth/OpenPasswordRecover.vue").default);

import OpenPasswordReset from "../views/frontend/auth/OpenPasswordReset.vue";
Vue.component('open-password-reset', require("../views/frontend/auth/OpenPasswordReset.vue").default);

import AgencyGallery from "../views/frontend/AgencyGallery.vue";
Vue.component('agency-gallery', require("../views/frontend/AgencyGallery.vue").default);


if (document.getElementById('registrazione-agenzia')) {    
    const cats = new Vue({
      el: '#registrazione-agenzia',
      components: { RegistrazioneAgenzia }
    });
    
  }

  if (document.getElementById('registrazione-agenzia-new')) {    
    const cats = new Vue({
      el: '#registrazione-agenzia-new',
      components: { RegistrazioneAgenzia }
    });
    
  }

  
  if (document.getElementById('registrazione-cliente')) {    
    const cats = new Vue({
      el: '#registrazione-cliente',
      components: { RegistrazioneCliente }
    });
    
  }

  if (document.getElementById('registrazione-agente')) {    
    const cats = new Vue({
      el: '#registrazione-agente',
      components: { RegistrazioneAgente }
    });
    
  }
/*
  if (document.getElementById('modulo-ricerca')) {    
    const cats = new Vue({
      el: '#modulo-ricerca',
      components: { Ricerca }
    });
    
  }
*/
  if (document.getElementById('new-search')) {    
    const cats = new Vue({
      el: '#new-search',
      components: { NewSearch }
    });
    
  }

  if (document.getElementById('new-search-home')) {    
    const cats = new Vue({
      el: '#new-search-home',
      components: { NewSearchHome }
    });
  }

if (document.getElementById('new-search-home-mobile')) {
    const cats = new Vue({
        el: '#new-search-home-mobile',
        components: { NewSearchHome }
    });

}


  if (document.getElementById('secondo-step')) {    
    const cats = new Vue({
      el: '#secondo-step',
      components: { Acquisto }
    });
    
  }
 
  if (document.getElementById('secondo-step-senza-dati')) {    
    const cats = new Vue({
      el: '#secondo-step-senza-dati',
      components: { AcquistoSenzaDati }
    });
    
  }

  if (document.getElementById('modifica-profilo-cliente')) {    
    const cats = new Vue({
      el: '#modifica-profilo-cliente',
      components: { EditCustomer }
    });
    
  }
 
  if (document.getElementById('contacts')) {    
    const cats = new Vue({
      el: '#contacts',
      components: { Contacts }
    });
    
  }

  if (document.getElementById('answer')) {    
    const cats = new Vue({
      el: '#answer',
      components: { Answer }
    });
    
  }

  

if (document.getElementById('barra-bootstrap')) {
  const sidebar_recensioni = new Vue({
      el: '#barra-bootstrap',
      components: {BSidebar},
  });  
}


if (document.getElementById('alternatives')) {
  const sidebar_recensioni = new Vue({
      el: '#alternatives',
      components: {Alternatives},
  });  
}

if (document.getElementById('result-map')) {
  const result_map = new Vue({
      el: '#result-map',
      components: {ResultMap},
  });  
}

if (document.getElementById('prodotti-correlati')) {
  const related = new Vue({
      el: '#prodotti-correlati',
      components: {Related},
  });  
}

if (document.getElementById('step1-data-modal')) {
  const related = new Vue({
      el: '#step1-data-modal',
      components: {Step1DataModal},
  });  
}

if (document.getElementById('btn-registrati')) {
  const related = new Vue({
      el: '#btn-registrati',
      components: {ButtonRegistrati},
  });  
}

if (document.getElementById('btn-registrati-mobile')) {
  const related = new Vue({
      el: '#btn-registrati-mobile',
      components: {ButtonRegistratiMobile},
  });  
}

if (document.getElementById('btn-accedi')) {
  const related = new Vue({
      el: '#btn-accedi',
      components: {LoginButton},
  });  
}

/*if (document.getElementsByClassName('blue-login-button')) {
  let accedi_elements = document.getElementsByClassName('blue-login-button');

  let i = 0;
  for (let c of accedi_elements) {
    c.setAttribute("id", "blue-login-button-" + i);

    new Vue({
      el: '#blue-login-button-' + i,
      components: {BlueLoginButton},
    });

    i++;
  }
  
}*/

if (document.getElementById('blue-login-button')) {
  const lgn = new Vue({
      el: '#blue-login-button',
      components: {LoginButton},
  });  
}

if (document.getElementById('blue-mobile-login-button')) {
  const lgnmob = new Vue({
      el: '#blue-mobile-login-button',
      components: {BlueMobileLoginButton},
  });  
}


if (document.getElementById('mobile-btn-accedi')) {
  const related = new Vue({
      el: '#mobile-btn-accedi',
      components: {MobileLoginButton},
  });  
}

if (document.getElementById('open-login')) {
  const related = new Vue({
      el: '#open-login',
      components: {OpenLogin},
  });  
}

if (document.getElementById('open-register')) {
  const related = new Vue({
      el: '#open-register',
      components: {OpenRegister},
  });  
}

if (document.getElementById('open-password-recover')) {
  const related = new Vue({
      el: '#open-password-recover',
      components: {OpenPasswordRecover},
  });  
}

if (document.getElementById('open-password-reset')) {
  const related = new Vue({
      el: '#open-password-reset',
      components: {OpenPasswordReset},
  });  
}

if (document.getElementById('agency-gallery')) {
  const related = new Vue({
      el: '#agency-gallery',
      components: {AgencyGallery},
  });  
}



$("#gridCheck").on("change", function(){
  let state;
  if ($(this).is(":checked")) {
    state = 1;
  } else {
    state = 0;
  }
  updateCommercialCommunication(state);
  //console.log("stato", state)

})

$("#payment-form").on("submit", function(){
  try {
    if (!$("#gridCheck").is(":checked")) {
      updateCommercialCommunication(0);
    }  
  } catch (error) {
    console.error(error.toString())
  }
  
})


function updateCommercialCommunication(state)
{
  jQuery.ajax({
    url: '/customer/update_commercial_communications/' + state,
    method: 'GET'
  }).fail(function(e) {
    console.log(e.toString());
  }).done(function(res) {
    if (res.data == 'OK') {
      console.log(res);
    }
  })
}


  