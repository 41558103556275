<template>
  <div>    
    <a class="nav-link btn btn-outline-primary" href="javascript:void(0)" v-on:click="openRegister">{{ str_accedi }}</a>
    <general-mobile-login-modal></general-mobile-login-modal>
  </div>  
</template>
<script>
//import EventBus from "./EventBus";
import EventBus from "../new-search/EventBus";
import GeneralMobileLoginModal from "./GeneralMobileLoginModal.vue";



const default_layout = "default";



export default {
  computed: {},
  components: {
      EventBus,
      GeneralMobileLoginModal,
  },
  data() {
      return {          
          str_accedi: translate("fe_layout.Accedi"),
      }
  },
  mounted() {
    
  },
  methods: {
      openRegister: function() {          
          EventBus.$emit("show_general_mobile_login_modal", 1);
      }
  }
};
</script>
