<template>
  <div>
    <button
      id="refreshButton"
      class="btn btn-secondary btn-lg"
      v-if="filtra"
      v-on:click="refresh"
    >
      <i class="fas fa-sync-alt"></i>
    </button>
    <!--<l-map style="height: 95vh" :zoom="zoom" :center="center" ref="mappa" v-if="!$isMobile()">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      
      <l-marker v-for="(marker, indice) in pins" :key="indice"
       :lat-lng="marker" :icon="predefinitaIcon"
        v-on:click="hilight(marker)" v-on:mouseover="showAgencyDetail(marker)" v-on:mouseout="cleanTooltip">
          <l-tooltip :options="{ direction: 'auto', offset: point}" :content="tooltip_content"></l-tooltip>
        </l-marker>
        
    </l-map>


    <l-map style="height: 95vh" :zoom="zoom" :center="center" ref="mappa" v-if="$isMobile() && pins.length > 0">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      
      <l-marker v-for="(marker, indice) in pins" :key="indice"
       :lat-lng="marker" :icon="predefinitaIcon"
         v-on:click="showAgencyDetail(marker)">
          <l-popup :options="{ direction: 'top', offset: point}" :content="tooltip_content"></l-popup>
        </l-marker>
        
    </l-map>-->

    <GmapMap
      ref="mappa"
      :center="center"
      :zoom="zoom"
      map-type-id="roadmap"
      style="height: 95vh"
      v-if="!$isMobile()"
    >
      <GmapMarker
        v-for="(marker, indice) in pins"
        :key="indice"
        :position="marker"
        :clickable="true"
        :draggable="false"
        :icon="markerOptions"
        @click="showAgencyDetail(marker)"
      />

      <gmap-info-window
        :options="{
          maxWidth: 300,
          pixelOffset: { width: 0, height: -35 },
        }"
        :position="infoWindow.position"
        :opened="infoWindow.open"
        @closeclick="infoWindow.open = false"
      >
        <div v-html="infoWindow.template"></div>
      </gmap-info-window>
    </GmapMap>

    <GmapMap
      ref="mappa"
      :center="center"
      :zoom="zoom"
      map-type-id="roadmap"
      style="height: 95vh"
      v-if="$isMobile() && pins.length > 0"
    >
      <GmapMarker
        v-for="(marker, indice) in pins"
        :key="indice"
        :position="marker"
        :clickable="true"
        :draggable="false"
        :icon="markerOptions"
        @click="showAgencyDetail(marker)"
      />

      <gmap-info-window
        :options="{
          maxWidth: 300,
          pixelOffset: { width: 0, height: -35 },
        }"
        :position="infoWindow.position"
        :opened="infoWindow.open"
        @closeclick="infoWindow.open = false"
      >
        <div v-html="infoWindow.template"></div>
      </gmap-info-window>

    </GmapMap>
  </div>
</template>
<style scoped>
#refreshButton {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1029;
}
</style>
<script>
import axios from "axios";
import { LMap, LTileLayer, LMarker, LTooltip, LPopup } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import { latLng } from "leaflet";

//import imgmarker from "./markers/marker-icon.png";
import imgmarker from "./markers/pin_color.png";
import imgmarkerdoppio from "./markers/marker-icon-2x.png";
import imgmarkerombra from "./markers/marker-shadow.png";
import imgmarkerdorato from "./markers/marker-icon-gold.png";

import GmapMarker from "vue2-google-maps/src/components/marker";
import { gmapApi } from "vue2-google-maps";

delete L.Icon.Default.prototype._getIconUrl;

import EventBus from "./EventBus";

const default_layout = "default";

export default {
  mounted() {
    /*if (this.pins.length == 0)  {
      
      this.$gmapApiPromiseLazy().then(() => {        
        var bounds = new google.maps.LatLngBounds();
        
        console.log("qui dentro", this.center)
        bounds.extend(this.center);
        setTimeout(() => {
          this.$refs.mappa.fitBounds(bounds)
        }, 100)
      })

    }*/

    EventBus.$on("send_markers", (markers) => {
      this.pins = [];
      markers.forEach((element) => {
        let lat = element[0];

        let lng = element[1];

        if (lat && lng) {
          //this.pins.push([lat, lng]);
          this.pins.push(latLng(lat, lng));
        }
      });

      for (let i = 0; i < this.pins.length; i++) {
        if (this.pins[i][0] == 0) {
          this.pins.splice(i, 1);
        }
      }

      //var group = L.featureGroup(this.pins);
      //this.$refs.mappa.mapObject.fitBounds(group.getBounds());

      if (this.pins.length) {
        //console.log(this.pins)

        this.$nextTick(() => {
          //this.$refs.mappa.mapObject.fitBounds(this.pins.map(m => { return [m[0], m[1]] }))
        });
      }

      if (this.pins.length > 0) {
        this.$gmapApiPromiseLazy().then(() => {
          var bounds = new google.maps.LatLngBounds();
          this.pins.forEach((element) => {
            bounds.extend(element);
          });
          setTimeout(() => {
            this.$refs.mappa.fitBounds(bounds);
          }, 100);
        });
      }
    });
  },

  computed: {
    google: gmapApi,
  },

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LPopup,
    GmapMarker,
  },
  data() {
    return {
      str_vai_a_profilo_agenzia: translate("vue.Vai al profilo dell'agenzia"),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 7,
      //center: [41.8926754, 12.4909212],
      //markerLatLng: [41.8926754, 12.4909212],

      center: latLng(53.3561549, -7.4038369),
      markerLatLng: latLng(53.3561549, -7.4038369),

      predefinitaIcon: L.icon({
        iconUrl: imgmarker,
        shadowUrl: imgmarkerombra,
      }),

      doppiaIcon: L.icon({
        iconUrl: imgmarkerdoppio,
        shadowUrl: imgmarkerombra,
      }),

      dorataIcon: L.icon({
        iconUrl: imgmarkerdorato,
        shadowUrl: imgmarkerombra,
      }),

      markerOptions: {
        url: imgmarker,
      },

      infoWindow: {
        position: { lat: 0, lng: 0 },
        open: false,
        template: "",
      },

      latitudine: 0,
      longitudine: 0,

      pins: [],

      tooltip_content: "",
      point: L.point(50, 50),
      filtra: false,
    };
  },
  methods: {
    setCoordinates: function() {
      this.center = [this.latitudine, this.longitudine];
      this.markerLatLng = [this.latitudine, this.longitudine];
    },

    hilight: function(marker) {
      this.filtra = true;
      //console.log(marker)
      EventBus.$emit("click_marker", marker);
    },

    showAgencyDetail: function(marker, idx) {
      //console.log(marker)
      //let latitude = marker[0];
      let latitude = marker.lat;
      //let longitude = marker[1];
      let longitude = marker.lng;

      this.infoWindow.position = { lat: latitude, lng: longitude };
      //this.infoWindow.template = `Prova`
      this.infoWindow.open = true;

      axios
        .get(`search/load_agency_by_markers/${latitude}/${longitude}`)
        .then((resp) => {
          //location.href = '/agency-profile/' + resp.data.id;
          //return;
          let ragione_sociale = resp.data.ragione_sociale;
          let agency_id = resp.data.cryptid;
          let indirizzo = resp.data.indirizzo_sede_principale;
          let cap = resp.data.cap;
          let comune = resp.data.comune;
          let provincia = resp.data.provincia_sigla;
          let points = this.convertPointsToStars(resp.data.average);

          let servizi = "";
          for (let s of resp.data.servizi) {
            servizi += s.tipi_indagine[0].nome + "<br>";
          }

          let n = 0;
          let template_info_window = `<p><b>${ragione_sociale}</b></p>`;
          template_info_window += `<p>${points}</p>`;
          template_info_window += `<p><a href="/agency-profile/${resp.data.id}" class="color_light_blue btn-descrizione_secondo">${this.str_vai_a_profilo_agenzia}</a></p>`;

          this.pins.forEach((element) => {
            if (element[0] == latitude && element[1] == longitude) {
              let logo;
              if (resp.data.logo) {
                logo = `<img src="/files/view_file_agenzia_frontend/${agency_id}/logo" alt="logo" height="30" />`;
              } else {
                logo = this.getLogoIniziali(resp.data.ragione_sociale);
              }

              let content = `<p>${logo} ${ragione_sociale} ${points}</p>`;
              content += `<p>${indirizzo}<br>`;
              content += `${cap} ${comune} (${provincia})</p>`;
              content += `<p>${servizi}</p>`;
              let clickable;
              if (this.$isMobile()) {
                let marker = [latitude, longitude];
              }

              this.tooltip_content = content;

              //template_info_window += content;
            }
            n++;
          });

          this.infoWindow.template = template_info_window;
        })
        .catch((e) => {
          console.log(e.toString());
        });
    },

    cleanTooltip: function() {
      this.tooltip_content = "";
    },

    convertPointsToStars: function(points) {
      let output;
      const vuota = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
          <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
        </svg>
      `;

      const mezza = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-half" viewBox="0 0 16 16">
          <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z"/>
        </svg>`;

      const piena = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
        </svg>
      `;

      const zero = vuota + vuota + vuota + vuota + vuota;
      const zero_cinque = mezza + vuota + vuota + vuota + vuota;
      const uno = piena + vuota + vuota + vuota + vuota;
      const uno_cinque = piena + mezza + vuota + vuota + vuota;
      const due = piena + piena + vuota + vuota + vuota;
      const due_cinque = piena + piena + mezza + vuota + vuota;
      const tre = piena + piena + piena + vuota + vuota;
      const tre_cinque = piena + piena + piena + mezza + vuota;
      const quattro = piena + piena + piena + piena + vuota;
      const quattro_cinque = piena + piena + piena + piena + mezza;
      const cinque = piena + piena + piena + piena + piena;

      if (points == 0) {
        output = zero;
      } else if (points > 0 && points <= 0.5) {
        output = zero_cinque;
      } else if (points > 0.5 && points <= 1) {
        output = uno;
      } else if (points > 1 && points <= 1.5) {
        output = uno_cinque;
      } else if (points > 1.5 && points <= 2) {
        output = due;
      } else if (points > 2 && points <= 2.5) {
        output = due_cinque;
      } else if (points > 2.5 && points <= 3) {
        output = tre;
      } else if (points > 3 && points <= 3.5) {
        output = tre_cinque;
      } else if (points > 3.5 && points <= 4) {
        output = quattro;
      } else if (points > 4 && points <= 4.5) {
        output = quattro_cinque;
      } else if (points > 4.5 && points <= 5) {
        output = cinque;
      }

      return output;
    },

    getLogoIniziali: function(rs) {
      let ragione_sociale = rs.substr(0, 2).toUpperCase();
      return `<svg
                    height="30"
                    width="30"
                    class="cerchio"                  
                  >
                    <circle
                      cx="15"
                      cy="15"
                      r="50%"
                      stroke="black"
                      stroke-width="0"
                      fill="grey"
                    />
                    Sorry, your browser does not support inline SVG.
                    <text
                      x="50%"
                      y="55%"
                      text-anchor="middle"
                      fill="white"
                      font-size="1em"
                    >
                      ${ragione_sociale}
                    </text>
                  </svg>`;
    },

    refresh: function() {
      EventBus.$emit("refresh_map", 1);
      this.filtra = false;
    },
  },
};
</script>
