<template>
  <div>
    <CoolLightBox 
                :items="uploaded_photos" 
                :index="index"
                @close="index = null"
                thumbsPosition="bottom" 
                :useZoomBar="true">
              </CoolLightBox>

    
     <div class="images-wrapper">
            
                <div class="row">
                  <div class="col-md-2 col-sm-6" v-for="(image, imageIndex) in uploaded_photos"
                  :key="imageIndex">
                    <a href="javascript:;"><img :src="image" alt="Foto" @click="index = imageIndex" class="img-fluid"></a>
                  </div>
                </div>  
              </div>

  </div>
</template>
<script>
import Vue from 'vue';

//Vue.prototype.translate = require('../VueTranslation/Translation');

const default_layout = "default";

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'




export default {
    components: {
        CoolLightBox
    },
    mounted() {
        if (this.foto1_param && this.foto1_param != '') {
            this.uploaded_photos.push(this.foto1_param);
        }
        if (this.foto2_param && this.foto2_param != '') {
            this.uploaded_photos.push(this.foto2_param);
        }
        if (this.foto3_param && this.foto3_param != '') {
            this.uploaded_photos.push(this.foto3_param);
        }
        if (this.foto4_param && this.foto4_param != '') {
            this.uploaded_photos.push(this.foto4_param);
        }
        if (this.foto5_param && this.foto5_param != '') {
            this.uploaded_photos.push(this.foto5_param);
        }
    },
  computed: {},
  props: {
      foto1_param: String,
      foto2_param: String,
      foto3_param: String,
      foto4_param: String,
      foto5_param: String,
  },
  data() {
      return {
         uploaded_photos: [],
         index: null,
      }
  }
};
</script>
