<template>
  <div>
    <b-modal
      id="modal-1"
      ref="password_recover-modal"
      centered
      :title="str_ripristina_password"
      :hide-footer="true"      
    >
      

      <div class="form-group">
          <label>{{ str_email }} *</label>
          <input type="text" :class="email_classes" v-model="email"  
           v-on:keyup="changeStateEmail"
                v-on:blur="changeStateEmail"  >
      </div>

      <div class="form-group">
          <label>{{ str_nuova_password }} ({{ str_min_8 }}) *</label>
          <div class="input-group">
              <input                
                type="password"
                ref="password"
                v-model="password"
                :class="password_classes"                
                v-on:keyup="changeStatePassword"
                v-on:blur="changeStatePassword"                
              />
              <div class="input-group-append">
                <span
                  class="input-group-text cursor-pointer"
                  v-on:click="mostraPassword('password')"
                >
                  <i class="fas fa-eye"></i>
                </span>
              </div>
            </div>
      </div>

      <div class="form-group">
          <label>{{ str_conferma_password }} *</label>
          <div class="input-group">
              <input                
                type="password"
                ref="password_confirmation"
                v-model="password_confirmation"
                :class="password_confirmation_classes"                
                v-on:keyup="changeStatePasswordConfirmation"
                v-on:blur="changeStatePasswordConfirmation"                
              />
              <div class="input-group-append">
                <span
                  class="input-group-text cursor-pointer"
                  v-on:click="mostraPassword('password_confirmation')"
                >
                  <i class="fas fa-eye"></i>
                </span>
              </div>
            </div>
      </div>

      <div class="row" v-if="error">
          <div class="col bg-danger">{{ str_error }}</div>
      </div>

      <button class="btn btn-primary" v-on:click="setNewPassword">{{ str_imposta_password }}</button>

           
      
    </b-modal>

   
  </div>
</template>
<style scoped>
/deep/ .header_blue {
  background-color: #0038a7;
  color: white;
}

input[type="text"],
input[type="password"] {
  border: 1px solid #0038a7;
}

button.btn-yellow {
  background-color: #ffa91e;
}

button.btn-outline-blue {
  border: 1px solid #0038a7;
  background-color: white;
  color: #0038a7;
}

p,
a {
  color: #0038a7;
}

hr {
  border-top: 1px solid #0038a7;
}
</style>

<script>
import axios from "axios";
import EventBus from "./EventBus";

import { BootstrapVue, BModal, BSpinner } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

const default_layout = "default";

export default {
  props: {
   token_param: String,
  },

  computed: {
   
    }, 

  components: {
    BootstrapVue,
    BModal,
    BSpinner,
    EventBus,
  },

    

  mounted() {
      
    EventBus.$on(
      "show_password_reset_modal",
      function(val) {          
        this.showModal();
      }.bind(this)
    );
    
  },

  computed: {},
  data() {
    return {
      /* Strings */
      str_ripristina_password: translate("vue.Ripristina password"),
      str_email: translate("vue.Indirizzo Email"),
      str_nuova_password: translate("vue.Nuova Password"),  
      str_conferma_password: translate("vue.Conferma Password"),
      str_imposta_password: translate("vue.Imposta nuova password"),
      str_min_8: translate("vue.Minimo 8 caratteri"),
      str_error: translate("vue.Controllare che i valori immessi siano corretti e rispettino i parametri indicati"),

      /* v-model */
      email: "",
      password: "",
      password_confirmation: "",
      token: this.token_param,   


      password_classes: 'form-control',
      password_confirmation_classes: 'form-control',
      email_classes: 'form-control',
      email_error: 0,
      password_error: 0,
      password_confirmation_error: 0,

      error: false,

    
      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),

      login_on_course: false,  
    };
  },

  methods: {
    showModal() {
      this.$refs["password_recover-modal"].show();
    },
    hideModal() {
      this.$refs["password_recover-modal"].hide();
    },

    setNewPassword: function() {
        if (this.email_error == 1 || this.password_error == 1 || this.password_confirmation_error == 1) {
            this.error = true;
        } else {
            this.error = false;
        }

        axios.post(`/reset-password`, {
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
            token: this.token,            
        }).then((res) => {
            
            if (res.data == 'passwords.reset') {
                this.$swal({            
                    icon: 'success',
                    confirmButtonColor: '#001f5c',
                 text: translate("vue.La tua password è stata modificata"),
                    confirmButtonText: `OK`,
                }).then((result) => {
                    this.email = '';  
                 this.hideModal();
                 location.href = '/';
                });
            } else {
                this.$swal({            
                    icon: 'error',
                    confirmButtonColor: '#001f5c',
                 text: translate("vue.Impossibile modificare la password tentativo di frode"),
                    confirmButtonText: `OK`,
                }).then((result) => {
                    this.email = '';  
                 this.hideModal();
                 location.href = '/';
                });
            }
            
            

        }).catch((e) => {
            console.log(e.toString());
        })
    },


    mostraPassword: function(campo) {
      if (campo == "password") {
        if (this.$refs.password.getAttribute("type") == "password") {
          this.$refs.password.setAttribute("type", "text");
          this.campo_mostra_password = "text";
        } else {
          this.$refs.password.setAttribute("type", "password");
          this.campo_mostra_password = "password";
        }
      } else {
        if (this.$refs.password_confirmation.getAttribute("type") == "password") {
          this.$refs.password_confirmation.setAttribute("type", "text");
          this.campo_mostra_password2 = "text";
        } else {
          this.$refs.password_confirmation.setAttribute("type", "password");
          this.campo_mostra_password2 = "password";
        }
      }
    },

    changeStatePassword: function() {
        if (this.password.length < 8) {
            this.password_classes = 'form-control is-invalid';
            this.password_error = 1;
        } else {
            this.password_classes = 'form-control is-valid';
            this.password_error = 0;
        }
    },

    changeStatePasswordConfirmation: function() {
        if (this.password_confirmation != this.password) {
            this.password_confirmation_classes = 'form-control is-invalid';
            this.password_confirmation_error = 1;
        } else {
            this.password_confirmation_classes = 'form-control is-valid';
            this.password_confirmation_error = 0;
        }
    },

    changeStateEmail: function() {
        if (this.email == '') {
            this.email_classes = 'form-control is-invalid';
            this.email_error = 1;
        } else {
            this.email_classes = 'form-control is-valid';
            this.email_error = 0;
        }
    },

     

    

    

    
  },
};
</script>
