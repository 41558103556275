<template>
  <div>
    <div class="bg_white band" v-if="correlati.length > 0">
      <div class="container">
        <div id="prodotti-correlati">
          <h4>{{ str_potrebbe_interessarti }}</h4>
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4">
            <div class="col" v-for="(rel, index) in correlati" :key="index">
              <div class="card p-1 h-100">
                <a :href="`/search-product?name=${encodeURIComponent(rel.nome)}&county=XX&service_date=${getSearchDate()}&from_agency_profile=1`">
                <img
                  :src="getProductPhoto(rel.id)"
                  class="card-img-top px-5 py-md-2 px-md-4 h-75"
                  style="object-fit: contain;"
                  :alt="getALTImage(rel)"
                />
              </a>
                <div class="card-body px-1">
                  <p class="card-text text-center">
                    <a :href="`/search-product?name=${encodeURIComponent(rel.nome)}&county=XX&service_date=${getSearchDate()}&from_agency_profile=1`">
                      {{ rel.nome }}
                    </a>
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "./EventBus";

//Vue.prototype.translate = require('../VueTranslation/Translation');

const default_layout = "default";

export default {
  components: {
    EventBus,
  },

  mounted() {
    EventBus.$on("prodotti_correlati", (rel) => {
      this.correlati = [];
      rel.forEach((element) => {
        this.correlati.push(element);
      });
    });
  },

  computed: {},
  data() {
    return {
      str_potrebbe_interessarti: translate("fe_vue_ricerca.Potrebbe interessarti anche"),

      correlati: [],
    };
  },

  methods: {
    getProductPhoto: function(id) {
      return `/files/view_file_prodotto_ricerca_frontend/${id}`;
    },
    getSearchDate: function () {
      //return moment(new Date()).format("DD-MM-YYYY");
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      return urlParams.get('service_date')
    },

    getALTImage: function (related) {
      const language_code = document.querySelector("html").getAttribute("lang");

      if(language_code === 'it') {
        return related.seo_alt_img_it;
      } else {
        return related.seo_alt_img_en;
      }

    }

  },
};
</script>
