<template>
  <modal name="modal-step1" width="60%" height="auto" :scrollable="datascrolleble" :clickToClose="false">
    <div class="card mb-n1">
      <div class="card-header">
        <h1 class="card-title">Fidavo.com | Completamento profilo</h1>

        <h5>
         Scusa l’interruzione, prima di poter acquistare il servizio abbiamo bisogno di alcune informazioni su di te
        </h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group" v-if="databusiness == 0">
              <label>Codice Fiscale</label>
              <input
                type="text"
                class="form-control"
                v-model="$v.codice_fiscale.$model"
                :class="status($v.codice_fiscale)"
              />
              <div
                class="error"
                v-if="!$v.codice_fiscale.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>

              <div
                class="error"
                v-if="!$v.codice_fiscale.validCodiceFiscale && bloccato == true"
              >
                {{ str_cf_non_valido }}
              </div>

              
            </div>

            <div class="form-group" v-if="databusiness == 1">
              <label>Partita IVA</label>
              <input
                type="text"
                class="form-control"
                v-model="$v.partita_iva.$model"
                :class="status($v.partita_iva)"
              />
              <div
                class="error"
                v-if="!$v.partita_iva.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Indirizzo</label>
              <input
                type="text"
                class="form-control"
                v-model="$v.indirizzo.$model"
                :class="status($v.indirizzo)"
              />
              <div
                class="error"
                v-if="!$v.indirizzo.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>CAP</label>
              <input
                type="text"
                class="form-control"
                v-model="$v.cap.$model"
                :class="status($v.cap)"
              />
              <div class="error" v-if="!$v.cap.required && bloccato == true">
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Comune</label>
              <input
                type="text"
                class="form-control"
                v-model="$v.comune.$model"
                :class="status($v.comune)"
              />
              <div class="error" v-if="!$v.comune.required && bloccato == true">
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label>Paese</label>
            <select
              class="form-control"
              v-model="$v.country_id.$model"
              :class="status($v.country_id)"
            >
              <option value=""></option>
              <option
                :value="paese.id"
                v-for="(paese, indice) in paesi"
                :key="indice"
                >{{ paese.name }}</option
              >
            </select>
            <div
              class="error"
              v-if="!$v.country_id.required && bloccato == true"
            >
              {{ str_campo_obbligatorio }}
            </div>
          </div>
          <div class="col-md-6">
            <label>Provincia</label>
            <select
              class="form-control"
              v-model="$v.provincia_sigla.$model"
              :class="status($v.provincia_sigla)"
              v-if="country_id == 151"
            >
              <option value=""></option>
              <option
                :value="provincia.codice"
                v-for="(provincia, indice) in dataprovince"
                :key="indice"
                >{{ provincia.nome }}</option
              >
            </select>
            <div
              class="error"
              v-if="!$v.provincia_sigla.required && bloccato == true"
            >
              {{ str_campo_obbligatorio }}
            </div>

            <input
              type="text"
              class="form-control"
              v-model="provincia_estera"
              v-if="country_id != 151"
            />
          </div>
        </div>

        <div class="row mt-3" v-if="databusiness == 1">
          <div class="col-md-6">
            <label>Ragione sociale</label>
            <input
              type="text"
              class="form-control"
              v-model="$v.ragione_sociale.$model"
              :class="status($v.ragione_sociale)"
            />
            <div
              class="error"
              v-if="!$v.ragione_sociale.required && bloccato == true"
            >
              {{ str_campo_obbligatorio }}
            </div>
          </div>
          <div class="col-md-6">
            <p>Disponi di una procura?</p>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                value="1"
                v-model="$v.disposizione_procura.$model"
                :class="status($v.disposizione_procura)"
              />
              <label class="form-check-label">Sí</label>
            </div>

            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                value="0"
                v-model="$v.disposizione_procura.$model"
                :class="status($v.disposizione_procura)"
              />
              <label class="form-check-label">No</label>
            </div>
            <div
              class="error"
              v-if="!$v.disposizione_procura.required && bloccato == true"
            >
              {{ str_campo_obbligatorio }}
            </div>
          </div>
        </div>

        <div class="row mt-3" v-if="disposizione_procura == 1">
          <div class="col">
            <div class="form-group">
              <label>Upload procura</label>
              <input
                type="file"
                v-on:change="handleFileUpload()"
                ref="procura_url"
              />

              <div v-if="procura_url">
                <span class="bg-secondary text-white"  v-if="procura_url">{{
                  procura_url.name
                }}</span>
              </div>

              <div class="error" v-if="errore_procura_url && bloccato == true">
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group" v-if="databusiness == 1">
              <label>Codice destinatario fatturazione elettronica</label>
              <input
                type="text"
                class="form-control"
                v-model="codice_fatturazione_elettronica"                
              />
              
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" v-if="databusiness == 1">
            <label>Codice Fiscale se diverso da partita IVA</label>
            <input type="text" class="form-control" v-model="codice_fiscale" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group mt-3">
              <button class="btn btn-secondary" v-on:click="chiudi">
                Chiudi
              </button>
              <!--<button class="btn btn-primary" v-if="bloccato == true" disabled>
                Prosegui con l'acquisto
              </button>-->
              <button class="btn btn-primary" v-on:click="prosegui()">
                Prosegui con l'acquisto
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>

  

</template>

<script>
import Vue from "vue";

//Vue.prototype.translate = require('../VueTranslation/Translation');

const default_layout = "default";

import axios from "axios";

import { required, requiredIf, helpers } from "vuelidate/lib/validators";

const validCodiceFiscale = helpers.regex("validCodiceFiscale", /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/);

export default {
  name: "ModalStep1",
  computed: {},
  /*props: ["business", "nazioni", "province", "agenzia_id", "prezzo", "prodotto"],*/
  props: {
    business: String,
    nazioni: String,
    province: String,
    agenzia_id: String,
    prezzo: String,
    prodotto: String
  },
  mounted: function() {
    this.$modal.show("modal-step1");
    console.log(this.agenzia)
  },
  data() {
    return {
      str_campo_obbligatorio: translate("vue.Campo obbligatorio"),
      str_cf_non_valido: translate("vue.Il codice fiscale inserito non è valido"),
      databusiness: this.business,
      datascrolleble: true,
      dataheight: 200,
      paesi: this.nazioni,
      dataprovince: this.province,
      bloccato: false,
      errore_procura_url: false,

      codice_fiscale: "",
      partita_iva: "",
      indirizzo: "",
      cap: "",
      comune: "",
      country_id: 151,
      provincia_sigla: "",
      provincia_estera: "",
      ragione_sociale: "",
      disposizione_procura: 0,
      procura_url: "",
      codice_fatturazione_elettronica: "",
      agenzia: this.agenzia_id,
      prezzo_acquisto: this.prezzo,
      prod_id: this.prodotto,
    };
  },
  methods: {
    chiudi: function() {
      this.$modal.hide("modal-step1");
      this.$parent.mostra_modal = false;
    },
    handleFileUpload() {
      this.procura_url = this.$refs.procura_url.files[0];
      this.errore_procura_url = false;
    },

    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },

    prosegui: function() {
      if (this.$v.$invalid) {
        this.bloccato = true;
        return false;
      }
      if (
        this.databusiness == 1 &&
        this.disposizione_procura == 1 &&
        this.procura_url == ""
      ) {
        this.errore_procura_url = true;
        this.bloccato = true;
        return false;
      }
      
      let formData = new FormData();      

      formData.append("codice_fiscale", this.codice_fiscale);
      if (this.partita_iva != "") {
        formData.append("partita_iva", this.partita_iva);
      }
      formData.append("indirizzo", this.indirizzo);
      formData.append("ragione_sociale", this.ragione_sociale);


      formData.append("cap", this.cap);
      formData.append("comune", this.comune);
      formData.append("country_id", this.country_id);
      if (this.provincia_sigla != '') {
        formData.append("provincia_sigla", this.provincia_sigla);
      }
      if (this.provincia_estera != '') {
        formData.append("provincia_estera", this.provincia_estera);
      }
      formData.append("disposizione_procura", this.disposizione_procura);
      if (this.procura_url != '') {
        formData.append("procura_url", this.procura_url);
      }
      if (this.codice_fatturazione_elettronica != '') {
        formData.append("codice_fatturazione_elettronica", this.codice_fatturazione_elettronica);
      }

      formData.append("agenzia_id", this.agenzia);
      formData.append("prezzo", this.prezzo_acquisto);
      formData.append("prod_id", this.prod_id)

       axios
        .post("cliente/primostep", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          location.href = '/search/redireziona_pagamento_semplice/' + this.agenzia + '/' + this.prod_id + '/' + this.prezzo_acquisto;
        })
        .catch((e) => {
          console.log(e);
          //this.errori_validazione.record(e.response.data);
        });
      
      
    },
  },

  validations: {
    partita_iva: {
      required: requiredIf(function() {
        return this.databusiness == 1;
      }),
    },

    codice_fiscale: {
      required: requiredIf(function() {
        return this.databusiness == 0;
      }),
      validCodiceFiscale
    },

    indirizzo: {
      required,
    },

    ragione_sociale: {
      required: requiredIf(function() {
        return this.databusiness == 1;
      }),
    },

    comune: {
      required,
    },

    provincia_sigla: {
      required: requiredIf(function() {
        return this.country_id == 151;
      }),
    },

    cap: {
      required,
    },

    country_id: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    disposizione_procura: {
      required: requiredIf(function() {
        //return this.databuness == 1;
        return 1 == 1;
      }),
    },

    procura_url: {
      required: requiredIf(function() {
        return this.databuness == 1 && this.disposizione_procura == 1;
      }),
    },

    
  },
};
</script>
