<template>
  <div>
       

    <form class="text-center rounded pt-3 px-4 m-4 frm-ricerca frm-ricerca-result">
      <div class="form-row input_ricerche">

        <div class="col-md-10">
          <div class="row">
            <div class="col-md-6 mb-1">
              <vue-simple-suggest
                  @focus="focusSearch"
                  @blur="blurSearch"
                  name="name"
                  v-model="nome"
                  :list="autocompleteProduct"
                  :filter-by-query="false"
                  @suggestion-click="onSuggestSelect()"
                  :placeholder="str_cosa"
                  ref="nome" :styles="autoCompleteStyle" autocomplete="off" :title="nome">

              </vue-simple-suggest>
            </div>
            <div class="col-md-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                </div>
                <select
                    class="form-control custom-select border-blue without-left-border"
                    v-model="provincia" :disabled="disabledProvincia"
                >
                  <option value="">{{ str_dove }}</option>
                  <option value="XX">All Ireland</option>
                  <option
                      :value="prov.codice"
                      v-for="(prov, indice) in dataprovince"
                      :key="indice"
                  >{{ prov.nome }}</option
                  >
                  <!--
                   <option value="MI">Milano</option>
                  <option value="PD">Padova</option>
                  <option value="RM">Roma</option>
                  <option value="UD">Udine</option>
                  <option value="SC">Charleston (USA - SC)</option>
                  -->
                </select>
              </div>
            </div>
          </div>
          <div class="row mt-1 mb-2">
            <div class="col">
              <datepicker



                  v-model="data_servizio"
                  :disabled="disabledDataServizio"
                  format="dd-MM-yyyy"
                  :language="it"
                  :disabledDates="disabledDates"
                  :placeholder="str_quando"
                  clear-button
                  :input-class="input_class_datepicker"
                  :bootstrap-styling="true"
                  wrapper-class="bg-date-white"
                  style="position: relative; z-index: 1029;"
              ></datepicker>
            </div>
          </div>
        </div>
        <div class="col-md-2 text-right d-flex align-items-center justify-content-end">
          <input type="hidden" ref="nascosto">
          <button
              type="button"
              class="btn btn-secondary"
              :disabled="disabledSearch"
              v-on:click="resetFiltra(); eseguiRicerca()"
          >
            {{ str_cerca }}
          </button>
        </div>






      </div>


    </form>

    <div class="form-row text-left px-5" v-if="tot_results > 0 || filtra">
      <!-- Ricerca avanzata -->
      <div class="form-group col-sm-auto mb-0">
        <p>
          <strong>{{ str_ricerca_avanzata }}</strong>
        </p>
      </div>
      <div class="form-group col-sm-9 mb-0">
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-sm btn-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ str_prezzo }}
          </button>
          <div class="dropdown-menu rounded mt-1 shadow-sm">
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              v-on:click="filterPrice(0, 300)"
              >&lt; 300 euro</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              v-on:click="filterPrice(350, 500)"
              >350-500 euro</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              v-on:click="filterPrice(550, 700)"
              >550-700 euro</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              v-on:click="filterPrice(750, 900)"
              >750-900 euro</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              v-on:click="filterPrice(950, 1100)"
              >950-1100 euro</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              v-on:click="filterPrice(1100, 1000000)"
              >&gt; 1100 euro</a
            >
          </div>
        </div>
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-sm btn-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ str_recensioni }}
          </button>
          <div class="dropdown-menu rounded mt-1 shadow-sm">
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              v-on:click="filterReviews(5)"
            >
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </a>
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              v-on:click="filterReviews(4)"
            >
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="far fa-star"></i>
            </a>
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              v-on:click="filterReviews(3)"
            >
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="far fa-star"></i>
              <i class="far fa-star"></i>
            </a>
          </div>
        </div>

        <div class="btn-group" v-if="filtra">
          <button type="button" class="btn btn-secondary btn-sm" v-on:click="refresh">{{ str_elimina_filtri }}</button>
        </div>
      </div>



    </div>

    <!-- Results -->
    <div v-if="risultati && mostra_risultati" class="mt-0">
      <div class="loader" v-if="loader">Loading...</div>

      <div class="pt-3 px-3" v-if="tot_results > 0">
        <p class="small text-right">
          {{ tot_results }} {{ str_risultati }}
        </p>
      </div>

      <!-- Nuovo pannello risultato -->

      <div class="overflow-auto max-h-vh">
        <!--<div v-if="filtra">  -->
        <!--<div
          class="bg_white mb-3"
          v-for="(res, indice) in elenco_risultati"
          :key="indice"
        >-->
        <div class="bg_white mb-3 border-top-bottom" v-for="(res, indice) in lists" :key="indice">
          <div class="px-1 px-xl-3 mx-1 mx-xl-5">
            <div class="card mb-3">
              <div class="row">
                <div class="col-md-4 col-lg-2 my-3">
                  <img
                    :src="res.logo_criptato"
                    v-if="res.logo"
                    class="img-fluid img-h100 fit-cover rounded"
                    alt="..."
                  />

                  <div class="cerchio" v-if="!res.logo">
                    <div class="testo-cerchio">
                      {{ res.ragione_sociale | iniziali(res.ragione_sociale) }}
                    </div>
                    
                  </div>

                </div>
                <div class="col-md-8 col-lg-7">
                  <div class="card-body">
                    

                    <h5 class="card-title">
                      <a :href="getUrlAgencyProfile(res.id)">{{
                        res.ragione_sociale
                      }}</a>

                      <span v-if="res.piano == 3">
                        <a role="button" class="btn btn-danger bg_red color_white btn-sm" href="#">PREMIUM</a>
                      </span>
                                           
                      
                    </h5>

                    <p class="ml-n2">
                      <span v-if="res.online" class="text-success ml-3">
                        <small>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wifi" viewBox="0 0 16 16">
                          <path d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z"/>
                          <path d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z"/>
                        </svg>
                         ONLINE
                        </small>
                        
                      </span>
                      <span v-if="!res.online" class="text-danger ml-3">
                        <small>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wifi-off" viewBox="0 0 16 16">
                          <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"/>
                          </svg>
                        (Offline) {{ str_non_attivo_da }} {{ getNonAttivoDa(res.online_datetime_difference) }}
                        </small>  
                        
                        
                      </span>
                    </p>

                    <p class="card-text color_orange">
                      <span v-html="convertPointsToStars(res.average)"></span>

                      <a href="javascript:void(0)" @click="mostra_recensioni(res.id)"><span
                        class="color_dark_blue"
                        >({{ res.reviews }} {{ str_recensioni }})</span
                      ></a>
                    </p>

                    <p class="card-text">
                      <small>
                        <!--<i class="fas fa-circle text-success"></i>
                      <img src="data-frontend/images/message.jpg" alt="..." />
                      Contattaci
                      -->
                        <!--<span
                          v-for="(prod, chiave) in res.prodotti"
                          :key="chiave"
                        >-->

                        <span
                          v-for="(prod, chiave) in res.products"
                          :key="chiave"
                        >
                          <!--<span v-if="chiave <= 0">
                            <span v-for="(pr, i) in prod.products" :key="i">{{                              
                              pr.nome
                            }}</span>
                          </span>-->
                          <span v-if="lingua_sito == 'en' && prod.nome_en && prod.nome_en != ''">{{ prod.nome_en }}</span>
                          <span v-if="lingua_sito != 'en' || (lingua_sito == 'en' && (!prod.nome_en || prod.nome_en == ''))">{{ prod.nome }}</span>
                          



                        </span>
                      </small>
                    </p>
                  </div>
                </div>
                <div class="col-lg-3 py-3">
                  <!--<h5 class="text-center font-light price-class">
                    
                      <span v-for="(prod, chiave) in res.products" :key="chiave">
                      <span v-if="chiave <= 0">
                      <del v-if="res.prezzo_scontato && res.prezzo_scontato > 0">{{ res.prezzo }} €</del>
                      </span>
                    </span>
                  </h5>-->
                      <h5 class="text-center price-class" v-if="!res.prezzo_scontato || res.prezzo_scontato == 0">
                        {{ res.prezzo }} €
                      </h5>
                      <h5 class="text-center price-class" v-if="res.prezzo_scontato && res.prezzo_scontato > 0">
                        {{ res.prezzo_scontato }} €
                      </h5>
                      
                  <!--<span v-for="(prod, chiave) in res.prodotti" :key="chiave">-->
                    <span v-for="(prod, chiave) in res.products" :key="chiave">
                      
                    
                    <span v-if="chiave <= 0">
                    <!--<span v-for="(pr, i) in prod.products" :key="i">-->
                      <a
                        class="btn btn-secondary w-100"
                        href="#"
                        v-if="res.prezzo_scontato && res.prezzo_scontato > 0"
                        v-on:click="
                          procediAcquisto(
                            res.prezzo_scontato,
                            res.agency_id,
                            res.product_id,
                            data_servizio
                          )
                        "
                        >{{ str_acquista }}</a
                      >
                      <a
                        class="btn btn-secondary w-100"
                        href="#"
                        v-if="!res.prezzo_scontato || res.prezzo_scontato == 0"
                        v-on:click="
                          procediAcquisto(
                            res.prezzo,
                            res.agency_id,
                            res.product_id,
                            data_servizio
                          )
                        "
                        >{{ str_acquista }}</a
                      >

                      <button
                        class="btn btn-outline-primary btn-sm btn-block mt-1"
                        v-on:click="
                          openProductDetailModal(
                            res,
                            res.agency_id,
                            res.product_id,
                            data_servizio
                          )
                        "
                      >
                        {{ str_vedi_dettagli }}
                      </button>


                      <button
                          class="btn btn-outline-primary btn-sm btn-block mt-1"
                          type="button" @click="createShareLink(res.prezzo,
                            res.agency_id,
                            res.product_id,
                            data_servizio)"
                      >
                        {{ str_condividi }}
                      </button>

                    <!--</span>-->
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Fine nuovo pannello risultato -->

    

      <b-pagination
        :total-rows="totalRows"
        v-model="currentPage"
        :per-page="perPage"
        align="center" v-if="tot_results > 0"
      />

      <div class="card" v-if="elenco_risultati.length == 0">
        <div class="card-body">
          {{ str_no_results }}
        </div>
      </div>
    </div>
    <!-- End results -->

    <login-modal></login-modal>
    <customer-search-register-modal
      :locale_param="locale"
      :professional_role_names_param="professional_role_names"
    ></customer-search-register-modal>

    <form action="/search/redireziona_pagamento" method="post" ref="formredir">
      <!--<input type="hidden" ref="form_token" name="_token" :value="csrf" />-->
      <input type="hidden" ref="form_token" name="_token" v-model="csrf" />
      <input
        type="hidden"
        name="agenzia_id"
        ref="frm_agenzia_id"
        :value="agenzia_acquisto_id"
      />
      <input
        type="hidden"
        name="prezzo"
        ref="frm_prezzo_id"
        :value="prezzo_acquisto"
      />
      <input type="hidden" name="prod_id" ref="frm_prod_id" :value="prod_id" />
      <input
        type="hidden"
        name="data_servizio"
        ref="frm_data_servizio"
        :value="data_servizio"
      />

      <input
        type="hidden"
        name="search_text"
        ref="frm_search_text"
        :value="nome"
      />
      <input type="hidden" name="from_price" ref="frm_from_price" value="0" />
      <input type="hidden" name="to_price" ref="frm_to_price" value="0" />
      <input type="hidden" name="category_id" ref="frm_category_id" value="0" />
      <input
        type="hidden"
        name="provincia_sigla"
        ref="frm_provincia_sigla"
        :value="provincia"
      />
      <input
        type="hidden"
        name="search_date"
        ref="frm_search_date"
        :value="data_servizio"
      />
    </form>

    <b-modal
      centered
      hide-footer
      id="modal-1"
      :title="str_vedi_dettagli"
      ref="product-detail-modal"
    >
      <div v-html="product_detail_text"></div>
      <a
        class="btn btn-secondary w-100"
        href="#"
        v-on:click="procedi_acquisto_handler"
        >{{ str_acquista }}</a
      >
    </b-modal>
  </div>
</template>
<style scoped>
/*.input_ricerche [class*=col-] {
  padding-left: 2px;
  padding-right: 2px;
}*/


.input-group-text {
  background-color: white;
  border: 1px solid #001f5c;
  border-right: none;
}
.without-left-border {
  border-left: none;
}


.premium-label a {
  color: white;
  text-decoration:none;
  font-size: 8px;
}
.premium-label {
    position: relative;
    width: 150px;
    padding: 10px 20px 10px 10px;
    font-size: 20px;
    position: relative;
    color: #FFF;
    background: #ffa91f;
}
.premium-label:after {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #ffa91f;
    transform-origin: bottom left;
    -ms-transform: skew(-30deg, 0deg);
    -webkit-transform: skew(-30deg, 0deg);
    transform: skew(-30deg, 0deg);
}

.cerchio {
  background-color: grey;
  color: white;
  width: 100%; 
  padding-top: 100%;
  border-radius: 50%;  
   position: relative;
}

.testo-cerchio {
  position: absolute;  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
 
 
}

.testo-bianco {color: white !important;line-height: 2;}


ul#topbar a {
  background-color: #ffa91f;
  color: #ffffff;
}

ul#topbar a.active {
  border: outset #ffa91f 5px;  
}

ul#topbar {
  background-color: #ffa91f;
}
.z-1000000 {z-index: 1030;}

h5.price-class {
  font-size: 1.0rem;
}

/*
/deep/ input::placeholder {
  font-size: 0.75vw  !important;

}

/deep/ select.custom-select {
  font-size: 0.75vw  !important;

}

/deep/ .tighter {
  font-size: 0.75vw  !important;

}

/deep/ .form-control {

  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
*/

</style>


<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

const customStyles = {
  ul: {},
  li: {
    display: "inline-block",
    border: "none",
  },
  a: {
    background: "#fffff",
    color: "#001f5c",
  },
};

import axios from "axios";

import Datepicker from "vuejs-datepicker";
import { en, it } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import ExpandableTextLine from "vue-expandable-text-line";

import JwPagination from "jw-vue-pagination";

import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css' // Optional CSS


import {
  BootstrapVue,
  BPagination,
  BModal,
  BButton,
  VBModal,
  BTooltip
} from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

import EventBus from "./EventBus";

import LoginModal from "./includes/LoginModal.vue";
import CustomerSearchRegisterModal from "./includes/CustomerSearchRegisterModal.vue";

const default_layout = "default";

export default {
  components: {
    Datepicker,
    ExpandableTextLine,
    LoginModal,
    CustomerSearchRegisterModal,
    EventBus,
    BootstrapVue,
    BPagination,
    JwPagination,
    BModal,
    BButton,
    VBModal,        
    VueSimpleSuggest,
    BTooltip
  },



  props: {
    province: String,
    user: String,
    provincia_param: String,
    data_servizio_param: String,
    nome_param: String,
    locale_param: String,
    user_role_param: String,
    topbar_param: String,
    category_id_param: String,
    all_products_param: String,
    from_agency_profile_param: String,
    professional_role_names_param: String,
  },

  mounted() {
    window.addEventListener('keyup', (e) => {
      if (e.key == 'Backspace') {
        this.indietro = 1;
      }      
    });

    if (this.from_agency_profile) {
      this.$session.remove('name');
      this.$session.remove('county');
      this.$session.remove('service_date');
    }

    if (this.$session.get('name') && this.$session.get('county') && this.$session.get('service_date')) {
      
      this.nome = this.$session.get('name');
      this.provincia = this.$session.get('county');
      this.data_servizio = this.$session.get('service_date');

      /*if (this.$session.get('category_id')) {
        this.category_id = this.$session.get('category_id');
      }*/

      this.eseguiRicerca();
    } else if (this.provincia != "" && this.nome != "" && this.data_servizio != "") {
      this.eseguiRicerca();
    }

    /*if (this.category_id) {
      this.topbar.forEach((element) => {
        if (element.id == this.category_id) {
          element.active = true;
        }
      })
    }*/

    if (this.provincia == 'XX') {
      this.disabled_data_servizio = true;
      this.disabled_provincia = true;
      this.input_class_datepicker = 'border-blue-without-left testo-bianco tighter';
    }

    
    EventBus.$on("datacustomer", (customer) => {
      this.datacustomer = customer;

      this.procediAcquisto(
        this.prezzo,
        this.agenzia_id,
        this.prod_id,
        this.data_servizio
      );
      //this.riprendiAcquisto(this.prezzo, this.agenzia_id, this.prod_id);
    });

    EventBus.$on("click_marker", (marker) => {
      this.filterMarker(marker[0], marker[1]);
    });

    EventBus.$on("refresh_map", (val) => {
      this.filtra = false;
      this.eseguiRicerca();
    });


    window.addEventListener("keyup", (ev) => {
      if (ev.key == 'Enter') {
        this.$refs.nascosto.focus();
        this.manageEnterButton();
      }
    })


    // Check if shared link exists
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const share = urlParams.get('share');

    if (share) {
      const parameters = atob(share);
      const parameters_array = parameters.split('|');
      const prezzo_array = parameters_array[0].split('::');
      const prezzo = prezzo_array[1];
      const agenzia_id_array = parameters_array[1].split('::');
      const agenzia_id = agenzia_id_array[1];
      const prod_id_array = parameters_array[2].split('::');
      const prod_id = prod_id_array[1];
      const data_servizio_array = parameters_array[3].split('::');
      const data_servizio = data_servizio_array[1];

      setTimeout(() => {
        this.procediAcquisto(
            prezzo,
            agenzia_id,
            prod_id,
            data_servizio
        );
      }, 1000)
    }


  },

  filters: {
    truncate: function(value, lung) {
      if (value.length > lung) {
        return value.substr(0, lung) + "...";
      } else {
        return value;
      }
    },

    iniziali: function(value) {
      return value.substr(0, 2).toUpperCase();
    },
  },

  computed: {
    disabledSearch: function() {
      if (this.data_servizio != "" && this.nome != "" && this.provincia != "") {
        return false;
      }
      return true;
    },

    lists() {
      const items = this.elenco_risultati;
      // Return just page of items needed
      return items.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },

    totalRows() {
      return this.elenco_risultati.length;
    },

    getActiveAllCategories: function() {
      if (this.category_id) {
        return 'nav-link';
      } else {
        return 'nav-link active';
      }
    },

    disabledProvincia: function() {
      if (this.disabled_provincia) {
        return true;
      } else {
        return false;
      }
    },

    disabledDataServizio: function() {
      if (this.disabled_data_servizio) {
        return true;
      } else {
        return false;
      }
    }

  },

  data() {
    return {
      /* For props */
      professional_role_names: JSON.parse(this.professional_role_names_param),
      dataprovince: JSON.parse(this.province),

      datacustomer: this.user ? JSON.parse(this.user) : [],
      user_role: this.user_role_param,
      topbar: JSON.parse(this.topbar_param),
      all_products: JSON.parse(this.all_products_param),

      /* Strings to translate */
      str_cosa: translate("fe_vue_ricerca.Cosa stai cercando?"),
      str_dove: translate("fe_vue_ricerca.Dove?"),
      str_quando: translate("fe_vue_ricerca.Quando?"),
      str_cerca: translate("fe_vue_ricerca.Cerca"),
      str_risultati: translate("fe_vue_ricerca.risultati"),
      str_recensioni: translate("fe_vue_ricerca.Recensioni"),
      str_clicca_leggi_tutto: translate("fe_vue_ricerca.Clicca per leggere tutto"),
      str_prezzo: translate("fe_vue_ricerca.Prezzo"),
      str_acquista: translate("fe_vue_ricerca.Acquista"),
      str_fini_giudiziari: translate("fe_vue_ricerca.Prova valida ai fini giudiziari"),
      str_videochiamata: translate(
        "fe_vue_ricerca.Questo prodotto richiede una videochiamata prima della conferma"
      ),
      str_localizzazione: translate(
        "fe_vue_ricerca.Questo prodotto richiede di indicare un luogo dove si svolgerà"
      ),
      str_ricerca_avanzata: translate("fe_vue_ricerca.Ricerca Avanzata"),            
      str_no_results: translate("fe_vue_ricerca.Non ci sono risultati"),
      str_vedi_dettagli: translate("fe_vue_ricerca.Vedi dettagli"),
      str_elimina_filtri: translate("fe_vue_ricerca.Elimina filtri ricerca"),
      str_tutta_italia: translate("fe_vue_ricerca.Tutta Italia"),
      str_ricerca_libera: translate("fe_vue_ricerca.Ricerca libera"),
      /*str_non_attivo_da: translate("fe_vue_ricerca.Non attivo da"),*/
      str_non_attivo_da: translate("fe_vue_ricerca.non attivo da"),
      str_non_attivo_da_piu_di_30_gg: translate("fe_vue_ricerca.più di 30 giorni"),
      str_ultima_volta_online: translate("fe_vue_ricerca.Ultima volta online"),
      str_giorni: translate("fe_vue_ricerca.giorni"),
      str_ore: translate("fe_vue_ricerca.ore"),
      str_minuti: translate("fe_vue_ricerca.minuti"),
      str_fa: translate("fe_vue_ricerca.fa"),
      str_condividi: translate("fe_vue_ricerca.Condividi"),

      it: it,
      en: en,
      disabledDates: {
        to: moment().toDate(),
      },
      locale: this.locale_param == "en" ? "en-US" : "it-IT",
      lingua_sito: this.locale_param == "en" ? "en" : "it",

      disabled_provincia: false,
      disabled_data_servizio: false,

      /* v-models */
      data_servizio: this.data_servizio_param,
      nome: this.nome_param,
      provincia: this.provincia_param,
      //category_id: this.category_id_param,
      from_agency_profile: this.from_agency_profile_param,

      /* Others */
      elenco_risultati: [],
      elenco_categorie: [],
      descrizione_categoria: null,
      risultati: false,
      loader: false,

      agenzia_acquisto_id: 0,
      prezzo_acquisto: 0,
      prod_id: 0,

      tot_results: 0,
      perPage: 10,
      currentPage: 1,
      filtra: false,
      pageOfItems: [],
      customLabels,
      customStyles,

      marker: [],

      correlati: [],

      mostra_risultati: true,

      product_detail_text: "",
      agency_id_modal: 0,
      product_id_modal: 0,
      data_servizio_modal: 0,
      prezzo_modal: 0,

      elenco_risultati_completo: [],


      indietro: 0,

      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),

      autoCompleteStyle : {         
          defaultInput : "form-control input-with-lens2 tighter",
          suggestItem: "search-result-list",    
          vueSimpleSuggest: "z-1000000"
        },
      input_class_datepicker: 'border-blue-without-left',
    };
  },

  methods: {
    manageEnterButton: function() {
      if (this.data_servizio != "" && this.nome != "" && this.provincia != "") {
        this.$refs.nascosto.focus();        
        this.eseguiRicerca();        
      } else {
        
        return;
      }
      
    },

    resetFiltra: function() {
      this.filtra = false;
    },

    eseguiRicerca: function() {
      EventBus.$emit("send_markers", []);

      this.pageOfItems = [];
      this.elenco_risultati = [];
      this.elenco_risultati_completo = [];
      this.elenco_categorie = [];

      this.marker = [];
      this.tot_results = 0;
      this.descrizione_categoria = null;

      this.$session.set('name',this.nome);
      this.$session.set('county',this.provincia);
      this.$session.set('service_date',this.data_servizio);
      //this.$session.set('category_id',this.category_id);


      let data_servizio =
        this.data_servizio != ""
          ? moment(this.data_servizio).format("YYYY-MM-DD")
          : "";

      this.risultati = true;
      this.loader = true;

      this.elenco_risultati = [];
      this.elenco_risultati_completo = [];

      const nome = this.nome.replace('%', '');
      this.nome = nome;
      if (nome == '') {
         this.loader = false;
        return;
      }

      axios
        .post("/search/cerca", {
          nome: nome,
          provincia: this.provincia,
          data_servizio: data_servizio,          
        })
        .then((res) => {


          this.loader = false;

          let risultati = res.data;

          risultati.forEach((element) => {


            if (element.prodotti.length > 0) {
              this.marker.push([element.latitude, element.longitude]);
              //this.elenco_risultati.push(element);
              //this.tot_results += 1;

              element.prodotti.forEach((element2) => {
                element2.ragione_sociale = element.ragione_sociale;
                element2.id = element.id;
                element2.piano = element.piano;
                element2.average = element.average;
                element2.reviews = element.reviews;
                element2.logo_criptato = element.logo_criptato;
                element2.logo = element.logo;
                element2.latitude = element.latitude;
                element2.longitude = element.longitude;
                element2.online = element.online;
                element2.online_datetime = element.online_datetime;
                element2.online_datetime_difference = element.online_datetime_difference;

                this.elenco_risultati_completo.push(element2);
                this.elenco_risultati.push(element2);
                this.tot_results += 1;
              })


              /*element.prodotti.forEach((prod) => {
                prod.products.forEach((prds) => {
                  prds.relateds.forEach((rlt) => {
                    this.correlati.push(rlt.related);

                  });
                });
              });*/
              
            }


          });
          if (!this.filtra) {
            EventBus.$emit("send_markers", this.marker);
          }
//console.log(risultati);

          //this.correlati = this.multiDimensionalUnique(this.correlati);
          this.correlati = this.getCorrelati(risultati);
          //EventBus.$emit("prodotti_correlati", this.correlati);
         
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getCorrelati: function(prodotti) {
      let da_cercare_multi = [];
      prodotti.forEach((element) => {
        if (element.prodotti[0] && element.prodotti[0].product_id) {
          da_cercare_multi.push(element.prodotti[0].product_id);
        }
        

        //console.log(element.prodotti[0].product_id)
      })
      let da_cercare = [...new Set(da_cercare_multi)];
      
      


      axios.post(`/search/relateds`, {
        product_ids: da_cercare
      }).then((res) => {
        //console.log(res.data);
        EventBus.$emit("prodotti_correlati", res.data);
      }).catch((e) => {
        console.log(e.toString());
      })
    },

    convertPointsToStars: function(points) {
      let output;
      const vuota = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
          <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
        </svg>
      `;

      const mezza = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-half" viewBox="0 0 16 16">
          <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z"/>
        </svg>`;

      const piena = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
        </svg>
      `;

      const zero = vuota + vuota + vuota + vuota + vuota;
      const zero_cinque = mezza + vuota + vuota + vuota + vuota;
      const uno = piena + vuota + vuota + vuota + vuota;
      const uno_cinque = piena + mezza + vuota + vuota + vuota;
      const due = piena + piena + vuota + vuota + vuota;
      const due_cinque = piena + piena + mezza + vuota + vuota;
      const tre = piena + piena + piena + vuota + vuota;
      const tre_cinque = piena + piena + piena + mezza + vuota;
      const quattro = piena + piena + piena + piena + vuota;
      const quattro_cinque = piena + piena + piena + piena + mezza;
      const cinque = piena + piena + piena + piena + piena;

      if (points == 0) {
        output = zero;
      } else if (points > 0 && points <= 0.5) {
        output = zero_cinque;
      } else if (points > 0.5 && points <= 1) {
        output = uno;
      } else if (points > 1 && points <= 1.5) {
        output = uno_cinque;
      } else if (points > 1.5 && points <= 2) {
        output = due;
      } else if (points > 2 && points <= 2.5) {
        output = due_cinque;
      } else if (points > 2.5 && points <= 3) {
        output = tre;
      } else if (points > 3 && points <= 3.5) {
        output = tre_cinque;
      } else if (points > 3.5 && points <= 4) {
        output = quattro;
      } else if (points > 4 && points <= 4.5) {
        output = quattro_cinque;
      } else if (points > 4.5 && points <= 5) {
        output = cinque;
      }

      return output;
    },

    getFotoProdotto: function(product_id) {
      return `/files/view_file_prodotto_ricerca/${product_id}`;
    },

    procediAcquisto: function(prezzo, agenzia_id, prod_id, data_servizio) {
      //console.log(prezzo, agenzia_id, prod_id, data_servizio);return;

      if (!data_servizio) {
        data_servizio = moment().format('Y-MM-DD');
      }
      console.log(data_servizio)

      //console.log(this.datacustomer);return;
      if (this.datacustomer.length == 0) {
        if (this.user_role != '') {
          this.$swal({
            text: translate("fe_vue_ricerca.acquisto_non_permesso_per_ruolo"),
            showDenyButton: true,
            denyButtonText: `Logout`,            
            confirmButtonText: translate("fe_vue_ricerca.Annulla"),
          }).then((result) => {
            if (result.isConfirmed) {
              return;
            } else if (result.isDenied) {
              location.href = '/logout';
            }
          });
          return;
        }

        // Se l'utente non ha fatto il login appare il modal che lo invita a farlo
        this.prezzo = prezzo;
        this.agenzia_id = agenzia_id;
        this.prod_id = prod_id;
        this.data_servizio = data_servizio;

        EventBus.$emit("show_login_modal", 1);
        EventBus.$emit("save_prezzo", this.prezzo);
        EventBus.$emit("save_agency_id", this.agenzia_id);
        EventBus.$emit("save_product_id", this.prod_id);
        EventBus.$emit("save_service_data", this.data_servizio);

        EventBus.$emit("save_search_text", this.nome);
        EventBus.$emit("save_provincia_sigla", this.provincia);
        
      } else {
        // L'utente è già loggato
        //if (this.datacustomer.cap) { // L'utente ha completato tutti i dati della prima fase
        //this.saveQuery();

        this.agenzia_acquisto_id = agenzia_id;
        this.prezzo_acquisto = prezzo;

        this.$refs.frm_agenzia_id.value = agenzia_id;
        this.$refs.frm_prezzo_id.value = prezzo;
        this.$refs.frm_prezzo_id.value = prezzo;
        this.$refs.frm_prod_id.value = prod_id;
        //this.$refs.frm_data_servizio.value = data_servizio;

        this.$refs.frm_data_servizio.value = moment(data_servizio).format(
          "DD-MM-YYYY"
        );
        this.$refs.frm_search_date.value = moment(data_servizio).format(
          "YYYY-MM-DD"
        );

        this.$refs.formredir.submit();

        //} else { // L'utente NON ha completato i dati della prima fase. Si deve aprire un modal per richiederli
        /*this.mostra_modal = true;
          this.agenzia_acquisto_id = agenzia_id;
          this.prezzo_acquisto = prezzo;
          this.prod_id = prod_id;
          this.data_servizio = data_servizio;
          */

        //}
      }
    },

    filterReviews: function(n) {
      this.filtra = true;
      this.mostra_risultati = false;


      this.eseguiRicerca();

      EventBus.$emit("send_markers", []);
      let marcatori = [];
      setTimeout(() => {
        let risultati = this.elenco_risultati.filter(function(e) {
          return parseInt(e.average) == n;
        });

        risultati.forEach((el) => {
          marcatori.push([el.latitude, el.longitude]);
        })


        this.elenco_risultati = risultati;
        this.tot_results = this.elenco_risultati.length;
        this.mostra_risultati = true;
        EventBus.$emit("send_markers", marcatori);
      }, 500);
    },

    filterPrice: function(da, a) {
      this.filtra = true;
      this.mostra_risultati = false;
      this.eseguiRicerca();

      EventBus.$emit("send_markers", []);
      let marcatori = [];
//console.log("elenco risultati", this.elenco_risultati);
      setTimeout(() => {
        let risultati = this.elenco_risultati.filter(function(e) {   
          
          
          //for (let i = 0; i < e.prodotti.length; i++) {
            
              
            //if (e.prodotti[i].prezzo_scontato > 0) {
             if (e.prezzo_scontato > 0) { 
              return (
                //parseInt(e.prodotti[i].prezzo_scontato) >= da &&
                parseInt(e.prezzo_scontato) >= da &&
                //parseInt(e.prodotti[i].prezzo_scontato) <= a
                parseInt(e.prezzo_scontato) <= a
              );
            } else {
              return (
                //parseInt(e.prodotti[i].prezzo) >= da &&
                parseInt(e.prezzo) >= da &&
                //parseInt(e.prodotti[i].prezzo) <= a
                parseInt(e.prezzo) <= a
              );
            }
          //}
        });

        

        risultati.forEach((el) => {
          marcatori.push([el.latitude, el.longitude]);
        })

        this.elenco_risultati = risultati;
        this.tot_results = this.elenco_risultati.length;
        this.mostra_risultati = true;
        EventBus.$emit("send_markers", marcatori);
      }, 500);
    },

    filterMarker: function(lat, lng) {
      
      this.filtra = true;
      this.mostra_risultati = false;
      this.eseguiRicerca();

      EventBus.$emit("send_markers", []);
      let marcatori = [];

      setTimeout(() => {
        let risultati = this.elenco_risultati.filter(function(e) {          
          return e.latitude == lat && e.longitude == lng;
        });

        risultati.forEach((el) => {
          marcatori.push([el.latitude, el.longitude]);
        })

        this.elenco_risultati = risultati;
        this.tot_results = this.elenco_risultati.length;
        this.mostra_risultati = true;
        EventBus.$emit("send_markers", marcatori);
      }, 500);
    },

    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },

    getUrlAgencyProfile: function(id) {
      return `/agency-profile/${id}`;
    },

    multiDimensionalUnique: function(arr) {
      var uniques = [];
      var itemsFound = {};
      for (var i = 0, l = arr.length; i < l; i++) {
        var stringified = JSON.stringify(arr[i]);
        if (itemsFound[stringified]) {
          continue;
        }
        uniques.push(arr[i]);
        itemsFound[stringified] = true;
      }
      return uniques;
    },

    prova: function() {
      EventBus.$emit("show_step1_modal", 1);
    },

    openProductDetailModal: function(
      prod,
      agency_id,
      product_id,
      data_servizio
    ) {


      let description = "";


      prod.products.forEach((pr) => {



        if (this.lingua_sito == 'en') {
          if (pr.descrizione_en && pr.descrizione_en != '') {
            description += pr.descrizione_en;
          } else {
            description += pr.descrizione;  
          }
        } else {
          description += pr.descrizione;
        }
        
      });
      this.product_detail_text = description;

      this.agency_id_modal = agency_id;
      this.product_id_modal = product_id;
      this.data_servizio_modal = data_servizio;
      if (prod.prezzo_scontato && prod.prezzo_scontato > 0) {
        this.prezzo_modal = prod.prezzo_scontato;
      } else {
        this.prezzo_modal = prod.prezzo;
      }

      this.$refs["product-detail-modal"].show();
    },

    procedi_acquisto_handler: function() {
      this.procediAcquisto(
        this.prezzo_modal,
        this.agency_id_modal,
        this.product_id_modal,
        this.data_servizio_modal
      );
    },

    refresh: function() {
      this.filtra = false;
      this.eseguiRicerca();
    },

    mostra_recensioni: function(id) {
      location.href = '/agency-profile/' + id + '#see-reviews'
    },

    focusSearch: function () {
      this.nome = ' ';
    },
    blurSearch: function () {
      if (this.nome == ' ') {
        this.nome = '';
      }
    },

    firstSuggestions: function () {
      return axios.get(`/search/suggestions`)
          .then((res) => {

            if (this.indietro == 0) {
              return res.data;
            } else {
              this.indietro = 0;
              return [];
            }

          }).catch((e) => {
            console.log(e.toString());
          })
    },

    autocompleteProduct: function() {
      if (this.nome.length >= 2) {
        const nome = this.nome;
        
        return axios.post(`/search/autocomplete`, {
          nome: nome,
          category_id: this.category_id,
        }).then((res) => {
          
          //return res.data;
          if (this.indietro == 0) {
            //console.log(res.data)
            return res.data;
          } else {
            this.indietro = 0;            
            return [];
          }
        }).catch((e) => {
          console.log(e.toString());
        })
      }

      return this.firstSuggestions();
    },

    onSuggestSelect: function() {
      
      setTimeout(() => {
        this.all_products.forEach((element) => {
          if (this.nome == element.nome || this.nome == element.nome_en) {
            if (element.localizzazione == 0) {
              
              this.provincia = 'XX';
              this.disabled_provincia = true;
              this.data_servizio = moment().format('YYYY-MM-DD');
              this.disabled_data_servizio = true;
              this.input_class_datepicker = 'border-blue-without-left testo-bianco';
            } else {
              
              this.provincia = '';
              this.disabled_provincia = false;
              this.data_servizio = '';
              this.disabled_data_servizio = false;
              this.input_class_datepicker = 'border-blue-without-left';
            }
          }
        })
      }, 100);
    },

    setCategoryId: function(catid, index) {
      this.nome = '';
      this.$refs['tutte'].setAttribute('class', 'nav-link');
      this.topbar.forEach(element => {
        element.active = false;
      });


      let item = this.topbar[index];
      item.active = !item.active;
      this.category_id = catid;
      this.$set(this.topbar, index, item);
      this.provincia = '';
      this.disabled_provincia = false;
      this.data_servizio = '';
      this.disabled_data_servizio = false;
    },

    setCategoryFalse: function() {
      this.nome = '';
      this.$refs['tutte'].setAttribute('class', 'nav-link active');
      this.topbar.forEach(element => {
        element.active = false;
      });
      this.category_id = null;
      this.provincia = '';
      this.disabled_provincia = false;
      this.data_servizio = '';
      this.disabled_data_servizio = false;

    },

    getNonAttivoDa: function(obj)
    {
      
      if (obj == '') {
        return this.str_non_attivo_da_piu_di_30_gg;
        //return '';
      }
      obj = JSON.parse(obj);



      let output = '';
      
      //output += this.str_non_attivo_da + " ";
      output += " ";
      if (obj.giorni > 0) {
        output += obj.giorni + " " + this.str_giorni + " ";
      }
      if (obj.ore > 0) {
        output += obj.ore + " " + this.str_ore + " ";
      }
      if (obj.minuti > 0) {
        output += obj.minuti + " " + this.str_minuti + " ";
      }
      return output;
    },

    createShareLink: function (prezzo, agenzia_id, prod_id, data_servizio) {
      const url = window.location.href;
      let domain = (new URL(url));

      const protocol = domain.protocol;
      let port = domain.port;
      domain = domain.hostname.replace('www.','');
      if (port == 80) {
        port = '';
      } else {
        port = ':' + port;
      }

      let share = protocol  + '//' + domain +  port + '/search-product?share='
          + btoa('prezzo::'+ prezzo + '|agenzia_id::'+ agenzia_id  + '|prod_id::'+ prod_id + '|data_servizio::'+ data_servizio);


      navigator.clipboard.writeText(share);

      this.$swal({
        text: translate("fe_vue_ricerca.link_copiato"),

      })

    }


  },
};
</script>
