<template>
  <div>
    
    <b-modal
      id="modal-3"
      ref="step1-modal"
      centered
      :title="str_testo_completamento_profilo"
      :hide-footer="true"     
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <p>{{ str_descrizione }}</p>

      <div class="row">
        <div class="col">
          <div class="form-group" v-if="databusiness == 0">
            <label class="sr-only">{{ str_codice_fiscale }} *</label>
            <b-form-input
              type="text"
              :state="codice_fiscale_state"
              v-on:keyup="changeStateCodiceFiscale"
              v-on:blur="changeStateCodiceFiscale"
              v-model="codice_fiscale"
              class="form-control border-blue"
              :placeholder="str_codice_fiscale"  
              autocomplete="off" 
              role="presentation"           
            />
          </div>

          <div class="form-group" v-if="databusiness == 1">
            <label class="sr-only">{{ str_partita_iva }} *</label>
            <b-form-input
              type="text"
              :state="partita_iva_state"
              v-on:keyup="changeStatePartitaIVA"
              v-on:blur="changeStatePartitaIVA"
              v-model="partita_iva"
              class="form-control border-blue"
              :placeholder="str_partita_iva"
              autocomplete="off"
              role="presentation"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>{{ str_indirizzo }}</label>
            <b-form-input
              type="text"
              :state="indirizzo_state"
              v-on:keyup="changeStateIndirizzo"
              v-on:blur="changeStateIndirizzo"
              v-model="indirizzo"
              class="form-control border-blue"
              :placeholder="str_placeholder_indirizzo"
              autocomplete="off"
              role="presentation"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="sr-only">{{ str_cap }} *</label>
            <b-form-input
              :state="cap_state"
              v-on:keyup="changeStateCAP"
              v-on:blur="changeStateCAP"
              type="text"
              v-model="cap"
              class="form-control border-blue"
              :placeholder="str_cap"
              autocomplete="off"
              role="presentation"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="sr-only">{{ str_comune }} *</label>
            <b-form-input
              :state="comune_state"
              v-on:keyup="changeStateComune"
              v-on:blur="changeStateComune"
              type="text"
              v-model="comune"
              class="form-control border-blue"
              :placeholder="str_comune"
              autocomplete="off"
              role="presentation"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="sr-only">{{ str_paese }} *</label>
            <b-form-select v-model="country_id" class="border-blue" :state="country_state" v-on:change="changeStateCountry">
              <option value="">{{ str_paese }}</option>
              <option
                :value="naz.id"
                v-for="(naz, indice) in paesi"
                :key="indice"
                >{{ naz.name }}</option
              >
            </b-form-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="sr-only">{{ str_provincia }} *</label>
            <b-form-select
              :state="provincia_sigla_state"
              v-on:change="changeStateProvinciaSigla"
              v-model="provincia_sigla"
              class="border-blue"
              v-if="country_id == 151"
            >
              <option value="">{{ str_provincia }}</option>
              <option
                :value="prov.codice"
                v-for="(prov, indice) in dataprovince"
                :key="indice"
                >{{ prov.nome }}</option
              >
            </b-form-select>
            <input
              type="text"
              class="form-control border-blue"
              v-model="provincia_estera"
              v-if="country_id != 151"
            />
          </div>
        </div>
      </div>

      <div class="row mb-2" v-if="databusiness == 1">
        <div class="col-md-6">
          <div class="form-group">
            <label class="sr-only">{{ str_ragione_sociale }} *</label>
            <b-form-input
              :state="ragione_sociale_state"
              v-on:keyup="changeStateRagioneSociale"
              v-on:blur="changeStateRagioneSociale"
              type="text"
              class="form-control border-blue"
              v-model="ragione_sociale"
              :placeholder="str_ragione_sociale"
              autocomplete="off"
              role="presentation"
            />
          </div>
        </div>
        <div class="col-md-6">
          <p>{{ str_disposizione_procura }}</p>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              value="1"
              v-model="disposizione_procura"
            />
            <label class="form-check-label">{{ str_si }}</label>
          </div>

          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              value="0"
              v-model="disposizione_procura"
            />
            <label class="form-check-label">{{ str_no }}</label>
          </div>
        </div>
      </div>

      <div class="row mt-3" v-if="disposizione_procura == 1">
        <div class="col">
          <div class="form-group">

            <b-form-file
              v-model="procura_url"
              :state="procura_url_state"
              :placeholder="str_upload_procura"
              :drop-placeholder="str_upload_procura"
              v-on:input="changeStateProcuraUrl"
              :browse-text="str_browse_text"
              accept="image/jpeg, image/png, image/gif, application/pdf"
            ></b-form-file>

          </div>
        </div>
      </div>

      <div class="row" v-if="databusiness == 1">
        <div class="col-md-12">
          <label class="sr-only">{{ str_fatt_elettronica }}</label>
          <input
            type="text"
            class="form-control border-blue"
            v-model="codice_fatturazione_elettronica"
            :placeholder="str_fatt_elettronica"
            autocomplete="off"
            role="presentation"
          />
        </div>
      </div>

      <div class="row mt-1" v-if="databusiness == 1">
        <div class="col-md-12">
          <div class="form-group">
            <label class="sr-only">{{ str_cod_fisc_div_pi }}</label>
            <!--<input
              type="text"
              class="form-control border-blue"
              v-model="codice_fiscale"
              :placeholder="str_cod_fisc_div_pi"
              :state="codice_fiscale_state"
              v-on:keyup="changeStateCodiceFiscale"
              v-on:blur="changeStateCodiceFiscale"
            />-->

            <b-form-input
              type="text"
              :state="codice_fiscale_state"
              v-on:keyup="changeStateCodiceFiscale"
              v-on:blur="changeStateCodiceFiscale"
              v-model="codice_fiscale"
              class="form-control border-blue"
              :placeholder="str_cod_fisc_div_pi" 
              autocomplete="off"      
              role="presentation"       
            />


          </div>
        </div>
      </div>

      <div class="text-center">
        <input type="hidden" ref="nascosto">
        <div style="display: inline-block" class="p-1">
        <button
          class="btn btn-primary"
          
          v-on:click="prosegui"
          
        >
          {{ str_prosegui }}
        </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<style scoped>
/deep/ .header_blue {
  background-color: #0038a7;
  color: white;
}

input[type="text"],
input[type="password"] {
  border: 1px solid #0038a7;
}

button.btn-yellow {
  background-color: #ffa91e;
}

button.btn-outline-blue {
  border: 1px solid #0038a7;
  background-color: white;
  color: #0038a7;
}

p,
a {
  color: #0038a7;
}

hr {
  border-top: 1px solid #0038a7;
}
</style>

<script>
import axios from "axios";
import EventBus from "../EventBus";

import { BootstrapVue, BModal, BFormInput, BFormSelect, BFormFile } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

const default_layout = "default";

export default {
  props: {
    business: String,
    nazioni: String,
    province: String,
  },

  components: {
    BootstrapVue,
    BModal,
    EventBus,
    BFormInput,
    BFormSelect,
    BFormFile
  },

  mounted() {
    this.showModal();
  },

  computed: {
    disableSubmit: function() {
      if (
        this.checkPartitaIVA() == true ||
        this.checkCodiceFiscale() == true ||
        this.checkIndirizzo() == true ||
        this.checkRagioneSociale() == true ||
        this.checkComune() == true ||
        this.checkProvinciaSigla() == true ||
        this.checkCAP() == true ||
        this.checkCountry() == true ||
        this.checkProcuraUrl() == true
      ) {
        return true;
      }
      return false;
    },

    /*checkCodiceFiscaleField: function() {
      const regex = new RegExp(
        /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/
      );
      if (this.databusiness == 0 && !regex.test(this.codice_fiscale)) {        
        return false;
      }
      return true;
    },
    checkPartitaIVAField: function() {
      if (this.databusiness == 1 && this.partita_iva == "") {        
        return false;
      }
      return true;
    },
    checkIndirizzoField: function() {
      if (this.indirizzo == "") {        
        return false;
      }
      return true;
    },
    checkComuneField: function() {
      if (this.comune == "") {        
        return false;
      }
      return true;
    },

    checkProvinciaSiglaField: function() {
      if (this.country_id == 151 && this.provincia_sigla == "") {       
        return false;
      }
      return true;
    },

    checkCAPField: function() {
      if (this.cap == "") {        
        return false;
      }
      return true;
    },
    checkCountryField: function() {
      if (this.country_id == "") {        
        return false;
      }
      return true;
    },
    checkRagioneSocialeField: function() {
      if (this.databusiness == 1 && this.ragione_sociale == "") {        
        return false;
      }
      return true;
    },
    checkProcuraUrlField: function() {
      if (
        this.databusiness == 1 &&
        this.disposizione_procura == 1 &&
        this.procura_url == ""
      ) {
       
        return false;
      }
      return true;
    },*/


  },
  data() {
    return {
      /* From props */

      databusiness: this.business,
      paesi: JSON.parse(this.nazioni),
      dataprovince: JSON.parse(this.province),

      /* Strings */
      str_testo_completamento_profilo: translate(
        "fe_vue_profilo_cliente.str_testo_completamento_profilo"
      ),
      str_descrizione: translate(
        "fe_vue_profilo_cliente.Scusa l’interruzione, prima di poter acquistare il servizio abbiamo bisogno di alcune informazioni su di te"
      ),
      str_codice_fiscale: translate("fe_vue_profilo_cliente.Codice fiscale") + '*',
      str_partita_iva: translate("fe_vue_profilo_cliente.Partita IVA") + '*',
      str_indirizzo: translate("fe_vue_profilo_cliente.Indirizzo") + '*',
      str_placeholder_indirizzo: translate("fe_vue_profilo_cliente.Via Roma 10"), 
      str_cap: translate("fe_vue_profilo_cliente.CAP") + '*',
      str_comune: translate("fe_vue_profilo_cliente.Comune") + '*',
      str_paese: translate("fe_vue_profilo_cliente.Paese") + '*',
      str_provincia: translate("fe_vue_profilo_cliente.Provincia") + '*',
      str_ragione_sociale: translate("fe_vue_profilo_cliente.Ragione sociale") + '*',
      str_disposizione_procura: translate("fe_vue_profilo_cliente.Disponi di una procura"),      
      str_si: translate("fe_vue_profilo_cliente.Sì"),
      str_no: translate("fe_vue_profilo_cliente.No"),
      str_upload_procura: translate("fe_vue_profilo_cliente.Upload procura") + '*',
      str_fatt_elettronica: translate(
        "fe_vue_profilo_cliente.Codice destinatario fatturazione elettronica"
      ),
      str_cod_fisc_div_pi: translate(
        "fe_vue_profilo_cliente.Codice Fiscale se diverso da partita IVA"
      ),
      str_prosegui: translate("fe_vue_profilo_cliente.Prosegui con l'acquisto"),
      str_browse_text: translate("fe_vue_profilo_cliente.Sfoglia"),

      /* v-model */
      codice_fiscale: "",
      partita_iva: "",
      indirizzo: "",
      cap: "",
      comune: "",
      country_id: 151,
      provincia_sigla: "",
      provincia_estera: "",
      ragione_sociale: "",
      disposizione_procura: 0,
      procura_url: [],
      codice_fatturazione_elettronica: "",

      /* Status */
      codice_fiscale_state: null,
      partita_iva_state: null,
      indirizzo_state: null,
      comune_state: null,
      provincia_sigla_state: null,
      cap_state: null,
      country_state: null,
      ragione_sociale_state: null,
      procura_url_state: false,




      /* Other */

      agenzia_id: 0,
      prod_id: 0,
      prezzo: 0,

      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  },

  methods: {
    showModal() {
      this.$refs["step1-modal"].show();
      window.addEventListener("keyup", (ev) => {
        if (ev.key == 'Enter') {
          this.manageEnter();
        }
      })
    },
    hideModal() {
      this.$refs["step1-modal"].hide();
    },
    handleFileUpload() {
      this.procura_url = this.$refs.procura_url.files[0];   
    },

    /* Methods to validate fields */
    checkPartitaIVA: function() {
      if (this.country_id != 151) {
        return false;
      }
      if (this.databusiness == 1 && this.partita_iva == "") {        
        return true;
      }
      return false;
    },

    checkCodiceFiscale: function() {
      if (this.country_id != 151) {        
        return false;
      }
      const regex = new RegExp(
        /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/
      );
      if (this.databusiness == 0 && !regex.test(this.codice_fiscale)) {        
        return true;
      } else if (this.databusiness == 1 && this.codice_fiscale != '' && !regex.test(this.codice_fiscale)) {
        return true;
      } else {
        return false;
      }
      
    },

    checkIndirizzo: function() {
      if (this.indirizzo == "") {        
        return true;
      }
      return false;
    },

    checkRagioneSociale: function() {
      if (this.databusiness == 1 && this.ragione_sociale == "") {        
        return true;
      }
      return false;
    },

    checkComune: function() {
      if (this.comune == "") {        
        return true;
      }
      return false;
    },

    checkProvinciaSigla: function() {
      if (this.country_id == 151 && this.provincia_sigla == "") {        
        return true;
      }
      return false;
    },

    checkCAP: function() {
      if (this.cap == "") {        
        return true;
      }
      return false;
    },

    checkCountry: function() {
      if (this.country_id == "") {        
        return true;
      }
      return false;
    },

    checkProcuraUrl: function() {
      if (
        this.databusiness == 1 &&
        this.disposizione_procura == 1 &&
        !this.procura_url.name
      ) {       
        return true;
      }
      return false;
    },

    /* End methods to validate */

    prosegui: function() {
      if (this.disableSubmit) {
        return this.mostraMancanti();
      }

      
      let formData = new FormData();

      formData.append("codice_fiscale", this.codice_fiscale);
      if (this.partita_iva != "") {
        formData.append("partita_iva", this.partita_iva);
      }
      formData.append("indirizzo", this.indirizzo);
      formData.append("ragione_sociale", this.ragione_sociale);

      formData.append("cap", this.cap);
      formData.append("comune", this.comune);
      formData.append("country_id", this.country_id);
      if (this.provincia_sigla != "") {
        formData.append("provincia_sigla", this.provincia_sigla);
      }
      if (this.provincia_estera != "") {
        formData.append("provincia_estera", this.provincia_estera);
      }
      formData.append("disposizione_procura", this.disposizione_procura);
      if (this.procura_url != "") {
        formData.append("procura_url", this.procura_url);
      }
      if (this.codice_fatturazione_elettronica != "") {
        formData.append(
          "codice_fatturazione_elettronica",
          this.codice_fatturazione_elettronica
        );
      }

      formData.append("agenzia_id", this.agenzia);
      formData.append("prezzo", this.prezzo_acquisto);
      formData.append("prod_id", this.prod_id);

     

      axios
        .post("customer/primostep", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {        
          this.hideModal();            
        })
        .catch((e) => {
          console.log(e);
        
        });
    },

    /* Chamge status functions */
    changeStateCodiceFiscale: function() {
      if (this.country_id != 151) {        
        this.codice_fiscale_state = true;
        return;
      }
      const regex = new RegExp(
        /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/
      );
      if (this.databusiness == 0 && !regex.test(this.codice_fiscale)) {              
        this.codice_fiscale_state = false;
      } else if (this.databusiness == 1 && this.codice_fiscale != '' && !regex.test(this.codice_fiscale)) {        
        this.codice_fiscale_state = false;
      } else {        
        this.codice_fiscale_state = true;
      }      
    },

    changeStatePartitaIVA: function() {
      if (this.databusiness == 1 && this.partita_iva == "") {        
        this.partita_iva_state = false;       


      } else {
        //this.partita_iva_state = true;
        if (this.country_id == 151) {
          var reg = /^\d+$/;
          if (this.partita_iva.length == 11) {
            this.partita_iva_state = true;
          } 
          if (reg.test(this.partita_iva)) {
            //this.errore_non_numerica_pi = false;
            this.partita_iva_state = true;
          }

          if (this.partita_iva.length != 11 || !reg.test(this.partita_iva)) {
            this.partita_iva_state = false;
          } else {          
            this.partita_iva_state = true;
          }
        } else {
          this.partita_iva_state = true;          
        }
      }      
    },

    changeStateIndirizzo: function() {
      if (this.indirizzo == "") {        
        this.indirizzo_state = false;
      } else {
        this.indirizzo_state = true;
      }      
    },

    changeStateComune: function() {
      if (this.comune == "") {        
        this.comune_state = false;
      } else {
        this.comune_state = true;
        if (this.provincia_sigla == '') {
          this.provincia_sigla_state = false;
        }        
      }      
    },

    changeStateProvinciaSigla: function() {
      if (this.country_id == 151 && this.provincia_sigla == "") {       
        this.provincia_sigla_state = false;
      } else {
        this.provincia_sigla_state = true;
      }      
    },

    changeStateCAP: function() {
      if (this.cap == "") {        
        this.cap_state = false;
      } else {
        this.cap_state = true;
      }      
    },

    changeStateCountry: function() {
      if (this.country_id == "") {        
        this.country_state = false;
      } else {
        this.changeStatePartitaIVA();
        this.changeStateCodiceFiscale();        
        this.country_state = true;
      }      
    },

    changeStateRagioneSociale: function() {
      if (this.databusiness == 1 && this.ragione_sociale == "") {        
        this.ragione_sociale_state = false;
      } else {
        this.ragione_sociale_state = true;
      }      
    },

    changeStateProcuraUrl: function() {      
      if (
        this.databusiness == 1 &&
        this.disposizione_procura == 1 &&
        !this.procura_url
      ) {
       
        this.procura_url_state = false;
      } else {
        this.procura_url_state = true;
      }      
    },

    manageEnter: function() {
      if (!this.disableSubmit) {
        this.$refs.nascosto.focus();
        this.prosegui();
      }
      
    },

    mostraMancanti: function(event) {     
      if (this.disableSubmit) {
        this.changeStateCodiceFiscale();
        this.changeStatePartitaIVA();
        this.changeStateIndirizzo();
        this.changeStateComune();
        this.changeStateProvinciaSigla();
        this.changeStateCAP();
        this.changeStateCountry();
        this.changeStateRagioneSociale();
        this.changeStateProcuraUrl();
        
      }
      
    },

  },
};
</script>
