<template>
  <div style="display: inline">    
    <a class="btn btn-blue2" href="javascript:void(0)" v-on:click="openRegister">{{ str_accedi }}</a>
    <general-mobile-login-modal privacy_url_param="privacy_url"></general-mobile-login-modal>
    <customer-mobile-register-modal :locale_param="locale" :professional_role_names_param="professional_role_names"></customer-mobile-register-modal>
  </div>  
</template>
<script>
//import EventBus from "./EventBus";
import EventBus from "../new-search/EventBus";
import GeneralMobileLoginModal from "./GeneralMobileLoginModal.vue";
import CustomerMobileRegisterModal from "../new-search/includes/CustomerMobileRegisterModal.vue";



const default_layout = "default";



export default {
  props: {    
    locale_param: String,
    professional_role_names_param: String,
  },
  computed: {},
  components: {
      EventBus,
      GeneralMobileLoginModal,
      CustomerMobileRegisterModal,
  },
  data() {
      return {          
          str_accedi: translate("fe_layout.Accedi"),

          privacy_url : this.privacy_url_param,
          register_url: this.register_url_param,

          locale: this.locale_param,
          professional_role_names: JSON.parse(this.professional_role_names_param),
      }
  },
  mounted() {
    
  },
  methods: {
      openRegister: function() {          
          EventBus.$emit("show_general_mobile_login_modal", 1);
      }
  }
};
</script>
