import { render, staticRenderFns } from "./CustomerMobileRegisterModal.vue?vue&type=template&id=2318df08&scoped=true&"
import script from "./CustomerMobileRegisterModal.vue?vue&type=script&lang=js&"
export * from "./CustomerMobileRegisterModal.vue?vue&type=script&lang=js&"
import style0 from "./CustomerMobileRegisterModal.vue?vue&type=style&index=0&id=2318df08&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2318df08",
  null
  
)

export default component.exports