<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-person-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
            />
          </svg>
          {{ str_anagrafica_titolare }}
        </h4>
      </div>
      <div class="card-body">      
        <div class="row">

          <div class="col-md-6">
            <div class="form-group">
              <label class="form__label"
                >{{ str_nome }} <span class="text-danger">*</span></label
              >

              <b-form-input
                v-model.trim="$v.name.$model"
                class="form-control"
                tabindex="1"
                :state="name_state"
                v-on:keyup="changeStateName"
                v-on:blur="changeStateName"
                autocomplete="off"
                role="presentation"
              ></b-form-input>

              <div class="error" v-if="!$v.name.required && bloccato == true">
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label
                >{{ str_cognome }} <span class="text-danger">*</span></label
              >

              <b-form-input
                v-model.trim="$v.last_name.$model"
                class="form-control"
                tabindex="1"
                :state="last_name_state"
                v-on:keyup="changeStateLastName"
                v-on:blur="changeStateLastName"
                autocomplete="off"
                role="presentation"
              ></b-form-input>

              <div
                class="error"
                v-if="!$v.last_name.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>{{ str_email }} <span class="text-danger">*</span></label>

              <b-form-input
                autocomplete="off"
                v-model.trim="$v.email.$model"
                :state="email_state"
                v-on:keyup="changeStateEmail"
                v-on:blur="changeStateEmail"
                v-on:input="changeStateEmail"
                class="form-control"
                ref="email"
                tabindex="3"
                role="presentation"

              ></b-form-input>

              <div class="error" v-if="!$v.email.required && bloccato == true">
                {{ str_campo_obbligatorio }}
              </div>
              <div class="error" v-if="!$v.email.email && bloccato == true">
                {{ str_email_non_valida }}
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label
                  >{{ str_paese }} <span class="text-danger">*</span></label
                >
                <b-form-select
                  class="border-blue"
                  v-model="prefix_cell"
                  :options="prefixes"
                ></b-form-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label
                    >{{ str_cellulare }} ({{ str_senza_spazi }})
                    <span class="text-danger">*</span></label
                  >

                  <b-form-input
                    type="tel"
                    v-model="$v.cellulare.$model"
                    autocomplete="off"
                    role="presentation"
                    class="form-control"
                    pattern="[\+]?\d{5,16}"
                    tabindex="4"
                    placeholder="12345678"
                    :state="cellulare_state"
                    v-on:keyup="changeStateCellulare"
                    v-on:blur="changeStateCellulare"                                 
                  ></b-form-input>

                  <div
                    class="error"
                    v-if="!$v.cellulare.required && bloccato == true"
                  >
                    {{ str_campo_obbligatorio }}
                  </div>

                  <div
                    class="error"
                    v-if="!$v.cellulare.validPhone && bloccato == true"
                  >
                    {{ str_formato_telefono_non_valido }}
                  </div>
            </div>
            
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <!-- Data scadenza carta d'identità -->
            <div class="form-group">
              <label
                >{{ str_data_scadenza_carta_identita }}
                <span class="text-danger">*</span></label
              >
<!--
              <b-form-datepicker
                :state="data_scadenza_ci_state"
                v-on:shown="changeStateDataScadenzaCI"
                v-on:hidden="changeStateDataScadenzaCI"
                v-model="$v.data_scadenza_ci.$model"
                :locale="locale"
                class="form-control border-blue"
                no-flip
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                :date-disabled-fn="getMaxDateScadenzaCI"
                show-decade-nav
                :initial-date="getInitialDateCI"
                :placeholder="str_data_scadenza_carta_identita"
                hide-header
              ></b-form-datepicker>
              -->

              <b-form-input 
              type="date" v-model="$v.data_scadenza_ci.$model"
              :min="getStartCalendar" 
              @input="changeStateDataScadenzaCI" 
                class="form-control border-blue" :state="data_scadenza_ci_state"></b-form-input> 

              <div
                class="error"
                v-if="!$v.data_scadenza_ci.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>

              <div
                class="error"
                v-if="errore_data_scadenza_ci"
              >
                {{ str_errore_data_scadenza_ci }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Carta di identità -->
            <div class="form-group">
              <label
                >{{ str_carta_identita_fronte_retro }}
                <span class="text-danger">*</span></label
              >
              <input
                type="file"
                v-on:change="handleFileUploadCI()"
                accept="image/jpeg, image/gif, image/png, application/pdf"
                ref="ci_fronte_url"
                tabindex="6"
              />
              <div v-if="ci_fronte_url && mostra_ci_fronte_url">
                <span>{{ ci_fronte_url.name }}</span>
              </div>

              <div
                class="error"
                v-if="errore_ci_fronte_url && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <!-- Codice fiscale -->
            <div class="form-group">
              <label
                >{{ str_cf_fronte_retro }}
                <span class="text-danger">*</span></label
              >
              <input
                type="file"
                v-on:change="handleFileUploadCF()"
                accept="image/jpeg, image/gif, image/png, application/pdf"
                ref="cf_url"
                tabindex="7"
              />
              <div v-if="cf_url && mostra_cf_url">
                <span>{{ cf_url.name }}</span>
              </div>

              <div class="error" v-if="errore_cf_url && bloccato == true">
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Checkboxes -->

            <div class="form-check align-middle">
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                id="privacy"
                v-model="$v.privacy.$model"
                tabindex="8"
                :class="status($v.privacy)"
              />
              <label class="form-check-label" for="privacy">
                {{ str_accettazione }}
                <a href="/terms-and-conditions-agency" target="_blank"><u>{{ str_condizioni }}</u></a> {{ str_and }} <br>
                <a href="/privacy" target="_blank"><u>Privacy policy</u></a>
                <span class="text-danger">*</span>
                <div
                  class="error"
                  v-if="!$v.privacy.sameAs && bloccato == true"
                >
                  {{ str_campo_obbligatorio }}
                </div>
              </label>
            </div>

            <div class="form-check align-middle mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                id="comm"
                v-model="comunicazioni_commerciali"
                tabindex="9"
              />
              <label class="form-check-label" for="comm">
                {{ str_comunicazioni_commerciali }}
              </label>
            </div>

            <div class="form-check align-middle mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                id="contr"
                v-model="fidavo_contract"
                tabindex="9"
              />
              <label class="form-check-label" for="contr">
                {{ str_accettazione_contratto }} <a href="/terms-and-conditions-agency" target="_blank"><u>{{ str_condizioni }}</u></a> 
                {{ str_accettazione_contratto_2 }}
                 <span class="text-danger">*</span>
                <div
                  class="error"
                  v-if="!$v.fidavo_contract.sameAs && bloccato == true"
                >
                  {{ str_campo_obbligatorio }}
                </div>

              </label>
            </div>




          </div>
        </div>

       

        <div class="row mt-2">
          <div class="col-md-6">
            <div class="form-group">
              <label
                >{{ str_password }} <span class="text-danger">*</span></label
              >

              <password-analyzer @writepassword="assignPassword" />

              <div
                class="error"
                v-if="!$v.password.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>

              <div
                class="error"
                v-if="!$v.password.minLength && bloccato == true"
              >
                {{ str_password_lunghezza }}
              </div>

              <div
                  class="error"
                  v-if="!$v.password.validPassword && bloccato == true"
              >
                <p>{{ str_password_must_include }}</p>
                <ul>
                  <li> {{ str_longer_than_seven }}</li>
                  <li>{{ str_at_least_a_capital }}</li>
                  <li>{{ str_at_least_a_lowercase }}</li>
                  <li> {{ str_at_least_a_number }}</li>
                  <li>{{ str_at_least_a_special_char }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                >{{ str_conferma_password }}
                <span class="text-danger">*</span></label
              >
              <div class="input-group">                
                <input
                  :type="getTipoCampoPasswordConferma"
                  ref="conferma_password"                  
                  v-on:keyup="changeStatePasswordConfirmation"
                  v-on:blur="changeStatePasswordConfirmation"                  
                  autocomplete="off"
                  role="presentation"
                  v-model="$v.conferma_password.$model"
                  tabindex="11"
                  :class="password_confirmation_classes"
                  :placeholder="str_conferma_password"                            
                />

                <div class="input-group-append">
                  <span
                    class="input-group-text cursor-pointer"
                    v-on:click="mostraPassword('password_confirmation')"
                  >
                    <i class="fas fa-eye"></i>
                  </span>
                </div>
              </div>

              <div
                class="error"
                v-if="!$v.conferma_password.sameAsPassword && bloccato == true"
              >
                {{ str_password_diversa }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-3 my-3">
        <div class="col text-right">
          <button
            class="btn btn-secondary"
            v-if="!verificaCompletiPasso1"
            disabled
          >
            {{ str_avanti }}
          </button>
          <button
            class="btn btn-primary btn-yellow"
            v-on:click="vaiAPasso2()"
            v-if="verificaCompletiPasso1"
          >
            {{ str_avanti }}
          </button>
        </div>
      </div>

      <div class="row mx-3 my-3">
        <div class="col-md-12">
          <span class="text-danger">*</span> {{ str_campi_obbligatori }}
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
input[type="file"] {
  color: #001f5c;
}
.form-check-label {
  font-size: 1rem;
}
button.btn-yellow {
  background-color: #ffa91f;
  color: #fff;
}

.dirty {
  border-color: #5a5;
  background: #efe;
}

.dirty:focus {
  outline-color: #8e8;
}

.error {
  border: 1px solid red;
  background: #fdd;
  padding: 5px;
}

.error:focus {
  outline-color: #f99;
}

.cfpwd {
  /*-webkit-text-security: disc;*/
}
</style>
<script>
import moment from "moment";
import axios from "axios";

import {
  required,
  email,
  sameAs,
  requiredIf,
  helpers,
  minLength,
  numeric,
} from "vuelidate/lib/validators";

const validPhone = helpers.regex("validPhone", /^[\+]?\d{5,16}$/);


import {
  BootstrapVue,
  IconsPlugin,
  BFormInput,
  BFormCheckbox,
  BFormFile,
  BFormDatepicker,
  BFormSelect,
} from "bootstrap-vue";
import PasswordAnalyzer from "../../includes/PasswordAnalyzer.vue";

const default_layout = "default";

export default {
  props: {
    passo1_param: Boolean,
    passo2_param: Boolean,
    bloccato_param: Boolean,
    prefixes_param: Array,
    prefix_cell_param: Number,

    name_param: String,
    last_name_param: String,
    email_param: String,

    cellulare_param: String,
    data_scadenza_ci_param: String,
    ci_fronte_url_param: [String, File],
    cf_url_param: [String, File],
    privacy_param: [Number, Boolean],
    fidavo_contract_param: [Number, Boolean],
    comunicazioni_commerciali_param: [Number, Boolean],
    password_param: String,
    conferma_password_param: String,
    locale_param: String,

  },
  components: {
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    BFormSelect,
    PasswordAnalyzer,    
  },
  computed: {
    getInitialDateCI: function() {
      return moment().toDate();
    },

    verificaCompletiPasso1: function() {
      if (
        this.name != "" &&
        this.last_name != "" &&
        this.validEmail() != false &&
        this.validCellulare() != false &&
        this.data_scadenza_ci != "" &&
        this.ci_fronte_url != "" &&
        this.cf_url != "" &&
        this.privacy != 0 &&
        this.fidavo_contract != 0 &&
        this.password != "" &&
        this.conferma_password != "" &&
        this.password == this.conferma_password
      ) {
        return true;
      } else {
        return true;
      }
    },

    getTipoCampoPasswordConferma: function() {
      return this.tipo_campo_password_conferma;
    },

    getStartCalendar: function() {
      return moment().format('YYYY-MM-DD');
    }
  },
  data() {
    return {
      /* Props */
      passo1: this.passo1_param,
      passo2: this.passo2_param,
      bloccato: this.bloccato_param,
      prefixes: this.prefixes_param,
      comunicazioni_commerciali: this.comunicazioni_commerciali_param,

      passo2: false,

      /* Stringhe */

      str_anagrafica_titolare: translate("fe_vue_registrazione_agenzia.Anagrafica titolare"),
      str_nome: translate("fe_vue_registrazione_agenzia.Nome titolare licenza"),
      str_cognome: translate("fe_vue_registrazione_agenzia.Cognome titolare licenza"),
      str_email: translate("fe_vue_registrazione_agenzia.Email (non pec)"),
      str_data_scadenza_carta_identita: translate(
        "fe_vue_registrazione_agenzia.Data scadenza carta d'identità"
      ),
      str_cf_fronte_retro: translate(
        "fe_vue_registrazione_agenzia.Codice fiscale (fronte/retro) (Pdf, jpg, jpeg, png)"
      ),
      str_paese: translate("fe_vue_registrazione_agenzia.Paese"),
      str_cellulare: translate("fe_vue_registrazione_agenzia.Numero cellulare"),
      str_senza_spazi: translate("fe_vue_registrazione_agenzia.Senza spazi"),
      str_carta_identita_fronte_retro: translate(
        "fe_vue_registrazione_agenzia.Carta di identità (fronte/retro) (Pdf, jpg, jpeg, png)"
      ),
      /*str_accettazione: translate("fe_vue_registrazione_agenzia.Accettazione"),*/

      str_accettazione: translate("fe_vue_registrazione_agenzia.Dichiaro di accettare le presenti"),
      str_condizioni: translate("fe_vue_registrazione_agenzia.Condizioni generali"),
      str_and: translate("fe_vue_registrazione_agenzia.str_and"),

      str_comunicazioni_commerciali: translate(
        "fe_vue_registrazione_agenzia.Accettazione invio comunicazioni commerciali"
      ),

      str_accettazione_contratto: translate("fe_vue_registrazione_agenzia.str_accettazione_contratto"),
      str_accettazione_contratto_2: translate("fe_vue_registrazione_agenzia.str_accettazione_contratto_2"),

      str_password: translate("fe_vue_registrazione_agenzia.Password"),
      str_conferma_password: translate("fe_vue_registrazione_agenzia.Conferma Password"),
      str_avanti: translate("fe_vue_registrazione_agenzia.Avanti"),
      str_campi_obbligatori: translate("fe_vue_registrazione_agenzia.Campi obbligatori"),
      str_campo_obbligatorio: translate("fe_vue_registrazione_agenzia.Campo obbligatorio"),
      str_password_lunghezza: translate(
        "fe_vue_registrazione_agenzia.La password deve essere almeno di 8 caratteri"
      ),
      str_password_diversa: translate("fe_vue_registrazione_agenzia.Le 2 password devono corrispondere"),
      str_formato_telefono_non_valido: translate(
        "fe_vue_registrazione_agenzia.telefono_non_valido_esteso"
      ),
      str_email_non_valida: translate("fe_vue_registrazione_agenzia.Email non valida"),
      str_errore_data_scadenza_ci: translate("fe_vue_registrazione_agenzia.Non può essere nel passato"),

      
      str_password_must_include: translate("vue.La password deve includere"),
      str_longer_than_seven: translate("vue.Più di 7 caratteri"),
      str_at_least_a_capital: translate("vue.Almeno una lettera maiuscola"),
      str_at_least_a_lowercase: translate("vue.Almeno una lettera minuscola"),
      str_at_least_a_number: translate("vue.Almeno un numero"),
      str_at_least_a_special_char: translate("vue.Almeno un carattere speciale"),

      /* Models */
      prefix_cell: this.prefix_cell_param,
      name: this.name_param,
      last_name: this.last_name_param,
      email: this.email_param,
      cellulare: this.cellulare_param,
      data_scadenza_ci: this.data_scadenza_ci_param,
      ci_fronte_url: this.ci_fronte_url_param,
      cf_url: this.cf_url_param,
      privacy: this.privacy_param,
      fidavo_contract: this.fidavo_contract_param,
      comunicazioni_commerciali: this.comunicazioni_commerciali_param,
      password: this.password_param,
      conferma_password: this.conferma_password_param,

      /* Errori */
      errore_cf_url: false,
      errore_ci_fronte_url: false,
      errore_data_scadenza_ci: false,

      /* States */
      name_state: null,
      last_name_state: null,
      email_state: null,
      data_scadenza_ci_state: null,
      cellulare_state: null,

      /* Altro */
      password_confirmation_classes: "form-control cfpwd",
      mostra_ci_fronte_url: true,
      mostra_cf_url: true,
      locale: this.locale_param,

      tipo_campo_password_conferma: "text",
    };
  },

  methods: {
    assignPassword: function(value) {
      this.password = value;
    },

    handleFileUploadCI() {
      this.ci_fronte_url = this.$refs.ci_fronte_url.files[0];
      this.errore_ci_fronte_url = false;
      this.mostra_ci_fronte_url = false;
    },

    handleFileUploadCF() {
      this.cf_url = this.$refs.cf_url.files[0];
      this.errore_cf_url = false;
      this.mostra_cf_url = false;
    },

    verificaEsistenzaEmail: function() {
      axios
        .post("/agency/check_if_email_exists", {
          email: this.email,
        })
        .then((res) => {
          if (res.data == "SI") {
            this.$swal({
              text: translate(
                "fe_vue_registrazione_agenzia.Questo indirizzo email risulta già registrato"
              ),
              confirmButtonColor: "#001f5c",
            }).then((result) => {
              if (result.isConfirmed) {
                this.email_state = false;
              }
            });
            this.email = "";
            this.$refs.email.focus();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    validEmail: function() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(this.email)) {
        return false;
      }
      return true;
    },

    validCellulare: function() {
      let cellulare = this.cellulare;
      for (let i = 0; i < cellulare.length; i++) {
        if (isNaN(cellulare[i]) && cellulare[i] != "+") {
          return false;
        }
      }
      return true;
    },

    validDataScadenzaCI: function() {
      const date_deadline = moment(this.data_scadenza_ci).toDate()
      const now_obj = moment().toDate();
      if (date_deadline < now_obj) {
        this.errore_data_scadenza_ci = true;
        return false;
      } else {
        this.errore_data_scadenza_ci = false;
        return true;
      }
    },

    // States
    changeStateName: function() {
      if (this.name == "") {
        this.name_state = false;
      } else {
        this.name_state = true;
      }
    },

    changeStateLastName: function() {
      if (this.last_name == "") {
        this.last_name_state = false;
      } else {
        this.last_name_state = true;
      }
    },

    changeStateEmail: function() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(this.email)) {
        this.email_state = false;
      } else {
        this.email_state = true;
        this.verificaEsistenzaEmail();
      }
    },

    changeStateDataScadenzaCI: function() {
      const date_deadline = moment(this.data_scadenza_ci).toDate()
      const now_obj = moment().toDate();

      if (this.data_scadenza_ci == "") {
        this.data_scadenza_ci_state = false;
      } else if (date_deadline < now_obj) {
        this.data_scadenza_ci_state = false;
      } else {
        this.data_scadenza_ci_state = true;
        this.errore_data_scadenza_ci = false;
      }
    },

    changeStateCellulare: function() {
      let cellulare = this.cellulare;
      const reg = /^[\+]?\d{5,16}$/;
      if (!reg.test(cellulare)) {
        this.cellulare_state = false;
      } else {
        this.cellulare_state = true;
      }
    },

    trasformaInTypePassword: function()
    {
      this.tipo_campo_password_conferma = "password";
      /*if (this.conferma_password.length > 0) {        
        this.tipo_campo_password_conferma = "password";
      } else {        
        this.tipo_campo_password_conferma = "text";
      }*/      
    },

    changeStatePasswordConfirmation: function() {    
      
      this.trasformaInTypePassword();
      

      if (this.conferma_password != this.password) {
        this.password_confirmation_classes = "form-control cfpwd is-invalid";
      } else {
        this.password_confirmation_classes = "form-control cfpwd is-valid";
      }
    },

    getMaxDateScadenzaCI: function(ymd, date) {
      return date < moment().toDate();
    },

    mostraPassword: function(campo) {
      if (campo == "password") {
        if (this.$refs.password.getAttribute("type") == "password") {
          this.$refs.password.setAttribute("type", "text");
        } else {
          this.$refs.password.setAttribute("type", "password");
        }
      } else {
        if (this.$refs.conferma_password.getAttribute("type") == "password") {
          this.$refs.conferma_password.setAttribute("type", "text");
        } else {
          this.$refs.conferma_password.setAttribute("type", "password");
        }
      }
    },

    vaiAPasso2: function() {
      if (this.$v.$invalid || this.ci_fronte_url == "" || this.cf_url == "" || !this.validDataScadenzaCI()) {
        this.bloccato = true;
        if (!this.ci_fronte_url) {
          this.errore_ci_fronte_url = true;
        }

        if (!this.cf_url) {
          this.errore_cf_url = true;
        }
      } else {
        this.bloccato = false;
      }

      if (this.bloccato == false) {
        this.passo1 = false;
        this.passo2 = true;

        this.$emit(
          "vaiapasso2",
          this.passo1,
          this.passo2,
          this.prefix_cell,
          this.name,
          this.last_name,
          this.email,
          this.cellulare,
          this.data_scadenza_ci,
          this.ci_fronte_url,
          this.cf_url,
          this.privacy,
          this.fidavo_contract,
          this.comunicazioni_commerciali,
          this.password,
          this.conferma_password
        );
      }
    },

    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },

  validations: {
    name: {
      required,
    },

    last_name: {
      required,
    },

    email: {
      required,
      email,
    },

    cellulare: {
      required,
      validPhone,
    },

    data_scadenza_ci: {
      required,
    },

    privacy: {
      sameAs: sameAs(() => true),
    },

    fidavo_contract: {
      sameAs: sameAs(() => true),
    },

    password: {
      required,
      minLength: minLength(8),
      validPassword: function(value) {
        const containsUppercase = /[A-Z]/.test(value)
        const containsLowercase = /[a-z]/.test(value)
        const containsNumber = /[0-9]/.test(value)
        const containsSpecial = /[#?!@$%^&*-_]/.test(value)
        return containsUppercase && containsLowercase && containsNumber && containsSpecial
      }
    },

    conferma_password: {
      sameAsPassword: sameAs("password"),
    },
  },
};

class Errors {
  constructor() {
    this.errors = {};
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  record(errors) {
    this.errors = errors;
  }
}
</script>
