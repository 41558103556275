<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
            <button class="btn btn-primary" @click="generaPassword" type="button">
            {{ str_genera_password }}
            </button>
        </div>
        
      </div>

      <div class="col-md-3">
        <div class="form-group">
            <input
            type="text"
            v-if="password_generata != ''"
            v-model="password_generata"
            class="form-control"
            />
        </div>
        
      </div>
      <div class="col-md-6">
        <div class="form-check" v-if="password_generata != ''">
          <input
            class="form-check-input"
            type="checkbox"
            value="1"
            v-model="ceccato"
          />
          <label class="form-check-label">
            {{ str_copiato_passwrd }}
          </label>
        </div>
      </div>
    </div>

    <div class="row" v-if="password_generata != '' && ceccato == 1">
        <div class="col">
            <div class="form-group">
                <button v-if="ceccato == true"
                type="button"
                class="btn btn-primary"
                v-on:click="usaPassword"
                
                >
                {{ str_usa_password }}
                </button>
            </div>
        </div>
        
    </div>
  </div>
</template>
<script>
import Vue from "vue";

//Vue.prototype.translate = require('../VueTranslation/Translation');

const default_layout = "default";

export default {
  computed: {},
  data() {
    return {
      str_copiato_passwrd: translate(
        "fe_vue_profilo_cliente.Ho copiato la password in un posto sicuro"
      ),
      str_genera: translate("fe_vue_profilo_cliente.Genera"),
      str_genera_password: translate("fe_vue_profilo_cliente.Genera password"),
      str_chiudi: translate("fe_vue_profilo_cliente.Chiudi"),
      str_usa_password: translate("fe_vue_profilo_cliente.Usa password"),

      password_generata: "",
       ceccato: 0, 
    };
  },

  methods: {
    generaPassword: function() {
      let length = 8,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*(){}[]=<>/,.|~?",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }

      this.password_generata = retVal;
    },

    usaPassword: function() {
      //this.$parent.password = this.password_generata;
      //this.$parent.password_confirmation = this.password_generata;

      //this.$parent.$refs.password.setAttribute("type", "password");
      //this.$parent.$refs.conferma_password.setAttribute("type", "password");
      this.$emit("usepassword", this.password_generata)
      this.ceccato = 0;
      this.password_generata = '';
     

    },
  },
};
</script>
