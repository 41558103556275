<template>
  <div>
    <b-modal
      id="modal-2"
      ref="register-modal"
      centered
      :title="str_register"
      :hide-footer="true"
      size="lg"
      @hidden="resetModal"
    >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="sr-only">{{ str_nome }}</label>
            <b-form-input
              :state="name_state"
              v-on:input="changeStateName"
              type="text"
              class="form-control border-blue"
              v-model="name"
              :placeholder="str_nome"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="sr-only">{{ str_cognome }}</label>
            <b-form-input
              :state="last_name_state"
              v-on:keyup="changeStateLastName"
              v-on:blur="changeStateLastName"
              type="text"
              v-model="last_name"
              class="form-control border-blue"
              :placeholder="str_cognome"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="sr-only">{{ str_email }}</label>
            <b-form-input
              :state="email_state"
              v-on:keyup="changeStateEmail"
              v-on:blur="changeStateEmail"
              v-on:input="changeStateEmail"
              type="text"
              v-model="email"
              class="form-control border-blue"
              :placeholder="str_email"                        
            />
          </div>
        </div>

         <div class="col-md-6">
          <label>{{ str_data_di_nascita }}</label>     
<!--
          <b-form-datepicker :state="data_nascita_state"                   
          v-on:shown="changeStateDataNascita"
          v-on:hidden="changeStateDataNascita"
          v-model="data_di_nascita" 
          class="form-control border-blue" 
          :locale="locale"
          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"          
          :date-disabled-fn="getMaxDate" 
          show-decade-nav 
          :initial-date="getInitialDate"
          :placeholder="str_data_di_nascita" hide-header no-flip></b-form-datepicker>  
-->
          <!--<b-form-datepicker :state="data_nascita_state"    
          v-if="isIOSDevice"     
          v-on:shown="changeStateDataNascita"
          v-on:hidden="changeStateDataNascita"
          v-model="data_di_nascita" 
          class="form-control border-blue" 
          :locale="locale"
          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"          
          :date-disabled-fn="getMaxDate"
           show-decade-nav 
           hide-header
           :initial-date="getInitialDate"
           :placeholder="str_data_di_nascita" no-flip></b-form-datepicker>

          <b-form-input v-if="!isIOSDevice" 
        :type="datepicker_type" v-model="data_di_nascita" :placeholder="str_data_di_nascita"
         :max="getStartCalendar" 
         @input="changeStateDataNascita" 
          class="form-control border-blue" :state="data_nascita_state" @focus="setAsDate"></b-form-input> 
-->
          <!--<b-form-input 
        :type="datepicker_type" v-model="data_di_nascita" :placeholder="str_data_di_nascita"
         :max="getStartCalendar" 
         @input="changeStateDataNascita" 
          class="form-control border-blue" :state="data_nascita_state" @focus="setAsDate"></b-form-input> -->

          <b-form-input 
        type="date" v-model="data_di_nascita" 
         :max="getStartCalendar" 
         @input="changeStateDataNascita" 
          class="form-control border-blue" :state="data_nascita_state"></b-form-input> 

          <p class="text-danger" v-if="invalid_date_18_yo">{{ str_invalid_date_18_yo }}</p>

        </div>

        

      </div>

      <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group">
            <label class="sr-only">{{ str_tipo_cliente }}</label>
            <b-form-select class="border-blue" v-model="tipo_cliente" :state="tipo_cliente_state" v-on:change="changeStateTipoCliente">
              <option value="">{{ str_tipo_cliente }}</option>
              <option value="0">{{ str_cliente_privato }}</option>
              <option value="1">{{ str_cliente_business }}</option>
            </b-form-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group" v-if="tipo_cliente == 1">
            <label class="sr-only">{{ str_professione_ruolo }}</label>
            <b-form-select class="border-blue" v-model="professione" :state="professione_state" v-on:change="changeStateProfessione" :options="professional_role_names">
              <option value="">{{ str_professione_ruolo }}</option>
              <!--<option :value="str_avvocato">{{ str_avvocato }}</option>
              <option :value="str_hr_manager">{{ str_hr_manager }}</option>
              <option :value="str_perito">{{ str_perito }}</option>
              <option :value="str_cfo">{{ str_cfo }}</option>
              <option :value="str_ceo">{{ str_ceo }}</option>
              <option :value="str_commercialista">{{
                str_commercialista
              }}</option>-->
              <option :value="str_altro">{{ str_altro }}</option>
              
            </b-form-select>
          </div>
        </div>
      </div>
      <!--
      <div class="row">
        <div class="col">          
         
        <b-form-group :label="str_sesso" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group-2"            
            :aria-describedby="ariaDescribedby"
            name="radio-sub-component"
            v-model="gender" :state="gender_state"
            v-on:change="changeStateGender"
          >
            <b-form-radio value="M">{{ str_maschio }}</b-form-radio>
            <b-form-radio value="F">{{ str_femmina }}</b-form-radio>            
            <b-form-radio value="X">{{ str_no_specificato }}</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        </div>     
        
      </div>
      -->


       <GeneratorePwd @usepassword="usePassword" />
          

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="sr-only">{{ str_password }}</label>           
            <!--<password-analyzer @writepassword="assignPassword" />           -->

            <div class="input-group">
              <input                
                :type="getTipoCampoPassword"
                ref="password"
                v-model="password"
                :class="password_classes"
                :placeholder="str_password"
                v-on:keyup="changeStatePassword"
                v-on:blur="changeStatePassword"     
                @keypress="trasformaInTypePassword"
                
              />
              <div class="input-group-append">
                <span
                  class="input-group-text cursor-pointer"
                  v-on:click="mostraPassword('password')"
                >
                  <i class="fas fa-eye"></i>
                </span>
              </div>
            </div>

            <p v-if="show_str_pwd_length" v-text="str_pwd_length"></p>

          </div>
          
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="sr-only">{{ str_conferma_password }}</label>
            <div class="input-group">
              <input                
                :type="getTipoCampoPasswordConferma"
                ref="conferma_password"
                v-model="conferma_password"
                :class="password_confirmation_classes"
                :placeholder="str_conferma_password"
                v-on:keyup="changeStatePasswordConfirmation"
                v-on:blur="changeStatePasswordConfirmation"    
                @keypress="trasformaInTypePasswordCP"  
                autocomplete="off"             
                role="presentation"
              />
              <div class="input-group-append">
                <span
                  class="input-group-text cursor-pointer"
                  v-on:click="mostraPassword('password_confirmation')"
                >
                  <i class="fas fa-eye"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-check align-middle">
            <b-form-checkbox
              id="checkbox-1"
              :state="privacy_state"
              v-model="privacy"              
              value="1"
              v-on:change="changeStatePrivacy"
            >
            {{ str_accettazione }}
            <a href="/terms-and-conditions" target="_blank">{{ str_condizioni_generali }}</a> {{ str_and }}
            <a href="/privacy" target="_blank">Privacy policy</a> *
            </b-form-checkbox>

          </div>
        </div>

        <div class="col-md-6">
          <div class="form-check align-middle">

             <b-form-checkbox
              id="checkbox-2"              
              v-model="comunicazioni_commerciali"              
              value="1"             
            >
            {{ str_comunicazioni_commerciali }}
            </b-form-checkbox>           
            
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-check align-middle">

             <b-form-checkbox
              id="checkbox-3"     
              :state="contract_state"            
              v-model="fidavo_contract"   
               v-on:change="changeStateContract"              
              value="1"                       
            >
            {{ str_contratto_fidavo }} <a href="/terms-and-conditions" target="_blank">{{ str_condizioni_generali }}</a>: {{ str_contratto_fidavo2 }} *
            </b-form-checkbox>           
            
          </div>
        </div>
      </div>

      <div class="row" v-if="checking_mail">
            <div class="col text-center">
              <b-spinner label="Waiting..."></b-spinner>
            </div>
          </div>

      <div class="row" v-if="saving">
            <div class="col text-center">
              <b-spinner label="Waiting..."></b-spinner>
            </div>
          </div>

      <div class="text-center">
        <input type="hidden" ref="nascosto">
        <button
          class="btn btn-primary btn-yellow"
          v-on:click="register"
          :disabled="disableButtonRegister" v-if="!saving"
        >
          {{ str_register }}
        </button>

        <hr />
        <p class="mt-1 mb-3">
          {{ str_sei_un }} <b>{{ str_agenzia }}</b> {{ str_o_un }}
          <b>{{ str_agente }}</b
          >?
        </p>
        <button class="btn btn-outline-blue" v-on:click="registerAgencyUrl">{{ str_register }}</button>
      </div>
    </b-modal>

    <popup-success></popup-success>
  </div>
</template>
<style scoped>
/deep/ .header_blue {
  background-color: #0038a7;
  color: white;
}

input[type="text"],
input[type="password"] {
  border: 1px solid #0038a7;
}

button.btn-yellow {
  background-color: #ffa91f;
  color: #fff;
}

button.btn-blue {  
  background-color: #001f5c;
  color: #fff;  
}

button.btn-outline-blue {
  border: 1px solid #0038a7;
  background-color: white;
  color: #0038a7;
}

p,
a {
  color: #0038a7;
}

hr {
  border-top: 1px solid #0038a7;
}

.b-calendar-grid-body {
  height: 228px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

</style>

<script>
import axios from "axios";
import EventBus from "../EventBus";

import { BootstrapVue, BModal, BFormInput, BFormSelect, BFormFile,
 BFormCheckbox, BFormDatepicker, BSpinner, BFormGroup, BFormRadio, BFormRadioGroup } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Datepicker from "vuejs-datepicker";
import { en, it } from "vuejs-datepicker/dist/locale";
import moment from "moment";

import PasswordAnalyzer from "../../includes/PasswordAnalyzer.vue";
import PopupSuccess from "./PopupSuccess.vue";


import GeneratorePwd from "./GeneratorePwd.vue";

const default_layout = "default";

export default {
  props: {
    locale_param: String,
    professional_role_names_param: Array,
  },

  components: {
    BootstrapVue,
    BModal,
    EventBus,
    Datepicker,
    BFormInput,
    BFormSelect,
    BFormFile,
    BFormCheckbox,
    BFormDatepicker,
    PasswordAnalyzer,
    PopupSuccess,
    BSpinner,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    GeneratorePwd
  },

  mounted() {
    EventBus.$on(
      "show_mobile_register_modal",
      function(val) {        
        this.showModal();
      }.bind(this)
    );

    EventBus.$on("set_register_url", (val) => {      
      this.register_url = val;
    });


    this.professional_role_names_param.forEach(element => {
      
      if (this.locale_param == 'it' || this.locale_param == 'it-IT') {
        this.professional_role_names.push({        
          'value': element.name_it,
          'text': element.name_it,
        });
      } else {
        this.professional_role_names.push({        
        'value': element.name_en,
        'text': element.name_en,
      });
      }
      
    });


  },

  computed: {
    disableButtonRegister: function() {
      if (
        this.checkName() == true ||
        this.checkLastName() == true ||
        this.checkEmail() == true ||
        this.checkTipoCliente() == true ||
        this.checkProfessione() == true ||
        this.checkDataNascita() == true ||
        this.checkPassword() == true ||
        this.checkPrivacy() == true ||
        this.checkContract() == true /*|| 
        this.checkGender() == true */
      ) {
        return true;
      }
      return false;
    },  
    
    getInitialDate: function() {
      return moment().subtract(18, "years").toDate();
    },

     getTipoCampoPasswordConferma: function() {
      return this.tipo_campo_password_conferma;
    },

    getTipoCampoPassword: function() {      
      return this.tipo_campo_password;
    },

    getStartCalendar: function() {     
      return moment().subtract(18, "years").format('YYYY-MM-DD');
    },

    isIOSDevice: function(){
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

  },

  data() {
    return {
      professional_role_names: [],
      /* Strings */
      str_nome: translate("fe_vue_profilo_cliente.Nome") + ' * ',
      str_cognome: translate("fe_vue_profilo_cliente.Cognome") + ' * ',
      str_email: translate("fe_vue_profilo_cliente.Email") + ' * ',
      str_accettazione_privacy: translate(
        "fe_vue_profilo_cliente.Accettazione T&C / Privacy policy"
      ),
      
       /*str_accettazione: translate("fe_vue_profilo_cliente.Accettazione"),*/
      str_accettazione: translate("fe_vue_profilo_cliente.Dichiaro di accettare le presenti"),
      str_condizioni_generali: translate("fe_vue_profilo_cliente.Condizioni generali"),
      str_and: translate("fe_vue_profilo_cliente.str_and"),


      str_comunicazioni_commerciali: translate(
        "fe_vue_profilo_cliente.Accettazione invio comunicazioni commerciali"
      ),
      /*str_contratto_fidavo: translate(
        "fe_vue_profilo_cliente.str_accept_fidavo_contract",
      ),*/
      str_contratto_fidavo: translate(
        "fe_vue_profilo_cliente.str_new_contract_explanation_1",
      ),
      str_contratto_fidavo2: translate(
        "fe_vue_profilo_cliente.str_new_contract_explanation_2",
      ),


      str_password: translate("fe_vue_profilo_cliente.Password") + ' * ',
      str_conferma_password: translate("fe_vue_profilo_cliente.Conferma Password") + ' * ',
      str_data_di_nascita: translate("fe_vue_profilo_cliente.Data di nascita") + ' * ',
      str_generatore_password: translate("fe_vue_profilo_cliente.Genera password"),
      str_tipo_cliente: translate("fe_vue_profilo_cliente.Tipo cliente") + ' * ',
      str_cliente_privato: translate("fe_vue_profilo_cliente.Privato"),
      str_cliente_business: translate("fe_vue_profilo_cliente.Business"),
      str_professione_ruolo:
        translate("fe_vue_profilo_cliente.Professione") + " / " + translate("fe_vue_profilo_cliente.Ruolo") + ' * ',
      str_avvocato: translate("fe_vue_profilo_cliente.Avvocato"),
      str_hr_manager: translate("fe_vue_profilo_cliente.HR Manager"),
      str_perito: translate("fe_vue_profilo_cliente.Perito"),
      str_cfo: translate("fe_vue_profilo_cliente.CFO"),
      str_ceo: translate("fe_vue_profilo_cliente.CEO"),
      str_commercialista: translate("fe_vue_profilo_cliente.Commercialista"),
      str_altro: translate("fe_vue_profilo_cliente.Altro"),
      str_invia: translate("fe_vue_profilo_cliente.Invia"),
      str_register: translate("fe_vue_profilo_cliente.Registrati"),
      str_sei_un: translate("fe_vue_profilo_cliente.Sei un"),
      str_agenzia: translate("fe_vue_profilo_cliente.agenzia investigativa"),
      str_o_un: translate("fe_vue_profilo_cliente.o un"),
      str_agente: translate("fe_vue_profilo_cliente.agente"),
      str_no_registered: translate("fe_vue_profilo_cliente.Non sei ancora iscritto"),
      str_genera: translate("fe_vue_profilo_cliente.Genera"),
      str_pwd_copiata: translate(
        "fe_vue_profilo_cliente.Ho copiato la password in un posto sicuro"
      ),
      str_usa_pwd: translate("fe_vue_profilo_cliente.Usa password"),
      str_indirizzo_email_gia_registrato: translate(
        "fe_vue_profilo_cliente.str_indirizzo_gia_registrato"
      ),
      str_msg_pwd: translate("fe_vue_profilo_cliente.Minimo 8 caratteri"),

      str_sesso:  translate("fe_vue_profilo_cliente.Sesso"),
      str_maschio:  translate("fe_vue_profilo_cliente.Maschio"),
      str_femmina:  translate("fe_vue_profilo_cliente.Femmina"),
      str_no_specificato: translate("fe_vue_profilo_cliente.Preferisco non specificare"),
      str_pwd_length: translate("fe_vue_profilo_cliente.La password deve essere almeno di 8 caratteri"),

      str_invalid_date_18_yo: translate("fe_vue_profilo_cliente.Minimo 18 anni"),

      /* v-model */

      name: "",
      last_name: "",
      email: "",
      data_di_nascita: "",
      privacy: 0,
      comunicazioni_commerciali: 0,
      fidavo_contract: 0,
      password: "",
      conferma_password: "",
      tipo_cliente: "",
      professione: "",
      /*gender: '',*/

      disabledDates: {
        /*from: this.getData18AnniFa()*/
        from: moment()
          .subtract(18, "years")
          .toDate(),
      },
      register_url: null,

      it: it,
      en: en,
      locale: this.locale_param ? this.locale_param : 'it-IT',

      modale: false,
      campo_mostra_password: "password",
      campo_mostra_password2: "password",

      mostra_generatore: false,

      password_generata: "",
      ceccato: 0,
      saving: false,

      password_classes: 'form-control border-blue',

      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
  
      
      /* Validation states */
      name_state: null,
      last_name_state: null,
      email_state: null,
      tipo_cliente_state: null,
      professione_state: null,
      data_nascita_state: null,
      password_state: null,
      privacy_state: false,
      contract_state: false,
      /*gender_state: null,*/


      /* Other */
      password_confirmation_classes: 'form-control',
      

      checking_mail: false,

      tipo_campo_password_conferma: "text",
      tipo_campo_password: "text",
      show_str_pwd_length: false,

      datepicker_type: 'text',
      invalid_date_18_yo: false,

    };
  },

  methods: {
    showModal() {
      this.$refs["register-modal"].show();

      window.addEventListener("keyup", (ev) => {
        if (ev.key == 'Enter') {
          if (!this.disableButtonRegister) {
            this.$refs.nascosto.focus();
            this.register();
          }
          
        }
      })

    },
    hideModal() {
      this.$refs["register-modal"].hide();
    },

    apriGeneratorePassword: function() {
      this.mostra_generatore = true;      
    },

    generaPassword: function() {
      let length = 8,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*(){}[]=<>/,.|~?",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }

      this.password_generata = retVal;
    },

    usaPassword: function() {
      this.password = this.password_generata;
      this.conferma_password = this.password_generata;
      this.mostra_generatore = false;
    },

    mostraPassword: function(campo) {
      if (campo == "password") {
        if (this.$refs.password.getAttribute("type") == "password") {
          this.$refs.password.setAttribute("type", "text");
          this.campo_mostra_password = "text";
        } else {
          this.$refs.password.setAttribute("type", "password");
          this.campo_mostra_password = "password";
        }
      } else {
        if (this.$refs.conferma_password.getAttribute("type") == "password") {
          this.$refs.conferma_password.setAttribute("type", "text");
          this.campo_mostra_password2 = "text";
        } else {
          this.$refs.conferma_password.setAttribute("type", "password");
          this.campo_mostra_password2 = "password";
        }
      }
    },

    verificaEsistenzaEmail: function() {
      this.checking_mail = true;
      axios
        .post("/agency/check_if_email_exists", {
          email: this.email,
        })
        .then((res) => {
          setTimeout(() => {
            this.checking_mail = false;

            if (res.data == "SI") {
              this.$swal({
                text: this.str_indirizzo_email_gia_registrato,
                confirmButtonColor: '#001f5c',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.email_state = false;
                }
              });
              this.email = null;
              this.$refs.email.focus();            
            }  

          }, 500)
          
          
        })
        .catch((e) => {
          console.log(e);
        });
    },

    register: function() {   
      this.saving = true;   
      axios
          .post("/customer/store", {
            name: this.name,
            last_name: this.last_name,
            email: this.email,
            privacy: this.privacy ? 1 : 0,
            comunicazioni_commerciali: this.comunicazioni_commerciali ? 1 : 0,
            fidavo_contract: this.fidavo_contract ? 1 : 0,
            password: this.password,
            password_confirmation: this.conferma_password,
            data_di_nascita: moment(this.data_di_nascita).format("YYYY-MM-DD"),
            privato_business: this.tipo_cliente,
            professione: this.professione,
            /*gender: this.gender,*/
          })
          .then((res) => {
            /*this.saving = false;
            this.hideModal();  
            EventBus.$emit("customer_register_success", 1);
            */
           
          /* axios.post(`/email/resend`).then((ver) => { // Send verification email

              this.saving = false;        
                this.hideModal();  
                EventBus.$emit("customer_register_success", this.email);
              
            }).catch((e) => {
              console.warn(e.toString())
            })*/

             this.saving = false;        
              this.hideModal();  
              EventBus.$emit("customer_register_success", this.email);

          })
          .catch((e) => {
            console.log(e);
            this.errori_validazione.record(e.response.data);
          });
    },

    /* Methods to validate form */
    checkName: function() {
      if (this.name == "") {
        return true;
      }
      return false;
    },

    checkLastName: function() {
      if (this.last_name == "") {
        return true;
      }
      return false;
    },

    checkEmail: function() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(this.email)) {
        return true;
      }
      return false;
    },

    checkTipoCliente: function() {
      if (this.tipo_cliente == "") {
        return true;
      }
      return false;
    },

    checkProfessione: function() {
      if (this.tipo_cliente == 1 && this.professione == "") {
        return true;
      }
      return false;
    },
    /*
    checkGender: function() {
      if (this.gender == '') {
        return true;
      }
      return false;
    },
    */

    checkDataNascita: function() {
      if (!this.data_di_nascita || this.data_di_nascita == "" || this.data_nascita_state == false) {
        return true;
      }
      return false;
    },

    checkPassword: function() {
      if (this.password.length < 8 || this.conferma_password != this.password) {
        return true;
      }
      return false;
    },

    checkPrivacy: function() {
      if (this.privacy == 0) {
        return true;
      }
      return false;
    },

    checkContract: function() {
      if (this.fidavo_contract == 0) {
        return true;
      }
      return false;
    },

    /* Methods to change state */
    changeStateName: function() {
      if (this.name == '') {
        this.name_state = false;
      } else {
        this.name_state = true;
      }      
    },

    changeStateLastName: function() {
      if (this.last_name == '') {
        this.last_name_state = false;
      } else {
        this.last_name_state = true;
      }      
    },

    changeStateEmail: function() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(this.email)) {
        this.email_state = false;
      } else {
        this.email_state = true;
        this.verificaEsistenzaEmail();
      }      
      
    },

    changeStateTipoCliente: function() {
      if (this.tipo_cliente == '') {
        this.tipo_cliente_state = false;
      } else {
        if (this.tipo_cliente == 1) {
          this.professione_state = false;
        }
        this.tipo_cliente_state = true;
      }
    },


    changeStateProfessione: function() {
      if (this.tipo_cliente == 1 && this.professione == "") {
        this.professione_state = false;
      } else {
        this.professione_state = true;
      }
    },
    /*
    changeStateGender: function() {
      if (this.gender == '') {
        this.gender_state = false;
      } else {
        this.gender_state = true;
      }
    },
    */
    changeStateDataNascita: function() {      
      
      const date_of_birth = moment(this.data_di_nascita).toDate()
      const eighteen_years_ago = moment().subtract(18, "years").toDate()
            

      if (this.data_di_nascita == '') {
        this.invalid_date_18_yo = false;
        this.data_nascita_state = false;
      } else if (date_of_birth > eighteen_years_ago) {
        this.invalid_date_18_yo = true;
        this.data_nascita_state = false;
      }else {
        this.invalid_date_18_yo = false;
        this.data_nascita_state = true;
      }      
    },

    changeStatePrivacy: function() {
      if(this.privacy == 0) {
        this.privacy_state = false;
      } else {
        this.privacy_state = true;
      }
    }, 

    changeStateContract: function() {
      if(this.fidavo_contract == 0) {
        this.contract_state = false;
      } else {
        this.contract_state = true;
      }
    }, 

   

    trasformaInTypePassword: function()
    {
      this.tipo_campo_password = "password";
            
    },

     trasformaInTypePasswordCP: function()
    {
      this.tipo_campo_password_conferma = "password";       
    },

    changeStatePassword: function() {
        this.trasformaInTypePassword();
      //if (this.conferma_password != this.password) {
      if (this.password.length < 8) {
        this.show_str_pwd_length = true;
        this.password_classes = 'form-control is-invalid';
      } else {
        this.show_str_pwd_length = false;
        this.password_classes = 'form-control is-valid';
      }
    },

    changeStatePasswordConfirmation: function() {
      this.trasformaInTypePasswordCP();
      if (this.conferma_password != this.password) {
        this.password_confirmation_classes = 'form-control is-invalid';
      } else {
        this.password_confirmation_classes = 'form-control is-valid';
      }
    },



    registerAgencyUrl: function() {
      if (this.register_url) {
        location.href = this.register_url;
      } else {        
        location.href = process.env.MIX_URL_REGISTRAZIONE_AGENZIA;
      }
    },

    getMaxDate: function(ymd, date) {      
      return date > moment().subtract(18, "years").toDate();
    },

    assignPassword: function(value) {
      this.password = value;
    },

    resetModal: function() {
      this.name = '';      
      this.last_name = "";
      this.email =  "";
      this.data_di_nascita = "";
      this.privacy = 0;
      this.comunicazioni_commerciali = 0;
      this.fidavo_contract = 0;
      this.password = "";
      this.conferma_password = "";
      this.tipo_cliente = "";
      this.professione = "";
      //this.gender = "";

      this.name_state = null;
      this.last_name_state = null;
      this.email_state = null;
      this.tipo_cliente_state = null;
      this.professione_state = null;
      this.data_nascita_state = null;
      //this.gender_state = null;
      this.password_state = null;
      this.privacy_state = false;
       this.contract_state = false;

      /* Other */
      this.password_confirmation_classes = 'form-control';

      this.checking_mail = false;
    },

     usePassword: function(pwd) {
      this.password = pwd;
      this.conferma_password = pwd;
      this.tipo_campo_password = 'password';
      this.tipo_campo_password_conferma = 'password';

      this.changeStatePassword();
      this.changeStatePasswordConfirmation();
    },

    setAsDate: function() {
      this.datepicker_type = 'date';
    },

     

  },
};
</script>
