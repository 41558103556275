<template>
  <div class="container-pwd-analyzer">
    <div class="form-group">
      <div class="input-group">
        <input
          :placeholder="str_password"
          name="password"
          ref="password_field"
          v-on:focus="showSuggerimenti"
          v-on:blur="hideSuggerimenti"
          v-on:keyup="passToParent"
          class="form-control border-blue"
          :type="getTipoCampoPassword"
          @input="password_check"          
          v-model="message"
          autocomplete="off"
          role="presentation"          
        />
        <div class="input-group-append">
          <span
            class="input-group-text cursor-pointer"
            v-on:click="mostraPassword"
          >
            <i class="fas fa-eye"></i>
          </span>
        </div>
      </div>
    </div>

    <div class="suggerimenti mt-1 bg-light" v-if="suggerimenti == 1">
        <p>{{ str_password_must_include }}</p>
      <p
        class="frmValidation"
        :class="{ 'frmValidation--passed': message.length > 7 }"
      >
        <i
          class="frmIcon fas"
          :class="message.length > 7 ? 'fa-check' : 'fa-times'"
        ></i>
        {{ str_longer_than_seven }}
      </p>
      <p
        class="frmValidation"
        :class="{ 'frmValidation--passed': has_uppercase }"
      >
        <i
          class="frmIcon fas"
          :class="has_uppercase ? 'fa-check' : 'fa-times'"
        ></i>
        {{ str_at_least_a_capital }}
      </p>
      <p
        class="frmValidation"
        :class="{ 'frmValidation--passed': has_lowercase }"
      >
        <i
          class="frmIcon fas"
          :class="has_lowercase ? 'fa-check' : 'fa-times'"
        ></i>
        {{ str_at_least_a_lowercase }}
      </p>
      <p class="frmValidation" :class="{ 'frmValidation--passed': has_number }">
        <i
          class="frmIcon fas"
          :class="has_number ? 'fa-check' : 'fa-times'"
        ></i>
        {{ str_at_least_a_number }}
      </p>
      <p
        class="frmValidation"
        :class="{ 'frmValidation--passed': has_special }"
      >
        <i
          class="frmIcon fas"
          :class="has_special ? 'fa-check' : 'fa-times'"
        ></i>
        {{ str_at_least_a_special_char }}
      </p>
      
    </div>
  </div>
</template>
<style scoped>
.frmValidation {
  font-size: 13px;
}
.frmValidation--passed {
  color: #717b85;
}
.frmIcon {
  color: #eb0029;
}
.frmValidation--passed .frmIcon {
  color: #0fa140;
}
</style>
<script>
const default_layout = "default";

export default {
  computed: {
    getTipoCampoPassword: function() {      
      return this.tipo_campo_password;
    },
  },
  props: {
    
  },
  data() {
    return {
      /* Strings */
      str_password: translate("vue.Password") + ' * ',
      str_password_must_include: translate("vue.La password deve includere"), 
      str_longer_than_seven: translate("vue.Più di 7 caratteri"),  
      str_at_least_a_capital: translate("vue.Almeno una lettera maiuscola"),  
      str_at_least_a_lowercase: translate("vue.Almeno una lettera minuscola"),  
      str_at_least_a_number: translate("vue.Almeno un numero"), 
      str_at_least_a_special_char: translate("vue.Almeno un carattere speciale"),  

      message: "",
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,

      suggerimenti: 0,

      tipo_campo_password: 'text',
    };
  },

  methods: {
    trasformaInTypePassword: function()
    {
      this.tipo_campo_password = "password";
      /*if (this.message.length > 0) {        
        this.tipo_campo_password = "password";
      } else {        
        this.tipo_campo_password = "text";
      }    */  
    },

    password_check: function() {
      this.trasformaInTypePassword();

      this.has_number = /\d/.test(this.message);
      this.has_lowercase = /[a-z]/.test(this.message);
      this.has_uppercase = /[A-Z]/.test(this.message);
      this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.message);
    },

    showSuggerimenti: function() {
      this.suggerimenti = 1;
    },

    hideSuggerimenti: function() {
      this.suggerimenti = 0;
    },
    passToParent: function() {
      this.$emit("writepassword", this.message);
    },
    mostraPassword: function() {
      if (this.$refs.password_field.getAttribute("type") == "password") {
        this.$refs.password_field.setAttribute("type", "text");
      } else {
        this.$refs.password_field.setAttribute("type", "password");
      }
    },
  },
};
</script>
