var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.filtra)?_c('button',{staticClass:"btn btn-secondary btn-lg",attrs:{"id":"refreshButton"},on:{"click":_vm.refresh}},[_c('i',{staticClass:"fas fa-sync-alt"})]):_vm._e(),_vm._v(" "),(!_vm.$isMobile())?_c('GmapMap',{ref:"mappa",staticStyle:{"height":"95vh"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"map-type-id":"roadmap"}},[_vm._l((_vm.pins),function(marker,indice){return _c('GmapMarker',{key:indice,attrs:{"position":marker,"clickable":true,"draggable":false,"icon":_vm.markerOptions},on:{"click":function($event){return _vm.showAgencyDetail(marker)}}})}),_vm._v(" "),_c('gmap-info-window',{attrs:{"options":{
        maxWidth: 300,
        pixelOffset: { width: 0, height: -35 },
      },"position":_vm.infoWindow.position,"opened":_vm.infoWindow.open},on:{"closeclick":function($event){_vm.infoWindow.open = false}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.infoWindow.template)}})])],2):_vm._e(),_vm._v(" "),(_vm.$isMobile() && _vm.pins.length > 0)?_c('GmapMap',{ref:"mappa",staticStyle:{"height":"95vh"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"map-type-id":"roadmap"}},[_vm._l((_vm.pins),function(marker,indice){return _c('GmapMarker',{key:indice,attrs:{"position":marker,"clickable":true,"draggable":false,"icon":_vm.markerOptions},on:{"click":function($event){return _vm.showAgencyDetail(marker)}}})}),_vm._v(" "),_c('gmap-info-window',{attrs:{"options":{
        maxWidth: 300,
        pixelOffset: { width: 0, height: -35 },
      },"position":_vm.infoWindow.position,"opened":_vm.infoWindow.open},on:{"closeclick":function($event){_vm.infoWindow.open = false}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.infoWindow.template)}})])],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }