<template>
  <div>
    <div class="category_container">
      <ul class="nav nav-pills rounded without_bottom_border pt-3 px-3" id="topbar">
        <li class="nav-item">
          <a class="nav-link active" href="javascript:void(0)" ref="tutte" v-on:click="setCategoryFalse" :style="padding_cerca_tutto">{{ str_ricerca_libera }}</a>
        </li>
        <li class="nav-item" v-for="(tb, indice) in topbar" :key="indice">
          <a class="nav-link" :class="{ active: tb.active}" href="javascript:void(0)" v-on:click="setCategoryId(tb.id, indice)" v-text="getNomeCategoria(tb)"></a>
        </li>
        
      </ul>
    </div>
    
    

    
    <form class="text-center bg_white shadow-sm rounded pt-3 px-3 my-5 frm-ricerca without_top_border"
     method="get" action="/search-product" 
     style="margin-top: 0px !important;border-bottom-left-radius: 1em !important;border-bottom-right-radius: 1em !important;">
     
      <div class="form-row">
        <div class="form-group col-md-4">
         
          <vue-simple-suggest
              @focus="focusSearch"
              @blur="blurSearch"
                        name="name"
                        v-model="nome"                        
                        :list="autocompleteProduct"
                        :filter-by-query="false"                        
                         @suggestion-click="onSuggestSelect()"                         
                         :placeholder="str_cosa"                                                 
                          ref="nome" :styles="autoCompleteStyle" autocomplete="off">
                    
                      </vue-simple-suggest>
            

        </div>
        <div class="form-group border-to-the-right col-md-3">
          <select class="form-control custom-select" v-model="provincia" :disabled="disabledProvincia" v-if="!disabled_provincia">
            <option value="">{{ str_dove }}</option>
            <option value="XX">All Ireland</option>
          <option
              :value="prov.codice"
              v-for="(prov, indice) in dataprovince"
              :key="indice"
              >{{ prov.nome }}</option
            >
            <!--
            <option value="MI">Milano</option>
            <option value="PD">Padova</option>
            <option value="RM">Roma</option>
            <option value="UD">Udine</option>
            <option value="SC">Charleston (USA - SC)</option>
            -->
          </select>
          <input type="hidden" name="county" v-model="provincia">
        </div>
        <div class="form-group col-md-3">
          <datepicker  
            v-model="data_servizio"          
            :disabled="disabledDataServizio"
            name="service_date"
            id="home_ricerca_data_servizio"
            :bootstrap-styling="true"
            format="dd-MM-yyyy"
            :language="it"
            :disabledDates="disabledDates"
            :placeholder="str_quando"            
            :input-class="datepicker_input_class"
            v-if="!disabled_data_servizio"
          ></datepicker>
          <input type="hidden" name="service_date" v-model="data_servizio" :disabled="disabledDataServizioBis">
          

        </div>

        <input type="hidden" name="category_id" :value="getCategoryId">

        <div class="form-group col-md-2 d-flex justify-content-end">
          <button
            type="submit"
            class="btn btn-secondary"
            :disabled="disabledSearch"
           
          >
            {{ str_cerca }}
          </button>
        </div>
      </div>
    </form>

   

   

  </div>
</template>
<style scoped>
.category_container {
  margin-top: 100px !important;
}

@media (max-width: 576px) {
  .category_container {
    margin-top: 0px !important;
  }
}

ul#topbar li {
  position: relative;
}
ul#topbar a {
  background-color: #ffa91f;
  color: #ffffff;
  opacity: 0.5;
}

ul#topbar a.active {  
  opacity: 1;
}

ul#topbar a.active::before {  
  content: '';
  position: absolute;
  border: 10px solid transparent;
  border-top: 0;
  border-bottom-color: white;
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
}

@media only screen and (max-width: 767px) {
  ul#topbar a.active::before {  
    content: '';
    position: absolute;
    border: 10px solid transparent;
    border-top: 0;
    border-bottom-color: white;
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
    opacity: 0;
  }
}

ul#topbar {
  background-color: #ffa91f;
  padding-bottom: 8px !important;
}

.without_bottom_border {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.without_top_border {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
</style>

<script>
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import { en, it } from "vuejs-datepicker/dist/locale";
import moment from "moment";

import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css' // Optional CSS



const default_layout = "default";

export default {
  components: {
    Datepicker,  
    VueSimpleSuggest 
  },

  props: {
    province: String,
    topbar_param: String,
    all_products_param: String,
    lingua_param: String,
  },

  mounted() { 
    if (this.$isMobile) {
      this.padding_cerca_tutto = '';
    }  

    this.$session.remove('name');
    this.$session.remove('county');
    this.$session.remove('service_date');

    window.addEventListener('keyup', (e) => {
      if (e.key == 'Backspace') {
        this.indietro = 1;        
      }      
    });
  },

  computed: {
    disabledSearch: function() {
      if (this.data_servizio != "" && this.nome != "" && this.provincia != "") {
        return false;
      }
      return true;
    },

    getCategoryId: function() {
      return this.category_id;
    },

    disabledProvincia: function() {
      if (this.disabled_provincia) {
        return true;
      } else {
        return false;
      }
    },

    disabledDataServizio: function() {
      if (this.disabled_data_servizio) {
        return true;
      } else {
        return false;
      }
    },

    disabledDataServizioBis: function() {
      if (this.disabled_data_servizio_bis) {
        return true;
      } else {
        return false;
      }
    }
  },

  data() {
    return {
      /* For props */
      dataprovince: JSON.parse(this.province),

      datacustomer: this.user ? JSON.parse(this.user) : [],
      topbar: JSON.parse(this.topbar_param),
      all_products: JSON.parse(this.all_products_param),

      /* Strings to translate */
      str_cosa: translate("fe_vue_ricerca.Cosa stai cercando?"),
      str_dove: translate("fe_vue_ricerca.Dove?"),
      str_quando: translate("fe_vue_ricerca.Quando?"),
      str_cerca: translate("fe_vue_ricerca.Cerca"),
      str_tutta_italia: translate("fe_vue_ricerca.Tutta Italia"),
      str_ricerca_libera: translate("fe_vue_ricerca.Ricerca libera"),
      
      it: it,
      en: en,
      disabledDates: {
        to: moment().toDate(),
      },
      disabled_provincia: false,
      disabled_data_servizio: false,
      disabled_data_servizio_bis: true,
      padding_cerca_tutto: "padding-left: 8px !important;",

      /* v-models */
      nome: '',
      data_servizio: '',
      provincia: '',
      category_id: null,

      indietro: 0,

      lingua_sito: this.lingua_param,


      
      autoCompleteStyle : {         
          defaultInput : "form-control",         
          suggestItem: "search-result-list"
        },

     datepicker_input_class: 'last',   
     
     
    };
  },

  methods: {

    focusSearch: function () {
      this.nome = ' ';
    },
    blurSearch: function () {
      if (this.nome == ' ') {
        this.nome = '';
      }
    },

    firstSuggestions: function () {
      return axios.get(`/search/suggestions`)
          .then((res) => {

            if (this.indietro == 0) {
              return res.data;
            } else {
              this.indietro = 0;
              return [];
            }

          }).catch((e) => {
            console.log(e.toString());
          })
    },

    autocompleteProduct: function() {      
      
      if (this.nome.length >= 2) {
        const nome = this.nome;
        
        return axios.post(`/search/autocomplete`, {
          nome: nome,
          category_id: this.category_id,
        }).then((res) => {          
          if (this.indietro == 0) {
            return res.data;
          } else {
            this.indietro = 0;            
            return [];
          }          
          
        }).catch((e) => {
          console.log(e.toString());
        })
      }

      return this.firstSuggestions();
    },

    onSuggestSelect: function() {
      setTimeout(() => {
        this.all_products.forEach((element) => {
          
          if (this.nome == element.nome || this.nome == element.nome_en) {
            
            if (element.localizzazione == 0) {
              this.provincia = 'XX';
              this.disabled_provincia = true;
              //this.data_servizio = moment().format('YYYY-MM-DD');
              this.data_servizio = moment().format('DD-MM-YYYY');
              this.disabled_data_servizio = true;
              this.disabled_data_servizio_bis = false;
              this.datepicker_input_class = 'last text-white';
            } else {
              this.provincia = '';
              this.disabled_provincia = false;
              this.data_servizio = '';
              this.disabled_data_servizio = false;
              this.disabled_data_servizio_bis = true;
              this.datepicker_input_class = 'last';
            }
          }
        })
      }, 100);
    },

       
    keyUpHandler: function() {
      console.log("Cliccato indietro")
    },

    setCategoryId: function(catid, index) {
      this.nome = '';
      this.$refs['tutte'].setAttribute('class', 'nav-link');
      this.topbar.forEach(element => {
        element.active = false;
      });


      let item = this.topbar[index];
      item.active = !item.active;
      this.category_id = catid;
      this.$set(this.topbar, index, item);
      this.provincia = '';
      this.disabled_provincia = false;
      this.data_servizio = '';
      this.disabled_data_servizio = false;
    },

    setCategoryFalse: function() {
      this.nome = '';
      this.$refs['tutte'].setAttribute('class', 'nav-link active');
      this.topbar.forEach(element => {
        element.active = false;
      });
      this.category_id = null;
      this.provincia = '';
      this.disabled_provincia = false;
      this.data_servizio = '';
      this.disabled_data_servizio = false;
    },

    getNomeCategoria: function(cat) {      
      if (this.lingua_param == 'en') {        
        if (cat.nome_en && cat.nome_en != '') {
          return cat.nome_en;
        } else {
          return cat.nome;
        }
      } else {
        return cat.nome;
      }
    },


  },
};
</script>

