<template>
  <div>
    <b-modal
      id="modal-1"
      ref="password_recover-modal"
      centered
      :title="str_ripristina_password"
      :hide-footer="true"      
    >
      

       <div class="form-group">
          <label>{{ str_email }}</label>
          <input type="text" class="form-control" v-model="email">
      </div>

      
      <button class="btn btn-primary" v-on:click="sendLink">{{ str_invia_link }}</button>

           
      
    </b-modal>

   
  </div>
</template>
<style scoped>
/deep/ .header_blue {
  background-color: #0038a7;
  color: white;
}

input[type="text"],
input[type="password"] {
  border: 1px solid #0038a7;
}

button.btn-yellow {
  background-color: #ffa91e;
}

button.btn-outline-blue {
  border: 1px solid #0038a7;
  background-color: white;
  color: #0038a7;
}

p,
a {
  color: #0038a7;
}

hr {
  border-top: 1px solid #0038a7;
}
</style>

<script>
import axios from "axios";
import EventBus from "./EventBus";

import { BootstrapVue, BModal, BSpinner } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

const default_layout = "default";

export default {
  props: {
   
  },

  computed: {},

  components: {
    BootstrapVue,
    BModal,
    BSpinner,
    EventBus,
  },

  mounted() {
      
    EventBus.$on(
      "show_password_recover_modal",
      function(val) {          
        this.showModal();
      }.bind(this)
    );
    
  },

  computed: {},
  data() {
    return {
      /* Strings */
      str_email: translate("vue.Indirizzo Email"),
      str_invia_link: translate("vue.Invia link di ripristino"),  
      str_ripristina_password: translate("vue.Ripristina password"),

      /* v-model */
      email: "",
     

    
      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),

      login_on_course: false,  
    };
  },

  methods: {
    showModal() {
      this.$refs["password_recover-modal"].show();
    },
    hideModal() {
      this.$refs["password_recover-modal"].hide();
    },

    sendLink: function() {
        if (this.email == '') {
          this.$swal({
                text: translate("vue.La mail è obbligatoria"),
                confirmButtonColor: '#001f5c',
              })
          return false;
        }

        axios.post(`/recover-password`, {
            email: this.email,
            motivo: 'ripristina',
        }).then((res) => {
            
            this.$swal({            
              icon: 'success',
              confirmButtonColor: '#001f5c',
              text: translate("vue.Ti è stata inviata una mail per ripristinare la password"),
              confirmButtonText: `OK`,
            }).then((result) => {
              this.email = '';  
              this.hideModal();
              location.href = '/';
            });     

        }).catch((e) => {
            console.log(e.toString());
        })
    }

    

    

    
  },
};
</script>
