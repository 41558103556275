<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-info-circle-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
          {{ str_dettagli_pubblici_agenzia }}
        </h4>
      </div>
      <div class="card-body">
        <div class="row mt-3">
          <div class="col-md-12">
            <label>{{ str_presentazione }}</label>
            <editor
              api-key="1bi08iqtgrt5329fayzjzgdvkkkw2er4381fkz93y60sfjlf"
              :init="{
                height: 200,
                branding: false,
                menubar: false,                
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
              }"
              
              v-model="presentazione"              
            />
          </div>
        </div>

        <div class="row mt-5 mb-5">
          <div class="col-md-4">
            <label>{{ str_logo }}</label>
            <input
              type="file"
              v-on:change="handleFileUploadLogotipo()"
              accept="image/jpeg, image/gif, image/png"
              ref="logotipo"
              style="color: #ffffff"
            />
            <div v-if="logo">
              <span v-if="logo">{{ logo.name }}</span>
            </div>
          </div>

          <div class="col-md-4">
            <label>{{ str_c_9001_url }}</label>
            <input
              type="file"
              v-on:change="handleFileUpload9001()"
              ref="c_9001_url"
              accept="image/jpeg, image/gif, image/png, application/pdf"
            />
          </div>

          <div class="col-md-4">
            <label>{{ str_iso_18000_url }}</label>
            <input
              type="file"
              v-on:change="handleFileUpload18000()"
              ref="iso_18000_url"
              accept="image/jpeg, image/gif, image/png, application/pdf"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-check align-middle">
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                v-model="localizzazione_gps"
              />
              <label class="form-check-label">
                {{ str_localizzazione_gps }}
              </label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-check align-middle">
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                v-model="bonifica_ambientale"
              />
              <label class="form-check-label">
                {{ str_bonifica_ambientale }}
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col"><hr /></div>
        </div>

        <div class="row">
          <div class="col">
            <label>{{ str_foto }} ({{ str_foto_instructions }})</label>

            <b-form-file
              accept="image/jpeg, image/png, image/gif"
              v-on:input="handleFileUploadFoto()"
              ref="foto"
              multiple
              :placeholder="str_foto"
              :browse-text="str_sfoglia"
            >
            </b-form-file>

            <div class="bg-light p-1" v-if="foto.length > 0">
              <p v-for="(n, index) in foto" :key="index">{{ n.name }}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <button class="btn btn-outline-secondary mt-2" @click="clearFiles">
              {{ str_rimuovi_files }}
            </button>
          </div>
        </div>

        <div class="row" v-if="salvataggio_agenzia_in_corso">
          <div class="col text-center">
            <!--<div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>-->
            <b-spinner label="Spinning"></b-spinner>
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-md-6 text-left">
            <button
              class="btn btn-secondary btn-yellow"
              v-on:click="tornaAPasso3"
            >
              {{ str_indietro }}
            </button>
          </div>
          <div class="col-md-6 text-right">
            <button
              class="btn btn-secondary btn-yellow"
              v-on:click="salvaAgenzia()"  v-if="!salvataggio_agenzia_in_corso"
            >
              {{ str_invia }}
            </button>
          </div>
        </div>
      </div>

      <div
        class="alert alert-danger"
        v-for="(err, indice) in errori_validazione.errors.errors"
        :key="indice"
      >
        {{ err[0] }}
      </div>
    </div>
  </div>
</template>
<style scoped>
input[type="file"] {
  color: #001f5c;
}
.form-check-label {
  font-size: 1rem;
}
button.btn-yellow {
  background-color: #ffa91f;
  color: #fff;
}

.dirty {
  border-color: #5a5;
  background: #efe;
}

.dirty:focus {
  outline-color: #8e8;
}

.error {
  border: 1px solid red;
  background: #fdd;
  padding: 5px;
}

.error:focus {
  outline-color: #f99;
}
</style>
<script>
import moment from "moment";
import axios from "axios";
import Editor from "@tinymce/tinymce-vue";

import {
  required,
  email,
  sameAs,
  requiredIf,
  helpers,
  minLength,
  numeric,
} from "vuelidate/lib/validators";

const validPhone = helpers.regex("validPhone", /^[\+]?\d{5,16}$/);

import {
  BootstrapVue,
  IconsPlugin,
  BFormInput,
  BFormCheckbox,
  BFormFile,
  BFormDatepicker,
  BFormSelect,
  BSpinner
} from "bootstrap-vue";


const default_layout = "default";

export default {
  watch: {
    presentazione() {
      const max_number_of_words = 250;
      let testo = this.strip_tags(this.presentazione);
      const testo_array = testo.split(" ");
      if (testo_array.length > max_number_of_words) {
        this.$swal({
          text: translate("fe_vue_registrazione_agenzia.Massimo 250 parole"),
          confirmButtonColor: "#001f5c",
        });

        let contenuto = '';
        for (let i = 0; i < max_number_of_words; i++) {
            contenuto += testo_array[i] + ' ';
        }
        this.presentazione = contenuto;

      }
    }
  },
  props: {
    passo1_param: Boolean,
    passo2_param: Boolean,
    passo3_param: Boolean,
    bloccato_param: Boolean,

    presentazione_param: String,
    logo_param: [String, File],
    c_9001_url_param: [String, File],
    iso_18000_url_param: [String, File],
    foto_param: Array,
    localizzazione_gps_param: [Boolean, Number],
    bonifica_ambientale_param: [Boolean, Number],
    salvataggio_agenzia_in_corso_param: [Boolean, Number],
  },
  components: {
    BFormInput,
    BFormCheckbox,   
    BFormFile,    
    BSpinner, 
    Editor
  },
  computed: {
    verificaCompletiPasso3: function() {
      if (this.tariffario != "" && this.tipi_indagine_da_inviare.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      /* Props */
      passo1: this.passo1_param,
      passo2: this.passo2_param,
      passo3: this.passo3_param,
      bloccato: this.bloccato_param,

      /* Stringhe */
      str_dettagli_pubblici_agenzia: translate("fe_vue_registrazione_agenzia.Dettagli pubblici agenzia"),
      str_presentazione: translate("fe_vue_registrazione_agenzia.Presentazione"),
      str_logo: translate("fe_vue_registrazione_agenzia.Logo"),
      str_c_9001_url: translate(
        "fe_vue_registrazione_agenzia.Certificato ISO 9001 (Pdf, jpg, jpeg, png)"
      ),
      str_iso_18000_url: translate(
        "fe_vue_registrazione_agenzia.Certificato ISO 18000 (Pdf, jpg, jpeg, png)"
      ),
      str_localizzazione_gps: translate(
        "fe_vue_registrazione_agenzia.Sistemi di localizzazione satellitare GPS"
      ),
      str_bonifica_ambientale: translate("fe_vue_registrazione_agenzia.Bonifica ambientale"),

      str_campo_obbligatorio: translate("fe_vue_registrazione_agenzia.Campo obbligatorio"),
      str_avanti: translate("fe_vue_registrazione_agenzia.Avanti"),
      str_indietro: translate("fe_vue_registrazione_agenzia.Indietro"),
      str_campi_obbligatori: translate("fe_vue_registrazione_agenzia.Campi obbligatori"),
      str_foto: translate("fe_vue_registrazione_agenzia.Fotografie della tua agenzia"),
      str_foto_instructions: translate(
        "fe_vue_registrazione_agenzia.max 5 foto in formato JPG, JPEG, PNG, GIF"
      ),
      str_sfoglia: translate("fe_vue_registrazione_agenzia.Sfoglia"),
      str_rimuovi_files: translate("fe_vue_registrazione_agenzia.Rimuovi files"),
      str_invia: translate("fe_vue_registrazione_agenzia.Invia"),

      /* Models */
      presentazione: this.presentazione_param,
      logo: this.logo_param,
      c_9001_url: this.c_9001_url_param,
      iso_18000_url: this.iso_18000_url_param,
      foto: this.foto_param,
      localizzazione_gps: this.localizzazione_gps_param,
      bonifica_ambientale: this.bonifica_ambientale,
      /* Errori */
      errori_validazione: new Errors(),

      /* States */

      /* Altro */
      salvataggio_agenzia_in_corso: this.salvataggio_agenzia_in_corso_param,
    };
  },

  methods: {
    tornaAPasso3: function() {
      this.$emit("tornaapasso3");
    },

    handleFileUploadLogotipo() {
      this.logo = this.$refs.logotipo.files[0];
    },

    handleFileUpload9001() {
      this.c_9001_url = this.$refs.c_9001_url.files[0];
    },

    handleFileUpload18000() {
      this.iso_18000_url = this.$refs.iso_18000_url.files[0];
    },

    handleFileUploadFoto() {
      if (this.$refs.foto.files.length > 5) {
        //console.log("maggiore di 5")
        this.clearFiles();
        this.$swal({
          text: translate("fe_vue_registrazione_agenzia.Massimo 5 foto"),
          confirmButtonColor: "#001f5c",
        });
      }
      for (let i = 0; i < this.$refs.foto.files.length; i++) {
        this.foto.push(this.$refs.foto.files[i]);
      }
    },

    clearFiles: function() {
      this.$refs["foto"].reset();
      this.foto = [];
    },
    
    salvaAgenzia: function() {
      this.salvataggio_agenzia_in_corso = true;
      this.$emit(
        "preparasalvataggio",
        this.presentazione,
        this.logo,
        this.c_9001_url,
        this.iso_18000_url,
        this.foto,
        this.localizzazione_gps,
        this.bonifica_ambientale
      );
    },

    strip_tags: function(input, allowed) {
      allowed = (((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
      var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
        commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
        return input.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
        return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
      });
    },

    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },

  validations: {},
};

class Errors {
  constructor() {
    this.errors = {};
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  record(errors) {
    this.errors = errors;
  }
}
</script>
