<template>
  <div>
    <b-modal
      id="modal-1"
      ref="login-modal"
      centered
      :title="str_accedi"
      :hide-footer="true"      
    >
      <div class="bg-danger px-2 py-2" v-if="login_failed">
        {{ str_login_failed }}
      </div>

      <div class="bg-danger px-2 py-2" v-if="login_account_on_validation">
        {{ str_login_account_on_validation }}
      </div>

      <div class="bg-warning px-2 py-2" v-if="mostra_messaggio_geolocalizzazione">{{ str_messaggio_geolocalizzazione }}</div>

      <div class="form-group">
        <label class="sr-only">{{ str_email }}</label>
        <input
          type="text"
          class="form-control"
          v-model="email"
          :placeholder="str_email"
          @keyup.enter="login"
        />
      </div>

      <div class="form-group">
        <label class="sr-only">{{ str_password }}</label>
        <div class="input-group">
          <input
              type="password"
              class="form-control"
              ref="password"
              v-model="password"
              :placeholder="str_password"
              @keyup.enter="login"
          />
          <div class="input-group-append">
                <span
                    class="input-group-text cursor-pointer"
                    v-on:click="mostraPassword('password')"
                >
                  <i class="fas fa-eye"></i>
                </span>
          </div>
        </div>
      </div>

      <div v-if="login_on_course" class="text-center">
        <b-spinner label="Spinning"></b-spinner>
      </div>

      <div class="text-center">
        <button class="btn btn-primary btn-yellow" v-on:click="login">
          {{ str_accedi }}
        </button>
        <p class="mt-1 mb-1">
          
          <a href="/auth/forgot-password"
            ><b>{{ str_forgot_pwd }}</b></a
          >
        </p>

        <p class="mt-1 mb-1">
          {{ str_no_registered }}
          <a href="javascript:void(0)" v-on:click="openRegisterModal"
            ><b>{{ str_register }}</b></a
          >
        </p>

        <hr />
        <p class="mt-1 mb-3">
          {{ str_sei_un }} <b>{{ str_agenzia }}</b> {{ str_o_un }}
          <b>{{ str_agente }}</b
          >?
        </p>
        <button class="btn btn-outline-blue" v-on:click="registerAgencyUrl">{{ str_register }}</button>
      </div>
    </b-modal>

   
  </div>
</template>
<style scoped>
/deep/ .header_blue {
  background-color: #0038a7;
  color: white;
}

input[type="text"],
input[type="password"] {
  border: 1px solid #0038a7;
}

button.btn-yellow {
  background-color: #ffa91e;
}

button.btn-outline-blue {
  border: 1px solid #0038a7;
  background-color: white;
  color: #0038a7;
}

p,
a {
  color: #0038a7;
}

hr {
  border-top: 1px solid #0038a7;
}
</style>

<script>
import axios from "axios";
import EventBus from "../new-search/EventBus";

import { BootstrapVue, BModal, BSpinner } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

const default_layout = "default";

export default {
  props: {
   privacy_url_param: String,
  },

  components: {
    BootstrapVue,
    BModal,
    BSpinner,
    EventBus,
  },

  mounted() {
    EventBus.$on(
      "show_general_login_modal",
      function(val) {
        this.showModal();
      }.bind(this)
    );

    EventBus.$on("set_register_url", (val) => {      
      this.register_url = val;
    });

    if (this.$session.has('messaggio_geolocalizzazione')) {
      this.mostra_messaggio_geolocalizzazione = true;
    }
    
  },

  computed: {},
  data() {
    return {
      /* Strings */
      str_email: translate("fe_vue_profilo_cliente.Indirizzo Email"),
      str_password: translate("fe_vue_profilo_cliente.Password"),
      str_accedi: translate("fe_vue_profilo_cliente.Accedi"),
      str_no_registered: translate("fe_vue_profilo_cliente.Non sei ancora iscritto"),
      str_register: translate("fe_vue_profilo_cliente.Registrati"),
      str_sei_un: translate("fe_vue_profilo_cliente.Sei un"),
      str_agenzia: translate("fe_vue_profilo_cliente.agenzia investigativa"),
      str_o_un: translate("fe_vue_profilo_cliente.o un"),
      str_agente: translate("fe_vue_profilo_cliente.agente"),
      str_login_failed: translate(
        "fe_vue_profilo_cliente.Queste credenziali non corrispondono a nessun utente valido"
      ),
      str_login_account_on_validation: translate(
          "fe_vue_profilo_cliente.Il tuo account è ancora in fase di validazione"
      ),
      str_forgot_pwd: translate("fe_vue_profilo_cliente.Password dimenticata?"),
      str_messaggio_geolocalizzazione: translate("fe_vue_profilo_cliente.Per poter utilizzare Fidavo è necessario consentire la geo localizzazione"),

      privacy_url: this.privacy_url_param,
      register_url: null,

      /* v-model */
      email: "",
      password: "",

      /* Other */

      login_failed: false,
      login_account_on_validation: false,

      agenzia_id: 0,
      prod_id: 0,
      prezzo: 0,

      mostra_messaggio_geolocalizzazione: false,

    
      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      login_on_course: false,  
    };
  },

  methods: {
    showModal() {
      axios.get(`/ajax_remove_cookie`).then((res) => {
            this.$refs["login-modal"].show();
      }).catch((e) => {
            console.log(e.toString());
      })      
    },
    hideModal() {
      this.$refs["login-modal"].hide();
    },

    login: function() {
      this.login_on_course = true;
      axios
        .post("/login", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {


          if (res.data == "fail") {
            this.login_on_course = false;
            this.login_failed = true;
          } else if(res.data == 'noactive') {
            this.login_on_course = false;
            this.login_account_on_validation = true;
          } else if(res.data == "create_keys") {
            location.href = '/customer/cases/choose_password';
          } else if(res.data == 'verify_email'){
            location.href = '/email/verify';
          } else {

            //console.log(res.data);return;
            this.$cookie.set('toast', 1, { expires: '60s' });
                        
            setTimeout(() => {
              location.href = res.data;
            }, 500)
            
            
          }
        })
        .catch((e) => {

          if (e.toString().indexOf('419') > -1) {            
            this.$swal({            
              icon: 'error',
              confirmButtonColor: '#001f5c',
              text: translate("fe_vue_profilo_cliente.Sessione scaduta"),
              confirmButtonText: `OK`,
            }).then((result) => {
              if (result.isConfirmed) {
                location.reload();
              }
            });            
          }
          
          console.log(e.toString());
          
        });
    },

    openRegisterModal: function() {
      EventBus.$emit("show_register_modal", 1);
      this.hideModal();
    },

    registerAgencyUrl: function() {
      if (this.register_url) {
        location.href = this.register_url;
      } else {        
        location.href = process.env.MIX_URL_REGISTRAZIONE_AGENZIA;
      }
      
    },


    mostraPassword: function(campo) {
      if (this.$refs.password.getAttribute("type") == "password") {
        this.$refs.password.setAttribute("type", "text");
        this.campo_mostra_password = "text";
      } else {
        this.$refs.password.setAttribute("type", "password");
        this.campo_mostra_password = "password";
      }
    },

    
  },
};
</script>
