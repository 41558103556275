<template>
  <div>
    <b-modal
      id="modal-1"
      ref="login-modal"
      centered
      :title="str_accedi"
      :hide-footer="true"      
    >
      <div class="bg-danger px-2 py-2" v-if="login_failed">
        {{ str_login_failed }}
      </div>

      <div class="form-group">
        <label class="sr-only">{{ str_email }}</label>
        <input
          type="text"
          class="form-control"
          v-model="email"
          :placeholder="str_email"
          @keyup.enter="login"
        />
      </div>

      <div class="form-group">
        <label class="sr-only">{{ str_password }}</label>
        <div class="input-group">
          <input
              type="password"
              ref="password"
              class="form-control"
              v-model="password"
              :placeholder="str_password"
              @keyup.enter="login"
          />
          <div class="input-group-append">
                <span
                    class="input-group-text cursor-pointer"
                    v-on:click="mostraPassword('password')"
                >
                  <i class="fas fa-eye"></i>
                </span>
          </div>
        </div>
      </div>

      <div v-if="login_on_course" class="text-center">
        <b-spinner label="Spinning"></b-spinner>
      </div>

      <div class="text-center">
        <button class="btn btn-primary btn-yellow" v-on:click="login">
          {{ str_accedi }}
        </button>

        <p class="mt-1 mb-1">
          
          <a href="/auth/forgot-password"
            ><b>{{ str_forgot_pwd }}</b></a
          >
        </p>

        <p class="mt-1 mb-1">
          {{ str_no_registered }}
          <a href="javascript:void(0)" v-on:click="openRegisterModal"
            ><b>{{ str_register }}</b></a
          >
        </p>

        <hr />
        <p class="mt-1 mb-3">
          {{ str_sei_un }} <b>{{ str_agenzia }}</b> {{ str_o_un }}
          <b>{{ str_agente }}</b
          >?
        </p>
        <button class="btn btn-outline-blue" v-on:click="registerAgencyUrl">{{ str_register }}</button>
      </div>
    </b-modal>

   
  </div>
</template>
<style scoped>
/deep/ .header_blue {
  background-color: #0038a7;
  color: white;
}

input[type="text"],
input[type="password"] {
  border: 1px solid #0038a7;
}

button.btn-yellow {
  background-color: #ffa91e;
}

button.btn-outline-blue {
  border: 1px solid #0038a7;
  background-color: white;
  color: #0038a7;
}

p,
a {
  color: #0038a7;
}

hr {
  border-top: 1px solid #0038a7;
}
</style>

<script>
import axios from "axios";
import EventBus from "../EventBus";

import { BootstrapVue, BModal, BSpinner } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

const default_layout = "default";

export default {
  props: {
   
  },

  components: {
    BootstrapVue,
    BModal,
    BSpinner,
    EventBus,
  },

  mounted() {
    EventBus.$on(
      "show_login_modal",
      function(val) {
        this.showModal();
      }.bind(this)
    );

    EventBus.$on(
      "save_prezzo",
      function(val) {
        this.save_prezzo = val;
      }.bind(this)
    );

    EventBus.$on(
      "save_agency_id",
      function(val) {
        this.save_agency_id = val;
      }.bind(this)
    );

    EventBus.$on(
      "save_product_id",
      function(val) {
        this.save_product_id = val;
      }.bind(this)
    );

    EventBus.$on(
      "save_service_data",
      function(val) {
        this.save_service_data = val;
      }.bind(this)
    );

    EventBus.$on(
      "save_search_text",
      function(val) {
        this.save_search_text = val;
      }.bind(this)
    );

    EventBus.$on(
      "save_provincia_sigla",
      function(val) {
        this.save_provincia_sigla = val;
      }.bind(this)
    );
    
  },

  computed: {},
  data() {
    return {
      /* Strings */
      str_email: translate("fe_vue_profilo_cliente.Indirizzo Email"),
      str_password: translate("fe_vue_profilo_cliente.Password"),
      str_accedi: translate("fe_vue_profilo_cliente.Accedi"),
      str_no_registered: translate("fe_vue_profilo_cliente.Non sei ancora iscritto"),
      str_register: translate("fe_vue_profilo_cliente.Registrati"),
      str_sei_un: translate("fe_vue_profilo_cliente.Sei un"),
      str_agenzia: translate("fe_vue_profilo_cliente.agenzia investigativa"),
      str_o_un: translate("fe_vue_profilo_cliente.o un"),
      str_agente: translate("fe_vue_profilo_cliente.agente"),
      str_login_failed: translate(
        "fe_vue_profilo_cliente.Queste credenziali non corrispondono a nessun utente valido"
      ),
      str_forgot_pwd: translate("fe_vue_profilo_cliente.Password dimenticata?"),

      /* v-model */
      email: "",
      password: "",

      /* Other */

      login_failed: false,

      agenzia_id: 0,
      prod_id: 0,
      prezzo: 0,


      save_prezzo: 0,
      save_agency_id: 0,
      save_product_id: 0,
      save_service_data: 0,
      save_provincia_sigla: '',
      save_save_search_text: '',

    
      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),

      login_on_course: false,  
    };
  },

  methods: {
    showModal() {
      axios.get(`/ajax_remove_cookie`).then((res) => {
            this.$refs["login-modal"].show();
      }).catch((e) => {
            console.log(e.toString());
      })
    },
    hideModal() {
      this.$refs["login-modal"].hide();
    },

    login: function() {
      this.login_on_course = true;
      axios
        .post("/ajax_custyomer_login", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          

          if (res.data == "fail") {
            this.login_failed = true;
            this.login_on_course = false;
          } else if(res.data == "create_keys") {
            location.href = '/customer/cases/choose_password';
          } else if(res.data == 'verify_email'){
            location.href = '/email/verify';
          } else {
            EventBus.$emit("datacustomer", res.data);
            //this.refreshAppTokens();

            //this.$refs.formredir.submit();
          }
        })
        .catch((e) => {

          if (e.toString().indexOf('419') > -1) {            
            this.$swal({            
              icon: 'error',
              confirmButtonColor: '#001f5c',
              text: translate("fe_vue_profilo_cliente.Sessione scaduta"),
              confirmButtonText: `OK`,
            }).then((result) => {
              if (result.isConfirmed) {
                location.reload();
              }
            });            
          }

          console.log(e.toString());
        });
    },

    openRegisterModal: function() {
      EventBus.$emit("show_search_register_modal", 1);


      EventBus.$emit("save_prezzo", this.save_prezzo);
      EventBus.$emit("save_agency_id", this.save_agency_id);
      EventBus.$emit("save_product_id", this.save_product_id);
      EventBus.$emit("save_service_data", this.save_service_data);

      this.hideModal();
    },

    registerAgencyUrl: function() {
      location.href = 'agency/register';
    },


    mostraPassword: function(campo) {
      if (this.$refs.password.getAttribute("type") == "password") {
        this.$refs.password.setAttribute("type", "text");
        this.campo_mostra_password = "text";
      } else {
        this.$refs.password.setAttribute("type", "password");
        this.campo_mostra_password = "password";
      }
    },

    
  },
};
</script>
