<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-building"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
            />
            <path
              d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"
            />
          </svg>
          {{ str_anagrafica_agenzia }}
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <!-- Ragione sociale -->
            <div class="form-group">
              <label
                >{{ str_ragione_sociale }}
                <span class="text-danger">*</span></label
              >

              <b-form-input
                v-model.trim="$v.ragione_sociale.$model"
                tabindex="1"
                :state="ragione_sociale_state"
                v-on:keyup="changeStateRagioneSociale"
                v-on:blur="changeStateRagioneSociale"
              ></b-form-input>

              <div
                class="error"
                v-if="!$v.ragione_sociale.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Partita IVA  -->
            <div class="form-group">
              <label
                >{{ str_partita_iva }}
                <!--<span v-if="getStrFormatoPI">({{ str_11_numeri }})</span>-->
                <span class="text-danger">*</span></label
              >
              <b-form-input
                v-model.trim="$v.partita_iva.$model"
                tabindex="2"
                :state="partita_iva_state"
                v-on:keyup="changeStatePartitaIva"
                v-on:blur="changeStatePartitaIva"
              ></b-form-input>

              <div
                class="error"
                v-if="!$v.partita_iva.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>

              <div class="error" v-if="errore_lunghezza_pi && bloccato == true">
                {{ str_lunghezza_partita_iva }}
              </div>
              <div
                class="error"
                v-if="errore_non_numerica_pi && bloccato == true"
              >
                {{ str_solo_numeri }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <!-- PEC -->
            <div class="form-group">
              <label>{{ str_pec }}</label>

              <b-form-input
                autocomplete="off"
                v-model.trim="$v.pec.$model"
                :state="pec_state"
                v-on:keyup="changeStatePec"
                v-on:blur="changeStatePec"
                class="form-control"
                ref="pec"
                tabindex="3"
              ></b-form-input>

              <div class="error" v-if="!$v.pec.email && bloccato == true">
                {{ str_email_non_valida }}
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <label>{{ str_paese }} <span class="text-danger">*</span></label>
            <b-form-select
              class="border-blue"
              v-model="prefix_tel"
              :options="prefixes"
            ></b-form-select>
          </div>
          <div class="col-md-4">
            <!-- Numero di telefono -->
            <div class="form-group">
              <label
                >{{ str_telefono }} ({{ str_senza_spazi }})<span
                  class="text-danger"
                  >*</span
                ></label
              >

              <b-form-input
                type="tel"
                v-model="$v.telefono.$model"
                autocomplete="off"
                class="form-control"
                pattern="[\+]?\d{5,16}"
                tabindex="4"
                placeholder="12345678"
                :state="telefono_state"
                v-on:keyup="changeStateTelefono"
                v-on:blur="changeStateTelefono"
                role="presentation"                
              ></b-form-input>

              <div
                class="error"
                v-if="!$v.telefono.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>

              <div
                class="error"
                v-if="!$v.telefono.validFixedPhone && bloccato == true"
              >
                {{ str_formato_telefono_non_valido }}
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="show_google_place">
          <div class="col">
            <!-- Ricerca Google -->
            <div class="form-group">              
              <label role="presentation">{{ str_indirizzo }}</label>
              <vue-google-autocomplete
                id="map"
                classname="form-control"
                :placeholder="str_cerca_google_maps"
                v-on:placechanged="getAddressData"
                ref="ac_ind_sede"
                role="presentation"
              >
              </vue-google-autocomplete>
            </div>
          </div>
        </div>

        <div class="row" v-if="!show_google_place">
          <div class="col">
            <!-- Pulsante reimposta -->
            <div class="form-group">
              <button class="btn btn-info mt-2" v-on:click="resetAddress">
                {{ str_reset_address }}
              </button>
            </div>
          </div>
        </div>

        <div class="row" v-if="!show_google_place">
          <div class="col-md-6">
            <!-- Indirizzo -->
            <div class="form-group">
              <label
                >{{ str_indirizzo }} <span class="text-danger">*</span></label
              >

              <b-form-input
                type="text"
                v-model="$v.indirizzo_sede_principale.$model"
                autocomplete="off"
                class="form-control"
                tabindex="5"
                v-on:keyup="changeStateIndirizzoSedePrincipale"
                v-on:blur="changeStateIndirizzoSedePrincipale"
                :state="indirizzo_sede_principale_state"
              ></b-form-input>

              <div
                class="error"
                v-if="
                  !$v.indirizzo_sede_principale.required && bloccato == true
                "
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- CAP -->
            <div class="form-group">
              <label
                >{{ str_cap }}
                <span v-if="getStrFormatoCAP">{{ str_5_numeri }}</span>
                <span class="text-danger">*</span></label
              >

              <b-form-input
                autocomplete="off"
                v-model="$v.cap.$model"
                tabindex="6"
                :state="cap_state"
                v-on:keyup="changeStateCap"
                v-on:blur="changeStateCap"
              ></b-form-input>

              <div class="error" v-if="!$v.cap.required && bloccato == true">
                {{ str_campo_obbligatorio }}
              </div>
              <!--
              <div
                class="error"
                v-if="errore_cap_non_numerico && bloccato == true"
              >
                {{ str_solo_numeri }}
              </div>
              <div
                class="error"
                v-if="errore_cap_lunghezza && bloccato == true"
              >
                {{ str_5_numeri }}
              </div>
              -->
            </div>
          </div>
        </div>

        <div class="row" v-if="!show_google_place">
          <div class="col-md-6">
            <!-- Comune -->
            <div class="form-group">
              <label>{{ str_comune }} <span class="text-danger">*</span></label>

              <b-form-input
                v-model="$v.comune.$model"
                tabindex="7"
                :state="comune_state"
                v-on:keyup="changeStateComune"
                v-on:blur="changeStateComune"
              ></b-form-input>

              <div class="error" v-if="!$v.comune.required && bloccato == true">
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Paese -->
            <div class="form-group">
              <label>{{ str_paese }} <span class="text-danger">*</span></label>

              <b-form-select
                :state="country_state"
                class="border-blue"
                v-model="$v.country_id.$model"
                :options="paesi"
                v-on:change="changeStateCountry"
              >
                <option value="">&nbsp;</option>
              </b-form-select>

              <div
                class="error"
                v-if="!$v.country_id.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <!-- Provincia -->
            <div
              class="form-group"
              v-if="(country_id == 144) && !show_google_place"
            >
              <label
                >{{ str_provincia }} <span class="text-danger">*</span></label
              >

              <b-form-select
                :state="provincia_sigla_state"
                class="border-blue"
                v-model="$v.provincia_sigla.$model"
                :options="dataprovince"
                v-on:change="changeStateProvinciaSigla"
              >
                <option value="">&nbsp;</option>
              </b-form-select>

              <div
                class="error"
                v-if="!$v.provincia_sigla.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>

            <div
              class="form-group"
              v-if="(country_id != 144) && !show_google_place"
            >
              <label>{{ str_provincia }}</label>
              <input
                type="text"
                v-model="provincia_estera"
                class="form-control"
                tabindex="9"
              />
            </div>
          </div>
          <div class="col-md-6">
            <!-- Numero licenza -->
            <div class="form-group">
              <label
                >{{ str_numero_licenza }}
                <span class="text-danger">*</span></label
              >

              <b-form-input
                v-model="$v.numero_licenza.$model"
                tabindex="10"
                :state="numero_licenza_state"
                v-on:keyup="changeStateNumeroLicenza"
                v-on:blur="changeStateNumeroLicenza"
              ></b-form-input>
              <div
                class="error"
                v-if="!$v.numero_licenza.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <!-- Anno di apertura -->
            <div class="form-group">
              <label
                >{{ str_anno_apertura }}
                <span class="text-danger">*</span></label
              >

              <b-form-select
                :state="anno_apertura_state"
                class="border-blue"
                v-model="$v.anno_apertura.$model"
                :options="years"
                v-on:change="changeStateAnnoApertura"
              >
                <option value="">&nbsp;</option>
              </b-form-select>

              <div
                class="error"
                v-if="!$v.anno_apertura.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Ultima visura camerale -->
            <div class="form-group">
              <label
                >{{ str_ultima_visura }}
                <span class="text-danger">*</span></label
              >
              <input
                type="file"
                v-on:change="handleFileUploadVC()"
                ref="visura_camerale_url"
                tabindex="12"
                accept="image/jpeg, image/gif, image/png, application/pdf"
              />
              <div v-if="visura_camerale_url && mostra_visura_camerale_url">
                <span>{{ visura_camerale_url.name }}</span>
              </div>

              <div
                class="error"
                v-if="errore_visura_camerale_url && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <!-- Data emissione licenza prefettizia -->
            <div class="form-group">
              <label
                >{{ str_data_emissione_licenza_prefettizia }}
                <span class="text-danger">*</span></label
              >

              <!--<b-form-datepicker
                :state="data_emissione_licenza_prefettizia_state"
                v-on:shown="changeStateDataEmissioneLP"
                v-on:hidden="changeStateDataEmissioneLP"
                v-model="$v.data_emissione_licenza_prefettizia.$model"
                class="form-control border-blue"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                :date-disabled-fn="getMaxDateEmissioneLP"
                :locale="locale"
                show-decade-nav
                no-flip
                :placeholder="str_data_emissione_licenza_prefettizia"
                hide-header
              ></b-form-datepicker>-->

              <b-form-input 
              type="date" v-model="$v.data_emissione_licenza_prefettizia.$model"
              :max="getStartCalendarReleaseLP" 
              @input="changeStateDataEmissioneLP" 
                class="form-control border-blue" :state="data_emissione_licenza_prefettizia_state"></b-form-input> 

              <div
                class="error"
                v-if="
                  !$v.data_emissione_licenza_prefettizia.required &&
                    bloccato == true
                "
              >
                {{ str_campo_obbligatorio }}
              </div>

              <div
                class="error"
                v-if="errore_data_emissione_formato"
              >
                {{ str_data_emissione_formato }}
              </div>

              
              <!--</div>-->
            </div>
          </div>
          <div class="col-md-6">
            <!-- Data scadenza licenza prefettizia -->
            <div class="form-group">
              <label
                >{{ str_data_scadenza_licenza_prefettizia }}
                <span class="text-danger">*</span></label
              >
              <!--
              <b-form-datepicker
                :state="data_scadenza_licenza_prefettizia_state"
                v-on:shown="changeStateDataScadenzaLP"
                v-on:hidden="changeStateDataScadenzaLP"
                v-model="$v.data_scadenza_licenza_prefettizia.$model"
                class="form-control border-blue"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                :locale="locale"
                show-decade-nav
                :placeholder="str_data_scadenza_licenza_prefettizia"
                no-flip
                hide-header
              ></b-form-datepicker>
              -->
              <b-form-input 
              type="date" v-model="$v.data_scadenza_licenza_prefettizia.$model"             
              @input="changeStateDataScadenzaLP" 
                class="form-control border-blue" :state="data_scadenza_licenza_prefettizia_state"></b-form-input> 

              <div
                class="error"
                v-if="
                  !$v.data_scadenza_licenza_prefettizia.required &&
                    bloccato == true
                "
              >
                {{ str_campo_obbligatorio }}
              </div>

              <div class="error" v-if="errore_data_emissione_maggiore_scadenza">
                {{ str_data_emissione_maggiore_scadenza }}
              </div>
              <!--</div>-->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <!-- Licenza prefettizia (Pdf, jpg, jpeg, png) * -->
            <div class="form-group">
              <label
                >{{ str_licenza_prefettizia_url }}
                <span class="text-danger">*</span></label
              >
              <input
                type="file"
                v-on:change="handleFileUploadLP()"
                ref="licenza_prefettizia_url"
                accept="image/jpeg, image/gif, image/png, application/pdf"
              />
              <div v-if="licenza_prefettizia_url && mostra_licenza_prefettizia_url">
                <span>{{ licenza_prefettizia_url.name }}</span>
              </div>

              <div
                class="error"
                v-if="errore_licenza_prefettizia_url && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Vuoto -->
            <div
              class="form-group"
              v-if="mostra_comunicazione_prosecuzione_attivita"
            >
              <label
                >{{ str_comunicazione_prosecuzione_attivita }}
                <span class="text-danger">*</span></label
              >
              <input
                type="file"
                v-on:change="handleFileUploadCPA()"
                ref="comunicazione_prosecuzione_attivita"
                accept="image/jpeg, image/gif, image/png, application/pdf"
                tabindex="14"
              />
              <div v-if="comunicazione_prosecuzione_attivita && mostra_comunicazione_prosecuzione_attivita_url">
                <span>{{ comunicazione_prosecuzione_attivita.name }}</span>
              </div>
              <div>
                <small class="bg-warning p-1">{{ str_364_gg }}</small>
              </div>

              <div
                class="error"
                v-if="
                  errore_comunicazione_prosecuzione_attivita && bloccato == true
                "
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col">
            <h4>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-person-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                />
              </svg>
              {{ str_dettagli_titolare }}
            </h4>
          </div>
        </div>

        <div class="border p-3">
          <div class="row">
            <div class="col-md-6">
              <label
                >{{ str_comune_nascita }}
                <span class="text-danger">*</span></label
              >

              <b-form-input
                v-model="$v.holder_birthplace.$model"
                :state="holder_birthplace_state"
                v-on:keyup="changeStateHolderBirthplace"
                v-on:blur="changeStateHolderBirthplace"
              ></b-form-input>
              <div
                class="error"
                v-if="!$v.holder_birthplace.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
            <div class="col-md-6">
              <label
                >{{ str_data_nascita }}
                <span class="text-danger">*</span></label
              >
<!--
              <b-form-datepicker
                :state="data_nascita_state"
                v-on:shown="changeStateDataNascita"
                v-on:hidden="changeStateDataNascita"
                v-model="$v.holder_date_of_birth.$model"
                class="form-control border-blue"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                :locale="locale"
                :date-disabled-fn="getMaxDate"
                show-decade-nav
                :initial-date="getInitialDate"
                :placeholder="str_data_nascita"
                no-flip
                hide-header
              ></b-form-datepicker>
-->
                 <b-form-input 
        type="date" v-model="$v.holder_date_of_birth.$model"
         :max="getStartCalendar" 
         @input="changeStateDataNascita" 
          class="form-control border-blue" :state="data_nascita_state"></b-form-input> 


              <div
                class="error"
                v-if="!$v.holder_date_of_birth.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>

              <div
                class="error"
                v-if="errore_data_nascita_formato"
              >
                {{ str_data_nascita_formato }}
              </div>
              <!--</div>-->
            </div>
          </div>

          <div class="row mt-2" v-if="show_google_place_holder">
            <div class="col">
              <!-- Ricerca Google -->
              <div class="form-group">
                <label>{{ str_indirizzo }}</label>
                <vue-google-autocomplete
                  id="map2"
                  classname="form-control"
                  :placeholder="str_cerca_google_maps"
                  v-on:placechanged="getHolderAddressData"
                  ref="ac_ind_titolare"
                  role="presentation"
                >
                </vue-google-autocomplete>
              </div>
            </div>
          </div>

          <div class="row mt-2" v-if="!show_google_place_holder">
            <div class="col">
              <!-- Pulsante reimposta -->
              <div class="form-group">
                <button
                  class="btn btn-info mt-2"
                  v-on:click="resetHolderAddress"
                >
                  {{ str_reset_address }}
                </button>
              </div>
            </div>
          </div>

          <div class="row mt-1" v-if="!show_google_place_holder">
            <div class="col-md-6">
              <label
                >{{ str_indirizzo_titolare }}
                <span class="text-danger">*</span></label
              >

              <b-form-input
                v-model="$v.holder_address.$model"
                :state="holder_address_state"
                v-on:keyup="changeStateHolderAddress"
                v-on:blur="changeStateHolderAddress"
              ></b-form-input>
              <div
                class="error"
                v-if="!$v.holder_address.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
            <div class="col-md-6">
              <label
                >{{ str_comune_titolare }}
                <span class="text-danger">*</span></label
              >

              <b-form-input
                v-model="$v.holder_city.$model"
                :state="holder_city_state"
                v-on:keyup="changeStateHolderCity"
                v-on:blur="changeStateHolderCity"
              ></b-form-input>
              <div
                class="error"
                v-if="!$v.holder_city.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>

          <div class="row mt-2" v-if="!show_google_place_holder">
            <div class="col-md-4">
              <label
                >{{ str_cap_titolare }}
                <!--<span v-if="getStrFormatoHolderZipcode"
                  >({{ str_5_numeri }})</span
                >-->
                <span class="text-danger">*</span></label
              >

              <b-form-input
                v-model="$v.holder_zipcode.$model"
                :state="holder_zipcode_state"
                v-on:keyup="changeStateHolderZipcode"
                v-on:blur="changeStateHolderZipcode"
              ></b-form-input>
              <div
                class="error"
                v-if="!$v.holder_zipcode.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
              <!--
              <div
                class="error"
                v-if="errore_holder_zipcode_non_numerico && bloccato == true"
              >
                {{ str_solo_numeri }}
              </div>
              <div
                class="error"
                v-if="errore_holder_zipcode_lunghezza && bloccato == true"
              >
                {{ str_5_numeri }}
              </div>
              -->
            </div>
            <div class="col-md-4">
              <label>{{ str_paese }} <span class="text-danger">*</span></label>

              <b-form-select
                class="border-blue"
                v-model="$v.holder_country_id.$model"
                :options="paesi"
              >
              </b-form-select>

              <div
                class="error"
                v-if="!$v.holder_country_id.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
            <div class="col-md-4">
              <label
                >{{ str_provincia_titolare }}
                <span class="text-danger" v-if="holder_country_id == 144"
                  >*</span
                ></label
              >

              <b-form-select
                :state="holder_county_state"
                class="border-blue"
                v-model="$v.holder_county.$model"
                :options="dataprovince"
                v-on:change="changeStateHolderCounty"
                v-if="holder_country_id == 144"
              >
                <option value="">&nbsp;</option>
              </b-form-select>

              <b-form-input
                v-model="holder_foreign_county"
                v-if="holder_country_id != 144"
              ></b-form-input>

              <div
                class="error"
                v-if="!$v.holder_county.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col">
            <h4>
              <i class="fas fa-user-secret fa-lg"></i>
              {{ str_prefettura_questura }}
            </h4>
          </div>
        </div>

        <div class="border p-3">
          <div class="row mt-1">
            <div class="col-md-6">
              <label
                >{{ str_prefettura_appartenenza }}
                <span class="text-danger">*</span></label
              >

              <b-form-select
                :state="holder_prefecture_state"
                class="border-blue"
                v-model="$v.prefecture_id.$model"
                :options="prefetture"
                v-on:change="changeStateHolderPrefecture"
              >
                <option value="">&nbsp;</option>
              </b-form-select>

              <div
                class="error"
                v-if="!$v.prefecture_id.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
            <div class="col-md-6">
              <label
                >{{ str_questura_appartenenza }}
                <span class="text-danger">*</span></label
              >

              <b-form-select
                :state="holder_preccinte_state"
                class="border-blue"
                v-model="$v.preccint_id.$model"
                :options="questure"
                v-on:change="changeStateHolderPreccint"
              >
                <option value="">&nbsp;</option>
              </b-form-select>
              <div
                class="error"
                v-if="!$v.prefecture_id.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
          <!--  -->
        </div>

        <div class="p-2 bg-warning" v-if="latitude == 0 || longitude == 0">
          {{ str_no_coordinate }}
        </div>

        <div class="row mt-3">
          <div class="col-md-6 text-left">
            <button
              class="btn btn-secondary btn-yellow"
              v-on:click="tornaAPasso1"
            >
              {{ str_indietro }}
            </button>
          </div>
          <div class="col-md-6 text-right">
            <button
              class="btn btn-secondary"
              v-if="!verificaCompletiPasso2"
              disabled
            >
              {{ str_avanti }}
            </button>
            <button
              class="btn btn-secondary btn-yellow"
              v-on:click="vaiAPasso3()"
              v-if="verificaCompletiPasso2"
            >
              {{ str_avanti }}
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <span class="text-danger">*</span> {{ str_campi_obbligatori }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
input[type="file"] {
  color: #001f5c;
}
.form-check-label {
  font-size: 1rem;
}
button.btn-yellow {
  background-color: #ffa91f;
  color: #fff;
}

.dirty {
  border-color: #5a5;
  background: #efe;
}

.dirty:focus {
  outline-color: #8e8;
}

.error {
  border: 1px solid red;
  background: #fdd;
  padding: 5px;
}

.error:focus {
  outline-color: #f99;
}
</style>
<script>
import moment from "moment";
import axios from "axios";

import {
  required,
  email,
  sameAs,
  requiredIf,
  helpers,
  minLength,
  numeric,
} from "vuelidate/lib/validators";

const validFixedPhone = helpers.regex("validFixedPhone", /^[\+]?\d{5,16}$/);

import {
  BootstrapVue,
  IconsPlugin,
  BFormInput,
  BFormCheckbox,
  BFormFile,
  BFormDatepicker,
  BFormSelect,
} from "bootstrap-vue";
import PasswordAnalyzer from "../../includes/PasswordAnalyzer.vue";

import VueGoogleAutocomplete from "vue-google-autocomplete";

const default_layout = "default";

export default {
  props: {
    prefixes_param: Array,
    paesi_param: Array,
    dataprovince_param: Array,
    years_param: Array,
    prefetture_param: Array,
    questure_param: Array,
    show_google_place_param: Boolean,
    show_google_place_holder_param: Boolean,
    mostra_comunicazione_prosecuzione_attivita_param: Boolean,

    ragione_sociale_param: String,
    partita_iva_param: String,
    pec_param: String,
    prefix_tel_param: Number,
    telefono_param: String,
    indirizzo_sede_principale_param: String,
    cap_param: String,
    country_id_param: Number,
    provincia_sigla_param: String,
    provincia_estera_param: String,
    comune_param: String,
    numero_licenza_param: String,
    anno_apertura_param: [String, Number],
    visura_camerale_url_param: [String, File],
    data_emissione_licenza_prefettizia_param: String,
    data_scadenza_licenza_prefettizia_param: String,
    licenza_prefettizia_url_param: [String, File],
    comunicazione_prosecuzione_attivita_param: [String, File],
    holder_birthplace_param: String,
    holder_date_of_birth_param: String,
    holder_address_param: String,
    holder_city_param: String,
    holder_zipcode_param: String,
    holder_country_id_param: Number,
    holder_county_param: String,
    holder_foreign_county_param: String,
    prefecture_id_param: [String, Number],
    preccint_id_param: [String, Number],
    latitude_param: Number,
    longitude_param: Number,
    locale_param: String,
  },
  components: {
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    BFormSelect,
    VueGoogleAutocomplete,
  },
  computed: {
    getInitialDate: function() {
      return moment()
        .subtract(18, "years")
        .toDate();
    },

    getStrFormatoPI: function() {
      if (this.country_id == 144) {
        return true;
      } else {
        return false;
      }
    },

    getStrFormatoCAP: function() {
      if (this.country_id == 144) {
        return true;
      } else {
        return false;
      }
    },

    getStrFormatoHolderZipcode: function() {
      if (this.holder_country_id == 144) {
        return true;
      } else {
        return false;
      }
    },

    verificaCompletiPasso2: function() {
      if (
        this.ragione_sociale != "" &&
        this.validPartitaIva() != false &&
        this.validPec() != "" &&
        this.validTelefono() != false &&
        this.indirizzo_sede_principale != "" &&
        this.validCap() != false &&
        this.comune != "" &&
        this.country_id != "" &&
        this.country_id == 144 &&
        this.provincia_sigla != "" &&
        this.numero_licenza != "" &&
        this.anno_apertura != "" &&
        this.visura_camerale_url != "" &&
        this.data_emissione_licenza_prefettizia != "" &&
        this.data_scadenza_licenza_prefettizia != "" &&
        this.licenza_prefettizia_url != "" &&
        this.holder_birthplace != "" &&
        this.holder_date_of_birth != "" &&
        this.holder_address != "" &&
        this.holder_city != "" &&
        this.validCapTitolare() != false &&
        this.holder_country_id == 144 &&
        this.holder_county != "" &&
        this.prefecture_id != "" &&
        this.preccint_id != ""
      ) {
        return true;
      } else {
        return true;
      }
    },

    getStartCalendarReleaseLP: function() {
      return moment().format('YYYY-MM-DD');
    },

    getStartCalendar: function() {
      return moment().subtract(18, "years").format('YYYY-MM-DD');
    }

  },
  data() {
    return {
      /* Props */
      passo1: false,
      bloccato: this.bloccato_param,
      passo2: true,
      passo3: false,
      prefixes: this.prefixes_param,
      paesi: this.paesi_param,
      dataprovince: this.dataprovince_param,
      years: this.years_param,
      prefetture: this.prefetture_param,
      questure: this.questure_param,
      show_google_place: this.show_google_place_param,
      show_google_place_holder: this.show_google_place_holder_param,

      /* Stringhe */
      str_anagrafica_agenzia: translate("fe_vue_registrazione_agenzia.Anagrafica agenzia"),
      str_ragione_sociale: translate("fe_vue_registrazione_agenzia.Ragione sociale"),
      str_campo_obbligatorio: translate("fe_vue_registrazione_agenzia.Campo obbligatorio"),
      str_partita_iva: translate("fe_vue_registrazione_agenzia.Partita IVA"),
      str_11_numeri: translate("fe_vue_registrazione_agenzia.Composta da 11 numeri"),
      str_solo_numeri: translate("fe_vue_registrazione_agenzia.Solo numeri"),
      str_pec: translate("fe_vue_registrazione_agenzia.PEC"),
      str_email_non_valida: translate("fe_vue_registrazione_agenzia.Email non valida"),
      str_paese: translate("fe_vue_registrazione_agenzia.Paese"),
      str_telefono: translate("fe_vue_registrazione_agenzia.Numero di telefono"),
      str_senza_spazi: translate("fe_vue_registrazione_agenzia.Senza spazi"),
      str_formato_telefono_non_valido: translate(
        "fe_vue_registrazione_agenzia.telefono_non_valido_esteso"
      ),
      str_indirizzo: translate("fe_vue_registrazione_agenzia.Indirizzo"),
      str_cerca_google_maps: translate("fe_vue_registrazione_agenzia.cerca su Google Maps"),
      str_reset_address: translate("fe_vue_registrazione_agenzia.Reimposta indirizzo"),
      str_cap: translate("fe_vue_registrazione_agenzia.CAP"),
      str_5_numeri: translate("fe_vue_registrazione_agenzia.Composto da 5 numeri"),
      str_comune: translate("fe_vue_registrazione_agenzia.Comune"),
      str_provincia: translate("fe_vue_registrazione_agenzia.Provincia"),
      str_numero_licenza: translate("fe_vue_registrazione_agenzia.Numero licenza"),
      str_anno_apertura: translate("fe_vue_registrazione_agenzia.Anno di apertura"),
      str_ultima_visura: translate(
        "fe_vue_registrazione_agenzia.Ultima visura camerale (Pdf, jpg, jpeg, png)"
      ),
      str_data_emissione_licenza_prefettizia: translate(
        "fe_vue_registrazione_agenzia.Data emissione licenza prefettizia"
      ),
      str_data_scadenza_licenza_prefettizia: translate(
        "fe_vue_registrazione_agenzia.Data scadenza licenza prefettizia"
      ),
      str_licenza_prefettizia_url: translate(
        "fe_vue_registrazione_agenzia.Licenza prefettizia (Pdf, jpg, jpeg, png)"
      ),
      str_comunicazione_prosecuzione_attivita: translate(
        "fe_vue_registrazione_agenzia.Comunicazione prosecuzione attività (Pdf, jpg, jpeg, png)"
      ),
      str_364_gg: translate(
        "fe_vue_registrazione_agenzia.Documento obbligatorio in caso di licenza prefettizia scaduta da più di 364 giorni"
      ),
      str_dettagli_titolare: translate("fe_vue_registrazione_agenzia.Dettagli titolare agenzia"),
      str_comune_nascita: translate("fe_vue_registrazione_agenzia.Comune di nascita"),
      str_data_nascita: translate("fe_vue_registrazione_agenzia.Data di nascita"),
      str_indirizzo_titolare: translate("fe_vue_registrazione_agenzia.Indirizzo titolare"),
      str_comune_titolare: translate("fe_vue_registrazione_agenzia.Comune titolare"),
      str_cap_titolare: translate("fe_vue_registrazione_agenzia.CAP titolare"),
      str_provincia_titolare: translate("fe_vue_registrazione_agenzia.Provincia titolare"),
      str_prefettura_questura: translate("fe_vue_registrazione_agenzia.Prefettura e questura"),
      str_prefettura_appartenenza: translate("fe_vue_registrazione_agenzia.Prefettura di appartenenza"),
      str_questura_appartenenza: translate("fe_vue_registrazione_agenzia.Questura di appartenenza"),
      str_no_coordinate: translate("fe_vue_registrazione_agenzia.str_no_coordinate"),
      str_lunghezza_partita_iva: translate("fe_vue_registrazione_agenzia.LUNGHEZZA_PARTITA_IVA"),
      str_avanti: translate("fe_vue_registrazione_agenzia.Avanti"),
      str_indietro: translate("fe_vue_registrazione_agenzia.Indietro"),
      str_campi_obbligatori: translate("fe_vue_registrazione_agenzia.Campi obbligatori"),
      str_data_emissione_maggiore_scadenza: translate(
        "fe_vue_registrazione_agenzia.La data di emissione deve essere minore della data di scadenza"
      ),
      str_data_emissione_formato: translate("fe_vue_registrazione_agenzia.Non può essere nel futuro"),
      str_data_nascita_formato: translate("fe_vue_registrazione_agenzia.Minimo 18 anni"),

      /* Models */
      ragione_sociale: this.ragione_sociale_param,
      partita_iva: this.partita_iva_param,
      pec: this.pec_param,
      prefix_tel: this.prefix_tel_param,
      telefono: this.telefono_param,
      indirizzo_sede_principale: this.indirizzo_sede_principale_param,
      cap: this.cap_param,
      country_id: this.country_id_param,
      provincia_sigla: this.provincia_sigla_param,
      provincia_estera: this.provincia_estera_param,
      numero_licenza: this.numero_licenza_param,
      anno_apertura: this.anno_apertura_param,
      visura_camerale_url: this.visura_camerale_url_param,
      data_emissione_licenza_prefettizia: this
        .data_emissione_licenza_prefettizia_param,
      data_scadenza_licenza_prefettizia: this
        .data_scadenza_licenza_prefettizia_param,
      licenza_prefettizia_url: this.licenza_prefettizia_url_param,
      comunicazione_prosecuzione_attivita: this
        .comunicazione_prosecuzione_attivita_param,
      holder_birthplace: this.holder_birthplace_param,
      holder_date_of_birth: this.holder_date_of_birth_param,
      holder_address: this.holder_address_param,
      holder_city: this.holder_city_param,
      holder_zipcode: this.holder_zipcode_param,
      holder_country_id: this.holder_country_id_param,
      holder_county: this.holder_county_param,
      holder_foreign_county: this.holder_foreign_county_param,
      prefecture_id: this.prefecture_id_param,
      preccint_id: this.preccint_id_param,
      latitude: this.latitude_param,
      longitude: this.longitude_param,
      comune: this.comune_param,

      /* Errori */
      errore_lunghezza_pi: false,
      errore_non_numerica_pi: false,
      errore_cap_non_numerico: false,
      errore_cap_lunghezza: false,
      errore_data_emissione_maggiore_scadenza: false,
      errore_licenza_prefettizia_url: false,
      errore_comunicazione_prosecuzione_attivita: false,
      errore_holder_zipcode_lunghezza: false,
      errore_holder_zipcode_non_numerico: false,
      errore_visura_camerale_url: false,
      errore_data_emissione_formato: false,
      errore_data_nascita_formato: false,

      /* States */
      ragione_sociale_state: null,
      partita_iva_state: null,
      pec_state: null,
      telefono_state: null,
      indirizzo_sede_principale_state: null,
      cap_state: null,
      comune_state: null,
      country_state: null,
      provincia_sigla_state: null,
      numero_licenza_state: null,
      anno_apertura_state: null,
      data_emissione_licenza_prefettizia_state: null,
      data_scadenza_licenza_prefettizia_state: null,
      holder_birthplace_state: null,
      data_nascita_state: null,
      holder_address_state: null,
      holder_city_state: null,
      holder_zipcode_state: null,
      holder_county_state: null,
      holder_prefecture_state: null,
      holder_preccinte_state: null,

      /* Altro */
     
      mostra_comunicazione_prosecuzione_attivita: this.mostra_comunicazione_prosecuzione_attivita_param,
      mostra_visura_camerale_url: true,
      mostra_licenza_prefettizia_url: true,
      mostra_comunicazione_prosecuzione_attivita_url: true,
      locale: this.locale_param,
    };
  },

  methods: {
    getAddressData: function(addr, place) {
      let county;
      let nation;
      /*
      place.address_components.forEach((element) => {
        element.types.forEach((type) => {
            console.log(element)
          if (type == "administrative_area_level_1") {
            county = element.short_name;
          }
          if (type == "country") {
            this.paesi.forEach((paese) => {
              if (paese.code.toUpperCase() == element.short_name) {
                nation = paese.value;
              }
            });
          }
        });
      });
      */
      const comp = this.getAddressObject(place.address_components)

      const street_number = comp.home ? comp.home : "";
      const route = comp.street ? comp.street : "";
      const postal_code = comp.postal_code ? comp.postal_code : "";
      const locality = comp.city ? comp.city : "";

      county = comp.provincia;   

      nation = comp.country;

      if (nation === "IE") { // Ireland
                  const counties = this.getIrishCounties();
                  for (const [key, value] of Object.entries(counties)) {

                      if (comp.region === "County " + value.label) {
                          county = value.value;
                      }
                  }
              }

        this.paesi.forEach((paese) => {
            if (paese.code.toUpperCase() === comp.country) {
                nation = paese.value;
            }
        });

      this.country_id = nation;
      /*
      if (this.country_id == 144) {
        this.provincia_sigla = county;
      } else {

        if (county == 'Charleston County') {

          this.provincia_sigla = "SC";
        } else {
          console.log("altro estero")
          this.provincia_sigla = "EE";
        }
      }
      */
      //const route = addr.route ? addr.route : "";
      //const street_number = addr.street_number ? addr.street_number : "";
      //const postal_code = addr.postal_code ? addr.postal_code : "";
      //const locality = addr.locality ? addr.locality : "";
      const lat = addr.latitude ? addr.latitude : "";
      const lon = addr.longitude ? addr.longitude : "";

      this.indirizzo_sede_principale = route + ", " + street_number;
      this.cap = postal_code;
      this.comune = locality;
      this.latitude = lat;
      this.longitude = lon;
      this.provincia_sigla = county;
    



      this.changeStatePartitaIva();
      this.changeStateCap();

      this.show_google_place = false;
    },

    resetAddress: function() {
      this.indirizzo_sede_principale = "";
      this.cap = "";
      this.comune = "";
      this.latitude = "";
      this.longitude = "";

      this.show_google_place = true;
    },

    getHolderAddressData: function(addr, place) {
      let county;
      let nation;
      /*
      place.address_components.forEach((element) => {
        element.types.forEach((type) => {
          if (type == "administrative_area_level_1") {
            county = element.short_name;
          }
          if (type == "country") {
            this.paesi.forEach((paese) => {              
              if (paese.code.toUpperCase() == element.short_name) {
                nation = paese.value;
              }
            });
          }
        });
      });
      */


        const comp = this.getAddressObject(place.address_components)

        const street_number = comp.home ? comp.home : "";
        const route = comp.street ? comp.street : "";
        const postal_code = comp.postal_code ? comp.postal_code : "";
        const locality = comp.city ? comp.city : "";

        county = comp.provincia;


        nation = comp.country;

        if (nation === "IE") { // Ireland
                  const counties = this.getIrishCounties();
                  for (const [key, value] of Object.entries(counties)) {

                      if (comp.region === "County " + value.label) {
                          county = value.value;
                      }
                  }
              }

        this.paesi.forEach((paese) => {
            if (paese.code.toUpperCase() === comp.country) {
                nation = paese.value;
            }
        });






      this.holder_country_id = nation;
      this.holder_county = county;
      /*
      if (this.holder_country_id == 144) {
        this.holder_county = county;
      } else {
        if (county == 'Charleston County') {
          this.holder_county = "SC";
        } else {
          this.holder_county = "EE";
        }
        
      }
      */
      //const route = addr.route ? addr.route : "";
      //const street_number = addr.street_number ? addr.street_number : "";
      //const postal_code = addr.postal_code ? addr.postal_code : "";
      //const locality = addr.locality ? addr.locality : "";

      this.holder_address = route + ", " + street_number;
      this.holder_zipcode = postal_code;
      this.holder_city = locality;

      this.changeStateHolderZipcode();

      this.show_google_place_holder = false;
    },

    resetHolderAddress: function() {
      this.holder_county = "";
      this.holder_country_id = 144;

      this.holder_address = "";
      this.holder_zipcode = "";
      this.holder_city = "";

      this.show_google_place_holder = true;
    },

    getMaxDateEmissioneLP: function(ymd, date) {
      return date > moment().toDate();
    },

    checkDataEmissioneLPMinoreScadenza: function() {
      let ts_scadenza = moment(this.data_scadenza_licenza_prefettizia).format(
        "x"
      );
      let ts_emissione = moment(this.data_emissione_licenza_prefettizia).format(
        "x"
      );
      if (ts_scadenza < ts_emissione) {
        console.log("quiu", ts_scadenza, ts_emissione);
        this.errore_data_emissione_maggiore_scadenza = true;
        return true;
      } else {
        this.errore_data_emissione_maggiore_scadenza = false;
        return false;
      }
    },

    checkPILenght: function() {
      if (this.country_id == 144) {
        if (this.partita_iva.length != 11) {
          this.errore_lunghezza_pi = true;
          return true;
        }
      }
    },

    checkPINumeric: function() {
        return;
      if (this.country_id == 144) {
        const partita_iva = this.partita_iva;
        for (let i = 0; i < partita_iva.length; i++) {
          if (isNaN(partita_iva[i])) {
            this.errore_non_numerica_pi = true;
            return true;
          }
        }
      }
    },

    checkCAPLength: function() {
        return;
      if (this.country_id == 144) {
        if (this.cap.length != 5) {
          this.errore_cap_lunghezza = true;
          return true;
        }
      }
    },

    checkCAPNumeric: function() {
        return;
      if (this.country_id == 144) {
        const cap = this.cap;
        for (let i = 0; i < cap.length; i++) {
          if (isNaN(cap[i])) {
            this.errore_cap_non_numerico = true;
            return true;
          }
        }
      }
    },

    checkHolderZipcodeLength: function() {
        return;
      if (this.holder_country_id == 144) {
        if (this.holder_zipcode.length != 5) {
          this.errore_holder_zipcode_lunghezza = true;
          return true;
        } else {
          this.errore_holder_zipcode_lunghezza = false;
        }
      }
    },

    checkHolderZipcodeNumeric: function() {
        return;
      if (this.holder_country_id == 144) {
        const cap = this.holder_zipcode;
        for (let i = 0; i < cap.length; i++) {
          if (isNaN(cap[i])) {
            this.errore_holder_zipcode_non_numerico = true;
            return true;
          }
        }
      } else {
        this.errore_holder_zipcode_non_numerico = false;
      }
    },

    validPartitaIva: function() {
        return;
      let partita_iva = this.partita_iva;
      if (partita_iva.length != 11) {
        return false;
      }
      for (let i = 0; i < partita_iva.length; i++) {
        if (isNaN(partita_iva[i])) {
          return false;
        }
      }
      return true;
    },

    validCap: function() {
      /*let cap = this.cap;
      if (cap.length < 5) {
        return false;
      }
      for (let i = 0; i < cap.length; i++) {
        if (isNaN(cap[i])) {
          return false;
        }
      }*/
      return true;
    },

    validCapTitolare: function() {
      /*let cap = this.holder_zipcode;
      if (cap.length < 5) {
        return false;
      }
      for (let i = 0; i < cap.length; i++) {
        if (isNaN(cap[i])) {
          return false;
        }
      }*/
      return true;
    },

    validTelefono: function() {
      let telefono = this.telefono;
      for (let i = 0; i < telefono.length; i++) {
        if (isNaN(telefono[i]) && telefono[i] != "+") {
          return false;
        }
      }
      return true;
    },

    validPec: function() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(this.pec)) {
        return false;
      }
      return true;
    },

    verificaScadenzaLicenzaPrefettizia() {
      let data_scelta = moment(this.data_scadenza_licenza_prefettizia).format(
        "x"
      );

      let data_attuale = moment().format("x");

      if (data_scelta < data_attuale - 364 * 86400000) {
        this.mostra_comunicazione_prosecuzione_attivita = true;
      } else {
        this.mostra_comunicazione_prosecuzione_attivita = false;
      }
    },

    getMaxDate: function(ymd, date) {
      return (
        date >
        moment()
          .subtract(18, "years")
          .toDate()
      );
    },

    handleFileUploadVC() {
      this.visura_camerale_url = this.$refs.visura_camerale_url.files[0];
      this.errore_visura_camerale_url = false;
      this.mostra_visura_camerale_url = false;
    },

    handleFileUploadLP() {
      this.licenza_prefettizia_url = this.$refs.licenza_prefettizia_url.files[0];
      this.errore_licenza_prefettizia_url = false;
      this.mostra_licenza_prefettizia_url = false;
    },

    handleFileUploadCPA() {
      this.comunicazione_prosecuzione_attivita = this.$refs.comunicazione_prosecuzione_attivita.files[0];
      this.errore_comunicazione_prosecuzione_attivita = false;
      this.mostra_comunicazione_prosecuzione_attivita_url = false;
    },

    validDataEmissioneLP: function() {
      const date_release = moment(this.data_emissione_licenza_prefettizia).toDate()
      const now_obj = moment().toDate();
      if (date_release > now_obj) {
        this.errore_data_emissione_formato = true;
        return false;
      } else {        
        this.errore_data_emissione_formato = false;
        return true;
      }
      
      
    },

    validDataNascita: function() {
      const date_of_birth = moment(this.holder_date_of_birth).toDate()
      const eighteen_years_ago = moment().subtract(18, "years").toDate()
      if (date_of_birth > eighteen_years_ago) {
        this.errore_data_nascita_formato = true;
        return false;
      } else {
        this.errore_data_nascita_formato = false;
        return true;
      }
    },

    tornaAPasso1: function() {     
      this.$emit('tornaapasso1');
    },

    vaiAPasso3: function() {

      if (this.show_google_place && this.latitude == 0 && this.longitude == 0) {        
        this.$refs['ac_ind_sede'].clear();
        this.$refs['ac_ind_sede'].focus();
        return;
      }
      if (this.show_google_place_holder && this.holder_address == '') {

        this.$refs['ac_ind_titolare'].clear();
        this.$refs['ac_ind_titolare'].focus();
      }

      if (!this.validDataEmissioneLP() || !this.validDataNascita()) {
        return false;
      }


      const PILenght = this.checkPILenght();
      const PINumeric = this.checkPINumeric();
      const CAPLength = this.checkCAPLength();
      const CAPNumeric = this.checkCAPNumeric();
      const HolderZipcodeLength = this.checkHolderZipcodeLength();
      const HolderZipcodeNumeric = this.checkHolderZipcodeNumeric();
      const DataEmissioneLPMinoreScadenza = this.checkDataEmissioneLPMinoreScadenza();

      if (
        this.$v.$invalid ||
        this.visura_camerale_url == "" ||
        this.licenza_prefettizia_url == "" ||
        (this.mostra_comunicazione_prosecuzione_attivita == true &&
          this.comunicazione_prosecuzione_attivita == "")
      ) {
        this.bloccato = true;
        if (!this.visura_camerale_url) {
          this.errore_visura_camerale_url = true;
        }

        if (!this.licenza_prefettizia_url) {
          this.errore_licenza_prefettizia_url = true;
        }

        if (
          this.mostra_comunicazione_prosecuzione_attivita == true &&
          this.comunicazione_prosecuzione_attivita == ""
        ) {
          this.errore_comunicazione_prosecuzione_attivita = true;
        }
      } //else if (this.checkPILenght() || this.checkPINumeric() || this.checkCAPLength() || this.checkCAPNumeric()) {
      else if (
        (PILenght || PINumeric || CAPLength || CAPNumeric,
        HolderZipcodeLength ||
          HolderZipcodeNumeric ||
          DataEmissioneLPMinoreScadenza)
      ) {
        this.bloccato = true;
      } else {
        this.bloccato = false;
      }

      
      if (this.bloccato == false) {
        this.passo2 = false;
        this.passo3 = true;

        this.$emit(
          "vaiapasso3",
          this.passo1,
          this.passo2,
          this.passo3,
          this.ragione_sociale,
          this.partita_iva,
          this.pec,
          this.prefix_tel,
          this.telefono,
          this.indirizzo_sede_principale,
          this.cap,
          this.country_id,
          this.provincia_sigla,
          this.provincia_estera,
          this.numero_licenza,
          this.anno_apertura,
          this.visura_camerale_url,
          this.data_emissione_licenza_prefettizia,
          this.data_scadenza_licenza_prefettizia,
          this.licenza_prefettizia_url,
          this.comunicazione_prosecuzione_attivita,
          this.holder_birthplace,
          this.holder_date_of_birth,
          this.holder_address,
          this.holder_city,
          this.holder_zipcode,
          this.holder_country_id,
          this.holder_county,
          this.holder_foreign_county,
          this.prefecture_id,
          this.preccint_id,
          this.latitude,
          this.longitude,
          this.comune,
          this.mostra_comunicazione_prosecuzione_attivita
        );
      }
    },


      getAddressObject: function (address_components) {
          var ShouldBeComponent = {
              home: ["street_number"],
              postal_code: ["postal_code"],
              street: ["street_address", "route"],
              region: [
                  "administrative_area_level_1",
                  "administrative_area_level_2",
                  "administrative_area_level_3",
                  "administrative_area_level_4",
                  "administrative_area_level_5"
              ],
              city: [
                  "locality",
                  "sublocality",
                  "sublocality_level_1",
                  "sublocality_level_2",
                  "sublocality_level_3",
                  "sublocality_level_4",
                  "postal_town"
              ],
              provincia: ["administrative_area_level_1", "administrative_area_level_2"],
              country: ["country"]
          };

          var address = {
              home: "",
              postal_code: "",
              street: "",
              region: "",
              city: "",
              provincia: "",
              country: ""
          };
          address_components.forEach(component => {
              for (var shouldBe in ShouldBeComponent) {

                  if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {

                      if (shouldBe === "country" || shouldBe === "provincia") {
                          address[shouldBe] = component.short_name;
                      } else {
                          address[shouldBe] = component.long_name;
                      }
                  }
              }



          });
          return address;
      },

      getIrishCounties: function () {
          return [
              {"label": "Dublin", "value": "D"},
              {"label": "Galway", "value": "G"},
              {"label": "Leitrim", "value": "LM"},
              {"label": "Mayo", "value": "MO"},
              {"label": "Roscommon", "value": "RN"},
              {"label": "Sligo", "value": "SO"},
              {"label": "Antrim", "value": "BT41"},
              {"label": "Armagh", "value": "BT60"},
              {"label": "Cavan", "value": "CN"},
              {"label": "Donegal", "value": "DL"},
              {"label": "Down", "value": "BT30"},
              {"label": "Fermanagh", "value": "FR"},
              {"label": "Londonderry", "value": "BT47"},
              {"label": "Monaghan", "value": "MN"},
              {"label": "Tyrone", "value": "TY"},
              {"label": "Clare", "value": "CE"},
              {"label": "Cork", "value": "C"},
              {"label": "Kerry", "value": "KY"},
              {"label": "Limerick", "value": "L"},
              {"label": "Tipperary", "value": "T"},
              {"label": "Waterford", "value": "W"},
              {"label": "Carlow", "value": "CW"},
              {"label": "Dublino", "value": "D"},
              {"label": "Kildare", "value": "KE"},
              {"label": "Kilkenny", "value": "KK"},
              {"label": "Laois", "value": "LS"},
              {"label": "Longford", "value": "LD"},
              {"label": "Louth", "value": "LH"},
              {"label": "Meath", "value": "MH"},
              {"label": "Offaly", "value": "OY"},
              {"label": "Westmeath", "value": "WH"},
              {"label": "Wexford", "value": "WW"},
              {"label": "Wicklow", "value": "WX"}
          ]
      },
    

    // Change states
    changeStateRagioneSociale: function() {
      if (this.ragione_sociale == "") {
        this.ragione_sociale_state = false;
      } else {
        this.ragione_sociale_state = true;
      }
    },

    changeStatePartitaIva: function() {

        this.errore_lunghezza_pi = false;
        this.partita_iva_state = true;
        this.errore_non_numerica_pi = false;

        return;


      if (this.country_id == 144) {
        var reg = /^\d+$/;
        if (this.partita_iva.length == 11) {
          this.errore_lunghezza_pi = false;
        }
        if (reg.test(this.partita_iva)) {
          this.errore_non_numerica_pi = false;
        }

        if (this.partita_iva.length != 11 || !reg.test(this.partita_iva)) {
          this.partita_iva_state = false;
        } else {
          this.partita_iva_state = true;
        }
      } else {
        this.errore_lunghezza_pi = false;
        this.partita_iva_state = true;
        this.errore_non_numerica_pi = false;
      }
    },

    changeStatePec: function() {
      if (this.pec == "") {
        this.pec_state = true;
      } else {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regex.test(this.pec)) {
          this.pec_state = false;
        } else {
          this.pec_state = true;
        }
      }
    },

    changeStateTelefono: function() {
      let telefono = this.telefono;
      const reg = /^[\+]?\d{5,16}$/;
      if (!reg.test(telefono)) {
        this.telefono_state = false;
      } else {
        this.telefono_state = true;
      }
    },

    changeStateCap: function() {
        this.errore_cap_lunghezza = false;
        this.errore_cap_non_numerico = false;
        this.cap_state = true;
        return;


      const cap = this.cap;
      if (this.country_id != 144) {

        if (this.cap.length == 0) {
          this.cap_state = false;
        } else {
          this.errore_cap_lunghezza = false;
          this.errore_cap_non_numerico = false;
          this.cap_state = true;
        }


      } else {
        if (this.cap.length != 5) {
          this.cap_state = false;
        } else {
          this.errore_cap_lunghezza = false;

          for (let i = 0; i < cap.length; i++) {
            if (isNaN(cap[i])) {
              this.errore_cap_non_numerico = true;
              return true;
            }
          }
          this.errore_cap_non_numerico = false;
          this.cap_state = true;
        }
      }
    },

    changeStateComune: function() {
      if (this.comune == "") {
        this.comune_state = false;
      } else {
        this.comune_state = true;
      }
    },

    changeStateIndirizzoSedePrincipale: function() {
      if (this.indirizzo_sede_principale == "") {
        this.indirizzo_sede_principale_state = false;
      } else {
        this.indirizzo_sede_principale_state = true;
      }
    },

    changeStateCountry: function() {
      if (this.country_id == "") {
        this.country_state = false;
      } else {
        this.country_state = true;
        this.changeStatePartitaIva();
      }
    },

    changeStateProvinciaSigla: function() {
      if (this.provincia_sigla == "") {
        this.provincia_sigla_state = false;
      } else {
        this.provincia_sigla_state = true;
      }
    },

    changeStateNumeroLicenza: function() {
      if (this.numero_licenza == "") {
        this.numero_licenza_state = false;
      } else {
        this.numero_licenza_state = true;
      }
    },

    changeStateAnnoApertura: function() {
      if (this.anno_apertura == "") {
        this.anno_apertura_state = false;
      } else {
        this.anno_apertura_state = true;
      }
    },

    changeStateHolderBirthplace: function() {
      if (this.holder_birthplace == "") {
        this.holder_birthplace_state = false;
      } else {
        this.holder_birthplace_state = true;
      }
    },

    changeStateHolderAddress: function() {
      if (this.holder_address == "") {
        this.holder_address_state = false;
      } else {
        this.holder_address_state = true;
      }
    },

    changeStateHolderCity: function() {
      if (this.holder_city == "") {
        this.holder_city_state = false;
      } else {
        this.holder_city_state = true;
      }
    },

    changeStateHolderZipcode: function() {
        this.errore_holder_zipcode_lunghezza = false;
        this.errore_holder_zipcode_non_numerico = false;
        this.holder_zipcode_state = true;
        return;

      if (this.holder_country_id != 144) {
        if (this.holder_zipcode.length == 0) {
          this.errore_holder_zipcode_lunghezza = true;
          this.holder_zipcode_state = false;
        } else {
          this.errore_holder_zipcode_lunghezza = false;
          this.errore_holder_zipcode_non_numerico = false;
          this.holder_zipcode_state = true;
        }

      } else {
        if (this.holder_zipcode.length != 5) {
          this.errore_holder_zipcode_lunghezza = true;
          this.holder_zipcode_state = false;
        } else {
          const cap = this.holder_zipcode;
          for (let i = 0; i < cap.length; i++) {
            if (isNaN(cap[i])) {
              console.log(
                "E' stato in intercettato un valore non numerico nel cap"
              );
              this.errore_holder_zipcode_non_numerico = true;
              return true;
            }
          }
          this.errore_holder_zipcode_lunghezza = false;
          this.errore_holder_zipcode_non_numerico = false;
          this.holder_zipcode_state = true;
        }
      }
    },

    changeStateHolderCounty: function() {
      if (this.holder_county == "") {
        this.holder_county_state = false;
      } else {
        this.holder_county_state = true;
      }
    },

    changeStateHolderPrefecture: function() {
      if (this.prefecture_id == "") {
        this.holder_prefecture_state = false;
      } else {
        this.holder_prefecture_state = true;
      }
    },

    changeStateHolderPreccint: function() {
      if (this.preccint_id == "") {
        this.holder_preccinte_state = false;
      } else {
        this.holder_preccinte_state = true;
      }
    },

    changeStateDataEmissioneLP: function() {
      const date_release = moment(this.data_emissione_licenza_prefettizia).toDate()
      const now_obj = moment().toDate();

      if (this.data_emissione_licenza_prefettizia == "") {
        this.data_emissione_licenza_prefettizia_state = false;
      } else if (date_release > now_obj) {
        this.data_emissione_licenza_prefettizia_state = false;
      } else {
        this.errore_data_emissione_formato = false;
        this.data_emissione_licenza_prefettizia_state = true;
      }
    },

    changeStateDataScadenzaLP: function() {
      if (this.data_scadenza_licenza_prefettizia == "") {
        this.data_scadenza_licenza_prefettizia_state = false;
      } else {
        this.data_scadenza_licenza_prefettizia_state = true;
        this.verificaScadenzaLicenzaPrefettizia();
      }
    },

    changeStateDataNascita: function() {
      const date_of_birth = moment(this.holder_date_of_birth).toDate()
      const eighteen_years_ago = moment().subtract(18, "years").toDate()

      
      if (this.holder_date_of_birth == "") {
        this.data_nascita_state = false;
      } else if (date_of_birth > eighteen_years_ago) {
        this.data_nascita_state = false;
      } else {
        this.errore_data_nascita_formato = false;
        this.data_nascita_state = true;
      }
    },

    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },

  validations: {
    ragione_sociale: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    pec: {
      /*required: requiredIf(function() {
        return this.passo2;
      }),*/

      email,
    },

    indirizzo_sede_principale: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    comune: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    provincia_sigla: {
      required: requiredIf(function() {
        return this.country_id == 144 && this.passo2;
      }),
    },

    anno_apertura: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    data_emissione_licenza_prefettizia: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    partita_iva: {
      //numeric: numeric,
      //minLength: minLength(11),

      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    telefono: {
      required: requiredIf(function() {
        return this.passo2;
      }),
      validFixedPhone,
    },

    cap: {
      //numeric: numeric,
      //minLength: minLength(5),
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    country_id: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    holder_country_id: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    numero_licenza: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    data_scadenza_licenza_prefettizia: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    holder_birthplace: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    holder_date_of_birth: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    holder_address: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    holder_city: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    holder_zipcode: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    holder_county: {
      required: requiredIf(function() {
        return this.holder_country_id == 144 && this.passo2;
      }),
    },

    prefecture_id: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },

    preccint_id: {
      required: requiredIf(function() {
        return this.passo2;
      }),
    },
  },
};

class Errors {
  constructor() {
    this.errors = {};
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  record(errors) {
    this.errors = errors;
  }
}
</script>
