<template>
  <div>
      
  </div>
</template>
<script>
import EventBus from "./EventBus";

const default_layout = "default";



export default {
  components: {
      EventBus
  },  
  mounted() {      
      EventBus.$emit("show_password_recover_modal", 1);
  },
  computed: {},
  data() {
      return {
         
      }
  }
};
</script>
