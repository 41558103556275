<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <label class="form__label"
              >{{ str_nome }} <span class="text-danger">*</span></label
            >
            <input
              type="text"
              v-model.trim="$v.name.$model"
              class="form-control"
              :class="status($v.name)"
              tabindex="1"
            />
            <div class="error" v-if="!$v.name.required && bloccato == true">
              {{ str_campo_obbligatorio }}
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                >{{ str_cognome }} <span class="text-danger">*</span></label
              >
              <input
                type="text"
                v-model="$v.last_name.$model"
                class="form-control"
                :class="status($v.last_name)"
                tabindex="2"
              />
              <div
                class="error"
                v-if="!$v.last_name.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                >{{ str_data_di_nascita }}
                <span class="text-danger">*</span></label
              >
              <div class="input-group">
                <datepicker
                  v-model="$v.data_di_nascita.$model"
                  :bootstrap-styling="true"
                  format="dd-MM-yyyy"
                  :language="it"
                  :disabledDates="disabledDates"
                  tabindex="5"
                  :class="status($v.data_di_nascita)"
                ></datepicker>

                <div class="input-group-append">
                  <div class="input-group-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-calendar3"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"
                      />
                      <path
                        d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div
                class="error"
                v-if="!$v.data_di_nascita.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>{{ str_email }} <span class="text-danger">*</span></label>
              <input
                type="email"
                v-model.trim="$v.email.$model"
                v-on:blur="verificaEsistenzaEmail"
                class="form-control"
                ref="email"
                :class="status($v.email)"
                tabindex="3"
                autocomplete="off"
              />
              <div class="error" v-if="!$v.email.required && bloccato == true">
                {{ str_campo_obbligatorio }}
              </div>
              <div class="error" v-if="!$v.email.email && bloccato == true">
                {{ str_email_non_valida }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2">
            <label>&nbsp;</label>
            <button class="btn btn-info" v-on:click="apriGeneratorePassword">
              {{ str_generatore_password }}
            </button>
          </div>

          <div class="col-md-5">
            <div class="form-group">
              <label
                >{{ str_password }} <span class="text-danger">*</span></label
              >
              <div class="input-group">
                <input
                  type="password"
                  ref="password"
                  v-model="$v.password.$model"
                  class="form-control"
                  tabindex="10"
                  :class="status($v.password)"
                  autocomplete="off"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text cursor-pointer"
                    v-on:click="mostraPassword('password')"
                  >
                    <svg
                      v-if="campo_mostra_password == 'password'"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-eye"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                      />
                      <path
                        d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                      />
                    </svg>

                    <svg
                      v-if="campo_mostra_password == 'text'"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                      />
                      <path
                        d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                      />
                      <path
                        d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                      />
                    </svg>
                  </span>
                </div>
              </div>

              <div
                class="error"
                v-if="!$v.password.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>

              <div
                class="error"
                v-if="!$v.password.minLength && bloccato == true"
              >
                {{ str_password_lunghezza }}
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label
                >{{ str_conferma_password }}
                <span class="text-danger">*</span></label
              >
              <div class="input-group">
                <input
                  type="password"
                  ref="conferma_password"
                  v-model="$v.conferma_password.$model"
                  class="form-control"
                  tabindex="11"
                  :class="status($v.conferma_password)"
                  autocomplete="off"
                />

                <div class="input-group-append">
                  <span
                    class="input-group-text cursor-pointer"
                    v-on:click="mostraPassword('password_confirmation')"
                  >
                    <svg
                      v-if="campo_mostra_password2 == 'password'"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-eye"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                      />
                      <path
                        d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                      />
                    </svg>

                    <svg
                      v-if="campo_mostra_password2 == 'text'"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                      />
                      <path
                        d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                      />
                      <path
                        d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                      />
                    </svg>
                  </span>
                </div>
              </div>

              <div
                class="error"
                v-if="!$v.conferma_password.sameAsPassword && bloccato == true"
              >
                {{ str_password_diversa }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>{{ str_professione_ruolo }}</label>
              <select class="form-control" v-model="tipo_cliente">
                <option value="0">{{ str_cliente_privato }}</option>
                <option value="1">{{ str_cliente_business }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" v-if="tipo_cliente == 1">
              <label>{{ str_tipo_cliente }}</label>
              <select class="form-control" v-model="$v.professione.$model" :class="status($v.professione)">
                <option value=""></option>
                <option :value="str_avvocato">{{ str_avvocato }}</option>
                <option :value="str_hr_manager">{{ str_hr_manager }}</option>
                <option :value="str_perito">{{ str_perito }}</option>
                <option :value="str_cfo">{{ str_cfo }}</option>
                <option :value="str_ceo">{{ str_ceo }}</option>
                <option :value="str_commercialista">{{
                  str_commercialista
                }}</option>
                <option :value="str_altro">{{ str_altro }}</option>
              </select>
              
               <div class="error" v-if="!$v.professione.required && bloccato == true">
                {{ str_campo_obbligatorio }}
              </div>  

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-check align-middle">
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                id="privacy"
                v-model="$v.privacy.$model"
                tabindex="8"
                :class="status($v.privacy)"
              />
              <label class="form-check-label" for="privacy">
                {{ str_accettazione_privacy }}
                <span class="text-danger">*</span>
                <div
                  class="error"
                  v-if="!$v.privacy.sameAs && bloccato == true"
                >
                  {{ str_campo_obbligatorio }}
                </div>
              </label>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-check align-middle">
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                id="comm"
                v-model="comunicazioni_commerciali"
                tabindex="9"
              />
              <label class="form-check-label" for="comm">
                {{ str_comunicazioni_commerciali }}
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 text-right">
            <button class="btn btn-primary" v-on:click="salvaCliente" v-if="verificaCompleti">
              {{ str_invia }}
            </button>

            <button class="btn btn-primary" v-if="!verificaCompleti" disabled>
              {{ str_invia }}
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <span class="text-danger">*</span> {{ str_campi_obbligatori }}
          </div>
        </div>
      </div>

      <div
        class="alert alert-danger"
        v-for="(err, indice) in errori_validazione.errors.errors"
        :key="indice"
      >
        {{ err[0] }}
      </div>
    </div>
    <generatore-password v-if="modale"></generatore-password>
  </div>
</template>
<style scoped>
.dirty {
  border-color: #5a5;
  background: #efe;
}

.dirty:focus {
  outline-color: #8e8;
}

.error {
  border:1px solid red;
  background: #fdd;
  padding: 5px;
  
}

.error:focus {
  outline-color: #f99;
}

input[type='file'] {
  color: #fff;  
}
</style>
<script>
import Vue from "vue";

import axios from "axios";
import Datepicker from "vuejs-datepicker";
import { en, it } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import {
  required,
  email,
  sameAs,
  requiredIf,
  helpers,
  minLength,
} from "vuelidate/lib/validators";

import GeneratorePassword from "./GeneratorePassword";
//import "./registrazione-cliente.css";

const default_layout = "default";

export default {
  components: {
    Datepicker,
    GeneratorePassword,
  },
  computed: {
    verificaCompleti: function() {
      if (this.name != null && 
        this.last_name != null && this.email != null && this.data_di_nascita != null
        && this.privacy != 0 && this.password != null && this.conferma_password != null && ((this.tipo_cliente == 1 && this.professione != '') || (this.tipo_cliente == 0))) {
          return true;
        } else {
          return false;
        }
    }

  },
  
  data() {
    return {
      str_campi_obbligatori: translate('vue.Campi obbligatori'),
      str_nome: translate("vue.Nome"),
      str_cognome: translate("vue.Cognome"),
      str_email: translate("vue.Email"),
      str_accettazione_privacy: translate("vue.Accettazione T&C / Privacy policy"),
      str_comunicazioni_commerciali: translate(
        "vue.Accettazione invio comunicazioni commerciali"
      ),
      str_password: translate("vue.Password"),
      str_conferma_password: translate("vue.Conferma Password"),
      str_data_di_nascita: translate("vue.Data di nascita"),
      str_generatore_password: translate("vue.Genera password"),
      str_tipo_cliente: translate("vue.Tipo cliente"),
      str_cliente_privato: translate("vue.Privato"),
      str_cliente_business: translate("vue.Business"),
      str_professione_ruolo: translate("vue.Professione") + ' / ' + translate("vue.Ruolo"),
      str_avvocato: translate("vue.Avvocato"),
      str_hr_manager: translate("vue.HR Manager"),
      str_perito: translate("vue.Perito"),
      str_cfo: translate("vue.CFO"),
      str_ceo: translate("vue.CEO"),
      str_commercialista: translate("vue.Commercialista"),
      str_altro: translate("vue.Altro"),
      str_invia: translate("vue.Invia"),
      str_campo_obbligatorio: translate("vue.Campo obbligatorio"),
      str_email_non_valida: translate("vue.Email non valida"),
      str_password_diversa: translate("vue.Le 2 password devono corrispondere"),
      str_password_lunghezza: translate(
        "vue.La password deve essere almeno di 8 caratteri"
      ),
      str_indirizzo_email_gia_registrato: translate("vue.str_indirizzo_gia_registrato"),

      bloccato: false,
      it: it,
      en: en,

      disabledDates: {
        /*from: this.getData18AnniFa()*/
        from: moment()
          .subtract(18, "years")
          .toDate(),
      },
      
      dataImpostata: moment()
        .subtract(18, "years")
        .toDate(),
      errori_validazione: new Errors(),

      modale: false,
      campo_mostra_password: "password",
      campo_mostra_password2: "password",

      tipo_cliente: 0,
      professione: "",

      name: null,
      last_name: null,
      email: null,
      data_di_nascita: null,
      privacy: 0,
      comunicazioni_commerciali: 0,
      password: null,
      conferma_password: null,
    };
  },

  methods: {
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },

    verificaEsistenzaEmail: function() {
      axios
        .post("/agency/check_if_email_exists", {
          email: this.email,
        })
        .then((res) => {
          if (res.data == "SI") {
            this.$swal({
              text: this.str_indirizzo_email_gia_registrato,
            });
            this.email = null;
            this.$refs.email.focus();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    apriGeneratorePassword: function() {
      this.modale = true;
    },

    mostraPassword: function(campo) {
      if (campo == "password") {
        if (this.$refs.password.getAttribute("type") == "password") {
          this.$refs.password.setAttribute("type", "text");
          this.campo_mostra_password = "text";
        } else {
          this.$refs.password.setAttribute("type", "password");
          this.campo_mostra_password = "password";
        }
      } else {
        if (this.$refs.conferma_password.getAttribute("type") == "password") {
          this.$refs.conferma_password.setAttribute("type", "text");
          this.campo_mostra_password2 = "text";
        } else {
          this.$refs.conferma_password.setAttribute("type", "password");
          this.campo_mostra_password2 = "password";
        }
      }
    },

    salvaCliente: function() {
      if (this.$v.$invalid) {
        this.bloccato = true;
      } else {
        this.bloccato = false;
      }

      if (this.bloccato == false) {
        axios
          .post("/customer/store", {
            name: this.name,
            last_name: this.last_name,
            email: this.email,
            privacy: this.privacy ? 1 : 0,
            comunicazioni_commerciali: this.comunicazioni_commerciali ? 1 : 0,
            password: this.password,
            password_confirmation: this.conferma_password,
            data_di_nascita: moment(this.data_di_nascita).format("YYYY-MM-DD"),
            privato_business: this.tipo_cliente,
            professione: this.professione,
          })
          .then((res) => {
            this.$swal({
              icon: "success",
              text: translate(
                "vue.str_grazie_per_esserti_registrato"
              ),
              confirmButtonText: `OK`,
            }).then((result) => {
              if (result.isConfirmed) {
                location.href = "/";
              }
            });
          })
          .catch((e) => {
            console.log(e);
            this.errori_validazione.record(e.response.data);
          });
      }
    },
  },

  validations: {
    name: {
      required,
    },

    last_name: {
      required,
    },

    email: {
      required,
      email,
    },

    data_di_nascita: {
      required,
    },

    privacy: {
      sameAs: sameAs(() => true),
    },

    professione: {
      required: requiredIf(function() {
        return this.tipo_cliente == 1;
      }),
    },

    password: {
      required,
      minLength: minLength(8),
    },

    conferma_password: {
      sameAsPassword: sameAs("password"),
    },
  },
};

class Errors {
  constructor() {
    this.errors = {};
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  record(errors) {
    this.errors = errors;
  }
}
</script>
