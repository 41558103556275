<template>
  <div>
    <h2>{{ str_answer }}</h2>

    <div class="form-group">
      <label>{{ str_messaggio }}</label>
      <textarea
        cols="30"
        rows="10"
        v-model="message"
        class="form-control"
      ></textarea>
    </div>

    <div class="row mt-3" v-if="msg_invio_in_corso">
      <div class="col px-1 py-1">
        <div class="card bg-warning">
          <div class="card-body">
            {{ str_invio_in_corso }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group text-right">
      <button
        class="btn btn-primary"
        v-if="checkRequiredFields"
        @click="sendMessage"
      >
        {{ str_send }}
      </button>
      <button
        class="btn btn-primary"
        v-if="!checkRequiredFields"
        disabled="disabled"
      >
        {{ str_send }}
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";

const default_layout = "default";

export default {
  props: {
    prop_email_id: String,
    prop_subject: String,
    prop_first_name: String,
    prop_last_name: String,
    prop_email: String,
  },
  computed: {
    checkRequiredFields: function() {
      if (this.message != "" && this.msg_invio_in_corso == false) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      str_answer: translate("vue.Rispondi"),
      str_messaggio: translate("vue.Messaggio"),
      str_send: translate("vue.Invia"),
      str_response_message: translate(
        "vue.str_thanks_contatti"
      ),
      str_invio_in_corso: translate("vue.Invio messaggio in corso"),

      email_id: this.prop_email_id,
      subject: this.prop_subject,
      first_name: this.prop_first_name,
      last_name: this.prop_last_name,
      email: this.prop_email,
      message: "",
      msg_invio_in_corso: false,
    };
  },
  methods: {
    sendMessage: function() {
      this.msg_invio_in_corso = true;
      const formData = new FormData();
      formData.append("email_id", this.email_id);
      formData.append("subject", this.subject);
      formData.append("first_name", this.first_name);
      formData.append("last_name", this.last_name);
      formData.append("email", this.email);
      formData.append("message", this.message);

      axios
        .post("/contacts/reply", formData)
        .then((res) => {
          this.msg_invio_in_corso = false;
          this.$swal({
            icon: "success",
            text: this.str_response_message,

            confirmButtonText: `OK`,
          }).then((result) => {
            if (result.isConfirmed) {
              location.href = "/";
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
