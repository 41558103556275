<template>
  <div>
    <!--<b-button v-b-toggle.sidebar-1>Toggle Sidebar</b-button>-->
    <b-sidebar id="sidebar-1" :title="str_recensioni" shadow right width="50%">
      <div class="px-3 py-2 text-left">
        <p v-if="show_no_recensioni">
          {{ str_no_recensioni }}
        </p>

        <div class="row mb-2 mx-2 py-2 bg-white" v-for="(riga, indice) in righe" :key="indice">
          <div class="col-sm-3">
            <p>
             <b-icon icon="person-circle" font-scale="2" class="mr-2"></b-icon>
              {{ str_utente_fidavo }}
              </p>
              <p><small>{{ str_data_recensione }}: {{ formatDate(riga.created_at) }}</small></p>
          </div>
          <div class="col-sm-7">
            <h5>{{ riga.case.product.nome }}</h5>
            <p>{{ riga.review }}</p>
            <p>
              <small>{{ str_data_chiusura_caso }}: {{ formatDate(riga.case.updated_at) }}</small><br>
              <small>{{ str_data_durata_caso_gg }}: {{ calculateDuration(riga.case.created_at, riga.case.updated_at) }}</small>
            </p>
          </div>
          <div class="col-sm-2">            
            <p v-html="convertPointsToStars(riga.points)"></p>            
          </div>
        </div>

        <hr>

        
        
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import Vue from 'vue';
import axios from "axios";
import moment from 'moment';
import EventBus from "./EventBus";
import { BootstrapVue, BButton, BSidebar, BImg, VBToggle, BootstrapVueIcons } from 'bootstrap-vue'

//import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.component('b-button', BButton);
Vue.component('b-sidebar', BSidebar);
Vue.component('b-img', BImg);
Vue.directive("b-toggle", VBToggle);
Vue.use(BootstrapVueIcons);


const default_layout = "default";



export default {
  components: {
      EventBus,
      BootstrapVue,
      BButton,
      BSidebar,
      BImg,
      BootstrapVueIcons,
  }, 
  mounted() {
    EventBus.$on(
      "show_sidebar_agency",
      function(id) {     
        
        this.showPanel(id);


      }.bind(this)
    );
  },
  computed: {},
  data() {
      return {
          str_recensioni: translate("vue.Recensioni"),
          str_no_recensioni: translate("vue.Questa agenzia non ha ancora ricevuto recensioni."),
          str_utente_fidavo: translate("vue.Utente Fidavo"),
          str_data_recensione: translate("vue.Data recensione"),
          str_data_chiusura_caso: translate("vue.Data chiusura caso"),
          str_data_durata_caso_gg: translate("vue.Durata caso giorni"),

          righe: [],
          show_no_recensioni: false,
          
      }
  },
  methods: {
    showPanel: function(id) {
      this.righe = [];
      axios.get("/search/reviews/agency/" + id).then(res => {             
             if (!res.data[0]) {
               this.show_no_recensioni = true;
             } else {
               res.data.forEach(element => {
                 this.righe.push(element);
               });
             }             
           })

      this.$root.$emit('bv::toggle::collapse', 'sidebar-1')
    },

    formatDate: function(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    calculateDuration: function(start_date, end_date) {
      let ts_start_date = parseInt(moment(start_date).format('x'));
      let ts_end_date = parseInt(moment(end_date).format('x'));
      let diff = Math.ceil((ts_end_date - ts_start_date) / 86400000);
      return diff;
    },

    convertPointsToStars: function(points) {
      let stars = [];
      
      const piena = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>
      `;

      const vuota = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
</svg>
      `;

      stars[1] = piena + vuota + vuota + vuota + vuota;
      stars[2] = piena + piena + vuota + vuota + vuota;
      stars[3] = piena + piena + piena + vuota + vuota;
      stars[4] = piena + piena + piena + piena + vuota;
      stars[5] = piena + piena + piena + piena + piena;

      return stars[points];
    },
  }
};
</script>
