<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <label class="form__label"
              >{{ str_nome }} <span class="text-danger">*</span></label
            >

            <div class="form-group">
              <b-form-input
                v-model.trim="$v.name.$model"
                class="form-control border-blue"
                tabindex="1"
                :state="name_state"
                v-on:keyup="changeNameState"
                v-on:blur="changeNameState"
                autocomplete="off"
                role="presentation"
              ></b-form-input>

              <div class="error" v-if="!$v.name.required && bloccato == true">
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                >{{ str_cognome }} <span class="text-danger">*</span></label
              >

              <b-form-input
                v-model.trim="$v.last_name.$model"
                class="form-control border-blue"
                tabindex="2"
                :state="last_name_state"
                v-on:keyup="changeLastNameState"
                v-on:blur="changeLastNameState"
                autocomplete="off"
                role="presentation"
              ></b-form-input>
              <div
                class="error"
                v-if="!$v.last_name.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>{{ str_alias }} <span class="text-danger">*</span></label>

              <b-form-input
                v-model.trim="$v.alias.$model"
                class="form-control border-blue"
                tabindex="3"
                :state="alias_state"
                v-on:keyup="changeAliasState"
                v-on:blur="changeAliasState"
                v-on:input="changeAliasState"
                autocomplete="off"
                role="presentation"
              ></b-form-input>

              <small>{{ str_testo_spiegazioni }}</small>
              <div class="error" v-if="!$v.alias.required && bloccato == true">
                {{ str_campo_obbligatorio }}
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label
                >{{ str_data_di_nascita }}
                <span class="text-danger">*</span></label
              >
<!--
              <b-form-datepicker
                :state="data_nascita_state"
                v-on:shown="changeStateDataNascita"
                v-on:hidden="changeStateDataNascita"
                v-model="$v.data_di_nascita.$model"
                class="form-control border-blue"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                :date-disabled-fn="getMaxDate"
                :locale="locale"
                show-decade-nav
                :initial-date="getInitialDate"
                tabindex="4"
                :placeholder="str_data_di_nascita"
                no-flip
                hide-header
              ></b-form-datepicker>
-->

              <b-form-input 
              type="date" v-model="$v.data_di_nascita.$model"
              :max="getStartCalendar" 
              @input="changeStateDataNascita" 
                class="form-control border-blue" :state="data_nascita_state"></b-form-input> 


              <div
                class="error"
                v-if="!$v.data_di_nascita.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>

              <div
                class="error"
                v-if="err_min_18"
              >
                {{ str_min_18 }}
              </div>

              
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label>{{ str_paese }} <span class="text-danger">*</span></label>
              <b-form-select
                class="border-blue"
                v-model="prefix_tel"
                :options="prefixes"
              ></b-form-select>
            </div>
            
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label
                >{{ str_telefono }} ({{ str_senza_spazi }})<span
                  class="text-danger"
                  >*</span
                ></label
              >

              <b-form-input
                type="tel"
                v-model="$v.telefono.$model"
                autocomplete="off"
                class="form-control"
                pattern="[\+]?\d{5,16}"
                tabindex="5"
                placeholder="12345678"
                :state="telefono_state"
                v-on:keyup="changeTelefonoState"
                v-on:blur="changeTelefonoState"
              ></b-form-input>

              <div
                class="error"
                v-if="!$v.telefono.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>
              <div
                class="error"
                v-if="!$v.telefono.validPhone && bloccato == true"
              >
                {{ str_formato_telefono_non_valido }}
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>{{ str_email }}<span class="text-danger">*</span></label>              
              <b-form-input                
                autocomplete="off"                
                v-model.trim="$v.email.$model"
                :state="email_state"
                v-on:keyup="changeStateEmail"
                v-on:blur="changeStateEmail"
                v-on:input="changeStateEmail"
                class="form-control"
                ref="email"
                tabindex="6"
                role="presentation"                
              ></b-form-input>

              <div class="error" v-if="!$v.email.required && bloccato == true">
                {{ str_campo_obbligatorio }}
              </div>
              <div class="error" v-if="!$v.email.email && bloccato == true">
                {{ str_email_non_valida }}
              </div>
            </div>
          </div>
        </div>

        
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                >{{ str_password }} <span class="text-danger">*</span></label
              >
              <password-analyzer @writepassword="assignPassword" />

              <div
                class="error"
                v-if="!$v.password.required && bloccato == true"
              >
                {{ str_campo_obbligatorio }}
              </div>

              <div
                class="error"
                v-if="!$v.password.minLength && bloccato == true"
              >
                {{ str_password_lunghezza }}
              </div>

              <div
                  class="error"
                  v-if="!$v.password.validPassword && bloccato == true"
              >
                <p>{{ str_password_must_include }}</p>
                <ul>
                  <li> {{ str_longer_than_seven }}</li>
                  <li>{{ str_at_least_a_capital }}</li>
                  <li>{{ str_at_least_a_lowercase }}</li>
                  <li> {{ str_at_least_a_number }}</li>
                  <li>{{ str_at_least_a_special_char }}</li>
                </ul>
              </div>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                >{{ str_conferma_password }}
                <span class="text-danger">*</span></label
              >
              <div class="input-group">
                <input
                  :type="getTipoCampoPasswordConferma"
                  ref="conferma_password"
                  v-on:keyup="changeStatePasswordConfirmation"
                  v-on:blur="changeStatePasswordConfirmation"
                  v-model="$v.conferma_password.$model"
                  tabindex="11"
                  :placeholder="str_conferma_password"
                  :class="password_confirmation_classes"
                />

                <div class="input-group-append">
                  <span
                    class="input-group-text cursor-pointer"
                    v-on:click="mostraPassword('password_confirmation')"
                  >
                    <i class="fas fa-eye"></i>
                  </span>
                </div>
              </div>

              <div
                class="error"
                v-if="!$v.conferma_password.sameAsPassword && bloccato == true"
              >
                {{ str_password_diversa }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-check align-middle">
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                id="privacy"
                v-model="$v.privacy.$model"
                tabindex="8"
                :class="status($v.privacy)"
              />
              <label class="form-check-label" for="privacy">
                {{ str_accettazione }}
                <a href="/terms-and-conditions-agent" target="_blank"><u>{{ str_condizioni }}</u></a> {{ str_and }}
                <a href="/privacy" target="_blank"><u>Privacy policy</u></a>
                <span class="text-danger">*</span>
                <div
                  class="error"
                  v-if="!$v.privacy.sameAs && bloccato == true"
                >
                  {{ str_campo_obbligatorio }}
                </div>
              </label>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-check align-middle">
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                id="comm"
                v-model="comunicazioni_commerciali"
                tabindex="9"
              />
              <label class="form-check-label" for="comm">
                {{ str_comunicazioni_commerciali }}
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-check align-middle">
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                id="contratto"
                v-model="$v.contratto.$model"
                tabindex="8"
                :class="status($v.contratto)"
              />
              <label class="form-check-label" for="contratto">
                {{ str_accettazione_contratto_parte_1 }}
                <a href="/terms-and-conditions-agent" target="_blank"><u>{{ str_condizioni }}</u></a> {{ str_accettazione_contratto_parte_2 }}
                <span class="text-danger">*</span>
                <div
                  class="error"
                  v-if="!$v.contratto.sameAs && bloccato == true"
                >
                  {{ str_campo_obbligatorio }}
                </div>
              </label>
            </div>
          </div>
        </div>

        <div class="row" v-if="saving">
          <div class="col text-center">
            <b-spinner label="Spinning"></b-spinner>
          </div>
        </div>

        <div class="row" v-if="!saving">
          <div class="col-md-12 text-right">
            <input type="hidden" ref="nascosto" />
            <button class="btn btn-secondary" v-if="!verificaCompleti" disabled>
              {{ str_invia }}
            </button>
            <button
              class="btn btn-secondary"
              v-on:click="salvaCliente"
              v-if="verificaCompleti"
            >
              {{ str_invia }}
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <span class="text-danger">*</span> {{ str_campi_obbligatori }}
          </div>
        </div>
      </div>

      <div
        class="alert alert-danger"
        v-for="(err, indice) in errori_validazione.errors.errors"
        :key="indice"
      >
        {{ err[0] }}
      </div>
    </div>
    <!--<generatore-password v-if="modale"></generatore-password>-->
  </div>
</template>
<style scoped>
.dirty {
  border-color: #5a5;
  background: #efe;
}

.dirty:focus {
  outline-color: #8e8;
}

.error {
  border: 1px solid red;
  background: #fdd;
  padding: 5px;
}

.error:focus {
  outline-color: #f99;
}

/*input[type='file'] {
  color: #fff;  
}*/
</style>
<script>
import Vue from "vue";
import axios from "axios";
import EventBus from "../new-search/EventBus";
import Datepicker from "vuejs-datepicker";
import { en, it } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import {
  required,
  email,
  sameAs,
  requiredIf,
  helpers,
  minLength,
} from "vuelidate/lib/validators";

//import GeneratorePassword from "./GeneratorePassword";
//import "./registrazione-agente.css";

import PasswordAnalyzer from "../includes/PasswordAnalyzer.vue";

const validPhone = helpers.regex("validPhone", /^[\+]?\d{5,16}$/);

import {
  BootstrapVue,
  IconsPlugin,
  BFormInput,
  BFormCheckbox,
  BFormFile,
  BFormDatepicker,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";

const default_layout = "default";

export default {
  props: {
    privacy_url_param: String,
    register_url_param: String,
    prefixes_param: String,
    locale_param: String,
  },
  components: {
    Datepicker,
    /*GeneratorePassword,*/
    EventBus,
    PasswordAnalyzer,
    BFormDatepicker,
    BFormInput,
    BFormSelect,
    BSpinner,
  },
  mounted() {
    setTimeout(() => {
      EventBus.$emit("set_register_url", this.register_url);
    }, 100);

    window.addEventListener("keyup", (ev) => {
      if (ev.key == "Enter") {
        if (this.verificaCompleti) {
          this.salvaCliente();
        }
      }
    });
  },
  computed: {
    verificaCompleti: function() {
      if (
        this.name != null &&
        this.last_name != null &&
        this.alias != null &&
        this.telefono != null &&
        this.email != null &&
        this.data_di_nascita != null &&
        this.privacy != 0 &&
        this.contratto != 0 &&
        this.password != null &&
        this.conferma_password != null &&
        this.conferma_password == this.password
      ) {
        return true;
      } else {
        //return false;
        return true;
      }
    },

    getInitialDate: function() {
      return moment()
        .subtract(18, "years")
        .toDate();
    },

    getTipoCampoPasswordConferma: function() {
      return this.tipo_campo_password_conferma;
    },

    getStartCalendar: function() {     
      return moment().subtract(18, "years").format('YYYY-MM-DD');
    },
  },
  data() {
    return {
      str_campi_obbligatori: translate(
        "fe_vue_registrazione_agente.Campi obbligatori"
      ),
      str_nome: translate("fe_vue_registrazione_agente.Nome"),
      str_cognome: translate("fe_vue_registrazione_agente.Cognome"),
      str_alias: translate("fe_vue_registrazione_agente.Alias"),
      str_email: translate("fe_vue_registrazione_agente.Email"),
      str_telefono: translate("fe_vue_registrazione_agente.Cellulare"),

      /*str_accettazione: translate("fe_vue_registrazione_agente.Accettazione"),*/
      str_accettazione: translate("fe_vue_registrazione_agente.Dichiaro di accettare le presenti"),
      str_condizioni: translate("fe_vue_registrazione_agente.Condizioni generali"),
      str_and: translate("fe_vue_registrazione_agente.str_and"),
      
      str_accettazione_contratto_parte_1: translate("fe_vue_registrazione_agente.Dichiaro di aver attentamente letto, compreso e condiviso espressamente le seguenti clausole delle"),
      str_accettazione_contratto_parte_2: translate("fe_vue_registrazione_agente.str_articoli"),

      str_comunicazioni_commerciali: translate(
        "fe_vue_registrazione_agente.Accettazione invio comunicazioni commerciali"
      ),
      str_password: translate("fe_vue_registrazione_agente.Password"),
      str_conferma_password: translate(
        "fe_vue_registrazione_agente.Conferma Password"
      ),
      str_data_di_nascita: translate(
        "fe_vue_registrazione_agente.Data di nascita"
      ),

      str_invia: translate("fe_vue_registrazione_agente.Invia"),
      str_campo_obbligatorio: translate(
        "fe_vue_registrazione_agente.Campo obbligatorio"
      ),
      str_email_non_valida: translate(
        "fe_vue_registrazione_agente.Email non valida"
      ),
      str_password_diversa: translate(
        "fe_vue_registrazione_agente.Le 2 password devono corrispondere"
      ),
      str_password_lunghezza: translate(
        "fe_vue_registrazione_agente.La password deve essere almeno di 8 caratteri"
      ),
      str_formato_telefono_non_valido: translate(
        "fe_vue_registrazione_agente.telefono_non_valido_esteso"
      ),
      str_indirizzo_gia_esistente: translate(
        "fe_vue_registrazione_agente.Questo indirizzo email risulta già registrato"
      ),
      str_alias_gia_esistente: translate(
        "fe_vue_registrazione_agente.Questo alias risulta già registrato"
      ),
      str_testo_spiegazioni: translate(
        "fe_vue_registrazione_agente.spiegazione_uso_alias"
      ),
      str_senza_spazi: translate("fe_vue_registrazione_agente.Senza spazi"),
      str_paese: translate("fe_vue_registrazione_agente.Paese"),
      str_min_18: translate("fe_vue_registrazione_agente.Minimo 18 anni"),

      str_password_must_include: translate("vue.La password deve includere"),
      str_longer_than_seven: translate("vue.Più di 7 caratteri"),
      str_at_least_a_capital: translate("vue.Almeno una lettera maiuscola"),
      str_at_least_a_lowercase: translate("vue.Almeno una lettera minuscola"),
      str_at_least_a_number: translate("vue.Almeno un numero"),
      str_at_least_a_special_char: translate("vue.Almeno un carattere speciale"),

      privacy_url: this.privacy_url_param,
      register_url: this.register_url_param,
      prefixes: JSON.parse(this.prefixes_param),

      bloccato: false,
      saving: false,
      it: it,
      en: en,

      disabledDates: {
        /*from: this.getData18AnniFa()*/
        from: moment()
          .subtract(18, "years")
          .toDate(),
      },
      openDate: moment()
        .subtract(18, "years")
        .toDate(),
      locale: this.locale_param,

      dataImpostata: moment()
        .subtract(18, "years")
        .toDate(),
      errori_validazione: new Errors(),

      modale: false,
      campo_mostra_password: "password",
      campo_mostra_password2: "password",

      name: null,
      last_name: null,
      alias: null,
      telefono: null,
      email: null,
      data_di_nascita: null,
      privacy: 0,
      contratto: 0,
      comunicazioni_commerciali: 0,
      password: null,
      conferma_password: null,
      prefix_tel: 353,

      name_state: null,
      last_name_state: null,
      alias_state: null,
      telefono_state: null,
      email_state: null,
      data_nascita_state: null,

      password_confirmation_classes: "form-control",

      tipo_campo_password_conferma: "text",
      err_min_18: false,
    };
  },

  methods: {
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },

    verificaEsistenzaEmail: function() {
      axios
        .post("/agency/check_if_email_exists", {
          email: this.email,
        })
        .then((res) => {
          if (res.data == "SI") {
            this.$swal({
              text: this.str_indirizzo_gia_esistente,
              confirmButtonColor: "#001f5c",
            }).then((result) => {
              if (result.isConfirmed) {
                this.email_state = false;
              }
            });
            this.email = null;
            this.$refs.email.focus();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    verificaEsistenzaAlias: function() {
      axios
        .post("/agent/check_alias_if_exists", {
          alias: this.alias,
        })
        .then((res) => {
          if (res.data > 0) {
            this.$swal({
              text: this.str_alias_gia_esistente,
              confirmButtonColor: "#001f5c",
            }).then((result) => {
              if (result.isConfirmed) {
                this.alias_state = false;
              }
            });
            this.alias = null;
            this.$refs.alias.focus();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    apriGeneratorePassword: function() {
      this.modale = true;
    },

    mostraPassword: function(campo) {
      if (campo == "password") {
        if (this.$refs.password.getAttribute("type") == "password") {
          this.$refs.password.setAttribute("type", "text");
          this.campo_mostra_password = "text";
        } else {
          this.$refs.password.setAttribute("type", "password");
          this.campo_mostra_password = "password";
        }
      } else {
        if (this.$refs.conferma_password.getAttribute("type") == "password") {
          this.$refs.conferma_password.setAttribute("type", "text");
          this.campo_mostra_password2 = "text";
        } else {
          this.$refs.conferma_password.setAttribute("type", "password");
          this.campo_mostra_password2 = "password";
        }
      }
    },

    validDateOfBirth: function () {
      const date_of_birth = moment(this.data_di_nascita).toDate()
      const eighteen_years_ago = moment().subtract(18, "years").toDate()
      if (date_of_birth > eighteen_years_ago) {
        this.err_min_18 = true;
        return false;
      } else {
        this.err_min_18 = false;
        return true;
      }
    },


    openCalendarBirth: function() {
      this.$refs.data_di_nascita.showCalendar();
    },

    salvaCliente: function() {
      if (this.$v.$invalid) {
        this.bloccato = true;
      } else {
        this.bloccato = false;
      }

      if (!this.validDateOfBirth()) {
        return;
      }

      if (this.bloccato == false) {
        this.saving = true;

        axios
          .post("/agent/store", {
            name: this.name,
            last_name: this.last_name,
            alias: this.alias,
            email: this.email,
            telefono: this.telefono,
            prefix_tel: this.prefix_tel,
            privacy: this.privacy ? 1 : 0,
            fidavo_contract: this.contratto ? 1 : 0,
            comunicazioni_commerciali: this.comunicazioni_commerciali ? 1 : 0,
            password: this.password,
            password_confirmation: this.conferma_password,
            data_di_nascita: moment(this.data_di_nascita).format("YYYY-MM-DD"),
          })
          .then((res) => {
            this.saving = false;
            if (res.data == "OK") {
              this.$swal({
                icon: "success",
                text: translate(
                  "fe_vue_registrazione_agente.str_grazie_per_esserti_registrato"
                ),

                confirmButtonText: `OK`,
              }).then((result) => {
                if (result.isConfirmed) {
                  location.href = "/";
                }
              });
            } else {
              console.log(res);
            }
          })
          .catch((e) => {
            console.log(e);
            this.errori_validazione.record(e.response.data);
          });
      }
    },

    assignPassword: function(value) {
      this.password = value;
    },

    getMaxDate: function(ymd, date) {
      return (
        date >
        moment()
          .subtract(18, "years")
          .toDate()
      );
    },

    trasformaInTypePassword: function()
    {
      this.tipo_campo_password_conferma = "password";          
    },

    changeStatePasswordConfirmation: function() {
      this.trasformaInTypePassword();

      if (this.conferma_password != this.password) {
        this.password_confirmation_classes = "form-control is-invalid";
      } else {
        this.password_confirmation_classes = "form-control is-valid";
      }
    },

    changeNameState: function() {
      if (this.name == null || this.name == "") {
        this.name_state = false;
      } else {
        this.name_state = true;
      }
    },

    changeLastNameState: function() {
      if (this.last_name == null || this.last_name == "") {
        this.last_name_state = false;
      } else {
        this.last_name_state = true;
      }
    },

    changeAliasState: function() {
      if (this.alias == null || this.alias == "") {
        this.alias_state = false;
      } else {
        this.alias_state = true;
        this.verificaEsistenzaAlias();
      }
    },

    changeStateDataNascita: function() {
      const date_of_birth = moment(this.data_di_nascita).toDate()
      const eighteen_years_ago = moment().subtract(18, "years").toDate()

      if (this.data_di_nascita == null || this.data_di_nascita == "") {
        this.data_nascita_state = false;
      } else if (date_of_birth > eighteen_years_ago) {
        this.data_nascita_state = false;
      } else {
        this.err_min_18 = false;
        this.data_nascita_state = true;
      }
    },

    changeTelefonoState: function() {
      let cellulare = this.telefono;
      const reg = /^[\+]?\d{5,16}$/;
      if (!reg.test(cellulare)) {
        this.telefono_state = false;
      } else {
        this.telefono_state = true;
      }
    },

    changeStateEmail: function() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(this.email)) {
        this.email_state = false;
      } else {
        this.email_state = true;
        this.verificaEsistenzaEmail();
      }
    },
  },

  validations: {
    name: {
      required,
    },

    last_name: {
      required,
    },

    alias: {
      required,
    },

    email: {
      required,
      email,
    },

    telefono: {
      required,
      validPhone,
    },

    data_di_nascita: {
      required,
    },

    privacy: {
      sameAs: sameAs(() => true),
    },

    contratto: {
      sameAs: sameAs(() => true),
    },

    password: {
      required,
      minLength: minLength(8),
      validPassword: function(value) {
        const containsUppercase = /[A-Z]/.test(value)
        const containsLowercase = /[a-z]/.test(value)
        const containsNumber = /[0-9]/.test(value)
        const containsSpecial = /[#?!@$%^&*-_]/.test(value)
        return containsUppercase && containsLowercase && containsNumber && containsSpecial
      }
    },

    conferma_password: {
      sameAsPassword: sameAs("password"),
    },
  },
};

class Errors {
  constructor() {
    this.errors = {};
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  record(errors) {
    this.errors = errors;
  }
}
</script>
